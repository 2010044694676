import { Component, OnInit } from '@angular/core';
import {MessageService} from 'primeng/api';

export interface socialTool {
  id?:string;
  tool?: string;
  username?: any;
}

interface social {
  name: string
}

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  providers: [MessageService],
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {

  socialDialog: boolean;
  socialNames: social[];
  selectedSocial: social;
  submitted: boolean;
  socialTool: socialTool;

  socialTools: socialTool[] = [
    {
      id: '1',
      tool: 'Instagram',
      username: '@namesurname'
    },
    {
      id: '2',
      tool: 'Facebook',
      username: '@namesurname'
    },
    {
      id: '3',
      tool: 'Twitter',
      username: '@namesurname'
    }
  ];

  clonedSocialTool: { [s: string]: socialTool; } = {};

  constructor(private messageService: MessageService) {
    this.socialNames = [
      {name: 'Instagram'},
      {name: 'Facebook'},
      {name: 'Twitter'}
    ];
  }

  ngOnInit(): void {
  }

  onRowEditInit(socialTools: socialTool) {
    this.clonedSocialTool[socialTools.id] = {...socialTools};
  }

  onRowEditSave(socialTools: socialTool) {
    //this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid'});
    //this.messageService.add({severity:'success', summary: 'Success', detail:'Social tool is updated'});
  }

  onRowEditCancel(element: socialTool, index: number) {

    var result = this.socialTools.filter(tool => {
      return tool.id != element.id;
    })
    
    this.socialTools = result;
    
  }

  openNew() {
    this.socialTool = {};
    this.submitted = false;
    this.socialDialog = true;
  }

  hideDialogSocial() {
    this.socialDialog = false;
    this.submitted = false;
  }

  saveSocialTool(selectedSocial:any, userName:any) {
    console.log(selectedSocial);
    this.submitted = true;
    this.socialTool.id = this.createId();
    this.socialTool.tool = selectedSocial.name;
    this.socialTool.username = userName;
    this.socialTools.push(this.socialTool);
    this.socialDialog = false;
}

createId(): string {
  let id = '';
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for ( var i = 0; i < 5; i++ ) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
}

}
