import { Component, OnInit, Injectable } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { AuthAPIService } from '../../services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

import { LandingTemplate } from '../landing.template';
import { BuyerPersonaQuestionsTemplate } from '../buyer.Persona.Questions';
import { TemplateModules } from '../templates/template.modules';
import { ColorSchemaTemplate } from '../colorSchema.template';
import { FontFamilyTemplate } from '../fontFamilies.template';

import {listModules} from './steps-items/listModules';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styles: [],
  animations: [

    // Trigger animation cards array
    trigger('questionAnimation', [
      // Transition from any state to any state
      transition('* => *', [
        // Initially the all cards are not visible
        query(':enter', style({ opacity: 0 }), { optional: true }),

        // Each card will appear sequentially with the delay of 300ms
        query(':enter', stagger('200ms', [
          animate('.5s ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(-10px) scale(1)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ]))]), { optional: true }),

        // Cards will disappear sequentially with the delay of 300ms
        query(':leave', stagger('200ms', [
          animate('500ms ease-out', keyframes([
            style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
            style({ opacity: .5, transform: 'scale(.5)', offset: 0.3 }),
            style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
          ]))]), { optional: true })
      ]),
    ])
  ]
})

@Injectable()
export class StartComponent implements OnInit {
  
  createQuestions: any[];
  loadQuestions = new BuyerPersonaQuestionsTemplate().data; // Cargamos el objeto con las preguntas
  answers: any = [];
  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;
  
  public code: any;
  public currentdisplayType: any;
  public displayType: any;
  public landing_name:any;
  public catalogLandings = [];

  public landingType = 'type-a';
  public currentStep: any = "start";
  public newLandingObject  = new LandingTemplate().data; // Objeto con estructura de la nueva landing
  public responseData: any;
  public index: any;
  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  public landingPostData = {
    method: '',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0,
    landing_familySelection : '',
    landing_buyerSelection: ''
  };


  constructor(
    public authAPIService: AuthAPIService, 
    public user: UserService,
    public route: ActivatedRoute,
    public router: Router,
    private scrollToService: ScrollToService,

    ) { 
      this.userPostData = this.user.getData(); 
    }

  ngOnInit() {
     
    this.createQuestions = this.loadQuestions; // Cargamos las preguntas para pasarlas a la vista
    
  }

  setAnswer(item,group,i,a) {
    
    //Marcamos como activa la respuesta, antes reseteamos las preguntas para quitar el activo
    this.createQuestions[i].options.forEach(element => {
      element.active = false;
    });
    this.createQuestions[i].options[a].active = true;

    let _answer = {
      name: group,
      options: [item]
    } 

    let _index = this.answers.findIndex(obj => {
      return obj.name === group;
    });

    //Check si hemos almacenado la respuesta del bloque antes para los casos en los que el usuario use el botón "anterior"
    if(_index != -1) {
      //ya teniamos respuesta para este bloque, buscamos su index para actualizar con el nuevo valor (puede ser el mismo pero no lo cmporamos)
      this.answers[_index] = _answer;
    } else {
      this.answers.push(_answer);
    }

    //Check para ver si es el último paso, en se caso lanzamos el proceso de crear landing y llevamos al usuario al editor
    if( this.createQuestions.length == (i+1)) {
      this.createLanding(this.answers);
    } else {
      //Siguiente paso
      setTimeout(()=>{
        //Unos milisegundos para cambiar el borde del botón
        this.goToStep('question' + (i + 1));
      },300);
    }
      
  }

  goToStep(step) {
    this.currentStep = step;
  }

  goToNextStep(step, index, group) {

    let _index = this.answers.findIndex(obj => {
      return obj.name === group;
    });

    let _answer = {
      name: group,
      options: []
    } 

    //Check si hemos almacenado la respuesta del bloque antes para los casos en los que el usuario use el botón "siguiente"
    if(_index == -1) {
      //No teniamos respuesta para este bloque, buscamos buscamos el valor default para este bloque y la pasamos como respuesta
      let _findDefaultIndex = this.createQuestions[index].options.findIndex(obj => {
        return obj.default === true;
      });
      let _findDefaultAnswer = this.createQuestions[index].options[_findDefaultIndex];
      _answer.options.push(_findDefaultAnswer); 
      this.answers.push(_answer);
      this.createQuestions[index].options[_findDefaultIndex].active = true;
    } 

    //Check para ver si es el último paso, en se caso lanzamos el proceso de crear landing y llevamos al usuario al editor
    if( this.createQuestions.length == (index+1)) {
      this.createLanding(this.answers);
    } else {
      //Siguiente paso
      setTimeout(()=>{
        this.currentStep = step;
      },300);
    }
    
  }

  setModulesToLanding(modules){

    let moduleIndex:any;

    modules.forEach(element => {
        
        moduleIndex = this.newLandingObject.config.landingModules.indexOf(element);

        if( moduleIndex == -1) {
          this.newLandingObject.config.landingModules.push(element);
        }

    });

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Función para la creación de la landing desde la opción Buyer Persona

  createLanding(userAnswers){   

    this.landingPostData.method = 'getComponentModules';
    this.landingPostData.landing_familySelection = this.landingType;
    this.landingPostData.landing_buyerSelection = JSON.stringify(userAnswers);

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      
      this.responseData = result;

      if (this.responseData.code == 200) {

        // Modulos.
        // Desde Base de Datos, obtenemos listado de modulos a usar en el template
        // let modulesSelection = this.responseData.customModules.split(',');
        //let _currentConfigModules = this.setModulesToLanding(modulesSelection);

        // Colores.

        //let _currentConfigColor = this.defaultColorSchemas.schemas[0];
        let _currentConfigColor = this.responseData.customColors;
            
        _currentConfigColor.active = true;
        this.newLandingObject.config.landingColor = _currentConfigColor;
        
        // Fuentes.

        //let _currentConfigFont = this.defaultFontFamily.predefined[0];
        let _currentConfigFont = this.responseData.customType;
        
        _currentConfigFont.active = true;
        this.newLandingObject.config.landingFont = _currentConfigFont;

        // En este paso decidimos que tipo de landing deberiamos utilizar (o dejaremos que el usuario seleccione)
            
        this.newLandingObject.config.landingUse = this.landingType; // Actualizamos el valor del tipo de la landing
            
        //this.newLandingObject.config.landingModules = this.landingModules; // Actualizamos los modulos que usaremos, ojo esto son los nombres, por mantenerlo en el objeto a un nivel 0, no se está utilizando, futurible
        
        let mayUseLandingType = this.landingType;

        // Inyectamos en el objeto landing los modulos que llevará la landing
        // Comentado hasta refactorizar componentes
        // this.newLandingObject.config.landingModules.forEach(element => {
        //   let _moduleName = element;
        //   let componentData = new TemplateModules[mayUseLandingType]['modules'][_moduleName];
        //   this.newLandingObject.modules.push(componentData.structure); // REVISAR FORMATO OBJETO
        // });

        // Creamos la nueva landing

        this.landingPostData.method = 'create';
        this.landingPostData.landing_type = this.landingType;
        this.landingPostData.user_id = this.userPostData.user_id;
        this.landingPostData.landing_content = this.newLandingObject;

        this.authAPIService.postDataLanding(this.landingPostData).then( result => {
          this.responseData = result;
          if (this.responseData.code == 200) {

            if (this.userPostData.user_type === 'guest'){

              this.router.navigate(['tryout', this.responseData.landing_code]);
              
            } else {

              this.router.navigate(['editor', this.responseData.landing_code]);
            
            }
        
          } else {

            // Error en el proceso de creación
            this.user.sessionOut(); 
          }
        },
        err => {
            console.error('Error');
        });

      } else {

          // Error en la recuperación de los datos
          this.user.sessionOut(); 
      } 

    },
    err => {
        console.log('Error');
    }); 
 
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Función para obtener el catálogo completo de landings públicas

  showCatalogLanding(){   

    this.landingPostData.method = 'getCatalogLandings';

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      
      this.responseData = result;

      if (this.responseData.code == 200) {

        this.catalogLandings = this.responseData.catalogLanding;

        this.currentStep = 'catalog';

      } else {

          // Error en la recuperación de los datos
          this.user.sessionOut(); 
      } 

    },
    err => {
        console.log('Error');
    }); 
 
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Función para la creación de una landing desde el catalogo de landings 

  createLandingFromCatalog(indexLanding){

    let landingSelected = this.catalogLandings[indexLanding];

    // Creamos la nueva landing a partir de la seleccionada

    this.landingPostData.method = 'create';
    this.landingPostData.landing_type = this.landingType;
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_content = JSON.parse(landingSelected.landing_content_to_publish);

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;
      if (this.responseData.code == 200) {

        if (this.userPostData.user_type === 'guest'){

          this.router.navigate(['tryout', this.responseData.landing_code]);
          
        } else {

          this.router.navigate(['editor', this.responseData.landing_code]);
        
        }
    
      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }
    },
    err => {
        console.error('Error');
    }); 

  }

  triggerScrollTo(anchorValue) {

    // https://www.npmjs.com/package/@nicky-lenaers/ngx-scroll-to

    const config: ScrollToConfigOptions = {
      target: anchorValue
    };

    this.scrollToService.scrollTo(config);

  }

}
