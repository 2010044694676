import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingService } from '../../../services/landing.service';

@Component({
  selector: 'app-linked-template',
  templateUrl: './linked-template.component.html',
  styleUrls: ['./linked-template.component.scss']
})
export class LinkedTemplateComponent implements OnInit {

  public showPreview: boolean = false;
  public allLandings = []; // Todas las landings asociadas al usuario. Propias y como colaborador

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    private dataLandingService: LandingService,
  ) { }

  ngOnInit(): void {
  }

  askCodeTemplate(content) {
    this.modalService.open(content, { centered: true });
  }

  /**
   * Redirecciónp para comenzar a crear un nuevo template
   */
   startLanding() {
    // Redirección a la pantalla de Start
    this.router.navigate(['start']);
    // Cerramos la modal
    this.modalService.dismissAll();
  }

  closeModal() {
    // Cerramos la modal
    this.modalService.dismissAll();
  }

}
