<div class="c-form-editor">
  <div class="c-form-editor-body">
    <div class="c-form-editor-cab">Global Color Palette</div>
      <!-- Module Item -->
      <div class="c-module-item" style="border-bottom: none;">
        <div class="w-100">
          <div class="color-palette">
              <div class="color-palette-colors">
                <div class="color-palette-items" (click)="setGlobalColorSchema()">
                    <span class="color-palette-item col"
                    *ngFor="let detail of globalColorSchemas.schema; let a = index"
                    [ngStyle]="{backgroundColor:detail.color}"
                    [pTooltip]="detail.variableName"
                    tooltipPosition="top"
                    appendTo="body"></span>
                </div>
                <div class="color-palette-name text-truncate d-none d-sm-block">Vivid</div>
              </div>
              <!-- <button class="btn btn-icon btn-icon__right" [ngClass]="{'checked btn-secondary':globalColorSchemas.active, 'btn-outline-primary':!globalColorSchemas.active}" (click)="setGlobalColorSchema()">
                <ng-container *ngIf="globalColorSchemas.active; else elseBlock">Active <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-check-fill"/></svg></ng-container>
                <ng-template #elseBlock>Use <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></ng-template>
              </button> -->
          </div>
        </div>
      </div>
  </div>
</div>
<div class="c-form-editor">
  <div class="c-form-editor-body">
    <div class="c-form-editor-cab">Custom Palette</div>
      <!-- Module Item -->
      <div class="c-module-item">
            <div class="w-100">
              <div class="color-palette">
                <div class="d-flex align-items-center w-100">
                  <div class="color-palette-items">
                    <div class="color-palette-item d-flex align-items-center justify-content-center col"
                      *ngFor="let detail of data.schema; let a = index"
                      [ngStyle]="{backgroundColor:detail.color}"
                      [cpPosition]="'bottom'"
                      [cpPositionOffset]="'50%'"
                      [cpOutputFormat]="'hex'"
                      [cpPositionRelativeToArrow]="true"
                      [(colorPicker)]="detail.color"
                      (colorPickerChange)="setCustomColorValue(0,a,$event)"
                      [pTooltip]="detail.variableName"
                      tooltipPosition="top"
                      appendTo="body">
                      <button class="btn btn-plus btn-xs">
                        <svg class="icon ic-white ic-14"><use href="assets/icons/icons-sprite.svg#svg-edit"/></svg>
                      </button>
                    </div>
                  </div>
                </div>
                <button class="btn btn-icon btn-icon__right" [ngClass]="{'checked btn-secondary':data.active, 'btn-outline-primary':!data.active}"  *ngIf="data.type == 'custom'" (click)="setCustomColorSchema()">
                  <ng-container *ngIf="data.active; else elseBlock" class="ic-check">Active <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-check-fill"/></svg></ng-container>
                  <ng-template #elseBlock>Use <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></ng-template>
                </button>
              </div>
            </div>
      </div>
      <!-- / Module Item -->
  </div>
</div>
