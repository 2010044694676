import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { LandingService } from 'src/services/landing.service';
import { EditorCommonsOptions } from 'src/app/editor.commons.options';

@Component({
  selector: 'app-pexel-images',
  templateUrl: './pexel-images.component.html',
  styleUrls: ['./pexel-images.component.scss']
})
export class PexelImagesComponent implements OnInit {

  @Input() data:any;
  @Output() closeModalDialog = new EventEmitter<boolean>();
  items:any
  pexelImageSearch:string = '';
  dataLoaded:boolean = false;
  actualPage: number;

  constructor(
    private landingService: LandingService
  ) { 
    this.actualPage = 1;
   }

  updateMedia(item) {

    // Actualizamos el objeto, hemos podido modificarlo y necesitamos asegurar el tipo de dato que vamos a almacenar
    this.data.properties = new EditorCommonsOptions().schema.media.properties;

    this.data.properties.type.code = 'image';
    this.data.properties.src = item.src.large2x;
    this.data.properties.name = item.alt;
    this.data.properties.data.color = item.avg_color;

    // Cerramos modal
    this.closeModalDialog.emit(false);

  }

  search(){
    this.dataLoaded = false;
    this.items = [];
    this.landingService.getPexelImages(this.pexelImageSearch, 1).subscribe((response) => {
      this.dataLoaded = true;
      this.items = response.photos;
    })
  } 

  onScroll() {
    this.dataLoaded = false;
    this.landingService.getPexelImages(this.pexelImageSearch, this.actualPage++).subscribe((response) => {
      this.dataLoaded = true;
      response.photos.forEach((element)=>{
        this.items.push(element);
      });
    })
  }

  ngOnInit(): void {
    this.landingService.getPexelImages(this.pexelImageSearch, 1).subscribe((response) => {
      this.dataLoaded = true;
      this.items = response.photos;
    })

  }

}
