<main class="db">
  <!-- Alert -->
  <!--<app-alert></app-alert>-->
  <!-- / Alert -->

  <!-- Header -->
  <app-header></app-header>
  <!-- / Header -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                        Usuario validado                                    ###################-->
  <!-- #################################################################################################################################### -->

  <!-- Dashboard Menu -->
  <div class="db-sidebar">
    <div class="db-sidebar-menu">
      <div class="db-sidebar-wrap">
        <app-menu></app-menu>
        
        <!-- <button class="main-button" (click)='startLanding()'>
            <span class="main-button-title">Create a new landing</span>
            <span class="main-button-ico"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-add-circle"></use></svg></span>
          </button> -->
        <!-- <div class="dashboard-menu-lead" [ngClass]="{'active': isSelectedMenu == 'home'}">
          <svg class="icon"><use href="assets/icons/icons-sprite.svg#template-2"/></svg> -->
          <!--<span class="dashboard-menu-lead-title" (click)="getLandings()">Home</span>-->
          <!-- <span class="dashboard-menu-lead-title">Templates</span>
        </div> -->
        <!-- <div class="dashboard-menu-lead" [ngClass]="{'active': isSelectedMenu == 'screens'}">
          <svg class="icon"><use href="assets/icons/icons-sprite.svg#desktop"/></svg>
          <span class="dashboard-menu-lead-title">Screens</span>
        </div> -->
        <!-- <div class="dashboard-menu-lead transparent">
          <span class="dashboard-menu-lead-ico"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-workspace"></use></svg></span>
          <span class="dashboard-menu-lead-title">Your Workspaces</span>
          <button class="dashboard-menu-lead-btn" (click)="showModalNewWorkSpace(newWorkspaceModal)">Add</button>
        </div> -->
        <!-- <div *ngIf="userWorkSpaces">
          <ul class="dashboard-menu-item list-unstyled">
            <li *ngFor="let item of listWorkspaces" [ngClass]="{'active': isSelectedMenu == item.wsp_code}">
              <a routerLink="/dashboard/{{item.wsp_code}}/workspace">{{item.wsp_name}}</a>
              <button class="warning" (click)="showModalRemoveWorkSpace(removeWorkspaceModal,item.wsp_code,item.wsp_name)">
                <span class="ic-svg ic-danger ic-18"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-trash"></use></svg></span>
              </button>
            </li>
          </ul>
        </div> -->
      </div>
      <div class="db-sidebar-footer">
        <strong>© 2022 inApp.io.</strong> Todos los derechos reservados.
      </div>
    </div>
  </div>
  <div class="db-menu-mobile-footer">
    <div class="c-menu-mobile-footer d-block">
      <div class="row h-100 align-items-center">
        <div class="col d-flex">
          <button type="button" class="btn btn-menu mx-auto" routerLinkActive="active" routerLink="#">
            <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#home"/></svg> 
          </button>
        </div>
        <div class="col d-flex">
          <button type="button" class="btn btn-menu mx-auto" routerLinkActive="active" routerLink="/dashboard/templates">
            <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#template-2"/></svg> 
          </button>
        </div>
        <div class="col d-flex">
          <button type="button" class="btn btn-menu mx-auto" routerLinkActive="active" routerLink="/dashboard/screens">
            <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#desktop"/></svg> 
          </button>    
        </div>
      </div>
    </div>
  </div>
  <!-- / Dashboard Menu -->


    <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- //////////                                         START: Dashboard Panel                                                 ////////// -->
    <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    
    <div *ngIf="userVal" class="db-panel">
      <div class="db-panel-inner">
        <div class="db-panel-wrap">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

  <!-- #################################################################################################################################### -->
  <!-- ######################                                        Fin Usuario validado                                ################## -->
  <!-- #################################################################################################################################### -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                   Usuario pendiente de validación                          ###################-->
  <!-- #################################################################################################################################### -->

  <div *ngIf="!userVal" class="h-100 d-flex flex-column justify-content-center">
    <section class="sec-editor flex-grow-1 log-box h-100 d-flex flex-column justify-content-center">
      <main class="log-box h-100 d-flex flex-column justify-content-center">
        <h1 class="log-box-title">Bienvenido {{userPostData?.name}}.</h1>
        <div class="log-box-custom">
          <p class="log-box-custom-intro" *ngIf="!userVal">Antes de comenzar a crear tus landing pages, por favor revisa tu correo y sigue los pasos que encontrarás en nuestro
            email de confirmación para validar de tu cuenta.</p>
          <!--<div class="log-box-custom-btn">
            <button (click)='logout()' class="btn btn-logout"><span class="fa fa-sign-out"></span>Cerrar sesión</button>
          </div>-->
        </div>
        <div class="log-box-footer">
          ¿No has recibido ningún correo de confirmación? Comprueba que no ha entrado en alguna bandeja distinta a la de entrada, como
          la de notificaciones o la de correo no deseado (<em>spam</em>). Si tampoco lo encuentras allí, no te
          preocupes: te podemos <span><a (click)='resendConfirmationEMail()'>enviar un nuevo correo de confirmación</a></span>          ahora mismo.
        </div>
        <span class="log-header-footer d-md-none"><b>© 2020 BoostUp.</b> Todos los derechos reservados.</span>
      </main>
    </section>
  </div>

  <!-- #################################################################################################################################### -->
  <!-- ######################                               Fin Usuario pendiente de validación                          ###################-->
  <!-- #################################################################################################################################### -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                             Modales                                        ###################-->
  <!-- #################################################################################################################################### -->

  <!-- Modal para crear un espacio de trabajo -->

  <ng-template #newWorkspaceModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Create a new workspace</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <label>Your New Workspace Name</label>
        <input ngbAutofocus [(ngModel)]="workSpaceName" type="text" placeholder="New Workspace">
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='createWorkspace(workSpaceName)'>Add</button>
      </div>
    </div>
  </ng-template>

  <!-- Fin Modal para crear un espacio de trabajo -->

  <!-- Modal para eliminar un espacio de trabajo -->

  <ng-template #removeWorkspaceModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Remove <i>"{{workSpaceName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>This action doesn't remove the landings associated with this workspace</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='removeWorkspace(workSpaceId)'>Remove</button>
      </div>
    </div>
  </ng-template>

  <!-- Fin Modal para eliminar un espacio de trabajo -->

  <!-- Modal para asociar una landing a un espacio de trabajo -->

  <ng-template #asociateWorkspacetoLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Asociate Landing to workspace</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <!--<span aria-hidden="true">&times;</span>-->
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <label>Select Workspace </label>
        <select ngbAutofocus [(ngModel)]="selectedWokspace">
          <option *ngFor="let wsp of listWorkspaces" [value]="wsp.wsp_code">{{wsp.wsp_name}}</option>
        </select>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='associateLandingToWorkspace(landingIdToWorkspace,selectedWokspace)'>Asociate</button>
      </div>
    </div>
  </ng-template>

  <!-- Modal para dejar de seguir una landing -->
  
  <ng-template #unfollowLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Unfollow <i>"{{currentLandingName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>With this action you unfollow the landing. You will need permission from the owner to collaborate again</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='unfollowLanding(currentLandingId)'>Unfollow</button>
      </div>
    </div>
  </ng-template>

  <!-- Fin Modal para dejar de seguir una landing -->

  <!-- Modal de confirmación para borrar una landing -->
  <ng-template #removeLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Delete <i>"{{currentLandingName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want to permanently delete this Landing?</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='removeLanding(currentLandingId)'>Delete</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal de confirmación para borrar una landing -->

  <!-- Modal de confirmación para duplicar una landing -->
  <ng-template #duplicateLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Duplicate <i>"{{currentLandingName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want duplicate this Landing?</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">No</button>
        <button class="focus-button" ngbAutofocus (click)='duplicateLanding(currentLandingId)'>Yes</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal de confirmación para duplicar una landing -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                           Fin Modales                                      ###################-->
  <!-- #################################################################################################################################### -->

</main>