<header class="log-header">
  <div class="log-header-main">
    
    <span class="log-header-title">Cuéntale al mundo lo que sabes hacer</span>
    <span class="log-header-footer"><b>© 2020 BoostUp.</b> Todos los derechos reservados.</span>
  </div>
  <div class="page-main-animation brown" style="top: 25vh; left: 2vw; opacity: .3">
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
  </div>
  <div class="page-main-animation brown" style="bottom: 16.6vh; right: 4vw; opacity: .3">
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
  </div>
</header>


<body class="log-grid">
  <main class="log-box">
    <h1 class="log-box-title">¿Olvidaste tu contraseña?</h1>

    <!-- Custom SignUp -->
    <div class="log-box-custom">
      <p class="log-box-custom-intro">No te preocupes. Introduce el correo electrónico con el que te registraste y te enviaremos a él las instrucciones para recuperar tu contraseña.</p>
      <form class="log-box-custom-form">
        <div class="log-box-custom-form-fieldset input">
          <input type="email" [(ngModel)]="userPostData.email" [ngModelOptions]="{standalone: true}" name="email-field" placeholder="Correo electrónico">
          <span class="field-error" *ngIf="showErrorEmail">{{messageError}}</span>
        </div>
        <div class="log-box-custom-form-fieldset submit">
          <input type="submit" (click)="sendLinkEmailToResetPassword(userPostData.email)" value="Recuperar contraseña">
        </div>
      </form>
    </div>
    <!-- Are you already a member? -->
    <div class="log-box-footer"> 
      <span><a routerLink="/signin">Accede aquí a tu cuenta</a></span> si no necesitas recuperar tu contraseña.
    </div>
  </main>
</body>














<!--

<body class="log-grid">
    <header class="log-header">
      Tell everyone what you're the best at!
    </header>
    <main class="log-main">
      <div class="log-main-box">
        
        <h1 class="login-box-title">¿Olvidaste tu contraseña?</h1>

        <div>
          <p class="login-box-paragraph">No te preocupes. Introduce el correo electrónico con el que te registraste y te enviaremos a él las instrucciones para recuperar tu contraseña.</p>
          <form class="login-form">
            <div class="login-form-field text long">
              <label for="email-field">Email</label>
              <input type="email" [(ngModel)]="userPostData.email" [ngModelOptions]="{standalone: true}" name="email-field">
              <span class="field-error" *ngIf="showErrorEmail">{{messageError}}</span>
            </div>
            <div class="login-form-field button long">
              <input type="submit" (click)="sendLinkEmailToResetPassword(userPostData.email)" value="Recover my password">
            </div>
          </form>
        </div>
      </div>
    </main>

    <footer class="login-footer"> 
      <span><a routerLink="/signin">Log in</a></span> here if you don't need to reset your password.
    </footer>

  </body>

  -->