import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewContainerRef, ComponentFactoryResolver} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthAPIService } from '../../services/auth-api.service';
import { UserService } from '../../services/user.service';
import { LandingService } from '../../services/landing.service';
import { IframeService } from '../../services/iframe.service';

import { TemplateModules } from '../templates/template.modules';
import { LandingTemplate } from '../landing.template';

import { ColorSchemaTemplate } from '../colorSchema.template';
import { FontFamilyTemplate } from '../fontFamilies.template';


@Component({
  selector: 'app-landing-preview',
  templateUrl: './landing-preview.component.html',
  styleUrls: ['./landing-preview.component.scss']
})
export class LandingPreviewComponent implements OnInit {

  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  public responseData: any;
  landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  currentdisplayType:string = "desktop"; // Visualización preview desktop/tablet/mobile
  landingContentUpdate = new LandingTemplate().data;
  compRef: any;
  code: any;

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  defaultColorType = "ligth";

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: ''
  };

  public landingPostData = {
    method: 'getLandingByID',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  constructor(
    private route: ActivatedRoute,
    public authAPIService: AuthAPIService, 
    public user: UserService,
    public router: Router,
    protected sanitizer: DomSanitizer,
    private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private dataLandingService: LandingService,
    private iframeService: IframeService
  ) { 
    this.userPostData = this.user.getData();
  }

  displayType(type:string) {
    this.currentdisplayType = type;
  }

  addContentToIframe(_element:any, _index) {
    let _component = TemplateModules[_element.landingType][_element.moduleType];
    const compFactory = this.resolver.resolveComponentFactory(_component);
    this.compRef = this.vcRef.createComponent(compFactory);
  
    // Combinamos las dos estructuras, la del componente y la de BBDD por si hubiera cambios o mejoras en el componente
    //let newStructure = this.compareJSON(this.compRef.instance.structure, _element);

    //if(newStructure.length) {
    //  _element = newStructure[0];
    //}
    this.landingContentUpdate.modules[_index] = _element;
    this.compRef.instance.structure = _element;
    this.landingView.body.appendChild(this.compRef.location.nativeElement);

  }

  ngOnInit() {

    this.landingView = this.iframe.nativeElement.contentDocument || 
    this.iframe.nativeElement.contentWindow; // Asociamos iframe en el HTML a landingView que usaremos para cargar los diferentes modulos

    this.code = this.route.snapshot.paramMap.get('id');
    // Llamada a servicio para traernos los datos de la landing

    //Pasamos URL al iframe con la landing que vamos a editar
    //this.urlLanding = this.sanitizer.bypassSecurityTrustResourceUrl("/#/landing/"+ this.code);

    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = this.code;

    this.authAPIService.postDataLanding(this.landingPostData).then( respond => {
      this.responseData = respond;

      //this.landingPostData.landing_id = this.responseData.landing[0].landing_id; // Valor necesario para guardar los cambios

      if (this.responseData.code == 200) {
        let _result = JSON.parse(this.responseData.landing[0].landing_content);

        // Incluimos en el iframe que hemos creado vacio los assets necesarios, CSS, SCRIPTS, etc..
        this.iframeService.setCssScriptsToIframe(_result, this.landingView);
        
        // Este servicio DESENCADENA la creación de la landing en el iframe
        // Compartimos la información de la landing mediante un servicio para poder hacer y visualizar los cambios en tiempo real
        setTimeout(()=>{ // Esperamos XXX para que se carge el CSS y no tengamos un flash de la landing - Pensar en una mejor solución
          this.dataLandingService.changeLandingData(_result);
        },0)
    
      } else {

        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }
    },
    err => {
        console.log('Error');
    });


    // Cambio en el objeto landing actualizamos modulos
    this.dataLandingService.landingSource.subscribe((respond)=>{
      
      // Pasamos a la vista solo los modules de la respuesta
      this.landingContentUpdate = respond;
      
      // Borramos el contenido del iframe antes de incluir los modulos, esto es necesario para volver a montar la landing con nuevos modulos y que no los sume a los actuales
      this.landingView.body.innerHTML = '';
      
      // Pasamos a la vista solo los modules de la respuesta
      this.landingContentUpdate = respond;
      
      // Pasamos el objeto Iframe al servicio para luego poder utilizarlo en las diferentes funciones
      this.iframeService.setLandingView(this.landingView);
      
      // Incluimos en el iframe que hemos creado vacio los assets necesarios, CSS, SCRIPTS, etc..
      //this.iframeService.setCssScriptsToIframe(this.landingContentUpdate);

      // Configuramos el schema de color
      this.iframeService.setColorSchemaToIframe(this.landingContentUpdate.config.landingColor, this.landingView);

      // Configuramos el schema de color
      this.iframeService.setFontSchemaToIframe(this.landingContentUpdate.config.landingFont, this.landingView);
      
      // Borramos el contenido del iframe antes de incluir los modulos, esto es necesario para volver a montar la landing con nuevos modulos y que no los sume a los actuales
      this.landingView.body.innerHTML = '';
      // Por cada modulo recibido en la respuesta injectamos su componente en el iframe, esto nos facilita no tener que tener URL en el desarrollo para incluir en el desarrollo
      respond.modules.forEach((element,index) => {
         this.addContentToIframe(element,index); 
      });
      
    });


  }

}
