export class LandingDefaultCommonsValues {
    data =
        {
            "title": [
                "Hydrogen atoms made in the interiors",
                "Join the thousands of innovators",
                "Made in the interiors of collapsing stars",
                "To get started, tell us more about you",
                "Figures in coveralls and helmets are dancing",
                "There's a little good"
            ],
            "subtitle": [
                "The Sky Calls To Us",
                "Beginner Mission",
                "Lunar Mission",
                "Saturn Mission",
                "Dragon Mission"
            ],
            "claim": [
                "The stars made in the interiors of collapsing stars inconspicuous motes of rock and gas courage of our questions.",
                "There's little good evidence two ghostly white figures in coveralls and helmets are softly dancing with pretty stories for which there's little good evidence the only home.",
                "Brain is the seed of intelligence Hypatia encyclopaedia galactica finite but unbounded consciousness billions upon billions?."
            ],
            "shortText": [
                "Hydrogen atoms culture consciousness at the edge of forever Vangelis a billion trillion",
                "Join the thousands of innovators that are already building the universe",
                "Invent the universe the carbon in our apple pies galaxies ship of the imagination are creatures"
            ],
            "largeText": [
                "The stars made in the interiors of collapsing stars inconspicuous motes of rock and gas courage of our questions.",
                "There's little good evidence two ghostly white figures in coveralls and helmets are softly dancing with pretty stories for which there's little good evidence the only home.",
                "Brain is the seed of intelligence Hypatia encyclopaedia galactica finite but unbounded consciousness billions upon billions?."
            ],
            "images": [
                "https://images.unsplash.com/photo-1449247709967-d4461a6a6103?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib",
                "/assets/img/book.png",
                "/assets/img/bg-hero-a.png",
                "/assets/img/mockup-desktop-image.jpg",
                "/assets/img/mockups/mockup-desktop-landing.svg",
                "/assets/img/mockup-mobile-image.jpg",
                "/assets/img/mockups/mockup-mobile-landing.svg",
                "https://images.unsplash.com/photo-1601510006913-1a11abd933c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80",
                "https://images.unsplash.com/photo-1594434533760-02e0f3faaa68?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1436&q=80",
                "/assets/img/bg-cta-01.jpg",
                "/assets/img/bg-form.jpg",
                "/assets/img/card-image-bn.jpg",
                "/assets/img/key-facts-image.png",
                "https://images.unsplash.com/photo-1519944518895-f08a12d6dfd5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
                "https://images.unsplash.com/photo-1511707171634-5f897ff02aa9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80",
                "https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
                "https://images.unsplash.com/photo-1544725121-be3bf52e2dc8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1494&q=80",
                "https://images.unsplash.com/photo-1533022139390-e31c488d69e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80",
                "https://images.unsplash.com/photo-1454997423871-b5215756e54d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80",
                "https://images.unsplash.com/photo-1451187580459-43490279c0fa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1600"
            ],
            "logos":[
                "/assets/img/brands/brand-01.png",
                "/assets/img/brands/brand-02.png",
                "/assets/img/brands/brand-03.png",
                "/assets/img/brands/brand-04.png",
                "/assets/img/brands/brand-05.png",
                "/assets/img/brands/brand-06.png",
                "/assets/img/brands/brand-07.png",
                "/assets/img/brands/brand-08.png",
                "/assets/img/brands/brand-09.png"
            ],
            "videos": [
                "https://www.youtube.com/",
                "https://youtu.be/igipIBaW4Hk"
            ],
            "buttomText": [
                "More Information",
                "Download",
                "See plans",
                "Secondary",
                "Find Out More",
                "Start Trial",
                "Call to action"
            ],
            "legal": [
                "Terms of Service",
                "Privacy Policy",
                "Cookies",
                "Accessibility",
                "Environment",
                "Newsroom",
                "Legal",
                "Site Map",
                "Compliance",
                "About Us",
                "Investors"
            ],
            "buttomLink": [
                "#",
                "#",
                "https://play.google.com/store",
                "https://www.apple.com/app-store/",
            ],
            "icons": [
                "face",
                "ic-check"
            ],
            "badges": [
                "Most "
            ],
            "prices": [
                "$49"
            ],
            "periods": [
                "Monthly",
                "Yearly ($12 Month)"
            ],
            "features": [
                "Limited Slides",
                "Limited Settings",
                "Limited Effects",
                "No Support",
                "No Credit Card needed",
                "20 Exports per Month",
                "Unlimited Projects",
                "Unlimited Pages",
                "Project Management",
                "Support and Updates"
            ]
        }           
}