import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreensService {

  screensObject:any = {};
  public linkedScreens = new BehaviorSubject(this.screensObject);
  
  constructor( private http: HttpClient) { }

  updateScreensLinked(_screens) {
    this.linkedScreens.next(_screens);
    //this.onGetData.emit(message);
  }

  getScreenByTemplate(data) {

    const apiEndPoint = environment.apiEndPoint + 'screen/getscreensbytemplatecode';
    return this.http.post<any>(apiEndPoint, JSON.stringify(data));

  }

  getScreenByUser(data) {

    const apiEndPoint = environment.apiEndPoint + 'screen/getscreensbyuser';
    return this.http.post<any>(apiEndPoint, JSON.stringify(data));

  }

  addTemplateToScreen(data) {

    const apiEndPoint = environment.apiEndPoint + 'screen/addtemplatetoscreen';
    return this.http.post<any>(apiEndPoint, JSON.stringify(data));

  }

}
