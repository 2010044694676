import { Component, OnInit, Input } from '@angular/core';
import {MenuItem, PrimeIcons} from 'primeng/api';

interface Icon {
  name: string,
  code: string
}

@Component({
  selector: 'app-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.scss']
})
export class IconSelectComponent implements OnInit {

  @Input() data: any;
  //_iconList:[];
  iconList:Icon[];
  selectedIcon;

  constructor(
  ) { 
    //console.log(PrimeIcons);
    //console.log(PrimeIcons.AMAZON);
    
    this.iconList = [];
    const _iconList = Object.getOwnPropertyNames(PrimeIcons)
    .filter(prop => typeof PrimeIcons[prop] === "string");
    //console.log(_iconList); // ["one", "four"]

    _iconList.forEach(element => {
      //console.log(element);
      this.iconList.push({name: PrimeIcons[element], code: PrimeIcons[element]})
    });
  }

  ngOnInit(): void {
    console.log(this.data);
  };
    

}
