import { Component, OnInit, AfterViewInit, DoCheck, ElementRef, HostListener, Input } from '@angular/core';

// Importamos las opciones comunues que tiene la landing, en general objetos para acciones, scrollto, et..
import { LandingCommonsOptions } from '../../../landing.commons.options';

// Importamos los diferentes campos comunes para poder reutilizar los que sean necesarios
import { EditorCommonsOptions } from '../../../editor.commons.options';
import { ModuleTemplate } from 'src/app/module.template';
import { ColorSchemaTemplate } from 'src/app/colorSchema.template';

// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';

@Component({
  selector: 'app-module-group-text',
  templateUrl: './module-group-text.component.html',
  styleUrls: ['./module-group-text.component.scss']
})
export class ModuleGroupTextComponent implements OnInit, AfterViewInit {

  // Una vez que el componente ha sido usado le pasamos los datos finales para evitar que vuelva a usar estos datos
  @Input() newData: any;

  // Creamos una instancia de las opciones comunes
  public landingCommonsOptions = new LandingCommonsOptions();
  public schemaColor: any = new ColorSchemaTemplate().data.schemas[0];
  public structure: any = new ModuleTemplate().data;
  public dataLanding: any;

  constructor(
    public element: ElementRef,
    private dataLandingService: LandingService
  ) {

      this.dataLanding = this.structure;
      
      this.dataLanding.moduleName = "Cta";
      this.dataLanding.moduleColor = "bg-label-03";
      this.dataLanding.moduleIcon = "svg-cta";

      this.dataLanding.schema.title = new EditorCommonsOptions().schema.text;
      this.dataLanding.schema.title.properties.data = this.dataLandingService.getCustomValue('title',0);
      this.dataLanding.schema.title.properties.label = "Title";
      this.dataLanding.schema.title.properties.customSize = true;
      this.dataLanding.schema.title.id = "title";

      this.dataLanding.schema.subtitle = new EditorCommonsOptions().schema.text;
      this.dataLanding.schema.subtitle.properties.data = this.dataLandingService.getCustomValue('subtitle', 0);
      this.dataLanding.schema.subtitle.properties.label = "Subtitle";

      this.dataLanding.schema.claim = new EditorCommonsOptions().schema.text;
      this.dataLanding.schema.claim.properties.data = this.dataLandingService.getCustomValue('claim','random');
      this.dataLanding.schema.claim.properties.label = "Claim";

      this.dataLanding.schema.schemaColor = this.schemaColor;
      this.dataLanding.commonsOptions = this.landingCommonsOptions;
      
   }

    @HostListener('mouseenter') onMouseEnter(){
    this.dataLanding.commonsOptions.hoverTo = true;
    this.dataLanding.commonsOptions.scrollTo = false;
    }

    @HostListener('mouseleave') onMouseLeave(){
      this.dataLanding.commonsOptions.hoverTo = false;
    }

    @HostListener('click') onClick(){
      this.dataLanding.commonsOptions.activeMenu = true;
    }

    ngOnInit() {

      if(this.newData.moduleData.schema) {
  
        // Iteramos los elementos que tenemos en el schema almacenado, podemos tener nuevos y no estar almacenados.
        for (const item in this.newData.moduleData.schema) {
          if(this.dataLanding.schema[item]) {
            this.dataLanding.schema[item] = this.newData.moduleData.schema[item];
          }
        }
        
        // Podemos forzar algunas propiedades para escapar lo que tenemos almacenado
        this.dataLanding.schema.title.properties.customSize = true;
  
      }    
  
    }
  
    ngDoCheck() {
      if(this.dataLanding.commonsOptions.activeMenu) {
        this.element.nativeElement.scrollIntoView({behavior:"smooth"});
      }
    }
  
    ngAfterViewInit(): void {
      this.newData.moduleData = this.structure;
      this.dataLanding = this.newData.moduleData;
    }

}
