import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { LandingService } from 'src/services/landing.service';
import { EditorCommonsOptions } from 'src/app/editor.commons.options';

@Component({
  selector: 'app-pexel-videos',
  templateUrl: './pexel-videos.component.html',
  styleUrls: ['./pexel-videos.component.scss']
})
export class PexelVideosComponent implements OnInit {

  @Input() data:any;
  @Output() closeModalDialog = new EventEmitter<boolean>();
  items:any
  pexelImageSearch:any = '';
  currentVideoHover:boolean[] = [];
  dataLoaded:boolean = false;
  actualPage: number;

  constructor(
    private landingService: LandingService
  ) {
    this.actualPage = 1;
   }

  updateMedia(item) {
    // Actualizamos el objeto, hemos podido modificarlo y necesitamos asegurar el tipo de dato que vamos a almacenar
    this.data.properties = new EditorCommonsOptions().schema.media.properties;

    this.data.properties.type.code = 'video/mp4';
    this.data.properties.src = item.video_files[0].link;
    this.data.properties.duration = item.duration;
    this.data.properties.video_image = item.image;

    // Cerramos modal
    this.closeModalDialog.emit(false);

  }

  search(){
    this.items = [];
    this.dataLoaded = false;
    this.landingService.getPexelVideos(this.pexelImageSearch, 1).subscribe((response) => {
      this.dataLoaded = true;
      this.items = response.videos;
    })
  } 

  videoEnter(index){
    this.currentVideoHover[index] = true;
  }

  videoLeave(index){
    this.currentVideoHover[index] = false;
  }

  onScroll() {
    this.dataLoaded = false;
    this.landingService.getPexelVideos(this.pexelImageSearch, this.actualPage++).subscribe((response) => {
      this.dataLoaded = true;
      response.videos.forEach((element)=>{
        this.items.push(element);
      });
    })
  }

  ngOnInit(): void {
    this.landingService.getPexelVideos(this.pexelImageSearch, 1).subscribe((response) => {
      this.dataLoaded = true;
      this.items = response.videos;
    })

  }


}
