import { Injectable, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ColorSchemaTemplate } from '../app/colorSchema.template';
import { FontFamilyTemplate } from '../app/fontFamilies.template';
import { TemplateModules } from '../app/templates/template.modules';

@Injectable({
  providedIn: 'root'
})

export class IframeService {

  landingView;
  compRef: any;
  currentColorSchema:any;
  stylesTagsInIframe:any =[]; // Lo usamos para almacenar los diferentes IDs de los <style> que hemos ido generando y poder eliminarlos si hay cambios en la landing

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  defaultColorType = "ligth";

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;

  script = `
      console.log('I am in the function');
      //alert('test');
      let load = () => {
        setTimeout(()=>{
          (document).body.className += ' load';
        },Math.floor(Math.random() * 600) + 300);
        
        console.log("load event detected!");
      }
      window.onload = load();
      //load();
    `;

  constructor(
    //private vcRef: ViewContainerRef,
    //private resolver: ComponentFactoryResolver,
  ) { }

  setLandingView(_iframe){
    this.landingView = _iframe;
  }

  getLandingView() {
    return this.landingView;
  }

  addContentToIframe(_element:any, _index) {

    // let _component = TemplateModules[_element.landingType]['modules'][_element.moduleType];
    // const compFactory = this.resolver.resolveComponentFactory(_component);
    // this.compRef = this.vcRef.createComponent(compFactory);

    // this.compRef.instance.newData = _element;
    // //this.landingContentUpdate.modules[_index] = _element;
    // this.compRef.instance.structure = _element;
    // this.landingView.body.appendChild(this.compRef.location.nativeElement);
    
  }

  clearAllStyleTags() {
    // Eliminamos todas las etiquetas <style> que tengamos en el iframe
    Array.prototype.slice.call(this.landingView.getElementsByTagName('style')).forEach(
      function(item) {
        if(item.id != 'preview__content_iframe')  item.remove();
    });

  }

  setColorSchemaToModule(_customCss, _id){

    // Insertamos CSS en línea dentro del iframe
    let style = document.createElement('style');
    style.id = _id+'_style';
    style.type = 'text/css';
    style.textContent = _customCss;
    this.landingView.head.appendChild(style);

  }

  setSchemaToLanding(colorSchema, landingView){

    colorSchema.schema.forEach(element => {

      // Insertamos CSS en línea dentro del iframe
      // let style = document.createElement('style');
      // style.type = 'text/css';
      // style.textContent = iframeCss;
      // this.landingView.head.appendChild(style);

      //aplicamos al body del iframe la propiedad y el valor (variableCSS , color)
      landingView.body.style.setProperty('--'+element.variableName, element.color);
    });
  }

  setColorSchemaToIframe(_currentSchema, landingView){

    if(_currentSchema.schema == null) return;
    // nos aseguramos que la landing tenga ya un schema de color seleccionado, de lo contrario seleccionamos el primero que tenemos definido
    if(_currentSchema) {
      this.setSchemaToLanding(_currentSchema, landingView);
    } else {
      this.setSchemaToLanding(this.defaultColorSchemas.schemas[1], landingView);
      //this.dataLandingService.setSchemaToLanding(this.defaultColorSchemas[1],this.landingView);
    }

  }

  setFontToLanding(fontFamily, landingView){

    if(!landingView) return;

    //recorremos las fuentes
    fontFamily.font.forEach(element => {
      console.log(element);
      let urlGoogleFont = `https://fonts.googleapis.com/css?family=${element.type.code.replace(' ', '+')}`;
      //console.log(urlGoogleFont);
      //Comprobamos que no hayamos creado antes la etiqueta en el head para incluir la llamada a la fuente de google
      let styleTag = landingView.getElementById(element.domId);
      if(styleTag === null) {
       
        //injectamos en el head del iframe la llamada a la url de la fuente de google
        let link = document.createElement('link');
        link.id = element.domId;
        link.rel = 'stylesheet';
        link.href = urlGoogleFont;
        landingView.head.appendChild(link);
      } else {

        //ya hemos creado el tag anteriormente, solo actualizamos el valor del href
        //landingView.getElementById(element.domId).setAttribute("href", urlGoogleFont); 
      }

      //aplicamos al body del iframe la propiedad y el valor (variableCSS , fontFamily)
      landingView.body.style.setProperty('--'+element.variableName, element.type.name);
    });
  }

  setFontSchemaToIframe(_currentSchema, landingView){

    // nos aseguramos que la landing tenga ya una fuenta seleccionada, de lo contrario seleccionamos el primero que tenemos definido
    if(_currentSchema) {
      let fontId = _currentSchema.id;

      //if(this.defaultFontFamily.predefined[fontId].active === "undefined")
      this.defaultFontFamily.predefined[fontId] = _currentSchema;
      this.defaultFontFamily.predefined[fontId].active = true;
      //modificamos las varibles css para que aplique el esquema que hemos seleccionado
      this.setFontToLanding(this.defaultFontFamily.predefined[fontId], landingView);
    } else {
      this.setFontToLanding(this.defaultFontFamily.predefined[1], landingView);
    }

  }

  setCssScriptsToIframe(_landing, landingView){

    this.landingView = landingView;
    
    const iframeCss = `
    body.load {
      opacity:1; 
      -webkit-animation: fadein .4s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein .4s; /* Firefox < 16 */
          -ms-animation: fadein .4s; /* Internet Explorer */
          -o-animation: fadein .4s; /* Opera < 12.1 */
              animation: fadein .4s;
    }

    body {
      opacity:0;
    }

    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    .md-hero {min-height: calc(3/4*100vh)}
    `;

    const iframeJs = `
    //setTimeout(function(){ document.body.classList.add("loaded"); }, 0);
    `;

    // Recuperamos el tipo de landing
    let landingType =_landing.config.landingUse;

    //console.log(_landing);

    // Creamos elemento para insertar en el head del iframe
    let link = document.createElement('link');
    link.id = _landing.landing_code;
    link.rel = 'stylesheet';
    link.href = 'assets/landings/'+ landingType +'/css/styles.css';
    this.landingView.head.appendChild(link);

    let linkIcons = document.createElement('link');
    linkIcons.id = _landing.landing_code+'_icons';
    linkIcons.rel = 'stylesheet';
    linkIcons.href = 'assets/landings/primeicons/primeicons.css';
    this.landingView.head.appendChild(linkIcons);

    //Insertamos CSS en línea dentro del iframe
    let style = document.createElement('style');
    //style.type = 'text/css';
    style.id = 'preview__content_iframe';
    style.textContent = iframeCss;
    this.landingView.head.appendChild(style);


    let linkIconsMaterial = document.createElement('link');
    linkIconsMaterial.id = _landing.landing_code+'_iconsMaterial';
    linkIconsMaterial.rel = 'stylesheet';
    linkIconsMaterial.href = 'https://fonts.googleapis.com/css2?family=Material+Icons';
    this.landingView.head.appendChild(linkIconsMaterial);

    let customScriptTag = document.createElement('script');
    customScriptTag.type = 'type="text/javascript';
    customScriptTag.src = 'https://code.jquery.com/jquery-3.4.1.min.js';
    customScriptTag.setAttribute('integrity','sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=');
    customScriptTag.setAttribute('crossorigin','anonymous');
    customScriptTag.setAttribute('async','async');
    this.landingView.body.appendChild(customScriptTag);

    // const getBlobURL = (code, type) => {
    //   const blob = new Blob([code], { type })
    //   return URL.createObjectURL(blob)
    // }
    // const jsURL = getBlobURL(this.script, 'text/javascript')

    // let customScriptTag2 = this.landingView.createElement('script');
    // customScriptTag2.type = 'type="text/javascript';
    // customScriptTag2.src = jsURL;
    // customScriptTag2.setAttribute('async','async');
    // this.landingView.body.appendChild(customScriptTag2);


    // Insertamos JS en línea dentro del iframe
    let _script = this.landingView.createElement('script');
    _script.type = 'text/javascript';
    _script.textContent = this.script;
    this.landingView.body.appendChild(_script);

    // var func = function() { console.log('loaded!');};
    // if(this.landingView.addEventListener)
    // this.landingView.addEventListener('load', func, true);
    // else if(this.landingView.attachEvent)
    // this.landingView.attachEvent('onload',func);

  }

  setCssScriptsToIframeModule(_landing, landingView){

    this.landingView = landingView;
    
    const iframeCss = `
    body.load {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity:1; 
      -webkit-animation: fadein .4s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein .4s; /* Firefox < 16 */
          -ms-animation: fadein .4s; /* Internet Explorer */
          -o-animation: fadein .4s; /* Opera < 12.1 */
              animation: fadein .4s;
    }

    body {
      opacity:0;
    }

    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
    `;

    // Recuperamos el tipo de landing
    let landingType =_landing.config.landingUse;

    // Creamos elemento para insertar en el head del iframe
    let link = document.createElement('link');
    link.id = _landing.landing_code;
    link.rel = 'stylesheet';
    link.href = 'assets/landings/'+ landingType +'/css/styles.css';
    this.landingView.head.appendChild(link);

    let linkIcons = document.createElement('link');
    linkIcons.id = _landing.landing_code+'_icons';
    linkIcons.rel = 'stylesheet';
    linkIcons.href = 'assets/landings/primeicons/primeicons.css';
    this.landingView.head.appendChild(linkIcons);

    //Insertamos CSS en línea dentro del iframe
    let style = document.createElement('style');
    //style.type = 'text/css';
    style.textContent = iframeCss;
    this.landingView.head.appendChild(style);


    let linkIconsMaterial = document.createElement('link');
    linkIconsMaterial.id = _landing.landing_code+'_iconsMaterial';
    linkIconsMaterial.rel = 'stylesheet';
    linkIconsMaterial.href = 'https://fonts.googleapis.com/css2?family=Material+Icons';
    this.landingView.head.appendChild(linkIconsMaterial);


    // Insertamos JS en línea dentro del iframe
    let _script = this.landingView.createElement('script');
    _script.type = 'text/javascript';
    _script.textContent = this.script;
    this.landingView.body.appendChild(_script);

  }
}
