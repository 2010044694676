export class BuyerPersonaQuestionsTemplate {
    data =
    [
      {
        type: "radioButton",
        name: "question1",
        question: "1. What type of users will your landing page target?",
        options: [{
          key: "F",
          label: "Female users",
          value: "female",
          default: false
        }, 
        {
          key: "M",
          label: "Male users",
          value: "male",
          default: false
        },
        {
          key: "B",
          label: "Both",
          value: "both",
          default: true
        }]
      },
      {
        type: "radioButton",
        name: "question2",
        question: "2. What type of users will your landing page target?",
        options: [{
          key: "Y",
          label: "Young users (16 to 25-year-old)",
          value: "young",
          default: false
        }, 
        {
          key: "A",
          label: "Young adults users (26 to 49-year-old)",
          value: "adult",
          default: false
        },
        {
          key: "M",
          label: "Mature adults users (50-year-old and over)",
          value: "mature",
          default: false
        }, 
        {
          key: "O",
          label: "All age-ranged users",
          value: "all",
          default: true
        }]
      },
      {
        type: "radioButton",
        name: "question3",
        question: "3. What industry does your company work in?",
        options: [{
          key: "A",
          label: "Animals",
          value: "animals",
          default: false
        }, 
        {
          key: "B",
          label: "Banking",
          value: "banking",
          default: false
        },
        {
          key: "Y",
          label: "Beauty",
          value: "beauty",
          default: false
        }, 
        {
          key: "E",
          label: "Education",
          value: "education",
          default: true
        },
        {
          key: "G",
          label: "Gardening",
          value: "gardening",
          default: false
        },
        {
          key: "H",
          label: "Health",
          value: "health",
          default: false
        },
        {
          key: "Q",
          label: "Home",
          value: "home",
          default: false
        },
        {
          key: "L",
          label: "Logistics",
          value: "logistics",
          default: false
        },
        {
          key: "M",
          label: "Motor",
          value: "motor",
          default: false
        },
        {
          key: "R",
          label: "Real State",
          value: "real",
          default: false
        },
        {
          key: "N",
          label: "Restoration",
          value: "restoration",
          default: false
        },
        {
          key: "I",
          label: "Retail",
          value: "retail",
          default: false
        },
        {
          key: "T",
          label: "Tech",
          value: "tech",
          default: false
        },
        {
          key: "C",
          label: "Telecom",
          value: "telecom",
          default: false
        },
        {
          key: "V",
          label: "Travelling",
          value: "travelling",
          default: false
        },
        {
          key: "U",
          label: "Utilities",
          value: "utilities",
          default: false
        },
        {
          key: "W",
          label: "Wellness",
          value: "wellness",
          default: false
        },
        {
          key: "O",
          label: "Other",
          value: "other",
          default: true
        }]
      },
      {
        type: "radioButton",
        name: "question4",
        question: "4. What do you need your users to do in your landing page?",
        options: [{
          key: "S",
          label: "Subscribing to a service",
          value: "service",
          default: false
        }, 
        {
          key: "P",
          label: "Purchasing a product from my online store",
          value: "product",
          default: false
        },
        {
          key: "A",
          label: "Downloading my app",
          value: "app",
          default: false
        },
        {
          key: "E",
          label: "Registering for an event",
          value: "event",
          default: false
        },
        {
          key: "D",
          label: "Downloading a document",
          value: "download",
          default: false
        },  
        {
          key: "O",
          label: "Anothers",
          value: "anothers",
          default: true
        }]
      }
    ]
            
}