import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-module-item',
  templateUrl: './module-item.component.html',
  styleUrls: ['./module-item.component.scss']
})

export class ModuleItemComponent implements OnInit {
  
  @Input() modulesData : any;
  @Input() index : any;
  @Input() showModulesItemsSidebar:boolean = false;
  @Output() addModuleEvent = new EventEmitter<string>();
  @Output() removeModuleEvent = new EventEmitter<string>();

  constructor() { }

  addModule(index){
    this.addModuleEvent.emit(index);
  }

  removeModule(index){
    this.removeModuleEvent.emit(index);
  }
  ngOnInit() {
    //console.log(this.modulesData);
  }

}
