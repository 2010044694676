<!-- <div class="c-dropzone">
    <input type="file" #fileDropRef id="fileDropref" multiple (change)="fileBrowseHandler($event.target.files)">
    <svg class="icon ic-24"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
    <label for="fileDropref">Browse for file</label>
  </div> -->

<div class="c-dropzone mt-15" appDnd (fileDropped)="onFileDropped($event)">
	<input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
	<svg class="icon icon-stroke ic-64 mb-25"><use href="assets/icons/icons-sprite.svg#image"/></svg>
	<p class="mb-5">Upload your images</p>
	<span>Drag and drop here the images<br> you want to upload or</span>
	<label class="btn btn-primary mt-25" for="fileDropRef">Browse</label>
</div>
<div class="c-files-list">
	<div class="c-single-file" *ngFor="let file of files; let i = index">
		<!-- <svg class="icon icon-stroke ic-24 mr-10"><use href="assets/icons/icons-sprite.svg#image"/></svg> -->
		<div class="c-single-file__info">
			<h4 class="c-single-file__name">
				{{ file?.name }}
			</h4>
			<p class="c-single-file__size">
				{{ formatBytes(file?.size) }}
			</p>
			<app-progress [progress]="file?.progress"></app-progress>
		</div>

		<div class="c-single-file__delete" (click)="deleteFile(i)">
			<svg class="icon icon-stroke ic-danger ic-20"><use href="assets/icons/icons-sprite.svg#delete"/></svg>
		</div>
	</div>
</div>
