<p-inputNumber 
    [(ngModel)]="timer" 
    [showButtons]="true" 
    buttonLayout="horizontal" 
    inputId="horizontal" 
    spinnerMode="horizontal" 
    [step]="10"
    decrementButtonClass="p-button-outlined p-button-secondary" 
    incrementButtonClass="p-button-outlined p-button-secondary" 
    incrementButtonIcon="pi pi-plus" 
    decrementButtonIcon="pi pi-minus"
    suffix="s"
    [min]="10">
</p-inputNumber>
