<main class="d-flex flex-column h-100">
  <!-- Alert -->
  <app-alert></app-alert>
  <!-- / Alert -->
  <!-- Header -->
  <app-header></app-header>
  <!-- / Header -->
  
    <section class="">

      <form action="/charge" method="post" id="payment-form" class="checkout">
        <div class="form-row">
          <label>Nombre</label>
          <input type="text" name="name">
        </div>
        <div class="form-row">
          <label>Email</label>
          <input type="email" name="email">
        </div>
        <div class="form-row">
          <label>Country</label>
          <select name="country">
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="BE">Belgium</option>
            <option value="BR">Brazil</option>
            <option value="CA">Canada</option>
            <option value="CN">China</option>
            <option value="DK">Denmark</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="DE">Germany</option>
            <option value="HK">Hong Kong</option>
            <option value="IE">Ireland</option>
            <option value="IT">Italy</option>
            <option value="JP">Japan</option>
            <option value="LU">Luxembourg</option>
            <option value="MY">Malaysia</option>
            <option value="MX">Mexico</option>
            <option value="NL">Netherlands</option>
            <option value="NZ">New Zealand</option>
            <option value="NO">Norway</option>
            <option value="PL">Poland</option>
            <option value="PT">Portugal</option>
            <option value="SG">Singapore</option>
            <option value="ES">Spain</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="GB">United Kingdom</option>
            <option value="US" selected="selected">United States</option>
          </select>
        </div>
        <div class="form-row">
          <label for="card-info">Card Info</label>
          <div id="card-info" #cardInfo></div>
        </div>
        <div id="card-errors" role="alert"></div>
        <div class="form-row">
          <button type="submit">Enviar</button>
        </div>
      </form>
      
      <!-- <form action="/charge" method="post" id="payment-form">
        <div class="form-row">
          <label for="card-element">Credit or debit card</label>
          <div id="card-element" #cardInfo></div>
          <div id="card-errors" role="alert"></div>
        </div>
        <button>Add payment source</button>
      </form> -->


      <!-- Editor Menu -->
      <router-outlet></router-outlet>
      <!-- / Editor Menu -->
    </section>

  </main>
  
  
