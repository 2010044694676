<div class="c-add-media">
  <ng-container *ngIf="!data.properties.src">
    <div class="c-add-media__btn" (click)="showModalDialog()">
      <svg class="icon ic-white ic-24"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>
    </div>
    <span (click)="showModalDialog()">Add media</span>
  </ng-container>  
  <ng-container *ngIf="data.properties.src">
    <div class="c-add-media__preview" (click)="showModalDialog()">
      <div class="c-add-media__preview-hover">
        <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-edit"/></svg>
      </div>
      <ng-container *ngIf="data.properties.type.code == 'image'">
        <img width="100%" [src]="data.properties.src">
      </ng-container>
      <ng-container *ngIf="data.properties.type.code == 'video/mp4'">
        <img width="100%" [src]="data.properties.video_image">
      </ng-container>
    </div>
    <span (click)="showModalDialog()">Edit media</span>
  </ng-container>  
</div>

<!-- Dialog -->
<p-dialog header="Add media" [(visible)]="displayModal" [modal]="true" [draggable]="false" [resizable]="false" styleClass="p-dialog-media">
  <!-- hacer que solo tenga scroll el contenedor no todo el blque  -->
  <div class="row no-gutters p-dialog-media__row"> 
      <div class="col-sm-auto">
        <div class="p-dialog-media__sidebar">
            <!-- Menú desktop & laptop -->
            <div class="p-dialog-media-menu">
              <!-- Iteramos los vendors disponibles para poder hacerlo configurable -->
              <div class="p-dialog-media-menu__item" *ngFor="let item of vendors" 
                    [ngClass]="{'active': currentTabMedia == item.tab }" 
                    (click)="currentTabMedia = item.tab">
                <div class="p-dialog-media-menu__icon" *ngIf="item.src">
                  <img [src]="item.src" alt="{{item.name}}">
                </div>
                <div class="p-dialog-media-menu__icon" *ngIf="item.icon">
                  <svg class="icon icon-stroke ic-18"><use [attr.xlink:href]="'assets/icons/icons-sprite.svg#'+item.icon"/></svg>
                </div>
                <div class="p-dialog-media-menu__name">{{item.name}}</div>
              </div>
            </div>
            <p-dropdown [options]="vendors" [(ngModel)]="selectedMenu" optionLabel="name" placeholder="Select">
              <ng-template pTemplate="selectedItem">
                  <div class="p-dialog-media-menu__item active" *ngIf="selectedMenu">
                    <div class="p-dialog-media-menu__icon">
                      <img *ngIf="selectedMenu.src" src="{{selectedMenu.src}}" alt="{{selectedMenu.name}}">
                      <svg *ngIf="selectedMenu.icon" class="icon icon-stroke ic-18"><use [attr.xlink:href]="'assets/icons/icons-sprite.svg#'+selectedMenu.icon"/></svg>
                    </div>
                    <div class="p-dialog-media-menu__name">{{selectedMenu.name}}</div>
                  </div>
              </ng-template>
              <ng-template let-vendor pTemplate="item">
                <div class="p-dialog-media-menu__item" (click)="currentTabMedia = vendor.tab">
                  <div class="p-dialog-media-menu__icon">
                    <img *ngIf="vendor.src" src="{{vendor.src}}" alt="{{vendor.name}}">
                    <svg *ngIf="vendor.icon" class="icon icon-stroke ic-18"><use [attr.xlink:href]="'assets/icons/icons-sprite.svg#'+vendor.icon"/></svg>
                  </div>
                  <div class="p-dialog-media-menu__name">{{vendor.name}}</div>
                </div>
              </ng-template>
          </p-dropdown>
        </div>
      </div>
      <div class="col-sm">
        <ng-container *ngFor="let item of vendors">
          <ng-container *ngIf="currentTabMedia == item.tab">
            <app-unsplash-images [(data)]="data" (closeModalDialog)="closeModalDialog()" *ngIf="item.id == 'unsplash'"></app-unsplash-images>
            <app-pexel-images [(data)]="data" (closeModalDialog)="closeModalDialog()" *ngIf="item.id == 'pexels_images'"></app-pexel-images>
            <app-pexel-videos [(data)]="data" (closeModalDialog)="closeModalDialog()" *ngIf="item.id == 'pexels_videos'"></app-pexel-videos>
            <app-my-gallery [(data)]="data" (closeModalDialog)="closeModalDialog()" *ngIf="item.id == 'my_gallery'"></app-my-gallery>
            <div class="p-dialog-media__cnt" (closeModalDialog)="closeModalDialog()" *ngIf="item.id == 'upload'">
              <app-drag-drop-file ></app-drag-drop-file>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
        <!-- <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template> -->
</p-dialog>
