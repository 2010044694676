import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { LandingService } from '../../../../services/landing.service';
import { IframeService } from '../../../../services/iframe.service';

@Component({
  selector: 'app-schema-color',
  templateUrl: './schema-color.component.html',
  styleUrls: ['./schema-color.component.scss']
})
export class SchemaColorComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() moduleId: any;
  
  landingContentUpdate:any;
  globalColorSchemas:any;
  tempColorSchema:any;
  landingView:any;
  landingModuleView:any;
  customColorSchemas:any = {
    id: 0,
    type: 'custom',
    active: false,
    title: 'Default Color Schema',
    color:'light',
    schema: 
      [
          {color: '#ebebeb', variableName:'primary'},
          {color: '#e6e6e6', variableName:'secondary'},
          {color: '#c8c8c8', variableName:'linkColor'},
          {color: '#5e4848', variableName:'textColor'},
          {color: '#000000', variableName:'headingColor'},
          {color: '#dadada', variableName:'backgroundColor'}
      ]
  };
   
  constructor(
    private dataLandingService: LandingService,
    private iframeService: IframeService,
  ) { }
  
  setGlobalColorSchema(){
    
    //this.data = {};
    this.globalColorSchemas.active = true;
    this.data.active = false;
    this.landingModuleView.style ="";

  }

  setCustomColorValue() {

    this.globalColorSchemas.active = false;
    this.data.active = true;

    console.log(this.data);
    this.data.schema.forEach(element => {
      console.log(element);
      this.landingModuleView.style.setProperty('--'+element.variableName, element.color);
    });

  }

  setCustomColorSchema(colorIndex, color){
    
    this.data.active = true;
    this.globalColorSchemas.active = false;

    this.data.schema.forEach(element => {
      console.log(element);
      this.landingModuleView.style.setProperty('--'+element.variableName, element.color);
    });

  }

  ngOnInit(): void {
    
    // Necesitamos referencia al iframe
    //this.landingView = this.iframeService.getLandingView();
    this.landingView = document.getElementById('lndPreview');
    console.log(this.landingView);
   
    // Guardamos la configuración que haga el usuarioi de forma temporal
    this.tempColorSchema = this.customColorSchemas;
    console.log(this.data);
    console.log('/////////////////////////////////////////');
    console.log(this.data.type);
    console.log(this.moduleId);

    // asignamos el color personalizado
    // this.customColorSchemas = this.data;
    // Optenemos la información de la landing, vamos a guardar los schemas de color y tipo en el objeto de landing compartido en la aplicación para poder almacenar los cambios
    this.dataLandingService.landingSource.subscribe((respond)=>{
      console.log('******************************************');
      if(respond.landing_id == null) return;
      
      // pasamos al objeto landingContentUpdate toda la información de la landing
      this.landingContentUpdate = respond;

      // asignamos el color global para poder volver a usarlo si fuera necesario
      this.globalColorSchemas = respond.config.landingColor;
      
      const initglobalColorSchemas = this.globalColorSchemas;
      
      if(this.data.type === undefined) {
         this.data = this.customColorSchemas;
      }
      
    })


  }

  ngAfterViewInit(){
     
     // Dentro del iframe buscamos el modjolo al que aplicaremos el cambio de estilos en base a las variables css
     this.landingModuleView = this.landingView.contentWindow.document.getElementById(this.moduleId);
     console.log(this.landingModuleView);

     if(this.landingModuleView === null) {
       console.warn('>>> no incluido ID en el modulo: '+this.moduleId);
     } 

    if(!this.data.active) {
      this.setGlobalColorSchema();
    } else {
      this.data.schema.forEach(element => {
        console.log(element);
        this.landingModuleView.style.setProperty('--'+element.variableName, element.color);
      });
    }

    

  }

}
