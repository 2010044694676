<header class="log-header">
  <div class="log-header-main">
    <div class="log-header-brand">
        <a href="#!">
          <!-- <svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-logo"></use>
          </svg> -->
        </a>
    </div>
    <span class="log-header-title">Cuéntale al mundo lo que sabes hacer</span>
    <span class="log-header-footer d-none d-md-block"><b>© 2020 Boostup.io,</b> Inc. Todos los derechos reservados.</span>
  </div>
  <!-- <div class="page-main-animation brown" style="top: 25vh; left: 2vw; opacity: .3">
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
  </div> -->
  <!-- <div class="page-main-animation brown" style="bottom: 16.6vh; right: 4vw; opacity: .3">
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
  </div> -->
</header>

<section class="log-grid">
  <main class="log-box">
    <h1 class="log-box-title">Regístrate gratis</h1>
    <!-- SignUp a través de RRSS -->
    <div class="log-box-social">
      <button type="button" (click)="socialSignUp('google')" class="log-box-social-btn">
        <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-google"/></svg></span>
        <span>Con Google</span>
      </button>
      <button type="button" (click)="socialSignUp('facebook')" class="log-box-social-btn">
        <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-facebook"/></svg></span>
        <span>Con Facebook</span>
      </button>
    </div>
    <!-- Custom SignUp -->
    <div class="log-box-custom">
      <h2 class="log-box-custom-title">O rellena estos campos</h2>
      <form class="log-box-custom-form">
        <div class="log-box-custom-form-fieldset input">
          <input type="text" [(ngModel)]="userPostData.name" [ngModelOptions]="{standalone: true}" name="username-field" id="username-field" placeholder="Usuario">
          <span class="field-error" *ngIf="showErrorName">{{messageError}}</span>
        </div>
        <div class="log-box-custom-form-fieldset input">
          <input type="email" [(ngModel)]="userPostData.email" [ngModelOptions]="{standalone: true}" name="email-field" id="email-field" placeholder="Correo electrónico">
          <span class="field-error" *ngIf="showErrorEmail">{{messageError}}</span>
        </div>
        <div class="log-box-custom-form-fieldset input">
          <input type="password" [(ngModel)]="userPostData.password" [ngModelOptions]="{standalone: true}" name="password-field" id="password-field" placeholder="Contraseña">
          <span class="field-error" *ngIf="showErrorPass">{{messageError}}</span>
        </div>
        <div class="log-box-custom-form-fieldset check">
          <p>Al crear esta cuenta confirmas que has leído y aceptas nuestras <a href="https://site.landing.okbestudiointeractivo.com/">condiciones&nbsp;de&nbsp;uso</a> así como nuestra <a href="https://site.landing.okbestudiointeractivo.com/">política&nbsp;de&nbsp;privacidad</a>.</p>
        </div>
        <div class="log-box-custom-form-fieldset submit">
          <input type="submit" (click)="localSignUp(userPostData.name,userPostData.email,userPostData.password)" value="Crear mi cuenta">
        </div>
      </form>
    </div>
    <!-- Are you already a member? -->
    <div class="log-box-footer"> 
      ¿Ya tienes una cuenta? <span><a routerLink="/signin">Accede&nbsp;a&nbsp;ella</a></span> aquí.
    </div>
    <span class="log-header-footer d-md-none"><b>© 2019 Landings,</b> Inc. Todos los derechos reservados.</span>
  </main>
</section>