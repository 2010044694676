import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { LandingService } from '../../../services/landing.service';
import { IframeService } from '../../../services/iframe.service';
import { LandingTemplate } from '../../landing.template';
import { ColorSchemaTemplate } from '../../colorSchema.template';
import { take } from 'rxjs/operators'
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit {

  checked: boolean;
  dataLoaded:Boolean = false;
  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  landingContentUpdate = new LandingTemplate().data;

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  defaultColorType = "ligth";
  currentColorSchema:any; // Objeto para almacenar color si ya hemos seleccionado uno
  savedColorSchema:any;
  originalColorSchema:any;
  hasCustomColorSchema:boolean = false;
  themeMode:boolean = false; // color por defecto a no ser que tengamos otra cosa guardada en la configuración
  subscription: Subscription;

  private currentSchemaIndex:number;
  constructor(
    private dataLandingService: LandingService,
    private iframeService: IframeService
  ) { }

  handleChangeThemeMode(e) {
    
    let isChecked = e.checked;

    if(isChecked) {
      this.defaultColorType = "dark";
    } else {
      this.defaultColorType = "ligth"
    }

    this.setColorSchema(this.currentSchemaIndex)
  }

  setSavedColorSchema(schemaColor){

      //marcamos como activo el schema seleccionado, necesitamos pasar todos a desactivado y luego activar el seleccionado
      this.defaultColorSchemas.schemas.forEach(element => {
        element.active = false;
      });

      //actualizamos el schema utilizado en la landing, para ello actualizamos el valor que tenga landingColor en el objeto de la landing con todos los datos
      this.landingContentUpdate.config.landingColor = this.currentColorSchema;
      this.landingContentUpdate.config.landingColor.index = -1;
      this.defaultColorSchemas.schemas[0].schema = schemaColor;

      //actualizamos el objeto landing que compartimos con el resto de componentes para tenerlos actualizados
      this.dataLandingService.changeLandingData(this.landingContentUpdate);

    } 

  setColorSchema(schemaIndex){

    this.currentSchemaIndex = schemaIndex;
  
    //marcamos como activo el schema seleccionado, necesitamos pasar todos a desactivado y luego activar el seleccionado
    this.defaultColorSchemas.schemas.forEach(element => {
      element.active = false;
    });

    //activamos el schema seleccionado
    this.defaultColorSchemas.schemas[schemaIndex].active = true;
    
    //actualizamos el schema utilizado en la landing, para ello actualizamos el valor que tenga landingColor en el objeto de la landing con todos los datos
    this.landingContentUpdate.config.landingColor = this.defaultColorSchemas.schemas[schemaIndex];
    this.landingContentUpdate.config.landingColor.index = schemaIndex;

    // actualizamos el schema editable para que siempre tenga como base el seleccionado
    this.defaultColorSchemas.schemas[0].schema = this.defaultColorSchemas.schemas[schemaIndex].schema;

    //actualizamos el objeto landing que compartimos con el resto de componentes para tenerlos actualizados
    this.dataLandingService.changeLandingData(this.landingContentUpdate);

  }

  setCustomcolorSchema(index, colorIndex, color){
    
    this.currentSchemaIndex = index;

    //marcamos como activo el schema seleccionado, necesitamos pasar todos a desactivado y luego activar el seleccionado
    this.defaultColorSchemas.schemas.forEach(element => {
        element.active = false;
    });
    //activamos el schema seleccionado
    this.defaultColorSchemas.schemas[index].active = true;
    
    //actualizamos el schema utilizado en la landing, para ello actualizamos el valor que tenga landingColor en el objeto de la landing con todos los datos
    //this.defaultColorSchemas.schemas[index].schema[colorIndex].color = color;
    this.landingContentUpdate.config.landingColor = this.defaultColorSchemas.schemas[index];
    //this.landingContentUpdate.config.landingColor.color = 'light'; // en el caso de personalizado definimos siempre a light
    //this.landingContentUpdate.config.landingColor.index = index;

    //actualizamos el objeto landing que compartimos con el resto de componentes para tenerlos actualizados
    this.dataLandingService.changeLandingData(this.landingContentUpdate);

  }

  ngOnInit() {
    
    // Optenemos la información de la landing, vamos a guardar los schemas de color y tipo en el objeto de landing compartido en la aplicación para poder almacenar los cambios
    this.subscription = this.dataLandingService.landingSource.subscribe((respond)=>{

      if(respond.landing_id == null) return;
      this.dataLoaded = true;
      
      // pasamos al objeto landingContentUpdate toda la información de la landing
      this.landingContentUpdate = respond;
      this.savedColorSchema = this.landingContentUpdate.config.landingColor; 
      console.log(this.savedColorSchema);
      console.log(this.landingContentUpdate);
      
    })

    this.dataLandingService.landingSource.pipe(take(2)).subscribe((respond)=>{

      if(respond.landing_id == null || this.savedColorSchema) return;
      this.savedColorSchema = this.landingContentUpdate.config.landingColor;
      this.savedColorSchema.type = 'saved';
      this.savedColorSchema.id = -1;
      this.defaultColorSchemas.schemas.push(this.savedColorSchema);

      // console.log(this.defaultColorSchemas.schemas);
      
      // this.currentColorSchema = this.landingContentUpdate.config.landingColor;
      // this.defaultColorSchemas.schemas[0].schema = this.savedColorSchema;

    })

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
