import { Component, OnInit, Input } from '@angular/core';
// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';
import {MenuItem, PrimeNGConfig} from 'primeng/api';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-gallery-images',
  templateUrl: './gallery-images.component.html',
  styleUrls: ['./gallery-images.component.scss'],
  providers: [MessageService]
})
export class GalleryImagesComponent implements OnInit {

  @Input() data: any;
  
  items: any;
  activeItem: MenuItem;
  mediaType:any;

  //Uploads
  uploadedFiles: any[] = [];

  //Unsplash photos
  unsplashPhotos: any;
  unsplashSearch:any;

  constructor(
    private messageService: MessageService,
    private landingService:LandingService
  ) { }

  ngOnInit(): void {

    console.log(this.data);
    this.unsplashSearch = ["clean", "work", "building"];

    this.items = [
        {value: 'image', icon: 'pi pi-fw pi-image'},
        {value: 'video', icon: 'pi pi-fw pi-video'},
        {value: 'illustration', icon: 'pi pi-fw pi-calendar'},
        {value: 'upload', icon: 'pi pi-fw pi-upload'}]

    this.mediaType = this.items[0].value;

    // this.landingService.getFreeImages(this.unsplashSearch).subscribe((resp: any) => {
    //   this.unsplashPhotos =  resp.results;
    //   console.log(resp);
    // });;
    console.log(this.unsplashPhotos);

  }

  onChange(event:any): void {
    console.log(this.mediaType);
  }

  onUpload(event) {
    console.log(event);
    for(let file of event.files) {
          this.uploadedFiles.push(file);
      }

      this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
  }

  usePhoto(photoUrl) {
    this.data.properties.src = photoUrl;
  }

  searchPhotos(keyword) {
    // console.log(keyword);
    // this.unsplashPhotos =  [];
    // this.landingService.getFreeImages(keyword).subscribe((resp: any) => {
    //   this.unsplashPhotos =  resp.results;
    //   console.log(resp);
    // });;
  }

}
