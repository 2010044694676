import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { LandingService } from 'src/services/landing.service';
import { EditorCommonsOptions } from 'src/app/editor.commons.options';

@Component({
  selector: 'app-unsplash-images',
  templateUrl: './unsplash-images.component.html',
  styleUrls: ['./unsplash-images.component.scss']
})
export class UnsplashImagesComponent implements OnInit {

  @Input() data:any;
  @Output() closeModalDialog = new EventEmitter<boolean>();
  items:any
  pexelImageSearch:string = '';
  dataLoaded:boolean = false;
  actualPage: number;

  constructor(
    private landingService: LandingService
  ) {
    this.actualPage = 1;
   }

  updateMedia(item) {
    // Actualizamos el objeto, hemos podido modificarlo y necesitamos asegurar el tipo de dato que vamos a almacenar
    this.data.properties = new EditorCommonsOptions().schema.media.properties;

    this.data.properties.type.code = 'image';
    this.data.properties.src = item.urls.full;
    this.data.properties.name = item.alt_description;
    this.data.properties.data.color = item.color;

    // Cerramos modal
    this.closeModalDialog.emit(false);
  }

  search(){
    this.items = [];
    this.dataLoaded = false;
    this.landingService.getUnsplashImages(this.pexelImageSearch,1).subscribe((response) => {
      this.dataLoaded = true;
      this.items = response.results;
    },
    error => {
      this.dataLoaded = true;
      console.log(error);
    })
  } 

  onScroll() {
    this.dataLoaded = false;
    this.landingService.getUnsplashImages(this.pexelImageSearch, this.actualPage++).subscribe((response) => {
      this.dataLoaded = true;
      console.log(response);
      let listItems;
      if(response.results){
        listItems = response.results;
      } else {
        listItems = response;
      }
      listItems.forEach((element)=>{
        this.items.push(element);
      });
    },
    error => {
      this.dataLoaded = true;
      console.log(error);
    })
  }

  ngOnInit(): void {
    this.landingService.getUnsplashImages(this.pexelImageSearch,1).subscribe((response) => {
      this.dataLoaded = true;
      this.items = response;
    },
    error => {
      this.dataLoaded = true;
      console.log(error);
    })

  }

}