<div class="l-panel-editor">

    <!-- Panel Editor: Header -->
    <div class="l-panel-editor__header" [ngClass]="{'show': showPreview}">
      <div class="d-flex align-items-center">
        <div class="l-panel-editor__header__title">Linked template</div>
        <button type="button" class="btn btn-link d-none d-lg-inline-block d-xxxl-none ml-5" routerLink="../preview">Preview</button>
      </div>
      <div class="l-panel-editor__header__actions">
        <app-save-button></app-save-button>
        <button type="button" class="btn btn-primary btn-icon btn-icon__right btn-pause">Pause <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></button>
      </div>
    </div>
    <!-- / Panel Editor: Header -->
  
    <!-- Panel Editor: Body -->
    <div class="l-panel-editor__body">
  
      <div class="scroll-wrap">
  
        <div class="l-panel-editor__body__content">
  
            <!-- No tenemos templates, mostramos creatividad y botón -->
            <div class="mt-20 mt-md-40">
                <div class="c-message-no-content">
                  <div class="c-message-no-content__media">
                    <img class="img-fluid" src="assets/img/no-templates-yet.svg" alt="No template yet">
                  </div>
                  <div class="c-message-no-content__body">
                    <h5 class="c-message-no-content__title">No templates yet</h5>
                    <div class="c-message-no-content__description">
                      <p>Start adding your first template to this screen!</p>
                    </div>
                    <a class="c-message-no-content__link" href="#">More information</a>
                  </div>
                  <div class="c-message-no-content__action">
                    <button type="button" class="btn btn-primary btn-icon btn-icon__right" (click)="askCodeTemplate(addTemplateModal)">Add a template<svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></button>
                  </div>
                </div>
            </div>
            <!-- ENd creatividad y botón -->
  
        </div>
  
      </div>
  
    </div>
    <!-- / Panel Editor: Body -->
  
  </div>

<!-- Modal para agregar nuevo template -->
<ng-template #addTemplateModal let-modal>
    <div class="modal-card modal--code">
      
      <!-- Template List + Add Template -->
      <ng-container>
        
        <div class="modal-header">
          <h4 class="modal-title">Add a template</h4>
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
          </button>
        </div>
  
        <div class="modal-body">
          <div class="text-center mb-15">
            <div class="mb-25">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <button type="button" class="btn btn-primary btn-icon btn-icon__right" (click)='startLanding()'>Create a new template<svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></button>
          </div>
        </div>
  
        <div class="modal-body bg-light">
          <div class="c-line mb-15">
            <span class="c-line__text bg-light">or</span>
          </div>
          <div class="row">
            <div class="col-sm-6" *ngFor="let item of allLandings; let i = index">
              <div class="landing-thumb">
                <div class="landing-thumb-top">
                  <div class="thumb-wrapper">
                    <app-thumb-landing [modules]="item.landing_content"  (thumbLoaded)='checkThumbLoaded($event, i)'></app-thumb-landing>
                  </div>
                </div>
                <div class="landing-thumb-bottom">
                  <div class="landing-thumb-info">
                    <a class="font-weight-bold" style="font-size: 14px; margin-bottom: 3px" routerLink="../editor/{{item.landing_code}}">{{item.landing_name}}</a>
                    <div style="font-size: 12px; opacity: .5">{{item.create_date | date:'dd MMM yyyy'}} <span style="font-style: italic;" *ngIf="item.landing_wsp_name !== 'N/A'">({{item.landing_wsp_name}})</span></div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          
        </div>        
  
      </ng-container>
      <!-- / Template List + Add Template by code -->