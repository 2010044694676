export class EditorCommonsOptions {
    schema = 
        {  
            "buttom" : {
                "type": "inputSimpleComponent",
                "id": "content",
                "properties": {
                    "label": null,
                    "note": null,
                    "data": null,
                    "name": null,
                    "type": {
                        "code":null,
                        "name":null}
                        ,
                    "visible": 1,
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                }
        },
            "text" : {
                    "type": "inputSimpleComponent",
                    "id": "content",
                    "properties": {
                        "customSize": null,
                        "fontSize": null,
                        "label": null,
                        "note": null,
                        "data": null,
                        "name": null,
                        "type": {
                            "code":null,
                            "name":null},
                        "visible": 1,
                        "icon": {
                            "code": "pi pi-check",
                            "name": "pi pi-check"
                        },
                    }
            },
            "media" : {
                "type": "imageSimpleComponent",
                "id": "content",
                "properties": {
                    "avg_color": null,
                    "duration": null,
                    "video_image": null,
                    "src": null,
                    "label": null,
                    "note": null,
                    "data": {},
                    "name": null,
                    "type": {
                        "code":null,
                        "name":null},
                    "source": null,
                    "visible": 1,
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                },
                "items": []
            },
            "card" : {
                "type": "cardSimpleComponent",
                "id": "content",
                "properties": {
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                    "title": null,
                    "subtitle": null,
                    "description": null,
                    "link": null,
                    "linkText": null,
                    "src": null,
                    "label": null,
                    "note": null,
                    "data": null,
                    "name": null,
                    "type": {
                        "code":null,
                        "name":null},
                    "source": null,
                    "btnPrimary":null,
                    "visible": 1
                    
                },
                "items": []
            },
            "price" : {
                "type": "priceSimpleComponent",
                "id": "content",
                "properties": {
                    "badge": null,
                    "subtitle": null,
                    "price": null,
                    "period": null,
                    "title": null,
                    "description": null,
                    "highlighted": 0,
                    "link": null,
                    "linkText": null,
                    "features": [],
                    "label": null,
                    "note": null,
                    "data": null,
                    "name": null,
                    "type": {
                        "code":null,
                        "name":null},
                    "source": null,
                    "visible": 1,
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                },
                "items": []
            },
            "form" : {
                "type": "inputListFormComponent",
                "id": "form",
                "availableTypes": [
                    {"name": 'Text', "code": 'text'},
                    {"name": 'Email', "code": 'email'},
                    {"name": 'Phone', "code": 'phone'},
                    {"name": 'Number', "code": 'number'},
                    {"name": 'Date', "code": 'date'}
                ],
                "properties": 
                    {
                    "label": null,
                    "note": null,
                    "data": null,
                    "name": null,
                    "type": {
                        "code":null,
                        "name":null},
                    "visible": 1,
                    "required": [1],
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                },
                "fields": []
                
            },
            "formConfig" : {
                "type": "inputListFormComponentConfig",
                "id": "formConfig",
                "availableTypes": [
                    {"name":"Email","code":"email"},
                    {"name":"Form Handler (Salesforce / Pardot)","code":"form_handler"},
                    {"name":"getform.io","code":"getform"},
                    {"name":"Slack","code":"slack"},
                    {"name":"Asana","code":"asana"},
                    {"name":"Custom POST Action","code":"form_action"}
                ],
                "properties": 
                    {
                    "label": null,
                    "note": null,
                    "data": {},
                    "name": null,
                    "type": {"name": null, "code": null},
                    "visible": 1,
                    "required": [1],
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                },
                "fields": []
                
            },
            "keyFact" : {
                "type": "keySimpleComponent",
                "id": "content",
                "properties": {
                    "title": null,
                    "description": null,
                    "label": null,
                    "note": null,
                    "data": null,
                    "name": null,
                    "type": null,
                    "source": null,
                    "features": [],
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                    "visible": 1
                },
                "items": []
            },
            "textGroup" : {
                "type": "textGroup",
                "id": "content",
                "properties": {
                    "title": null,
                    "description": null,
                    "label": null,
                    "note": null,
                    "data": null,
                    "name": null,
                    "type": null,
                    "source": null,
                    "features": [],
                    "icon": {
                        "code": "pi pi-check",
                        "name": "pi pi-check"
                    },
                    "visible": 1
                },
                "items": []
            },
            "addressGroup" : {
                "type": "addressGroup",
                "id": "content",
                "properties": {
                    "name": null,
                    "address": null,
                    "zip": null,
                    "city": null,
                    "state": null,
                    "phoneone": null,
                    "phonetwo": null,
                    "mail": null
                },
                "items": []
            },
        }
}