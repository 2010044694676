import { Component, OnInit, OnDestroy, DoCheck, ComponentFactoryResolver, Type, ViewChild, ViewContainerRef, ChangeDetectorRef, ElementRef } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingService } from '../../../services/landing.service';
import { UserService } from '../../../services/user.service';
import { AuthAPIService } from '../../../services/auth-api.service';

//import { NoneComponent } from 'angular6-json-schema-form';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LandingEditorModules } from '../../templates/editors/landing.editor.modules';
import { LandingTemplate } from '../../landing.template';
import { TemplateModules } from '../../templates/template.modules';


import {ConfirmationService} from 'primeng/api';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: [

    // Trigger animation cards array
    trigger('cardAnimation', [
      // Transition from any state to any state
      transition('* => *', [
        // Initially the all cards are not visible
        query(':enter', style({ opacity: 0 }), { optional: true }),

        // Each card will appear sequentially with the delay of 300ms
        query(':enter', stagger('100ms', [
          animate('.4s ease-out', keyframes([
            style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(-10px) scale(1)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ]))]), { optional: true }),

        // Cards will disappear sequentially with the delay of 300ms
        // query(':leave', stagger('200ms', [
        //   animate('500ms ease-out', keyframes([
        //     style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
        //     style({ opacity: .5, transform: 'scale(.5)', offset: 0.3 }),
        //     style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
        //   ]))]), { optional: true })
      ]),
    ])
  ]
})
export class OverviewComponent implements OnInit, OnDestroy, DoCheck {

  visible: boolean = false;
  code:any;
  compEditorRef:any
  public responseData: any;
  public responseLanding: any;
  public showPreview: boolean = false;

  // Contenedor donde incluiremos los diferentes componentes que montarán el editor
  @ViewChild('formEditor', {static: false, read: ViewContainerRef}) formEditor!: ViewContainerRef;
  @ViewChild('scrollWrap', {static: false}) scrollWrap: ElementRef;

  // Listado de los componentes que estamos incluyendo dinamicamente por si tenemos que eliminarlos
  components = [];

  // Variables relacionadas con los usuarios
  ///////////////////////////////////////////////////////////////////////////////////////////

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: ''
  };

  // Variables relacionadas con landings
  ///////////////////////////////////////////////////////////////////////////////////////////

  public landingPostData = {
    method: '',
    user_id: '',
    landing_id: '',
    landing_name: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_save_and_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  landingContentUpdate = new LandingTemplate().data;
  landingInfo:any = [];
  allModulesAbailables:any = []; 
  showModuleFormSidebar:boolean = false; // Mostrar u ocultar el sidebar para editar el modulo
  currentModuleForm:any;
  showAddModule:boolean = false; // Mostrar u ocultar el botón Add Module
  editorModules:any = LandingEditorModules.editorModules;
  showMenuEditor:boolean = false;
  editorCurrentComponent:any;
  currentModuleActiveIndex:any; //Cuando hacemos click en algun compoente para editarlo aquí guardamos el index para poder luego eliminarlo o duplicarlo si fuera necesario
  ActiveModuleName:string; // Variable para almacenar el título del modulo activo en el editor
  isDisabled:boolean; // Control de las animaciones para que solo ocurra una vez
  subscription: Subscription;
  // yourWidgets = {
  //   submit: NoneComponent,  
  // }

  constructor(
    private dataLandingService: LandingService,
    private route: ActivatedRoute,
    public router: Router,
    public user: UserService,
    public authAPIService: AuthAPIService,
    private modalService: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
  ) { 
    this.userPostData = this.user.getData();
    this.isDisabled = false;
  }

  injectEditor(_componentClass, data){

    setTimeout(()=>{
      
      // Create component dynamically inside the ng-template
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory( _componentClass);
      const component:any = this.formEditor.createComponent(componentFactory);
      component.instance.data = data;
      //component.destroy();
      // Push the component so that we can keep track of which components are created
      this.components.push(component);

    })

  }

  showComponentEditor(componentClassName:any, moduleData, index) {

    console.warn('MODULE DATA');
    console.log(moduleData);

    this.isDisabled = true;
    this.ActiveModuleName = this.landingContentUpdate.modules[index].moduleName;

    //Activamos la propiedad activeMenu para hacer el autoScroll al mostrar el formulario de edición
    this.landingContentUpdate.modules[index].commonsOptions.activeMenu = true;
    
    this.showModuleForm(index);
    this.showMenuEditor = true;
    
    if(this.components.length > 0){
      this.removeComponent(this.editorCurrentComponent);
    }

    /// TEnemos el nombre, buscamos que editor le corresponde
    //this.dataLanding.commonsOptions.editorComponentName = TemplateModules['modules'][this.dataModules[0]]['editorClass'];
    //  Inyectamos el componente y le pasamos los datos que tendrá que editar

    /// TEnemos el nombre, buscamos que editor le corresponde
    // Almacenamos los datos de los diferentes modulos asignados al layout
    setTimeout(()=>{
      componentClassName.forEach((element,index) => {
        
        let _moduleComponent = TemplateModules['modules'][element];
        let moduleComponent;

        // Check si el modulo existe, podemos haberlo discontinuado o eliminado
        if(_moduleComponent) {
          moduleComponent = _moduleComponent['editorClass'];
        } else {
          return;
        }

        //let moduleComponent = TemplateModules['modules'][element]['editorClass'];
        //console.log(moduleComponent);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory( moduleComponent );
        
        let viewContainerRef = this.formEditor;
        //this.viewContainerRef.clear();
        this.compEditorRef = viewContainerRef.createComponent(componentFactory);
        console.log(moduleData.data[index].moduleData);
        this.compEditorRef.instance.data = moduleData.data[index].moduleData;

      });
    })
    
    

    //let _componentClass:Type <any> = TemplateModules['modules'][componentClassName[0]]['editorClass'];

    let _moduleComponent = TemplateModules['modules'][componentClassName[0]];
      
    // Check si el modulo existe, podemos haberlo discontinuado o eliminado
    if(_moduleComponent) {
      let _componentClass:Type <any> = _moduleComponent['editorClass'];
    } else {
      return;
    }

    //let _componentClass:Type <any> = componentClassName;
    //let _componentClass:Type <any> = this.editorModules[componentClassName];
    this.editorCurrentComponent = componentClassName;
    
    //this.injectEditor(_componentClass, data);
    setTimeout(()=>{
      
      // // Create component dynamically inside the ng-template
      // const componentFactory = this.componentFactoryResolver.resolveComponentFactory( _componentClass);
      // const component = this.formEditor.createComponent(componentFactory);
      // component.instance.data = data;
      // //component.destroy();
      // // Push the component so that we can keep track of which components are created
      // this.components.push(component);

      // //this.scrollWrap.nativeElement.scrollIntoView({alignToTop: true, block: 'start'});
      
      // //this.scrollWrap.nativeElement.scrollTop = 0;

    })

    setTimeout(()=>{
      //this.scrollWrap.nativeElement.scrollIntoView({behavior:"smooth"});
      this.landingContentUpdate.modules[index].commonsOptions.activeMenu = false;
    },1400);
    
  }

  removeComponent(componentClassName:any) {
    // Find the component
    this.scrollWrap.nativeElement.scrollIntoView({alignToTop: true, block: 'start'});
    this.formEditor.remove(0);
  }

  private showModuleForm(i) {
    this.currentModuleActiveIndex = i;
    // desactivamos cualquier modulo activo para ocultar su formulario
    // this.landingContentUpdate.modules.forEach(element => {
    //   element.commonsOptions.activeMenu = false
    // });

    this.showMenuEditor = true;
    
  }

  hideModuleForm() {
    this.showMenuEditor = false;
    this.currentModuleActiveIndex = -1;
    this.components = []; //Eliminamos cualquier componente que hayamos incluido para la edición
    this.landingContentUpdate.modules.forEach(element => {
      element.commonsOptions.activeMenu = false;
    });
    //this.removeComponent(this.editorCurrentComponent);
  }

  confirmRemoveComponentLanding(event: Event) {
    this.confirmationService.confirm({
        target: event.target,
        message: 'Do you want to permanently delete this Component?',
        //icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.removeComponentLanding();
        },
        reject: () => {
            //reject action
        }
    });
  }

  removeComponentLanding() {
    this.showMenuEditor = !this.showMenuEditor; //Ocultamos el editor
    this.components = []; //Eliminamos cualquier componente que hayamos incluido para la edición
    let index = this.currentModuleActiveIndex;
    this.landingContentUpdate.modules.splice(index, 1);
    this.dataLandingService.changeLandingData(this.landingContentUpdate);
  }

  duplicateComponentLanding() {
    let index = this.currentModuleActiveIndex;
    this.landingContentUpdate.modules.splice(index+1,0,this.landingContentUpdate.modules[index]);
    this.dataLandingService.changeLandingData(this.landingContentUpdate);

  }

  mouseenter(index) {
    //Usado para hacer autoscroll
    //this.landingContentUpdate.modules[index].commonsOptions.scrollTo = true;
  }

  mouseleave(index) {
    //Usado para hacer autoscroll
    //this.landingContentUpdate.modules[index].commonsOptions.scrollTo = false;
  }

  getShortDescription(index) {
    return this.landingContentUpdate.modules[index].data[Object.keys(this.landingContentUpdate.modules[index].data)[0]];
  }

  todo = [];
  done = this.allModulesAbailables;

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.dataLandingService.changeLandingData(this.landingContentUpdate);
      this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, this.landingPostData.landing_save_and_publish);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
      this.dataLandingService.changeLandingData(this.landingContentUpdate);
      this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, this.landingPostData.landing_save_and_publish);
      //this.saveLanding(); preparar servicio para poder guardar la landing desde cualquier sitio
    }
  }

  ngOnInit() {

    this.code = this.route.snapshot.parent.params.id;

    // Cambio en el objeto landing actualizamos modulos
    this.subscription = this.dataLandingService.landingSource.subscribe((respond:any)=>{
      
      // Pasamos a la vista solo los modules de la respuesta
      this.landingContentUpdate = respond;

      if(this.landingContentUpdate.modules.length) {
        setTimeout(()=>{
          this.showAddModule = true;
        },this.landingContentUpdate.modules.length*100);
        
      } 
      
    })

    // // Recuperamos toda la información de la landing
    // // Ver si nos podemos ahorrar la llamada pasando de el valor del save_and_publish desde el editor

    // this.landingPostData.method = 'getLandingByID';
    // this.landingPostData.user_id = this.userPostData.user_id;
    // this.landingPostData.landing_code = this.code;

    // this.authAPIService.postDataLanding(this.landingPostData).then( respond => {

    //   this.responseLanding = respond;

    //   if (this.responseLanding.code == 200) {

    //     this.landingPostData.landing_save_and_publish = this.responseLanding.landing[0].landing_save_and_publish;

    //   }

    // },
    // err => {
    //     console.log('Error');
    // });

  }

  ngDoCheck(): void {
    //console.log(this.landingContentUpdate.modules);
    var indexActive = this.landingContentUpdate.modules.findIndex(obj => {
      return obj.commonsOptions.activeMenu === true;
    })
    if(indexActive != -1 &&  this.currentModuleActiveIndex != indexActive) {
      this.showComponentEditor(this.landingContentUpdate.modules[indexActive].commonsOptions.editorComponentName, this.landingContentUpdate.modules[indexActive], indexActive)
      // this.landingContentUpdate.modules.forEach((element, index) => {
      //   //if (index != indexActive) {
      //     element.commonsOptions.activeMenu = false;
      //   //}
      // });
    } else {
      // this.landingContentUpdate.modules.forEach((element, index) => {
      //   if (index != indexActive) {
      //     element.commonsOptions.activeMenu = false;
      //   }
      //});
    }
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Funciones relacionadas con las modales /////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  // Actualización del nombre de una landing //////////////////////////////////////////////////

  // updateLandingName(LandingName) {

  //   this.landingPostData.method = 'editLandingName';
  //   this.landingPostData.user_id = this.userPostData.user_id;
  //   this.landingPostData.landing_name = LandingName;
  //   this.landingPostData.landing_code = this.code; // Nos viene dado por parámetro

  //   this.authAPIService.postDataLanding(this.landingPostData).then( result => {
  //     this.responseData = result;

  //     if (this.responseData.code == 200) {      
        
  //       // ... y se cierra la modal
  //       this.modalService.dismissAll();

  //     } 

  //   },
  //   err => { console.log('Error');}
  //   ); 
    
  // }

  // Modal para editar nombre de la landing //////////////////////////

  // changeNameLanding(content) {
  //   this.modalService.open(content, { centered: true });
  // }

}
