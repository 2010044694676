import { Directive, Input } from '@angular/core';

export type DataLabelSize = 'sm' | 'lg' | null;

@Directive({
  selector: 'app-data-label',
  host: {
    'class': 'c-data-label',
    '[class.c-data-label--sm]': 'size === "sm"',
    '[class.c-data-label--lg]': 'size === "lg"',
  },
})
export class DataLabelComponent {

  @Input('size') size: DataLabelSize = null;

}

@Directive({
  selector: '[data-label-caption]',
  host: {
    'class': 'c-data-label__caption',
  },
})
export class DataLabelCaptionComponent {}

@Directive({
  selector: '[data-label-text]',
  host: {
    'class': 'c-data-label__text',
  },
})
export class DataLabelTextComponent {}

@Directive({
  selector: '[data-label-actions]',
  host: {
    'class': 'c-data-label__actions',
  },
})
export class DataLabelActionsComponent {}

@Directive({
  selector: '[data-label-group]',
  host: {
    'class': 'c-data-label-group',
  },
})
export class DataLabelGroupComponent {}
