<main class="d-flex flex-column h-100">
  <!-- Alert -->
  <app-alert></app-alert>
  <!-- / Alert -->
  <!-- Header -->
  <app-header></app-header>
  <!-- / Header -->

    <section class="sec-preview flex-grow-1">
      <!-- Preview Content -->
      <div class="row no-gutters flex-grow-1">
        <div class="col-12 h-100">
          <!-- Preview Preview -->
          <div class="editor-prev">
            <div class="prev-hd">
              <div class="prev-hd-left">
                <button type="button" class="btn btn-gray" routerLink="/editor/{{code}}/overview"><span class="d-none d-md-inline-block">Close preview</span> <svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-view"/></svg></button>
              </div>
              <div class="prev-nav">
                <button type="button" [ngClass]="{'active': currentdisplayType=='mobile'}" (click)="displayType('mobile')"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-mobile"/></svg></button>
                <button type="button" [ngClass]="{'active': currentdisplayType=='tablet'}" (click)="displayType('tablet')"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-tablet"/></svg></button>
                <button type="button" [ngClass]="{'active': currentdisplayType=='desktop'}" (click)="displayType('desktop')"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-desktop"/></svg></button>
              </div>
              <div class="prev-hd-right">
                <button type="button" class="btn btn-primary" routerLink="/export/{{code}}"><span class="d-none d-md-inline-block">Download  / Export</span> <svg class="icon ic-white"><use href="assets/icons/icons-sprite.svg#svg-arrow-down-circle"/></svg></button>
              </div>
            </div>

            <div class="prev-cnt" [ngClass]="{'desktop': currentdisplayType=='desktop', 'tablet': currentdisplayType=='tablet', 'mobile': currentdisplayType=='mobile'}"> <!-- Class: mobile, tablet, desktop -->
              <div class="scroll-wrap position-relative">
                <iframe #iframe frameBorder="0"></iframe>
              </div>
            </div>
          </div>
          <!-- / Preview Preview -->
        </div>
      </div>
      <!-- / Preview Content -->
    </section>
    <!-- <div class="log-box-custom-btn" style="margin: 10px 0">
        <button (click)="saveLanding()" class="btn btn-logout"><span class="fa fa-sign-out"></span>Guardar</button>
    </div> -->
    <!-- <div class="log-box-custom-btn" style="margin: 10px 0">
      <button (click)="shareLanding()" class="btn btn-logout"><span class="fa fa-sign-out"></span>Compartir</button>
    </div> -->
  </main>
