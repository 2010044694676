export class LandingTemplate {
    data =
        {
            "landing_id":null,
            "landing_name":null,
            "config": {
                "landingSettings": {},
                "landingIntegrations":{},
                "landingType":null,
                "landingUse":null,
                "landingProduct":null,
                "landingImage":null,
                "landingFont":null,
                "landingColor": null,
                "landingModules": []
            },
            "modules":[]
        }
        
}