<div class="section-header">
    <h2>Screens</h2>
    <div class="row">
        <div class="col-auto">
            <button class="btn btn-icon btn-icon__right btn-primary" (click)="askCodeScreen(changeLandingNameModal)">
                <span class="d-none d-md-block">Pair a new screen</span> 
                <span class="d-md-none">New screen</span>
                <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>
            </button>
        </div>
        <div class="col-auto ml-auto section-header__filters">
            <p-dropdown [options]="filters" [(ngModel)]="selectedFilter" optionLabel="name"></p-dropdown>
            <!-- <nav class="tab-nav">
                <div class="on">All</div>
                <div>Favorites</div>
            </nav> -->
        </div>
    </div>
</div>
<div class="section-thumbs">
    <div class="c-list-row">
        <div class="c-list-row__item" *ngFor="let item of linkedScreens; let i = index">
            <div class="c-list-row__item__content">
                <div class="c-card-row" routerLink="/screen/555">
                    <div class="c-card-row__col c-card-row__col--media">
                        <div class="c-device-badge c-device-badge--1x1">
                            <div class="c-device-badge__item">
                            <svg class="icon icon-stroke ic-32"><use href="assets/icons/icons-sprite.svg#desktop"/></svg>
                            </div>
                        </div>
                    </div>
                    <div class="c-card-row__col">
                        <app-data-label size="sm">
                            <strong data-label-text>Screen title</strong>
                            <div>
                                <span *ngIf="!item.template_code && item.template_name" app-status type="warning" size="xs">Paused</span>
                                <span *ngIf="item.template_code && item.template_name" app-status type="success" size="xs">Streaming</span>
                                <span *ngIf="!item.template_code && !item.template_name" app-status type="danger" size="xs">Offline</span>
                                <span *ngIf="item.template_code && !item.template_name" app-status type="info" size="xs">Free</span>
                            </div>
                        </app-data-label>
                    </div>
                    <div class="c-card-row__col c-card-row__col--data">
                        <app-data-label size="sm">
                            <span data-label-caption>Template</span>
                            <span data-label-text *ngIf="!item.template_name">-</span>
                            <span data-label-text *ngIf="item.template_name">{{item.template_name}}</span>
                        </app-data-label>
                    </div>
                    <div class="c-card-row__col c-card-row__col--data d-none d-xl-flex">
                        <app-data-label size="sm">
                            <span data-label-caption>Code used</span>
                            <span data-label-text>{{item.screen_code}}</span>
                        </app-data-label>
                    </div>
                    <div class="c-card-row__col c-card-row__col--data">
                        <app-data-label size="sm">
                            <span data-label-caption>Size</span>
                            <span data-label-text>1920x768</span>
                        </app-data-label>
                    </div>
                    <div class="c-card-row__col c-card-row__col--data">
                        <app-data-label size="sm">
                            <span data-label-caption>Added</span>
                            <span data-label-text>{{item.screen_date | date}}</span>
                        </app-data-label>
                    </div>
                </div>
            </div>
            <div class="c-list-row__item__actions">
                <button *ngIf="item.template_name" (click)="play = !play" type="button" class="btn btn-icon btn-icon-only">
                <ng-template [ngIf]="play"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#play-circle"/></svg></ng-template>
                <ng-template [ngIf]="!play"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#pause-circle"/></svg></ng-template>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Modal para cambio de nombre landing -->
<ng-template #changeLandingNameModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Add Screen Code</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Puedes configurar una nueva pantalla en <a href="https://screen.inapp.io/" target="_blank">screen.inapp.io</a></p>
        <p-inputMask  [(ngModel)]="screen_code" mask="999999"></p-inputMask>
        <small *ngIf="modalRespond">{{modalRespond}}</small>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='addNewScreenToTemplate(screen_code)'>Save</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal para cambio de nombre landing -->