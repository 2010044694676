<main class="db db-sidebar-expand">
    <!-- Alert -->
    <!-- <app-alert></app-alert> -->
    <!-- / Alert -->
    <!-- Header -->
    <app-header></app-header>
    <!-- / Header -->
  
    <!-- Menu -->
    <div class="db-menu-mobile-header">
      <div class="c-menu-mobile-header">
        <div class="c-menu-mobile-header__left">
          <button type="button" class="btn btn-icon btn-icon-only" routerLink="/dashboard">
            <svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#home"/></svg>
          </button>
        </div>
        <div class="c-menu-mobile-header__right">
          Settings
        </div>
      </div>
    </div>
    <div class="db-menu-mobile-footer">
      <div class="c-menu-mobile-footer">
        <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="user-profile">
          <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#profile"/></svg>
          <span>User profile</span>
        </button>
        <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="preferences">
          <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#setting"/></svg>
          <span>Preferences</span>
        </button>
        <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="current-plan">
          <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#wallet"/></svg>
          <span>Current plan & billing</span>
        </button>
        <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="linked-tools">
          <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#links"/></svg>
          <span>Linked tools</span>
        </button>
      </div>
    </div>
    <div class="db-sidebar">
      <div class="db-sidebar-menu">
        <div class="db-sidebar-wrap pb-20 h-100 d-flex flex-column justify-content-between">
          <app-menu></app-menu>
        </div>
        <div class="db-sidebar-footer px-0 text-center">
          <strong>© 2022</strong>
        </div>
      </div>
      <div class="db-sidebar-submenu">
        <div class="db-sidebar-wrap pb-20 h-100 d-flex flex-column justify-content-between">
          <div class="c-submenu">
            <div class="c-submenu-block">
              Settings
            </div>
            <div class="c-submenu-block">
              <div class="c-submenu-title">Configuration</div>
              <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="user-profile">
                <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#profile"/></svg>
                <span>User profile</span>
              </button>
              <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="preferences">
                <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#setting"/></svg>
                <span>Preferences</span>
              </button>
              <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="current-plan">
                <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#wallet"/></svg>
                <span>Current plan & billing</span>
              </button>
              <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="linked-tools">
                <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#links"/></svg>
                <span>Linked tools</span>
              </button>
            </div>
          </div>
        </div>
        <div class="db-sidebar-footer">
          <p-confirmDialog header="Confirmation" rejectButtonStyleClass="btn-reject" rejectLabel="Cancel" acceptButtonStyleClass="btn-accept" acceptLabel="Delete"></p-confirmDialog>

          <button type="button" class="btn btn-icon d-flex align-items-center p-0" (click)='confirmRemoveComponentProfile($event)'>
            <svg class="icon icon-stroke ic-danger ic-20 mr-10"><use href="assets/icons/icons-sprite.svg#delete"/></svg>
            <span>Delete profile</span>
          </button>
        </div>
      </div>
    </div>
    <!-- / Menu -->
  
    <section class="db-panel">
      
      <!-- Editor Content -->
      <div class="l-template-container justify-content-center bg-light">
        
        <router-outlet></router-outlet>

      </div>
      <!-- / Editor Content -->
    </section>
  
  </main>

  
<!-- #################################################################################################################################### -->
<!-- ######################                                             Modales                                        ###################-->
<!-- #################################################################################################################################### -->

  <!-- Modal para borrar el perfil  -->
  <!-- <ng-template #removeLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Delete profile</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want to permanently delete this profile?</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='removeLanding(currentLandingId)'>Delete</button>
      </div>
    </div>
  </ng-template> -->
  <!-- Modal para borrar el perfil -->

<!-- #################################################################################################################################### -->
<!-- ######################                                             Modales                                        ###################-->
<!-- #################################################################################################################################### -->