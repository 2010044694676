<main class="d-flex flex-column h-100">
<!-- Header -->
<app-header></app-header>
<!-- / Header -->

  <section class="sec-editor flex-grow-1">
    <!-- Editor Menu -->
    <div class="editor-menu justify-content-between h-100">
      <div class="d-flex flex-column">
          <button type="button" class="btn btn-link btn-menu"  routerLinkActive="active" routerLink="overview"  ><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-home"/></svg></button>
          <button type="button" class="btn btn-link btn-menu"  routerLinkActive="active" routerLink="design" ><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-droplet"/></svg></button>
          <button type="button" class="btn btn-link btn-menu"  routerLinkActive="active" routerLink="font" ><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-font"/></svg></button>
          <button type="button" class="btn btn-link btn-menu"  routerLinkActive="active" routerLink="integrations" ><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-package"/></svg></button>
          <button type="button" class="btn btn-link btn-menu"  routerLinkActive="active" routerLink="components"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-layers"/></svg></button>
        </div>
    </div>
    <!-- / Editor Menu -->
    <!-- Editor Content -->
    <div class="row no-gutters w-100">
      <div class="col-lg-4 col-xxxl-5 h-100 position-relative">
        <!-- <div class="editor-modu">
          <div class="modu-hd">
            <div class="modu-hd-left">
              <a type="button" class="btn btn-gray" href="http://{{responseData.landing[0].landing_subdomain_code}}.boostup.io" target="_blank">http://{{responseData.landing[0].landing_subdomain_code}}.boostup.io <span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-view"></use></svg></span></a>
            </div>
          </div>
        </div> -->
        <!-- Edit Component -->
        <router-outlet></router-outlet>
        <!-- End Edit Component -->
      </div>
      <div class="col-lg-8 col-xxxl-7 h-100 d-none d-lg-block">
        <!-- Editor Preview -->
        <div class="editor-prev">
          <div class="prev-hd">
            <div class="prev-hd-left">
              <a type="button" class="btn btn-gray" href="http://{{subdomainCode}}.boostup.io" target="_blank">http://{{subdomainCode}}.boostup.io <svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-view"/></svg></a>
            </div>
            <div class="prev-nav">
              <button type="button" [ngClass]="{'active': currentdisplayType=='mobile'}" (click)="displayType('mobile')"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-mobile"/></svg></button>
              <button type="button" [ngClass]="{'active': currentdisplayType=='tablet'}" (click)="displayType('tablet')"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-tablet"/></svg></button>
              <button type="button" [ngClass]="{'active': currentdisplayType=='desktop'}" (click)="displayType('desktop')"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-desktop"/></svg></button>
            </div>
            <div class="prev-hd-right">
              <button type="button" class="btn btn-primary"  routerLink="/export/{{code}}">Download  / Export <svg class="icon ic-white"><use href="assets/icons/icons-sprite.svg#svg-arrow-down-circle"/></svg></button>
            </div>
          </div>

          <div class="prev-cnt"> <!-- Class: mobile, tablet, desktop -->
              <div id="editorPrev" #editorPrev class="prev-mockup" [ngClass]="{'desktop': currentdisplayType=='desktop', 'tablet': currentdisplayType=='tablet', 'mobile': currentdisplayType=='mobile'}">
                <img src="../../assets/img/mockups/mockup-{{currentdisplayType}}.svg" alt="">
                <iframe #iframe frameBorder="0"></iframe>
              </div>
          </div>
        </div>
        <!-- / Editor Preview -->
      </div>
    </div>
    <!-- / Editor Content -->
  </section>
</main>
