import { Component, OnInit, Input } from '@angular/core';
import { LandingTemplate } from '../../landing.template';

// Importamos los diferentes campos comunes para poder reutilizar los que sean necesarios
import { EditorCommonsOptions } from '../../editor.commons.options';

// Importamos Servicio
import { LandingService } from '../../../services/landing.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {

  dataLoaded:Boolean = false;
  landingContentUpdate: any = new LandingTemplate().data;
  subscription: any;
  adminSchema: any = {};
  dataLanding: any ;

  constructor(
    private dataLandingService: LandingService) { 

      this.landingContentUpdate.config.landingSettings.title = new EditorCommonsOptions().schema.text;
      this.landingContentUpdate.config.landingSettings.title.properties.data = this.dataLandingService.getCustomValue('title',0);
      this.landingContentUpdate.config.landingSettings.title.properties.label = "Title";

      this.landingContentUpdate.config.landingSettings.claim = new EditorCommonsOptions().schema.text;
      this.landingContentUpdate.config.landingSettings.claim.properties.data = this.dataLandingService.getCustomValue('claim',0);
      this.landingContentUpdate.config.landingSettings.claim.properties.label = "Claim";
      this.landingContentUpdate.config.landingSettings.claim.properties.customSize = false;
      this.landingContentUpdate.config.landingSettings.claim.id = "settings_logo_claim";

      this.landingContentUpdate.config.landingSettings.logo = new EditorCommonsOptions().schema.media;
      this.landingContentUpdate.config.landingSettings.logo.properties.src = this.dataLandingService.getCustomValue('unsplash',0);
      this.landingContentUpdate.config.landingSettings.logo.id = "settings_logo_src";

    }

  ngOnInit(): void {
     
    // Optenemos la información de la landing, vamos a guardar los schemas de color y tipo en el objeto de landing compartido en la aplicación para poder almacenar los cambios
    this.subscription = this.dataLandingService.landingSource.subscribe((respond:any)=>{
      
      if(respond.landing_id != null) {   

        this.dataLoaded = true;
        this.landingContentUpdate = respond;

        if(this.landingContentUpdate && Object.keys(this.landingContentUpdate).length === 0 && Object.getPrototypeOf(this.landingContentUpdate) === Object.prototype) {
          
          // Iteramos los elementos que tenemos en el schema almacenado, podemos tener nuevos y no estar almacenados.
          for (const item in respond.config.landingSettings) {
            if(this.landingContentUpdate.config.landingSettings[item]) {
              this.landingContentUpdate.config.landingSettings[item] = respond.config.landingSettings[item];
              console.log(this.landingContentUpdate.config.landingSettings);
            } 
          }
    
        }  else {

              // No tenemos información de los campos, asociamos copy
              if(!this.landingContentUpdate.config.landingSettings.title) {
                
                console.warn('NO TENEMOS CAMPO TITLE');
                console.log(this.landingContentUpdate.config.landingSettings.title);

                this.landingContentUpdate.config.landingSettings.title = new EditorCommonsOptions().schema.text;
                this.landingContentUpdate.config.landingSettings.title.properties.data = this.dataLandingService.getCustomValue('title',0);
                this.landingContentUpdate.config.landingSettings.title.properties.label = "Logo Name";

              }

              this.landingContentUpdate.config.landingSettings.title.properties.customSize = false;
              this.landingContentUpdate.config.landingSettings.title.id = "settings_logo_text";

              if(!this.landingContentUpdate.config.landingSettings.claim){

                // Claim
                this.landingContentUpdate.config.landingSettings.claim = new EditorCommonsOptions().schema.text;
                this.landingContentUpdate.config.landingSettings.claim.properties.data = this.dataLandingService.getCustomValue('claim',0);
                this.landingContentUpdate.config.landingSettings.claim.properties.label = "Claim";
                this.landingContentUpdate.config.landingSettings.claim.properties.customSize = false;
                this.landingContentUpdate.config.landingSettings.claim.id = "settings_logo_claim";

              }

              if(!this.landingContentUpdate.config.landingSettings.logo){

                // Logo
                this.landingContentUpdate.config.landingSettings.logo = new EditorCommonsOptions().schema.media;
                this.landingContentUpdate.config.landingSettings.logo.properties.src = this.dataLandingService.getCustomValue('unsplash',0);
                this.landingContentUpdate.config.landingSettings.logo.id = "settings_logo_src";

              } 

          }
      }
      
    })

  }

}
