<div class="c-form-editor-cab">{{typeName}} Order</div>
<div 
cdkDropList
[cdkDropListData]="data.items"
(cdkDropListDropped)="drop($event)">
    <div class="c-form-editor-item" *ngFor="let item of data.items; let i = index" cdkDrag>  
        <div class="c-form-editor-item-left">
            <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" ngDefaultControl [(ngModel)]="item.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
        </div>
        <div class="c-form-editor-form c-form-editor-repetible list-modules-item pt-10 pb-10 pl-10" [ngClass]="{'c-form-editor-hide':!item.visible}">
            <div class="row">
                <div class="col">
                    <span *ngIf="item['cardTitle']">{{item['price'].properties.data}} - {{item['cardTitle'].properties.data}}</span>
                    <span *ngIf="!item['cardTitle'] && item['title']">{{item['title'].properties.data}}</span>
                    <span *ngIf="!item['cardTitle'] && !item['title']">Image {{i}}</span>
                </div>
            </div>
            <div class="c-form-editor-repetible-drag-element" cdkDragHandle></div>
        </div>
        <ng-container *ngIf="allowDelete">
            <div class="c-form-editor-item-right" [ngClass]="{'c-form-editor-hide':!item.visible}" *ngIf="data.items.length > 1">
                <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeCard(i)"></button>      
            </div>
        </ng-container>
        
    </div>
</div>

<div class="c-form-editor-item c-form-editor-item__bg mt-10">
    <div class="c-form-editor-item-left"></div>
    <div class="c-form-editor-form">
    </div>
    <div class="pl-5" *ngIf="data.items.length < limit">
        <button pButton pRipple type="button" label="Add new {{typeName}}" icon="pi pi-plus" class="p-button-sm p-button-text mb-10 mr-40" (click)="addCard(data)"></button>
    </div>
</div>
<div class="c-form-editor-line"></div>
<div class="c-form-editor-cab">{{typeName}} Content</div>
<p-tabView>
    <p-tabPanel [header]="''+ (i+1)" *ngFor="let item of data.items | slice:0:limit; let i = index" [selected]="i == 0" [disabled]="!item['visible']">
        <div class="form-group" *ngIf="item['cardTitle']">
            <app-input-simple [(data)]="item['cardTitle']"></app-input-simple>
        </div>
        <div class="form-group" *ngIf="item['price']">
            <app-input-simple [(data)]="item['price']"></app-input-simple>
        </div>
        <div class="form-group" *ngIf="item['duration']">
            <app-input-simple [(data)]="item['duration']"></app-input-simple>
        </div>
        <div class="form-group" *ngIf="item['icon']">
            <div class="c-form-editor-item">
                <div class="c-form-editor-item-left">
                    <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" ngDefaultControl [(ngModel)]="item['icon'].properties.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
                </div>
                <div class="c-form-editor-form" [ngClass]="{'c-form-editor-hide':!item['icon'].properties.visible}">
                    <app-icon-select [(data)]="item['icon'].properties"></app-icon-select>
                </div>
            </div>
            
        </div>
        <div class="form-group" *ngIf="item['title']">
            <app-input-simple [(data)]="item['title']"></app-input-simple>
        </div> 
        <div class="form-group" *ngIf="item['subtitle']">
            <app-input-simple [(data)]="item['subtitle']"></app-input-simple>
        </div>
        <ng-container *ngIf="item['description']">
            <div class="c-form-editor-line"></div>
            <div class="c-form-editor-cab">Description</div>
            <div class="form-group" >
                <app-textarea-toolbar [(data)]="item['description']"></app-textarea-toolbar>
            </div>
        </ng-container>
        <ng-container *ngIf="item['features']">
            <div class="c-form-editor-line"></div>
            <div class="c-form-editor-cab">Features</div>
            <div class="form-group" >
                <app-input-simple-group-repetible [(data)]="item['features']"></app-input-simple-group-repetible>
            </div>
        </ng-container>
        <ng-container *ngIf="item['btn']">
            <div class="c-form-editor-line"></div>
            <div class="c-form-editor-cab">CTA</div>
            <div class="form-group">
                <app-button-cta [(data)]="item['btn']"></app-button-cta>
            </div>
        </ng-container>
        <ng-container *ngIf="item['image']">
            <div class="c-form-editor-line" *ngIf="item['title']"></div>
            <div class="c-form-editor-cab">Image</div>
            <div class="form-group" >
                <app-gallery-images [(data)]="item.image"></app-gallery-images>
            </div> 
        </ng-container>
    </p-tabPanel>
</p-tabView>

