<main class="db">
  <!-- Alert -->
  <!--<app-alert></app-alert>-->
  <!-- / Alert -->

  <!-- Header -->
  <app-header></app-header>
  <!-- / Header -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                        Usuario validado                                    ###################-->
  <!-- #################################################################################################################################### -->

  <div *ngIf="userVal">

    <!-- Dashboard Menu -->
    <div class="db-menu">
      <div class="db-menu-inner">
        <div class="db-menu-wrap">
          <button class="main-button" (click)='startLanding()'>
              <span class="main-button-title">Create a new landing</span>
              <span class="main-button-ico"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></span>
          </button>
          <div class="dashboard-menu-lead" [ngClass]="{'active': isSelectedMenu == 'home'}">
            <span class="dashboard-menu-lead-ico"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-home"/></svg></span>
            <span class="dashboard-menu-lead-title" routerLink="/dashboard">Home</span>
          </div>
          <div class="dashboard-menu-lead transparent">
            <span class="dashboard-menu-lead-ico"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-workspace"/></svg></span>
            <span class="dashboard-menu-lead-title">Your Workspaces</span>
            <button class="dashboard-menu-lead-btn" (click)="showModalNewWorkSpace(newWorkspaceModal)">Add</button>
          </div>
          <div *ngIf="userWorkSpaces">
            <ul class="dashboard-menu-item list-unstyled">
              <li *ngFor="let item of listWorkspaces" [ngClass]="{'active': isSelectedMenu == item.wsp_code}">
                <a (click)="redirectToSelectedWorkspace(item.wsp_code)">{{item.wsp_name}}</a>
                <!-- <button><span><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-dots-menu"></use></svg></span></button> -->
                <button class="warning" (click)="showModalRemoveWorkSpace(removeWorkspaceModal,item.wsp_code,item.wsp_name)">
                  <svg class="icon icon-stroke ic-danger"><use href="assets/icons/icons-sprite.svg#delete"/></svg>
                </button>
              </li>
            </ul>
            <!-- SI HAY MÁS WORKSPACES DE LOS QUE INICIALMENTE SE ESTÁN MOSTRANDO... -->
            <!--<div class="load-btn">
              <span>Load More</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- / Dashboard Menu -->

    <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- //////////                                         START: Dashboard Panel                                                 ////////// -->
    <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    
    <div class="db-panel">
      <div class="db-panel-inner">
        <div class="db-panel-wrap">
          
          <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          <!-- //////////                                  START: Dashboard Highlights                                                 //////////// -->
          <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          
          <div class="db-panel-section-thumbs">
             <!-- Header Filters -->
            
             <div class="section-header">
              <h2>Landings associated with your Workspace "{{currentWorkSpaceName}}"</h2>
              <div class="search-box">
                <input type="submit" value=""><input type="search" placeholder="Search" [(ngModel)]="inputSearch">
              </div>
            </div>

            <!-- End Header Filters -->
            <div class="section-thumbs row">
              
              <!-- Add a new landing page -->
              <div class="col-sm-6 col-lg-4">
                <div class="add-thumb" (click)='startLanding()'>
                  <div class="add-thumb-top">
                    <svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-add"/></svg>
                    <span>This is your next landing page</span>
                  </div>
                  <div class="add-thumb-bottom">
                    <div><span></span><span></span></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
              

              <!-- List of most recently landings-->
              <!-- This DIV is shown only while landing-thumbs are loading. Fade-out when landing-thumbs are loaded  -->
              <ng-container *ngFor="let item of loadingThumbs">
                <div class="col-sm-6 col-lg-4" *ngIf="!landingsLoaded">
                  <div class="loading-thumb on placeholder-loading" style="position: relative;">
                    <div class="top">
                    </div>
                    <div class="bottom">
                      <div><span></span><span></span></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- This DIV is shown only while landing-thumbs are loading. Fade-out when landing-thumbs are loaded  -->

              <ng-container *ngFor="let item of allLandings | searchBar:inputSearch; let i = index" [@listAnimation]="allLandings.length" >
                <div class="col-sm-6 col-lg-4" *ngIf="landingsLoaded">
                  <div class="landing-thumb">
                    <div class="landing-thumb-top">
                      <div class="thumb-wrapper" (click)="goToLanding(item.landing_code);">
                        <app-thumb-landing [modules]="item.landing_content"  (thumbLoaded)='checkThumbLoaded($event, i)'></app-thumb-landing>
                      </div>
                    </div>
                    <div class="landing-thumb-bottom">
                      <div class="landing-thumb-info">
                        <a class="font-weight-bold" style="font-size: 14px; margin-bottom: 3px" routerLink="../editor/{{item.landing_code}}">{{item.landing_name}}</a>
                        <div style="font-size: 12px; opacity: .5">{{item.create_date | date:'dd MMM yyyy'}} <span style="font-style: italic;" *ngIf="item.landing_wsp_name !== 'N/A'">({{item.landing_wsp_name}})</span></div>
                      </div>
                      
                      <!-- Diferentes iconos, estados y acciones en función de si la landing es propia o no y si está compartida o no  -->
                      <!-- ----------------------------------------------------------------------------------------------------------  -->

                      <!-- PROPIAS -->

                      <!-- Landing aún no compartida -->
                      <div class="shorthand-share-off" *ngIf="landingPostData.user_id === item.landing_user_id && item.landing_collaborators_number === '0'" routerLink="/editor/{{item.landing_code}}/collaborate">
                          <span><svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-share-with"/></svg><i>0</i></span>
                      </div>
                      
                      <!-- Landing compartida con al menos 1 usuario -->
                      <div class="shorthand-share-on" *ngIf="landingPostData.user_id === item.landing_user_id && item.landing_collaborators_number !== '0'" routerLink="/editor/{{item.landing_code}}/collaborate">
                        <span><svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-share-with"/></svg><i>{{item.landing_collaborators_number}}</i></span>
                      </div>

                      <!-- DE TERCEROS -->
                    
                      <div class="shorthand-collaborating" *ngIf="landingPostData.user_id !== item.landing_user_id">
                          <span><svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-team"/></svg><i>5</i></span>
                      </div>

                      <!--                                                     Favoritas                                               -->
                      <!-- ----------------------------------------------------------------------------------------------------------  -->
                    
                      <div [ngClass]="(item.landing_favourites == 1)?'shorthand-fav-on':'shorthand-fav-off'" (click)="changeFavouriteState(item.landing_code,item.landing_favourites,i)">
                        <svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-star"/></svg>
                      </div>

                    </div>
                    <div class="landing-thumb-menu" ngbDropdown placement="bottom-right">
                      <button class="landing-thumb-menu-btn" ngbDropdownToggle><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-dots-menu"/></svg></span></button>
                      <ul class="action-menu list-unstyled" ngbDropdownMenu>
                        <li ngbDropdownItem><button routerLink="/preview/{{item.landing_code}}"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-view"/></svg></span>Preview</button></li>
                        <li ngbDropdownItem><button routerLink="/editor/{{item.landing_code}}"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-edit"/></svg></span>Edit</button></li>
                        
                        <!--                                     Espacios de trabajo - Asignados o no                                    -->
                        <!-- ----------------------------------------------------------------------------------------------------------  -->
                        
                        <li ngbDropdownItem *ngIf="landingPostData.user_id === item.landing_user_id">
                          <!-- Asignada a un espacio de trabajo -->  
                          <button *ngIf="item.landing_wsp_name !== 'N/A'" (click)="showModalAsociateWorkSpaceToLanding(asociateWorkspacetoLandingModal,item.landing_code)"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-workspace"/></svg></span>Change WorkSpace</button>
                          <!-- No asignada a un espacio de trabajo -->
                          <button *ngIf="item.landing_wsp_name === 'N/A'" (click)="showModalAsociateWorkSpaceToLanding(asociateWorkspacetoLandingModal,item.landing_code)"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-workspace"/></svg></span>Assign Workspace</button>
                        </li>
                        
                        
                        <li ngbDropdownItem *ngIf="landingPostData.user_id === item.landing_user_id"><button (click)="showModalDuplicateLanding(duplicateLandingModal,item.landing_code,item.landing_name)"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-duplicate"/></svg></span>Duplicate</button></li>
                        <li ngbDropdownItem><button routerLink="/export/{{item.landing_code}}"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-download"/></svg></span>Download</button></li>
                        
                        <!--                                     Eliminación o dejar de seguir una landing                                    -->
                        <!-- ----------------------------------------------------------------------------------------------------------  -->

                        <!-- Landing propia. Permitir eliminarla  -->
                        <li ngbDropdownItem><button class="warning" *ngIf="landingPostData.user_id === item.landing_user_id" (click)='showModalRemoveLanding(removeLandingModal,item.landing_code,item.landing_name)'><span><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#delete"/></svg></span>Delete</button></li>
                        <!-- Landing de tercero. Permitir desvincularse -->
                        <li ngbDropdownItem><button class="warning" *ngIf="landingPostData.user_id !== item.landing_user_id" (click)='showModalUnfollowLanding(unfollowLandingModal,item.landing_code,item.landing_name)'><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#leave"/></svg></span>Stop Collaborating</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Only show when user doesn't have any landing right now -->
              <ng-container *ngFor="let item of ghostThumb;">
                <div class="col-sm-6 col-lg-4" *ngIf="landingsLoaded">
                  <div class="ghost-thumb">
                    &nbsp;
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
          <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          <!-- //////////                                          END: Dashboard Highlights                                             ////////// -->
          <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
        
        </div>
      </div>
    </div>
  </div>

  <!-- #################################################################################################################################### -->
  <!-- ######################                                        Fin Usuario validado                                ################## -->
  <!-- #################################################################################################################################### -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                   Usuario pendiente de validación                          ###################-->
  <!-- #################################################################################################################################### -->

  <div *ngIf="!userVal" class="h-100 d-flex flex-column justify-content-center">
    <section class="sec-editor flex-grow-1 log-box h-100 d-flex flex-column justify-content-center">
      <main class="log-box h-100 d-flex flex-column justify-content-center">
        <h1 class="log-box-title">Bienvenido {{userPostData?.name}}.</h1>
        <div class="log-box-custom">
          <p class="log-box-custom-intro" *ngIf="!userVal">Antes de comenzar a crear tus landing pages, por favor revisa tu correo y sigue los pasos que encontrarás en nuestro
            email de confirmación para validar de tu cuenta.</p>
          <!--<div class="log-box-custom-btn">
            <button (click)='logout()' class="btn btn-logout"><span class="fa fa-sign-out"></span>Cerrar sesión</button>
          </div>-->
        </div>
        <div class="log-box-footer">
          ¿No has recibido ningún correo de confirmación? Comprueba que no ha entrado en alguna bandeja distinta a la de entrada, como
          la de notificaciones o la de correo no deseado (<em>spam</em>). Si tampoco lo encuentras allí, no te
          preocupes: te podemos <span><a (click)='resendConfirmationEMail()'>enviar un nuevo correo de confirmación</a></span>          ahora mismo.
        </div>
        <span class="log-header-footer d-md-none"><b>© 2020 BoostUp.</b> Todos los derechos reservados.</span>
      </main>
    </section>
  </div>

  <!-- #################################################################################################################################### -->
  <!-- ######################                               Fin Usuario pendiente de validación                          ###################-->
  <!-- #################################################################################################################################### -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                             Modales                                        ###################-->
  <!-- #################################################################################################################################### -->

  <!-- Modal para crear un espacio de trabajo -->

  <ng-template #newWorkspaceModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Create a new workspace</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <label>Your New Workspace Name</label>
        <input [(ngModel)]="workSpaceName" type="text" placeholder="New Workspace">
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='createWorkspace(workSpaceName)'>Add</button>
      </div>
    </div>
  </ng-template>

  <!-- Fin Modal para crear un espacio de trabajo -->

  <!-- Modal para eliminar un espacio de trabajo -->

  <ng-template #removeWorkspaceModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Remove <i>"{{workSpaceName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>This action doesn't remove the landings associated with this workspace</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='removeWorkspace(workSpaceId)'>Remove</button>
      </div>
    </div>
  </ng-template>

  <!-- Fin Modal para eliminar un espacio de trabajo -->

  <!-- Modal para asociar una landing a un espacio de trabajo -->

  <ng-template #asociateWorkspacetoLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Asociate Landing to workspace</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <!--<span aria-hidden="true">&times;</span>-->
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <label>Select Workspace </label>
        <select [(ngModel)]="selectedWokspace">
          <option *ngFor="let wsp of listWorkspaces" [value]="wsp.wsp_code">{{wsp.wsp_name}}</option>
        </select>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='associateLandingToWorkspace(landingIdToWorkspace,selectedWokspace)'>Asociate</button>
      </div>
    </div>
  </ng-template>

  <!-- Modal para dejar de seguir una landing -->
  
  <ng-template #unfollowLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Unfollow <i>"{{currentLandingName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>With this action you unfollow the landing. You will need permission from the owner to collaborate again</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='unfollowLanding(currentLandingId)'>Unfollow</button>
      </div>
    </div>
  </ng-template>

  <!-- Fin Modal para dejar de seguir una landing -->

  <!-- Modal de confirmación para borrar una landing -->
  <ng-template #removeLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Delete <i>"{{currentLandingName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want to permanently delete this Landing?</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='removeLanding(currentLandingId)'>Delete</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal de confirmación para borrar una landing -->

  <!-- Modal de confirmación para duplicar una landing -->
  <ng-template #duplicateLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Duplicate <i>"{{currentLandingName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want duplicate this Landing?</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">No</button>
        <button class="focus-button" ngbAutofocus (click)='duplicateLanding(currentLandingId)'>Yes</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal de confirmación para duplicar una landing -->

  <!-- #################################################################################################################################### -->
  <!-- ######################                                           Fin Modales                                      ###################-->
  <!-- #################################################################################################################################### -->

</main>