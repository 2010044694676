import { Component, OnInit, Input } from '@angular/core';
import { EditorCommonsOptions } from '../../../editor.commons.options';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';

@Component({
  selector: 'app-image-group-repetible',
  templateUrl: './image-group-repetible.component.html',
  styleUrls: ['./image-group-repetible.component.scss']
})
export class ImageGroupRepetibleComponent implements OnInit {

  @Input() data: any;
  uploadedFiles: any[] = [];

  constructor(
    private dataLandingService: LandingService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  removeField(index){
    this.data.items.splice(index,1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
  
  addField(_data){

      const logo = new EditorCommonsOptions().schema.media.properties;
      logo.src = '';//this.dataLandingService.getCustomValue('logos','random');

      // Los incluimos en el objeto que vamos a utilizar para pasar al editor y a la vista
      _data.items.push(logo);

      console.log(this.data);
  
  }

  onUpload(event) {
    console.log(event);
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }

    //this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
}

}
