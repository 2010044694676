import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private router: Router, private user: UserService) { }

  canActivate(): boolean {

    if ( this.user.getData() ) {
      return true;
    } else {
      this.user.sessionOut();
      return false;
    }
 
  }

}
