import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthAPIService } from '../../../services/auth-api.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { query } from '@angular/core/src/render3';

@Component({
  selector: 'app-duplicate',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.scss']
})
export class DuplicateComponent implements OnInit{

  // Variables relacionadas con los usuarios
  ///////////////////////////////////////////////////////////////////////////////////////////

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: ''
  };

  // Variables relacionadas con landings
  ///////////////////////////////////////////////////////////////////////////////////////////

  public landingPostData = {
    method: '',
    user_id: '',
    landing_id: '',
    landing_name: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };


  public currentLandingId: any; 
  public currentLandingName: any;
  public duplicateLandingName: any;
  public duplicateLandingCode: any;
  
  public responseData: any; 

  constructor(
    public authAPIService: AuthAPIService, 
    public user: UserService,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal
  ) {
    this.userPostData = this.user.getData();
  }

  ngOnInit() {

    this.landingPostData.method = 'getLandingByID';
    this.landingPostData.landing_code = this.route.snapshot.parent.params.id;
    this.landingPostData.user_id = this.userPostData.user_id;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        this.currentLandingName = this.responseData.landing[0].landing_name;
        this.duplicateLandingName = this.currentLandingName + ' - copy'; 

      } else {

        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      } 

    },
    err => { console.log('Error');}
    );

  }

  // Duplicado de una landing //////////////////////////////////////////////////

  // Modal para inciar la tarea 
  
  showModalDuplicateLanding(content) {
    this.modalService.open(content, { centered: true });
  }

  duplicateLanding(newLandingName) {

    // Solo puede hacerlo el propietario de la landing

    this.landingPostData.method = 'duplicateLanding';
    
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      
      this.responseData = result;

      if (this.responseData.code == 200) {

        this.duplicateLandingCode = this.responseData.userLandings[0].landing_code;

        this.landingPostData.method = 'editLandingName';
        this.landingPostData.landing_name = newLandingName;
        this.landingPostData.user_id = this.userPostData.user_id;
        this.landingPostData.landing_code = this.duplicateLandingCode; // La primera del listado es la última creada

        this.authAPIService.postDataLanding(this.landingPostData).then( result => {
          this.responseData = result;

          if (this.responseData.code == 200) {
           

            this.router.navigateByUrl('dashboard', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/editor/'+this.duplicateLandingCode);
            }); 
            this.modalService.dismissAll();

          } 

        },
        err => { console.log('Error');}
        );

      } 

    },
    err => { console.log('Error');}
    );
    
  }

}
