import { Component, OnInit, Inject  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthAPIService } from '../../../services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { TemplateModules } from '../template.modules';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html'
})

export class BaseComponent implements OnInit {

  // Recuperamos el componente que incluiremos dinamicamente
  getComponent(_type:string,_moduleName:string) {
    let _component = TemplateModules[_type][_moduleName];
    return _component;
  }

  public load(callback, url) {
    let count = 0;
    let element;
      
      element = document.createElement('link');
      element.rel = "stylesheet";
      element.href = url;

      if(callback) {
          element.addEventListener("load", function() {});
      }

      this.document.getElementById('content').setAttribute('href', 'assets/css/landing/type-a/styles.css');
  }
  
  public loaded(url) {
      console.warn("All URLs are now loaded");
  }

  public responseData: any;
  newLanding:any;
  code: any;

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: ''
  };

  public landingPostData = {
    method: 'getLandingByID',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  constructor(
    private route: ActivatedRoute,
    public authAPIService: AuthAPIService, 
    public router: Router,
    public user: UserService,
    @Inject(DOCUMENT) private document
  ) {
    this.userPostData = this.user.getData();
   }

  ngOnInit() {

        this.code = this.route.snapshot.paramMap.get('id');
        // Llamada a servicio para traernos los datos de la landing
        
        this.landingPostData.user_id = this.userPostData.user_id;
        this.landingPostData.landing_code = this.code;

        this.authAPIService.postDataLanding(this.landingPostData).then( result => {
          
          this.responseData = result;
          if (this.responseData.code == 200) {

            let _result = JSON.parse(this.responseData.landing[0].landing_content);
            this.newLanding = _result.modules;

            // CArgamos la hoja de estilos correspondiente para esta landing, quitamos la de la aplicación
            this.load(this.loaded, "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.1/css/lightgallery.min.css");
                  
          } else {

            // Error en el proceso de validación usuario/landing
            this.user.logOut();
          }
        },
        err => {
            console.log('Error');
        }); 


  }

}
