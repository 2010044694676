export class FontFamilyTemplate {
    data =
    {
      'head':[
        {name:"Montserrat",code:"Montserrat:wght@900&display=swap"},
        {name:"Open Sans",code:"Open Sans"},
        {name:"IBM Plex Sans",code:"IBM Plex Sans"},
        {name:"Montserrat",code:"Montserrat:wght@900"},
        {name:"Roboto Mono",code:"Roboto Mono"},
      ],
      'paragraph':[
        {name:"Arial",code:"Arial"},
        {name:"Open Sans",code:"Open Sans"},
        {name:"Trebuchet MS",code:"Trebuchet MS"},
        {name:"Georgia",code:"Georgia"},
        {name:"Roboto",code:"Roboto"},
      ],
      'predefined': [
        {
          id: 0,
          active: false,
          tag: 'Trending',
          font: [
            {
              type:{name:"Montserrat",code:"Montserrat:wght@900"},
              domId: 'custom-font-head',
              variableName:'headings-font-family'
            },
            {
              type:{name:"Open Sans",code:"Open Sans"},
              domId: 'custom-font-paragraph',
              variableName:'font-family'
            }
          ]
        },
        {
          id: 1,
          active: false,
          tag: 'Best for code',
          font: [
            {
              type:{name:"Roboto Slab",code:"Roboto+Slab"},
              domId: 'custom-font-head',
              variableName:'headings-font-family'
            },
            {
              type:{name:"Arial",code:"arial"},
              domId: 'custom-font-paragraph',
              variableName:'font-family'
            }
          ]
        },
        {
          id: 2,
          active: false,
          tag: 'Trending',
          font: [
            {
              type:{name:"IBM Plex Sans",code:"IBM+Plex+Sans"},
              domId: 'custom-font-head',
              variableName:'headings-font-family'
            },
            {
              type:{name:"Inconsolata",code:"Inconsolata"},
              domId: 'custom-font-paragraph',
              variableName:'font-family'
            }
          ]
        },
        {
          id: 3,
          active: false,
          tag: '',
          font: [
            {
              type:{name:"Roboto Mono",code:"Roboto+Mono"},
              domId: 'custom-font-head',
              variableName:'headings-font-family'
            },
            {
              type:{name:"Open Sans",code:"Open Sans"},
              domId: 'custom-font-paragraph',
              variableName:'font-family'
            }
          ]
        }
      ]
      }

}
