<main class="d-flex flex-column h-100">
  <!-- Alert -->
  <app-alert></app-alert>
  <!-- / Alert -->
  <!-- Header -->
  <app-header></app-header>
  <!-- / Header -->

    <section class="sec-editor flex-grow-1 bg-light">
      <!-- Editor Content -->
      <div class="row no-gutters justify-content-center flex-grow-1">
        <!-- Editor Preview -->
         <!-- <div class="col-5 h-100">

          <div class="editor-prev">
            <div class="prev-hd justify-content-center">
              <div class="prev-nav">
                <button type="button" [ngClass]="{'active': currentdisplayType=='mobile'}" (click)="displayType('mobile')"><span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-mobile"></use></svg></span></button>
                <button type="button" [ngClass]="{'active': currentdisplayType=='tablet'}" (click)="displayType('tablet')"><span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-tablet"></use></svg></span></button>
                <button type="button" [ngClass]="{'active': currentdisplayType=='desktop'}" (click)="displayType('desktop')"><span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-desktop"></use></svg></span></button>
              </div>
            </div>

            <div class="prev-cnt" [ngClass]="{'desktop': currentdisplayType=='desktop', 'tablet': currentdisplayType=='tablet', 'mobile': currentdisplayType=='mobile'}">
                <iframe #iframe (load)="onLoad()" frameBorder="0"></iframe>
              </div>
            </div>
          </div>

        </div> -->
         <!-- / Editor Preview -->
        <div class="col-8 h-100 position-relative pricing">
          <div class="scroll-wrap w-100">
            <div class="inner-pricing">
              <section>
                <h1 class="pricing-h1">Ready to launch</h1>
                <p class="pricing-subtxt">Lorem ipsum is a very simple dummy text used since 15th century by linotypists at the printing industry.</p>
                <!-- <div class="pricing-thumb">
                  <div class="row">
                    <div class="col-4">
                      <div class="thumb-wrapper" (click)="goToLanding(item.landing_code);">
                        <app-thumb-landing [modules]="item.landing_content"  (thumbLoaded)='checkThumbLoaded($event, i)'></app-thumb-landing>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="pricing-subscription">
                  <span class="most-chosen">Chosen by most of business</span>
                  <span class="no-fees">No setup or hidden fees!</span>
                  <form class="flat-rate">
                    <div class="flat-rate-title">
                      Unlimited<br/>downloads
                    </div>
                    <div class="flat-rate-plans">
                      <label for="monthly"><strong>By Month</strong>Starter plan</label>
                      <input type="checkbox" id="annual" name="annual" value="annual">
                      <label for="annual"><strong>Annual</strong>Save 20%</label><br>
                    </div>
                    <div class="flat-rate-amont">
                      <span class="flat-rate-amont-price"><strong>69,15</strong>€ / Month</span>
                      <a class="flat-rate-amont-btn" href="#">Subscribe and download</a>
                    </div>
                  </form>
                  <ul class="terms">
                    <li>Get hundreds of feature updates each year</li>
                    <li>Get unlimited downloads</li>
                    <li>Download all current and future integrations at no additional cost</li>
                  </ul>
                </div>
              </section>
              <section>
                <h2 class="pricing-h2">Single download: Pay as you go</h2>
                <p class="pricing-subtxt">Lorem ipsum is a very simple dummy text used since 15th century by linotypists at the printing industry.</p>
                <table class="pricing-table">
                  <thead>
                    <tr>
                      <th><strong>Ready to use in:</strong></th>
                      <th><span class="logo pardot">Pardot</span></th>
                      <th><span class="logo html">HTML</span></th>
                      <th><span class="logo wordpress">WordPress</span></th>
                      <th><span class="logo hubspot">HubSpot</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>Price</strong>One time</td>
                      <td><span class="price">12,9<i>&euro;</i></span></td>
                      <td><span class="price">12,9<i>&euro;</i></span></td>
                      <td><span class="price">12,9<i>&euro;</i></span></td>
                      <td><span class="price">12,9<i>&euro;</i></span></td>
                    </tr>
                    <tr>
                      <td><strong>What includes?</strong> See what your download will includes? <a href="#">Download a demo</a></td>
                      <td>Everything you need to manage your landing in Pardot, HTML, files & our fast CDN for your CSS and JS</td>
                      <td>Everything you need to manage your landing in Pardot, HTML, files & our fast CDN for your CSS and JS</td>
                      <td>Everything you need to manage your landing in Pardot, HTML, files & our fast CDN for your CSS and JS</td>
                      <td>Everything you need to manage your landing in Pardot, HTML, files & our fast CDN for your CSS and JS</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td><button class="download">Download</button></td>
                      <td><button class="download">Download</button></td>
                      <td><button class="download">Download</button></td>
                      <td><button class="download">Download</button></td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </div>
      </div>
      <!-- / Editor Content -->
    </section>
    <!-- <div class="log-box-custom-btn" style="margin: 10px 0">
        <button (click)="saveLanding()" class="btn btn-logout"><span class="fa fa-sign-out"></span>Guardar</button>
    </div> -->
    <!-- <div class="log-box-custom-btn" style="margin: 10px 0">
      <button (click)="shareLanding()" class="btn btn-logout"><span class="fa fa-sign-out"></span>Compartir</button>
    </div> -->
  </main>
