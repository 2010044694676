import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class UserService {
constructor(public router: Router) {}

async storeData(data) {
    localStorage.setItem('userData', JSON.stringify(data));
    const newData = await this.getData();

    if(data.user_type == "guest") {

      return this.router.navigate(['start'], newData);

    } else {

      return this.router.navigate(['dashboard'], newData);

    }
    
}

getData() {
   return JSON.parse(localStorage.getItem('userData'));
}

sessionIn() {
   let A;
   if (this.getData()) {
       A = this.router.navigate(['dashboard'], this.getData());
   }
   return A;
}

sessionAsGuest() {
   let A;
   if (this.getData()) {
       A = this.router.navigate(['start'], this.getData());
   }
   return A;
}

sessionOut() {
   let A;
   if (!this.getData()) {
     A = this.router.navigate(['']);
   }
   return A;
}

logOut() {
   localStorage.setItem('userData', '');
   localStorage.clear();
   return this.router.navigate(['']);
}
}