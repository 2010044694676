<div class="l-panel-full">
    <div class="l-panel-full__content">
        <div class="l-panel-full__header">
            <h5>Linked tools</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius tortor ultrices volutpat nunc erat ullamcorper enim.</p>
        </div>
        <div class="l-panel-full__body">
            <div class="l-panel-full__body__content">
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">Social tools</div>
                        <p-table [value]="socialTools" dataKey="id" editMode="row" class="c-table-edit">
                            <ng-template pTemplate="body" let-socialTool let-editing="editing" let-ri="rowIndex">
                                <tr [pEditableRow]="socialTool">
                                    <td pEditableColumn>
                                        <app-data-label size="sm" class="c-data-label--row">
                                            <span data-label-text><strong>{{socialTool.tool}}</strong></span>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="socialTool.username">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span data-label-caption>{{socialTool.username}}</span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </app-data-label>
                                    </td>
                                    <td class="c-table-edit__actions">
                                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow (click)="onRowEditInit(socialTool)" class="btn btn-link px-0 p-button-text">Edit</button>
                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(socialTool)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(socialTool)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-icon__left ml-auto px-0" (click)="openNew()"><svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>Add new social tool</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="socialDialog" [style]="{width: '450px'}" header="Social tool" [modal]="true" styleClass="p-fluid dialog-tool">
    <ng-template pTemplate="content">
        <div class="p-field">
            <label for="name">Social tool</label>
            <p-dropdown [options]="socialNames" [(ngModel)]="selectedSocial" optionLabel="name" placeholder="Select Social tool"></p-dropdown>
        </div>
        <div class="p-field">
            <label for="userName">User name</label>
            <input type="text" pInputText id="userName"  [(ngModel)]="userName" required autofocus />
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="btn" (click)="hideDialogSocial()">Cancel</button>
        <button class="btn btn-primary" (click)="saveSocialTool(selectedSocial, userName)">Save</button>
    </ng-template>
</p-dialog>

