export class ModuleTemplate {
    data =
    {
        "componentName": null,
        "layoutType": "",
        "landingType": "type-a",
        "modules": [],
        "moduleTypeParent": "",
        "moduleType": "",
        "moduleName": null,
        "moduleIcon": null,
        "moduleImage":  null,
        "moduleColor":  null,
        "data": [{}],
        "schema": {},
        "commonsOptions": {}
    }
        
}