<div class="c-form-editor-item">  
    <div class="c-form-editor-item-left">
        <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" [(ngModel)]="data.properties.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
    </div>
    <div class="c-form-editor-form c-form-editor-repetible list-modules-item pt-10 pb-10 pl-10" [ngClass]="{'c-form-editor-hide':!data.properties.visible}">
        <!-- <app-icon-select [(data)]="data"></app-icon-select> -->
        <div class="row">
            <div class="col">
                <div class="p-float-label">
                    <input id="float-input" type="text" pInputText [(ngModel)]="data.properties.data"> 
                    <label for="float-input">{{data.properties.label}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="p-float-label">
                    <p-dropdown [options]="availableTypes" [(ngModel)]="data.properties.type" optionLabel="name" [showClear]="false" placeholder="Select button action" (onChange)="data.properties.src = ''"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="data.properties.type.code == 'link'">
            <div class="col">
                <div class="p-float-label">
                    <input id="float-input" type="text" pInputText [(ngModel)]="data.properties.src"> 
                    <label for="float-input">Add your external URL</label>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="data.properties.type.code == 'module'">
            <div class="col">
                <div class="p-float-label">
                    <p-dropdown [options]="availableModules" [(ngModel)]="data.properties.src" optionLabel="name" [showClear]="false" placeholder="Select a module in your page"></p-dropdown>
                </div>
                {{data.properties.src.code}}
            </div>
        </div>
        
        <div class="row">
            <div class="col">
            </div>
        </div>
    </div>
</div>
