
<div class="row">
    <div class="col">
        <div class="p-float-label">
            <p-dropdown [options]="iconList" [(ngModel)]="data.icon" optionLabel="code" filterBy="name" placeholder="Select a icon" class="iconListInput">
                <ng-template pTemplate="selectedItem">
                    <div class="icon-item icon-item-value"  *ngIf="data.icon">
                        <div>Selected Icon: &nbsp;<span [class]="data.icon.code"></span></div>
                    </div>
                </ng-template>
                <ng-template let-icon pTemplate="item">
                    <div class="icon-item">
                        <div [class]="icon.code"></div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>
</div>
