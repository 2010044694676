<div 
cdkDropList
[cdkDropListData]="data.items"
(cdkDropListDropped)="drop($event)">
    <div class="c-form-editor-item" *ngFor="let item of data.items; let i = index" cdkDrag>
        <div class="c-form-editor-item-left">
            <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" [(ngModel)]="item.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
        </div>
        <div class="c-form-editor-form c-form-editor-repetible list-modules-item pt-10 pb-10 pl-10" [ngClass]="{'c-form-editor-hide':!item.visible}">
            <div class="row">
                <div class="col">
                    <div class="p-float-label">
                        <input id="float-input" type="text" pInputText [(ngModel)]="item['menu'].properties.data"> 
                        <label for="float-input">{{item['menu'].properties.label}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="p-float-label">
                        <p-dropdown [options]="availableTypes" [(ngModel)]="item['menu'].properties.type" optionLabel="name" [showClear]="false" placeholder="Select button action" (onChange)="item['menu'].properties.src = ''"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="item['menu'].properties.type.code == 'link'">
                <div class="col">
                    <div class="p-float-label">
                        <input id="float-input" type="text" pInputText [(ngModel)]="item['menu'].properties.src"> 
                        <label for="float-input">Add your external URL</label>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="item['menu'].properties.type.code == 'module'">
                <div class="col">
                    <div class="p-float-label">
                        <p-dropdown [options]="availableModules" [(ngModel)]="item['menu'].properties.src" optionLabel="name" [showClear]="false" placeholder="Select a module in your page"></p-dropdown>
                    </div>
                    <!-- {{item['menu'].properties.src.code}} -->
                </div>
            </div>
            <div class="c-form-editor-repetible-drag-element" cdkDragHandle></div>
        </div>
    </div>
</div>

<div class="c-form-editor-item c-form-editor-item__bg mt-10">
    <div class="c-form-editor-item-left"></div>
    <div class="c-form-editor-form">
    </div>
    <div class="pl-5">
        <button pButton pRipple type="button" label="Add new group" icon="pi pi-plus" class="p-button-sm p-button-text mb-10 mr-40" (click)="addField(data)"></button>
    </div>
</div>
