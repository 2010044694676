import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-cta-a',
  templateUrl: './editor-cta-a.component.html',
  styleUrls: ['./editor-cta-a.component.scss']
})
export class EditorCtaAComponent implements OnInit {

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.data);
  }

}
