// Layouts
import { LayoutOneColumnComponent } from './layout-one-column/layout-one-column.component';
import { LayoutTwoColumnComponent } from './layout-two-column/layout-two-column.component';
import { LayoutThreeColumnComponent } from './layout-three-column/layout-three-column.component';

export const TemplateLayouts = {
        'layouts': {
            'layout-one-column': LayoutOneColumnComponent,
            'layout-two-column': LayoutTwoColumnComponent,
            'layout-three-column': LayoutThreeColumnComponent
        }
};