import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
@Injectable()
export class AuthAPIService {

FileUploadModel: any;

constructor( public http: HttpClient ) {}

    postData(credentials) {

        const apiUserUrl = "https://api.inapp.io/index.php/userapi/user/";

        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders();
            this.http.post(apiUserUrl, JSON.stringify(credentials), {headers: headers})
            .subscribe(res => {
                resolve(res);
                //resolve(res.json());
            }, (err) => {
            reject(err);
            });
        });
    
    }

    postDataLanding(data) {

        const apiLandingUrl = "https://api.inapp.io/index.php/landingapi/landing/";

        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders();
            this.http.post(apiLandingUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
                resolve(res);
                //resolve(res.json());
            }, (err) => {
            reject(err);
            });
        });
    
    }

    postDataWorkSpaces(data) {

        const apiWorkspaceUrl = "https://api.inapp.io/index.php/workspaceapi/workspace/";

        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders();
            this.http.post(apiWorkspaceUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
                resolve(res);
                //resolve(res.json());
            }, (err) => {
            reject(err);
            });
        });
    
    }

    postDataMessages(data) {

        const apiLandingUrl = "https://api.inapp.io/index.php/messagesapi/messages/";

        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders();
            this.http.post(apiLandingUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
                resolve(res);
                //resolve(res.json());
            }, (err) => {
            reject(err);
            });
        });
    
    }

    postDataExternal(data) {

        const apiLandingUrl = "https://api.inapp.io/index.php/externalapi/external/";

        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders();
            this.http.post(apiLandingUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
                resolve(res);
                //resolve(res.json());
            }, (err) => {
            reject(err);
            });
        });
    
    }

    //uploadFile(id_alteracion: string, filesToUpload: any, catalogacion: any) {
    uploadFile(data: any) {

        console.log(data);
        const apiLandingUrl = "https://api.inapp.io/index.php/filesapi/files/";
        const formData = new FormData();
        formData.append('query', JSON.stringify(data));

        for (let file of data.landing_media) {
            console.log(file);
            formData.append('asset', file);
        }

        console.log(formData);

        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders();
            this.http.post(apiLandingUrl, formData, {headers: headers})
            .subscribe(res => {
                resolve(res);
                //resolve(res.json());
            }, (err) => {
            reject(err);
            });
        });
    
    }

}