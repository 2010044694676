import { Component, OnInit, Input } from '@angular/core';
import { EditorCommonsOptions } from '../../../editor.commons.options';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';

@Component({
  selector: 'app-card-group-repetible',
  templateUrl: './card-group-repetible.component.html',
  styleUrls: ['./card-group-repetible.component.scss']
})
export class CardGroupRepetibleComponent implements OnInit {

  @Input() data: any;
  @Input() limit: any = 4; // Número máximo de elemntos que se podrán repetir
  @Input() typeName: string; // Usamos este nombre para las cabeceras y poder utilizar el componente en diferentes editores
  allowDelete: boolean;
  uploadedFiles: any[] = [];
  originalData: any;

  constructor(
    private dataLandingService: LandingService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.originalData = this.data;

    if(this.originalData.limit) {
      this.limit = this.originalData.limit;
    }

    console.log('>>>>>>>>>>>>>>>>'+ this.originalData.allowDelete);
    if(this.originalData.allowDelete  == null) {
      this.allowDelete = true;
    } else {
      this.allowDelete = this.originalData.allowDelete;
    }
    console.log('>>>>>>>>>>>>>>>>'+ this.allowDelete);

  }

  removeCard(index){
    this.data.items.splice(index,1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
  
  addCard(_data){

    let item = {};

    let icon = new EditorCommonsOptions().schema.media;
    icon.properties.label = "Icon";

    let image = new EditorCommonsOptions().schema.media;
    image.properties.src = this.dataLandingService.getCustomValue('images',0);
    image.properties.label = "Image";

    let title = new EditorCommonsOptions().schema.text;
    title.properties.data = this.dataLandingService.getCustomValue('title','random');
    title.properties.label = "Title";

    let subtitle = new EditorCommonsOptions().schema.text;
    subtitle.properties.data = this.dataLandingService.getCustomValue('subtitle',"random");
    subtitle.properties.label = "Title";

    let description = new EditorCommonsOptions().schema.text;
    description.properties.data = this.dataLandingService.getCustomValue('largeText',"random");
    description.properties.label = "Title";
    
    let btn = new EditorCommonsOptions().schema.buttom;
    btn.properties.data = this.dataLandingService.getCustomValue('buttomText',1);
    btn.properties.label = "Buttom CTA";
    btn.properties.type.code ='link';
    btn.properties.type.name ='External Link (link to URL)';

    let duration = new EditorCommonsOptions().schema.text;
    duration.properties.data = this.dataLandingService.getCustomValue('periods',"random");
    duration.properties.label = "Duration";

    let price = new EditorCommonsOptions().schema.text;
    price.properties.data = this.dataLandingService.getCustomValue('prices',"random");
    price.properties.label = "Price";
    
    let cardTitle = new EditorCommonsOptions().schema.text;
    cardTitle.properties.data = this.dataLandingService.getCustomValue('subtitle',"random");
    cardTitle.properties.label = "Card Title";

    // Listado de caracteristicas
    let features = new EditorCommonsOptions().schema.card;
    let numberOfFeatures = 5;
    for (let index = 0; index < numberOfFeatures; index++) {
      
      let title = new EditorCommonsOptions().schema.text;
      title.properties.data = this.dataLandingService.getCustomValue('features',"random");
      title.properties.label = "Feature";

      let icon = new EditorCommonsOptions().schema.media;
      icon.properties.label = "Icon";

      let feature = {
        "visible": true,
        "title": title,
        "icon": icon,
      };
      // Incluimos en items la funcionalidad
      features.items.push(feature);
    }

    // Antes de incluir un nuevo elemnto, hacemos un check para ver que tipo de campos teniá la tarjeta, para no incluir tipo de campos que no son necesarios
    item["visible"] = true;
    
    console.log(this.data.items);

    if(this.data.items[0]['title']) {
      item["title"] = title;
    }
    if(this.data.items[0]['cardTitle']) {
      item["cardTitle"] = cardTitle;
    }
    if(this.data.items[0]['subtitle']) {
      item["subtitle"] = subtitle;
    }
    if(this.data.items[0]['description']) {
      item["description"] = description;
    }
    if(this.data.items[0]['btn']) {
      item["btn"] = btn;
    }
    if(this.data.items[0]['image']) {
      item["image"] = image;
    }
    if(this.data.items[0]['icon']) {
      item["icon"] = icon;
    }
    if(this.data.items[0]['duration']) {
      item["duration"] = duration;
    }
    if(this.data.items[0]['features']) {
      item["features"] = features;
    }
    if(this.data.items[0]['price']) {
      item["price"] = price;
    }

    //Incluimos los datos en Items
    _data.items.push(item);

    console.log(this.data);
  
  }

  onUpload(event) {
    console.log(event);
    for(let file of event.files) {
        this.uploadedFiles.push(file);
    }

    //this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
}

}
