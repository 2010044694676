import { Component, OnInit } from '@angular/core';
import { AuthAPIService } from '../../../services/auth-api.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styles: []
})
export class RecoveryComponent implements OnInit {

  public responseData: any;
  public showErrorEmail: boolean = false; 
  public messageError: any;

  public userPostData = {
    method: 'recovery',
    email: '',
    email_conf: '1',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: ''
  };

  constructor(public authAPIService: AuthAPIService,
    public user: UserService) {}

  ngOnInit() {}

  // Send email function with link to reset passaword 

  public sendLinkEmailToResetPassword(email: string) {

    if (email.length == 0) {

      this.showErrorEmail = true;
      this.messageError = 'You must fill in this field';

    } else {

      this.userPostData.method        = 'recovery';
      this.userPostData.email         = email;
      this.userPostData.email_conf    = '1';
      this.userPostData.name          = 'N/A';
      this.userPostData.provider      = 'N/A';
      this.userPostData.password      = 'N/A';
      this.userPostData.provider_id   = 'N/A';
      this.userPostData.provider_pic  = 'N/A';
      this.userPostData.token         = 'N/A';

      this.apiConnection(this.userPostData);

    }

  }

  apiConnection(data:any) {

    this.authAPIService.postData(data).then( result => {
        this.responseData = result;

        if (this.responseData.code == 200) {

          // Email correcto. Enviamos enlace para cambiar la contraseña
  
           //Redirect a Login
           this.user.sessionOut();

        } else {

          // Email no registrado 

          this.showErrorEmail        = true;
          this.messageError          = this.responseData.message;

        }

      },
      err => {
          console.log('Error');
      }
    );
  }

}
