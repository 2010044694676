import { Component, OnInit, Input } from '@angular/core';
import { EditorCommonsOptions } from '../../../editor.commons.options';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';

@Component({
  selector: 'app-input-group-repetible',
  templateUrl: './input-group-repetible.component.html',
  styleUrls: ['./input-group-repetible.component.scss']
})
export class InputGroupRepetibleComponent implements OnInit {
  
  @Input() data: any;
  originalData: any;
  
  constructor(
    private dataLandingService: LandingService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  removeField(index){
    this.data.items.splice(index,1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
  
  addField(_data){

      let fields:[];
      let group:any = [];

      // Definimos los campos que vamos a utlizar
      let title = new EditorCommonsOptions().schema.text;
      title.properties.label = "Title";
      title.properties.data = this.dataLandingService.getCustomValue('subtitle','random');

      let description = new EditorCommonsOptions().schema.text;
      description.properties.label = "Description";
      description.properties.data = this.dataLandingService.getCustomValue('shortText','random');

      let icon = new EditorCommonsOptions().schema.media;
      icon.properties.label = "Icon";

      let item = {
        "visible": true,
        "title": title,
        "icon": icon,
        "description": description,
      };

      // Los incluimos en el objeto que vamos a utilizar para pasar al editor y a la vista
      _data.items.push(item);
  
  }

}
