import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule  } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// Material
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

// 3party Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ColorPickerModule } from 'ngx-color-picker';
import { CodeInputModule } from 'angular-code-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppComponent } from './app.component';

// Commons
import { environment } from '../environments/environment';

/* -------------------------------------------------------------------------- */
/*                                 COMPONENTS                                 */
/* -------------------------------------------------------------------------- */

// Login ----------------------------------------------------------------------
import { SigninComponent } from './login/signin/signin.component';
import { SignupComponent } from './login/signup/signup.component';
import { RecoveryComponent } from './login/recovery/recovery.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';

// Dashboard -----------------------------------------------------------------
import { DashboardComponent } from './dashboard/dashboard.component';

// Workspace ------------------------------------------------------------------
import { WorkspaceComponent } from './dashboard/workspace/workspace.component';

// Start ------------------------------------------------------------------
import { StartComponent } from './start/start.component';

// Layout ------------------------------------------------------------------
import { LayoutBaseComponent } from './templates/layouts/layout-base';

// Editor ------------------------------------------------------------------
import { EditorComponent } from './editor/editor.component';
import { TryoutComponent } from './editor/tryout.component';
import { CollaborationComponent } from './editor/collaboration/collaboration.component';
import { DuplicateComponent } from './editor/duplicate/duplicate.component';
import { DesignComponent } from './editor/design/design.component';
import { IntegrationsComponent } from './editor/integrations/integrations.component';
import { ModulesComponent } from './editor/modules/modules.component';
import { FontComponent } from './editor/font/font.component';
import { ModuleItemComponent } from './editor/modules/module-item/module-item.component';
import { SettingsComponent } from './editor/settings/settings.component';

// User Account
import { UserComponent } from './user/user.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { PreferencesComponent } from './user/preferences/preferences.component';
import { CurrentPlanComponent } from './user/current-plan/current-plan.component';
import { ToolsComponent } from './user/tools/tools.component';

// Layout
import { HeaderComponent } from './shared/header/header.component';
import { LayoutOneColumnComponent } from './templates/layouts/layout-one-column/layout-one-column.component';
import { LayoutTwoColumnComponent } from './templates/layouts/layout-two-column/layout-two-column.component';
import { LayoutThreeColumnComponent } from './templates/layouts/layout-three-column/layout-three-column.component';

// Shared
import { ThumbLandingComponent } from './shared/thumb-landing/thumb-landing.component';
import { AlertComponent } from './shared/alert/alert.component';
import { ChoiceRadioButtonComponent } from './shared/choice-radio-button/choice-radio-button.component';
import { FormConfigComponent } from './shared/components/form-config/form-config.component';
import { DataLabelComponent,
  DataLabelCaptionComponent,
  DataLabelTextComponent,
  DataLabelActionsComponent,
  DataLabelGroupComponent
} from './shared/components/data-label/data-label.component';

/**
 * Social Login Services
 */

import { AuthGuard } from './guards/auth.guard';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

/**
 * Services
 */
import { AuthAPIService } from '../services/auth-api.service';
import { UserService } from '../services/user.service';
import { LandingService } from '../services/landing.service';
import { IframeService } from '../services/iframe.service';
import { ScreensService } from '../services/screens.service';

import { BaseComponent } from './templates/base/base.component';
import { OverviewComponent } from './editor/overview/overview.component';
import { LandingPreviewComponent } from './landing-preview/landing-preview.component';
import { ExportComponent } from './export/export.component';
import { SearchBarPipe } from './core/search-bar.pipe';
import { SafeurlPipe } from './shared/safeurl.pipe';
import { SingleDownloadComponent } from './export/buy/single-download/single-download.component';
import { StartupComponent } from './export/buy/startup/startup.component';
import { StartupYearlyComponent } from './export/buy/startup-yearly/startup-yearly.component';
import { BuyComponent } from './export/buy/buy.component';
import { ModuleItemPreviewComponent } from './editor/modules/module-item-preview/module-item-preview.component';
import { InputSimpleComponent } from './templates/editors-components/input-simple/input-simple.component';
import { EditorCtaAComponent } from './templates/editors/editor-cta-a/editor-cta-a.component';
import { CustomFormComponent } from './templates/editors-components/custom-form/custom-form.component';
import { GalleryImagesComponent } from './templates/editors-components/gallery-images/gallery-images.component';

// PRIMENG
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {EditorModule} from 'primeng/editor';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DropdownModule} from 'primeng/dropdown';
import {RippleModule} from 'primeng/ripple';
import { MessageModule } from 'primeng/message';
import { TabMenuModule } from 'primeng/tabmenu';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FileUploadModule} from 'primeng/fileupload';
import {ToastModule} from 'primeng/toast';
import {GalleriaModule} from 'primeng/galleria';
import {ChipsModule} from 'primeng/chips';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TabViewModule} from 'primeng/tabview';
import { TextareaToolbarComponent } from './templates/editors-components/textarea-toolbar/textarea-toolbar.component';
import {ToolbarModule} from 'primeng/toolbar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {ConfirmationService} from 'primeng/api';
import {TooltipModule} from 'primeng/tooltip';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {TableModule} from 'primeng/table';

import { CustomFormTypeComponent } from './templates/editors-components/custom-form-type/custom-form-type.component';
import { InputGroupRepetibleComponent } from './templates/editors-components/input-group-repetible/input-group-repetible.component';
import { InputSimpleGroupRepetibleComponent } from './templates/editors-components/input-simple-group-repetible/input-simple-group-repetible.component';
import { IconSelectComponent } from './templates/editors-components/icon-select/icon-select.component';
import { ButtonCtaComponent } from './templates/editors-components/button-cta/button-cta.component';
import { ImageGroupRepetibleComponent } from './templates/editors-components/image-group-repetible/image-group-repetible.component';
import { CardGroupRepetibleComponent } from './templates/editors-components/card-group-repetible/card-group-repetible.component';
import { ButtonCtaRepetibleComponent } from './templates/editors-components/button-cta-repetible/button-cta-repetible.component';

import { QuillModule } from 'ngx-quill';
import { SchemaColorComponent } from './templates/editors-components/schema-color/schema-color.component';
import { ScreensComponent } from './dashboard/screens/screens.component';
import { TemplatesComponent } from './dashboard/templates/templates.component';
import { ScreensTemplateComponent } from './editor/screens-template/screens-template.component';
import { ScreensTemplateListComponent } from './editor/screens-template-list/screens-template-list.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { SaveButtonComponent } from './shared/save-button/save-button.component';
import { PublishButtonComponent } from './shared/publish-button/publish-button.component';
import { ButtonUploadComponent } from './templates/editors-components/button-upload/button-upload.component';
import { PreviewComponent } from './editor/preview/preview.component';
import { StatusComponent } from './shared/status/status.component';
import { TimerComponent } from './templates/editors-components/timer/timer.component';
import { DaySelectComponent } from './templates/editors-components/day-select/day-select.component';
import { ButtonAddMediaComponent } from './templates/editors-components/button-add-media/button-add-media.component';
import { DragDropFileComponent } from './templates/editors-components/drag-drop-file/drag-drop-file.component';
import { DndDirective } from './templates/editors-components/drag-drop-file/dnd.directive';
import { ModuleGroupTextComponent } from './templates/components/module-group-text/module-group-text.component';
import { DetailScreenComponent } from './detail-screen/detail-screen.component';
import { SettingsScreenComponent } from './detail-screen/settings-screen/settings-screen.component';
import { LinkedTemplateComponent } from './detail-screen/linked-template/linked-template.component';
import { PexelImagesComponent } from './templates/editors-components/button-add-media/pexel-images/pexel-images.component';
import { UnsplashImagesComponent } from './templates/editors-components/button-add-media/unsplash-images/unsplash-images.component';
import { PexelVideosComponent } from './templates/editors-components/button-add-media/pexel-videos/pexel-videos.component';
import { MyGalleryComponent } from './templates/editors-components/button-add-media/my-gallery/my-gallery.component';
import { ModuleTextComponent } from './templates/components/module-text/module-text.component';
import { ModuleMediaComponent } from './templates/components/module-media/module-media.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    WorkspaceComponent,
    StartComponent,
    SigninComponent,
    SignupComponent,
    RecoveryComponent,
    PasswordResetComponent,
    EditorComponent,
    TryoutComponent,
    BaseComponent,
    CollaborationComponent,
    ThumbLandingComponent,
    AlertComponent,
    DuplicateComponent,
    DesignComponent,
    IntegrationsComponent,
    ModulesComponent,
    OverviewComponent,
    HeaderComponent,
    ModuleItemComponent,
    SettingsComponent,
    LandingPreviewComponent,
    ExportComponent,
	  SearchBarPipe,
    ChoiceRadioButtonComponent,
    SafeurlPipe,
    SingleDownloadComponent,
    StartupComponent,
    StartupYearlyComponent,
    BuyComponent,
    FontComponent,
    ModuleItemPreviewComponent,
    InputSimpleComponent,
    EditorCtaAComponent,
    CustomFormComponent,
    GalleryImagesComponent,
    TextareaToolbarComponent,
    CustomFormTypeComponent,
    InputGroupRepetibleComponent,
    InputSimpleGroupRepetibleComponent,
    IconSelectComponent,
    ButtonCtaComponent,
    ImageGroupRepetibleComponent,
    CardGroupRepetibleComponent,
    FormConfigComponent,
    ButtonCtaRepetibleComponent,
    SchemaColorComponent,
    ScreensComponent,    //AuthGuard
    TemplatesComponent,
    DataLabelComponent,
    DataLabelCaptionComponent,
    DataLabelTextComponent,
    DataLabelActionsComponent,
    DataLabelGroupComponent,
    ScreensTemplateComponent,
    ScreensTemplateListComponent,
    MenuComponent,
    SaveButtonComponent,
    PublishButtonComponent,
    ButtonUploadComponent,
    PreviewComponent,
    StatusComponent,
    TimerComponent,
    DaySelectComponent,
    ButtonAddMediaComponent,
    DragDropFileComponent,
    DndDirective,
    LayoutOneColumnComponent,
    LayoutTwoColumnComponent,
    LayoutThreeColumnComponent,
    DetailScreenComponent,
    SettingsScreenComponent,
    LinkedTemplateComponent,
    ModuleGroupTextComponent,
    PexelImagesComponent,
    UserComponent,
    UserProfileComponent,
    PreferencesComponent,
    CurrentPlanComponent,
    ToolsComponent,
    UnsplashImagesComponent,
    PexelVideosComponent,
    MyGalleryComponent,
    ModuleTextComponent,
    ModuleMediaComponent
  ],
  entryComponents:[
    EditorCtaAComponent,
    ModuleGroupTextComponent,
    ModuleTextComponent,
    ModuleMediaComponent
  ],
  imports: [
    InfiniteScrollModule,
    BrowserModule,
    FormsModule,
    SocialLoginModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    ScrollToModule.forRoot(),
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    NgbModule,
    Ng2SearchPipeModule,
    ColorPickerModule,
    InputTextModule,
    InputSwitchModule,
    EditorModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    RippleModule,
    DropdownModule,
    MessageModule,
    TabMenuModule,
    SelectButtonModule,
    FileUploadModule,
    ToastModule,
    GalleriaModule,
    ChipsModule,
    ToggleButtonModule,
    TabViewModule,
    ToolbarModule,
    ConfirmDialogModule,
    DialogModule,
    TooltipModule,
    InputMaskModule,
    InputNumberModule,
    TableModule,
    QuillModule.forRoot({
      modules: {
        //syntax: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike', 'link'],
          ['emoji'],
        ],
        handlers: {'emoji': function() {}}  // toggled buttons
      },
    }),
    CodeInputModule.forRoot({
      codeLength: 6
    }),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.providerIdGoogle)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.providerIdFacebook)
          }
        ]
      } as SocialAuthServiceConfig,
    },
    LandingService,
    AuthAPIService,
    UserService,
    ConfirmationService,
    IframeService,
    ScreensService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
