<!-- Module Duplicate -->
<div class="module-section">
  <div class="detail-module">
      <div class="detail-module-hd bg-white">
        <p><strong>Duplicate</strong> - {{currentLandingName}}</p>
        <span routerLink="../overview"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg></span>
      </div>

      <div class="detail-module-cnt bg-light">
        <div class="scroll-wrap">
          
          <!-- #################################################################################################################################### -->
          <!-- ######################                                         Proceso de duplicado                               ###################-->
          <!-- #################################################################################################################################### -->

          <div class="c-module-box">
            <div class="c-module-box-title">New landing page by copy</div>
            <!-- Module Item -->
            <div class="c-module-item border-0">
              <div class="w-100 form-group flex-grow-1">
                <label for="">Name</label>
                <div class="w-100 d-flex align-items-start">
                  <div class="flex-grow-1">
                      <input [(ngModel)]="duplicateLandingName" class="form-control" type="text" placeholder="{{duplicateLandingName}}">
                      <small>We create a new Landing Page with the current configuration, modules, copys</small>
                  </div>
                  <button (click)='showModalDuplicateLanding(nextStep)' type="button" class="btn btn-gray ml-15">Copy</button>
                </div>
              </div>
            </div>
            <!-- / Module Item -->
          </div>
          
          <!-- #################################################################################################################################### -->
          <!-- ######################                                         Proceso de duplicado                               ###################-->
          <!-- #################################################################################################################################### -->

          <!-- Module Box Request -->
          <div class="c-module-box no-bg">
            <div class="c-module-box-title">Request Featured <span>- What do you wish XXXX could do or add?</span></div>
            <div class="form-group">
              <textarea class="form-control" id="commentRequestFeatured" rows="3" placeholder="Please include detail or some examples"></textarea>
            </div>
            <button type="submit" class="btn btn-gray">Send Request</button>
          </div>
          <!-- / Module Box Request -->
        </div>
      </div>
  </div>
</div>
<!-- / Module Duplicate -->

<!-- #################################################################################################################################### -->
<!-- ######################                                             Modales                                        ###################-->
<!-- #################################################################################################################################### -->

<!-- Modal para duplicar una landing -->
<ng-template #nextStep let-modal>
  <div class="modal-card">
    <div class="modal-header">
      <h4 class="modal-title"><i>Duplicate Landing "{{currentLandingName}}"</i></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
      </button>
    </div>
    <div class="modal-body">
      <p>Do you want to create a new landing "{{duplicateLandingName}}"?</p>
    </div>
    <div class="modal-footer">
      <button class="alt-button" (click)="modal.close('Close click')">No</button>
      <button class="focus-button" ngbAutofocus (click)="duplicateLanding(duplicateLandingName)">Yes</button>
    </div>
  </div>
</ng-template>
<!-- Modal para duplicar una landing -->

<!-- #################################################################################################################################### -->
<!-- ######################                                             Modales                                        ###################-->
<!-- #################################################################################################################################### -->
