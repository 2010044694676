<main class="db db-sidebar-expand">
  <!-- Alert -->
  <!-- <app-alert></app-alert> -->
  <!-- / Alert -->
  <!-- Header -->
  <app-header></app-header>
  <!-- / Header -->

  <!-- Menu -->
  <div class="db-menu-mobile-header">
    <div class="c-menu-mobile-header">
      <div class="c-menu-mobile-header__left">
        <button type="button" class="btn btn-icon btn-icon-only" routerLink="/dashboard">
          <svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#home"/></svg>
        </button>
      </div>
      <div class="c-menu-mobile-header__right">
        <button type="button" class="btn btn-landing-title" (click)="changeNameLanding(changeLandingNameModal)">
          <span [innerHTML]="landingContentUpdate.landing_name"></span>
          <svg class="icon icon-stroke ic-18 ml-20"><use href="assets/icons/icons-sprite.svg#edit"/></svg>
        </button>
        <button type="button" class="btn btn-link pr-0 ml-5" routerLink="preview">Preview</button>
      </div>
    </div>
  </div>
  <div class="db-menu-mobile-footer">
    <div class="c-menu-mobile-footer">
      <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="overview">
        <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#layers"/></svg>
        <span>Timeline</span>
      </button>
      <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="font">
        <svg class="icon ic-24"><use href="assets/icons/icons-sprite.svg#typo"/></svg>
        <span>Font Family</span>
      </button>
      <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="design">
        <svg class="icon ic-24"><use href="assets/icons/icons-sprite.svg#svg-color"/></svg>
        <span>Colors</span>
      </button>
      <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="settings">
        <svg class="icon ic-24"><use href="assets/icons/icons-sprite.svg#svg-more-circle"/></svg>
        <span>Settings</span>
      </button>
      <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="screens">
        <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#linked-screen"/></svg>
        <span>Linked screens </span>
        <span class="badge badge-pill badge-primary" *ngIf="linkedScreens.length">{{linkedScreens.length}}</span>
      </button>
      <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="components">
        <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#box"/></svg>
        <span>Components</span>
      </button>
    </div>
  </div>
  <div class="db-sidebar">
    <div class="db-sidebar-menu">
      <div class="db-sidebar-wrap pb-20 h-100 d-flex flex-column justify-content-between">
        <app-menu></app-menu>
      </div>
      <div class="db-sidebar-footer px-0 text-center">
        <strong>© 2022</strong>
      </div>
    </div>
    <div class="db-sidebar-submenu">
      <div class="db-sidebar-wrap pb-20 h-100 d-flex flex-column justify-content-between">
        <div class="c-submenu">
          <div class="c-submenu-block">
            <button type="button" class="btn btn-landing-title" (click)="changeNameLanding(changeLandingNameModal)">
              <span [innerHTML]="landingContentUpdate.landing_name"></span>
              <svg class="icon icon-stroke ic-18 ml-20"><use href="assets/icons/icons-sprite.svg#edit"/></svg>
            </button>
          </div>
          <div class="c-submenu-block">
            <div class="c-submenu-title">Content</div>
            <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="overview">
              <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#layers"/></svg>
              <span>Timeline</span>
            </button>
            <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="font">
              <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#typo"/></svg>
              <span>Font Family</span>
            </button>
            <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="design">
              <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-color"/></svg>
              <span>Colors</span>
            </button>
            <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="settings">
              <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-more-circle"/></svg>
              <span>Settings</span>
            </button>
          </div>
          <div class="c-submenu-block">
            <div class="c-submenu-title">Global configuration</div>
            <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="screens">
              <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#linked-screen"/></svg>
              <span>Linked screens </span>
              <span class="badge badge-pill badge-primary" *ngIf="linkedScreens.length">{{linkedScreens.length}}</span>
            </button>
            <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="components">
              <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#box"/></svg>
              <span>Components</span>
            </button>
          </div>
          <!-- <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="/dashboard" pTooltip="Back to dashboard" appendTo="body"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#home"/></svg></button> -->
          <!-- <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="integrations" pTooltip="Integrations" appendTo="body"><span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-package"></use></svg></span></button> -->
          <!-- <button *ngIf="landingOwner" type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="new-version" pTooltip="Create new by copy" appendTo="body"><span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-git-branche"></use></svg></span></button> -->
          <!-- <button *ngIf="landingOwner" type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="collaborate" pTooltip="Collaborate with others" appendTo="body"><span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-people"></use></svg></span><span *ngIf="numberOfCollaborators > 0" class="badge badge-pill badge-primary">{{numberOfCollaborators}}</span></button> -->
        </div>
      </div>
      <div class="db-sidebar-footer">
        <button *ngIf="landingOwner" (click)='showModalRemoveLanding(removeLandingModal,currentLandingId,currentLandingName)' type="button" class="btn btn-icon d-flex align-items-center p-0" routerLink="new-version">
          <svg class="icon icon-stroke ic-danger ic-20 mr-10"><use href="assets/icons/icons-sprite.svg#delete"/></svg>
          <span>Delete timeline</span>
        </button>
      </div>
    </div>
  </div>
  <!-- / Menu -->

  <section class="db-panel">
    
    <!-- Editor Content -->
    <div class="l-template-container">
      
      <div class="l-template-container__editor">

            <!-- <div class="detail-module-hd bg-light">
              <div class="block-save" >
                <button #btnSave id="btnSave" (click)="saveLanding()" type="button" class="btn btn-outline-primary btn-save btn-spinning">Save</button>
                <button id="btnPublish" type="button" class="btn btn-primary btn-publish btn-spinning">Publish</button>
                <ng-container>
                  <input type="checkbox" (change)="changeCheckStatus()" [checked]="checkSaveAndPublish"/>
                  <label for="activateCheck"> Save & Publish</label>
                </ng-container>
              </div>
              <div>
                <ng-container *ngIf="userPostData.user_type === 'admin' ">
                    <button (click)="changeStatusCalalog(code)" type="button" class="btn btn-gray" *ngIf="currentLandingCatalogStatus !== '1' ">Add to catalog </button>
                    <button (click)="changeStatusCalalog(code)" type="button" class="btn btn-gray" *ngIf="currentLandingCatalogStatus === '1' ">Remove from catalog </button>
                </ng-container>
              </div>
            </div> -->

        <!-- Edit Component -->
        <router-outlet></router-outlet>
        <!-- End Edit Component -->
      </div>

      <div class="l-template-container__preview" [ngClass]="{'show': showPreview}">
        
        <!-- Editor Preview -->
        <div class="l-panel-preview">

          <!-- Panel Editor: Header -->
          <div class="l-panel-preview__header">

            <div class="l-panel-preview__header__left">

            </div>

            <div class="l-panel-preview__header__center">
              <div class="l-panel-preview__header__actions">
                <button type="button" class="btn btn-icon btn-icon-only"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#arrow_left4"/></svg></button>
                <button type="button" class="btn btn-icon btn-icon-only"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#rotate"/></svg></button>
                <button type="button" class="btn btn-icon btn-icon-only"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#arrow_right4"/></svg></button>
              </div>
            </div>

            <div class="l-panel-preview__header__right">
              <div>
                <div app-status type="success" value="3" size="sm">Streaming</div>
              </div>
            </div>
            
          </div>
          <!-- / Panel Editor: Header -->

          <!-- Panel Editor: Body -->
          <div class="l-panel-preview__body">

            <div class="scroll-wrap">

              <div class="l-panel-preview__body__content">
              
                <div id="editorPrev" #editorPrev class="c-prev-mockup">
                  <div class="c-prev-mockup__iframe c-prev-mockup__iframe--16x9" #iframeWrapper>
                    <!-- <componente-preview></componente-preview> -->
                    <iframe #iframe frameBorder="0" src="" id="lndPreview"></iframe>
                    <!-- <div class="bg-secondary"></div> -->
                  </div>
                </div>

              </div>

            </div>

          </div>
          <!-- / Panel Editor: Body -->

        </div>

      </div>
        
    </div>
    <!-- / Editor Content -->
  </section>

</main>

<!-- #################################################################################################################################### -->
<!-- ######################                                             Modales                                        ###################-->
<!-- #################################################################################################################################### -->

  <!-- Modal para borrar la landing actual -->
  <ng-template #removeLandingModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Delete <i>"{{currentLandingName}}"</i></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want to permanently delete this Template?</p>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" ngbAutofocus (click)='removeLanding(currentLandingId)'>Delete</button>
      </div>
    </div>
  </ng-template>
  <!-- Modal para borrar la landing actual -->

  <!-- Modal para cambio de sudominio landing -->
  <ng-template #changeSubdomainCodeModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Change Subdomain Code</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <input [(ngModel)]="subdomainCode" type="text">
        <ng-container *ngIf="showSubdomainError">
          <small>Invalid code or code currently in use</small>
        </ng-container>
      </div>
      
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='updateSubdomainCode(subdomainCode)'>Save</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal para cambio de sudominio landing -->

  <!-- Modal para cambio de nombre landing -->
  <ng-template #changeLandingNameModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Change Landing Name</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <input [(ngModel)]="landingContentUpdate.landing_name" type="text">
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='updateLandingName(landingContentUpdate.landing_name)'>Save</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal para cambio de nombre landing -->

<!-- #################################################################################################################################### -->
<!-- ######################                                             Modales                                        ###################-->
<!-- #################################################################################################################################### -->
