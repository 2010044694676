import { Component, OnInit, Input, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { LandingFontSizes } from '../../../landing.fontSizes';

@Component({
  selector: 'app-input-simple',
  templateUrl: './input-simple.component.html',
  styleUrls: ['./input-simple.component.scss']
})
export class InputSimpleComponent implements OnInit, AfterViewInit, AfterContentInit {
  
  @Input() data: any;
  @Input() fontSize: boolean = false;
  listSizeOptions: any = new LandingFontSizes().data;
  sizeOptions:any; 
  sizeOptionCurrentIndex:number;

  constructor() {
  }

  fontDown(){
    if(this.sizeOptionCurrentIndex == 0) return;
    console.log(this.sizeOptions.length);
    console.log(this.sizeOptionCurrentIndex);
    this.data.properties.fontSize = this.sizeOptions[this.sizeOptionCurrentIndex-1].value;
    this.sizeOptionCurrentIndex = this.sizeOptionCurrentIndex-1;
  }

  fontUp(){
    if(this.sizeOptionCurrentIndex+1 == this.sizeOptions.length) return;
    console.log(this.sizeOptions.length);
    this.data.properties.fontSize = this.sizeOptions[this.sizeOptionCurrentIndex+1].value;
    this.sizeOptionCurrentIndex = this.sizeOptionCurrentIndex+1;
  }

  ngOnInit(): void {
    
    this.sizeOptions = this.listSizeOptions[this.data.id];

    setTimeout(() => {
      // Check para ver tenemos que mostrar la opción de tamaño y si el tamaño que nos viene de la respuesta está definido de lo contrario escogemos el primero
      if(this.data.properties.customSize) {
        
        // recorremos los tamaños, si no coincide con ninguno pasamos el primero de la lista (esto puede pasar si modificarmos los valores de los tamañas y alguna landing tiene un valor anterior)
        let result = this.sizeOptions.findIndex(element => {
          console.log(element.value + '==' + this.data.properties.fontSize);
          return element.value === this.data.properties.fontSize;
        });
        
        if(result == -1) {
          // no tenemos tamaño definido o no lo tenemos en el array
          let result = this.sizeOptions.findIndex(obj => {
            return obj.default === true
          })
          this.data.properties.fontSize = this.sizeOptions[result].value;
          console.log(this.data.properties.fontSize);
        }

        //en result almacenamos el indice del elemento que hemos seleccionado
        this.sizeOptionCurrentIndex = result;


      }
    });

  }

  ngAfterContentInit(){}
  ngAfterViewInit() { }

}
