import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { AuthAPIService } from '../../../services/auth-api.service';
import { UserService } from '../../../services/user.service';
//import { AuthService } from 'angular-6-social-login';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingService } from '../../../services/landing.service';
export interface Contact {
  email: string;
}
@Component({
  selector: 'app-collaboration',
  templateUrl: './collaboration.component.html',
  styles: []
})
export class CollaborationComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  landingCodeToShare: any;
  landingOwner: any;
  numberOfCollaborators: any; 
  listOfCollaborator: Contact[] = [];
  resumeCollaborator: [];
  showShare = false;
  public responseData: any;
  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: ''
  };
  public landingPostData = {
    method: '',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_collaborator_email: '',
    landing_favourite: 0
  };
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Añadimos colaboradores
    if ((value || '').trim()) {
      this.listOfCollaborator.push({email: value.trim()});
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  remove(collaborator: Contact): void {
    const index = this.listOfCollaborator.indexOf(collaborator);
    if (index >= 0) {
      this.listOfCollaborator.splice(index, 1);
    }
  } 
  constructor(
    private route: ActivatedRoute,
    public authAPIService: AuthAPIService, 
    public user: UserService,
    private modalService: NgbModal,
    private dataLandingService: LandingService,
  ) {
    this.userPostData = this.user.getData(); 
  }
  ngOnInit() {
    this.landingCodeToShare = this.route.snapshot.parent.params.id;
    this.landingOwner = this.userPostData.user_id;
    // Recuperamos la lista de colaboradores para mostrarsela al usuario
    this.landingPostData.method = 'getCollaborators';
    this.landingPostData.user_id = this.landingOwner;
    this.landingPostData.landing_code = this.landingCodeToShare;
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;
      if (this.responseData.code == 200) {
        if (this.responseData.owner === this.landingPostData.user_id){
          this.showShare = true;
        }
        this.resumeCollaborator = this.responseData.collaborators;
      } else {
        // Error en la actualización
      }
    },
    err => {
        console.log('Error');
    });
  }
  apiConnection(data:any) {}
  // Operaciones relacionadas con las landings ////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  // Envio de la lista de colaboradores ///////////////////////////////////////////
  sendListofCollaborator(listOfCollaborator){    
    this.landingPostData.method = 'collaborators';
    this.landingPostData.user_id = this.landingOwner;
    this.landingPostData.landing_code = this.landingCodeToShare;
    this.landingPostData.landing_collaborators_pending = listOfCollaborator;
    // Comprobamos si en la lista va incluido el email del propietario. Si está se elimina de la lista de colaboradores 

    if (listOfCollaborator.find(item => item === this.userPostData.email)){
      var index = listOfCollaborator.findIndex(item => item === this.userPostData.email);
      this.listOfCollaborator.splice(index, 1);
    } 
    if(listOfCollaborator.length > 0){
      this.authAPIService.postDataLanding(this.landingPostData).then( result => {
        this.responseData = result;
        if (this.responseData.code == 200) {
          // Limpiamos los elementos del input   
          this.listOfCollaborator = [];
          // Recuperamos la situación actualizada de colaboradores y su estado.
          this.resumeCollaborator = this.responseData.collaborators;
          this.numberOfCollaborators = this.resumeCollaborator.length;
          this.dataLandingService.changeCollaboratorNumber(this.numberOfCollaborators);
        } else {
          // Error en la actualización
        }
      },
      err => {
          console.log('Error');
      });
    } else {
      // No se ha introducido ningún usuario como colaborador.
    } 
  }
  // Función para desvincular a un colaborador ////////////////////////////////////////
  removeCollaboratorFromList(collaboratorEmail){    
    this.landingPostData.method = 'unlinkCollaborators';
    this.landingPostData.user_id = this.landingOwner;
    this.landingPostData.landing_code = this.landingCodeToShare;
    this.landingPostData.landing_collaborator_email = collaboratorEmail;
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;
      if (this.responseData.code == 200) {
        // Recuperamos la situación actualizada de colaboradores y su estado.
        this.resumeCollaborator = this.responseData.collaborators;
        this.numberOfCollaborators = this.resumeCollaborator.length;
        this.dataLandingService.changeCollaboratorNumber(this.numberOfCollaborators);
      } else {
        // Error en la actualización
      }
    },
    err => {
        console.log('Error');
    });
  }
  //////////////////////////////////////////////////////////////////////////////
  resendReminder(emailCollaborator) {
    this.userPostData.method  = 'resendReminder';
    this.userPostData.email = emailCollaborator; // Usamos este campo para trasladar al API el valor del email de reenvio
    this.authAPIService.postData(this.userPostData).then( result => {
      this.responseData = result;
        if (this.responseData.code == 200) {
          // Mostrar pop-up de confirmación del envio
        } else {
          // Error en el proceso de actualización.
          console.warn('Ha ocurrido un problema con el envio. Intentalo un poco más tarde');
        }
      },
      err => {
        console.warn('Ha ocurrido un problema con el envio. Intentalo un poco más tarde');
      }
    );
  }
  // Funciones relacionadas con las modales /////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  // Modal para eliminar un colaborador ///////////////////////////////////////////
  showModalRemoveCollaborator(content,collaboratorEmail) {
    this.modalService.open(content, { centered: true });
  }
}