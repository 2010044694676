// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pexelsApiKey: '563492ad6f917000010000016f8661497e8a44a4b86e72f7ddffbe62',
  providerIdFacebook: '388808438479756',
  providerIdGoogle: '149605805137-g747dj92gu7v50oab64sftfs8ummgb4u.apps.googleusercontent.com',
  googleFontApiKey:'AIzaSyDb3Na6t07e_yUxwOgQKHMcjHoimPEWj1w',
  unsplashAccessKey:'gvMR7kYPejScHZshk4gymqBgIx5ZEfEU7sN-mpSFrwg',
  unsplashSecretKey: '0I7ohM0b6Cjc8U4d8tePLxwTRi2maZIS0hx6ud3C24Q',
  apiEndPoint: 'https://api.inapp.io/index.php/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
