export const TemplateGroupsBasic =  [
            {
                'moduleName': 'About',
                'moduleColor': "bg-label-01",
                'moduleIcon': "about",
                'layout' : 'layout-one-column',
                'modules':[
                    'module-group-text'               
                    ]
            },
            {
                'moduleName': 'About',
                'moduleColor': "bg-label-01",
                'moduleIcon': "about",
                'colmuns': [40,30,30],
                'layout' : 'layout-two-column',
                'modules':[
                    'module-group-text',                            
                    'module-text'                            
                    ]
            },
            {
                'moduleName': 'About',
                'moduleColor': "bg-label-01",
                'moduleIcon': "about",
                'colmuns': [40,30,30],
                'layout' : 'layout-one-column',
                'modules':[
                    'module-media'                           
                    ]
            }
        ]
