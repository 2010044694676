import { Component, OnInit, Input } from '@angular/core';
import { EditorCommonsOptions } from '../../../editor.commons.options';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LandingService } from '../../../../services/landing.service';

interface Field {
  name: string,
  code: string
}

@Component({
  selector: 'app-button-cta-repetible',
  templateUrl: './button-cta-repetible.component.html',
  styleUrls: ['./button-cta-repetible.component.scss']
})
export class ButtonCtaRepetibleComponent implements OnInit {


  @Input() data: any;
  originalData: any;
  availableTypes: Field[];
  availableModules: Field[] = [];
  newType:Field;

  constructor(
    private dataLandingService: LandingService,
  ) { 
    this.availableTypes = [
      {name: 'External Link (link to URL)', code: 'link'},
      {name: 'Internal Link (link to any module)', code: 'module'}
  ];
    this.newType = {name: 'External Link (link to URL)', code: 'link'};
  }

  ngOnInit(): void {
    console.log(this.data);

    this.dataLandingService.landingSource.subscribe((respond)=>{
      respond.modules.forEach((element,index) => {
        console.log(element); 
        this.availableModules.push({'name':element.moduleName,code:element.moduleType});
     });
    });
  }
  removeField(index){
    this.data.items.splice(index,1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
  
  addField(_data){

      let fields:[];
      let group:any = [];

      // Definimos los campos que vamos a utlizar
      let menu = new EditorCommonsOptions().schema.text;
      menu.properties.data = this.dataLandingService.getCustomValue('legal', 'random');
      menu.properties.type.code ='link';
      menu.properties.type.name ='External Link (link to URL)';
      menu.properties.label = "Menu Link";

      let item = {
        "visible": true,
        "menu": menu,
      };

      // Los incluimos en el objeto que vamos a utilizar para pasar al editor y a la vista
      _data.items.push(item);  }

}
