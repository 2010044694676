<div class="l-panel-editor">

  <!-- Panel Editor: Header -->
  <div class="l-panel-editor__header" [ngClass]="{'show': showPreview}">
    <div class="d-flex align-items-center">
      <div class="l-panel-editor__header__title">Timeline</div>
      <button type="button" class="btn btn-link d-none d-lg-inline-block d-xxxl-none ml-5" routerLink="../preview">Preview</button>
    </div>
    <div class="l-panel-editor__header__actions">
      <app-save-button></app-save-button>
      <app-publish-button></app-publish-button>
    </div>
  </div>
  <!-- / Panel Editor: Header -->

  <!-- Panel Editor: Body -->
  <div class="l-panel-editor__body">

    <div class="scroll-wrap">

      <div class="l-panel-editor__body__content">
        <!-- List Modules -->
        <div *ngIf="!showMenuEditor" class="list-modules">
          <!-- <div class="list-modules-hd py-15 justify-content-between">
              <h6>Timeline</h6>
              <button type="button" class="btn btn-gray d-lg-none" routerLink="/preview/{{code}}" ><svg class="icon ic-inline ml-0"><use href="assets/icons/icons-sprite.svg#svg-view"/></svg></button>
          </div> -->
          <div class="list-modules-cnt editor-cnt-mw" [@.disabled]="isDisabled">
            
              <div 
                [@cardAnimation]="landingContentUpdate.modules.length"
                cdkDropList
                #todoList="cdkDropList"
                [cdkDropListData]="landingContentUpdate.modules"
                [cdkDropListConnectedTo]="[todoList]"
                class="w-100 d-flex align-items-center flex-column"
                (cdkDropListDropped)="drop($event)">
                <ng-container *ngFor="let item of landingContentUpdate.modules; let i = index">
                    <div class="list-modules-cnt-item-wrap">
                      <!-- -->
                      <div class="visibility-toggle" [hidden]="showMenuEditor">
                        <div class="visibility-toggle-item d-flex justify-content-center align-items-center">
                          <p-toggleButton [(ngModel)]="item.visible" onIcon="pi pi-eye-slash" offIcon="pi pi-eye"></p-toggleButton>
                        </div>
                      </div>
                      <!-- -->
                      <!-- <div class="c-module list-modules-item" [ngClass]="{'hover': item.commonsOptions.hoverTo || item.commonsOptions.activeMenu, 'c-form-editor-hide':item.visible}" cdkDrag (mouseenter)='mouseenter(i)' (mouseleave)='mouseleave(i)' (click)="showComponentEditor(item.commonsOptions.editorComponentName,item, i); item.activeMenu = true"> -->
                      <div class="c-module list-modules-item" [ngClass]="{'hover': item.commonsOptions.hoverTo || item.commonsOptions.activeMenu, 'c-form-editor-hide':item.visible}" cdkDrag (mouseenter)='mouseenter(i)' (mouseleave)='mouseleave(i)' (click)="showComponentEditor(item.modules ,item, i); item.activeMenu = true">
                        <div class="list-modules-item-left">
                          <div class="c-module-miniature" [ngClass]="item['groupColor']"><svg class="icon icon-stroke icon-stroke-2 ic-white ic-18"><use [attr.xlink:href]="'assets/icons/icons-sprite.svg#'+item.groupIcon"/></svg></div>
                          <div class="mb-0 list-modules-item-text"><span class="font-weight-bold">{{item['groupTitle']}}</span><span class="px-5"> - </span><span class="text-small text-truncate">{{item['parentGroupTitle']}}</span></div>
                        </div>
                        <div class="c-weekly">Every day</div>
                        <!-- La funcionalidad del menú desplegable para eliminar o duplicar lo incluiremos en la vista del formulario de edición-->
                      </div>
                      <div class="c-timer">
                        <span>Timer</span>
                        00:30
                      </div>
                      <!-- <div class="module-item-lock" [hidden]="showMenuEditor"><i class="pi pi-lock"></i></div> -->
                    </div>
                </ng-container>
                <div class="list-modules-cnt-item-wrap">
                  <button class="c-module list-modules-item add-module" routerLink="../components/{{i}}" *ngIf="showAddModule">
                    <div class="c-module-miniature bg-primary"><svg class="icon ic-white ic-18"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></div>
                    <!-- <svg><use href="assets/icons/icons-sprite.svg#add-module"/></svg> -->
                    Add section</button>
                </div>
              </div>
          </div>
        </div>
        <!-- / List Modules-->

        <!-- Module Detail -->
        <div class="l-module-detail__backdrop" *ngIf="showMenuEditor" (click)="hideModuleForm(i);"></div>
        <div class="l-module-detail" *ngIf="showMenuEditor">

          <div class="l-module-detail__header">
            <div class="l-module-detail__header__title">{{ActiveModuleName}}</div>
            <div class="l-module-detail__header__actions">
              <button type="button" class="ic-24 ic-svg ic-inline" (click)="hideModuleForm(i);"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg></button>
            </div>
          </div>
          <div class="l-module-detail__body">
            <div class="scroll-wrap">

              <div class="l-module-detail__body__content editor-cnt-mw">
                <p-confirmPopup></p-confirmPopup>
                <p-confirmDialog header="Confirmation" rejectButtonStyleClass="btn-reject" rejectLabel="Cancel" acceptButtonStyleClass="btn-accept" acceptLabel="Delete"></p-confirmDialog>
                <div class="c-form-editor-module">
                <p-tabView>
                  <!-- Module Box Content -->
                  <p-tabPanel header="Content">
                    <ng-template #formEditor></ng-template>
                  </p-tabPanel>
                </p-tabView>     
              </div>
                
                <button class="btn btn-icon d-flex align-items-center p-0" (click)='confirmRemoveComponentLanding($event)'>
                  <svg class="icon icon-stroke ic-danger ic-20 mr-10"><use href="assets/icons/icons-sprite.svg#delete"/></svg>
                  <span>Delete component</span>
                </button>
              </div>

            </div>
          </div>

        </div>
        <!-- / Module Detail -->


      </div>

    </div>

  </div>
  <!-- / Panel Editor: Body -->

</div>