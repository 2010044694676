import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingService } from '../../../services/landing.service';
//import { TemplateModules } from '../../template.modules';
import { TemplateGroups } from '../../templates/template.groups';
import { TemplateLayouts } from '../../templates/layouts/template.layouts';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss'],
  animations: [

    // Trigger animation cards array
    trigger('cardAnimationN', [
      // Transition from any state to any state
      transition('* => *', [
        // Initially the all cards are not visible
        query(':enter', style({ opacity: 0 }), { optional: true }),

        // Each card will appear sequentially with the delay of 300ms
        query(':enter', stagger('100ms', [
          animate('.4s ease-out', keyframes([
            style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(-10px) scale(1)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ]))]), { optional: true }),

        // Cards will disappear sequentially with the delay of 300ms
        query(':leave', stagger('200ms', [
          animate('500ms ease-out', keyframes([
            style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
            style({ opacity: .5, transform: 'scale(.5)', offset: 0.3 }),
            style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
          ]))]), { optional: true })
      ]),
    ])
  ]
})
export class ModulesComponent implements OnInit {
  
  currentIndex:any; // Index donde vamos a incluir el componente, por si venimos de la sección o de la edición para incluirlo en una posición en concreto
  closeUrl:any;
  code:any;
  auxModulesAbailables:any = [];
  currentModules:any = [];
  allModulesAbailables:any = []; 
  allGroupModules:any = []; // Objeto para almacenar todos los grupos disponibles en el tipo de landing ( Heros, LIst, etc)
  selectedGroupModules:any []; // Objeto para alamcenar los componentes dentro de un grupo seleccionado ( se ha seleccionado "Heros" queremos mostrar Hero-a, Hero-b, etc.)
  selectedGroupModulesInfo:any;
  showModulesItemsSidebar:boolean = false; // Mostrar u ocultar el sidebar para editar el modulo
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataLandingService: LandingService
  ) { }


  showModulesItems(groupInfo) {
    console.log(groupInfo);
    this.selectedGroupModules = groupInfo.groups;
    this.selectedGroupModulesInfo = groupInfo;
    this.showModulesItemsSidebar = true;
  }

  hideModulesItems() {
    this.showModulesItemsSidebar = false;
  }

  ngOnInit() {

    // Mostramos la seccion correspondiente en función de la URL, check la primera vez que accedemos
    this.route.paramMap.subscribe(paramMap  => {
      this.code = paramMap.get("id");

      //Si pasamos por URL el indice donde tenemos que incluir el componente, de lo contrario lo incluimos al final
      if(this.code != null && !isNaN(this.code) ){
        // Tenemos un indice, lo usamos
        this.closeUrl = '../../overview';
        this.currentIndex = this.code;
      } else {
        // no tenemos, lo incluimos al final
        this.closeUrl = '../overview';
        this.currentIndex = -1;
      }

    })

    // Cambio en el objeto landing actualizamos modulos
    this.subscription = this.dataLandingService.landingSource.subscribe((respond)=>{
      
      // En algunos casos se actualiza el contenido de la landing en el servicio antes de tener datos.. revisar, hacemos una check de una de las variables
      if(!respond.config.landingUse) return;

      this.currentModules = respond;
      // Recuperamos los modulos disponibles para el tipo de landing seleccionado (type-a, type-b, etc...)
      this.allGroupModules = TemplateGroups['groups'];

      console.log(this.allGroupModules);

      // Hacemos check para mostrar el listado de modulos disponibles que no se están usando y podrían incorporarse a la landing seleccionada
      //this.auxModulesAbailables =  Object.assign({}, TemplateModules[respond.config.landingUse]['layouts']);
      this.auxModulesAbailables =  Object.assign({}, TemplateLayouts['layouts']);
      
      // Por cada modulo no incluido en la landing obtenemos su estructura para poder incluirla si fuera necesario al agregar el modulo
      Object.keys(this.auxModulesAbailables).forEach((element,index) => {
        let _AbailableComponent = this.auxModulesAbailables[element];
        //console.log(_AbailableComponent);
        this.allModulesAbailables[index] = new _AbailableComponent().structure;
      });

      /*this.allModulesAbailables.forEach(element => {
        console.log(element);
        // incluimos moduleAdded false por defecto a todos los modulos
        let numberOfModules = respond.modules.filter(obj => { return obj.moduleType === element.moduleType});
        element.moduleAdded = false;
        // Incluimos el número de componentes disponibles (jpodemos tener duplicados)
        element.numberOfUsedModules = numberOfModules.length;
      });*/

      // Incluimos moduleAdded true a los modulos que ya se estén utilizando en la landing
      // respond.modules.forEach((element,index) => {
      //   let objIndex = this.allModulesAbailables.findIndex(item => item.moduleType === element.moduleType);
      //   this.allModulesAbailables[objIndex].moduleAdded = true;
      // });
      
    })
    
  }

  addModule(templateGroup:any){

    console.log(templateGroup);
    
    //let layoutsArray = Object.assign({}, TemplateGroups['groups']);
    console.log(this.allModulesAbailables);
    let index = this.allModulesAbailables.find((element:any) => {
      console.log(element);
      console.log(element.moduleType + ' = '+ templateGroup.layout);
      return  element.moduleType == templateGroup.layout
    });

    console.log(index);

    // Necesitamos el componente del layout y actualizarle los modulos que se le han asociado en el grupo
    index.modules = templateGroup.modules;
    index.moduleTypeParent = templateGroup.id;
    
    // No tenemos indice, lo inclimos al final
    if(this.currentIndex == -1) {
      
      //this.allModulesAbailables[index].scrollTo = true; // Hacemos scroll en la landing hacia el modulo
      console.log(this.currentModules);
      this.currentModules.modules.push(index);
      this.dataLandingService.changeLandingData(this.currentModules);
      this.router.navigate([this.closeUrl], { relativeTo: this.route });

    } else {
      
      // Tenemos indice lo inclimos a continuación
      //this.allModulesAbailables[index].scrollTo = true; // Hacemos scroll en la landing hacia el modulo
      this.currentModules.modules.splice(this.currentIndex+1,0,this.allModulesAbailables[index]);
      this.dataLandingService.changeLandingData(this.currentModules);
      // cerramos la ventana automaticamente
      this.router.navigate([this.closeUrl], { relativeTo: this.route });

    }
    
  }

  // No se está utlizando actualmente, la eliminación de los bloques se realiza desde el overview
  removeModule(index){
    let moduleType = this.allModulesAbailables[index].moduleType;
    let objIndex = this.currentModules.modules.findIndex(item => item.moduleType === moduleType);
    this.currentModules.modules.splice(objIndex, 1);
    this.dataLandingService.changeLandingData(this.currentModules);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
