<div class="p-dialog-media__search">
  <div class="c-form-editor-item">
    <div class="d-flex align-items-center w-100">
      <div class="c-form-editor-form" [ngClass]="{'c-form-editor-hide':!data.properties.visible}">
        <div class="p-float-label">
          <input id="float-input" type="text" [(ngModel)]="pexelImageSearch" (keydown.enter)="search()" pInputText> 
          <label for="float-input">Search</label>
        </div>  
      </div>
      <div class="ml-5" >
          <button class="btn btn-outline-primary btn-save btn-spinning draw" [ngClass]="{'spinning': !dataLoaded}" (click)="search()">
              <span>Search</span>
          </button>
      </div>
    </div>
  </div>
</div>
<div class="p-dialog-media__cnt" 
  infinite-scroll  
  [scrollWindow]="false"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()">
  <div class="form-row">
    <div class="col-6 col-lg-4" *ngFor="let item of items">
      <!-- 16:9 aspect ratio -->
      <div class="preview-component " (click)="updateMedia(item)">
        <div class="preview-component-top">
          <div class="preview-component-wrapper">
            <div class="embed-responsive embed-responsive-16by9">
                <img class="embed-responsive-item" [src]="item.src.medium">
            </div>
          </div>
          <div class="preview-component-add">
            <button type="button" class="btn btn-primary btn-icon btn-icon__right btn-add">
              Add <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>
            </button>
          </div>
        </div>
    </div>
      <!-- <div class="embed-responsive embed-responsive-16by9" (click)="updateMedia(item)">
        <img class="embed-responsive-item" [src]="item.src.medium">
      </div> -->
    </div>
  </div>
</div>