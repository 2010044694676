<!-- <p-inputSwitch ngDefaultControl [(ngModel)]="data.properties.visible"></p-inputSwitch> -->
<div class="c-form-editor-item">
    <div class="c-form-editor-item-left">
        <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" ngDefaultControl [(ngModel)]="data.properties.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
    </div>
    <div class="c-form-editor-form" [ngClass]="{'c-form-editor-hide':!data.properties.visible}">
        <div>
            <quill-editor  [(ngModel)]="data.properties.data" [modules]="modules"
        (onEditorCreated)="addBindingCreated($event)" class="p-editor-container" [style]="{'height':'130px'}"></quill-editor>
        </div>
        
        <!-- <p-editor [(ngModel)]="data.properties.data" [style]="{'height':'130px'}" [(modules)]="modules" value={quill_data.delta}>
            <p-header></p-header>
        </p-editor> -->

        <!-- <p-editor [(ngModel)]="data.properties.data" [style]="{'height':'130px'}" [(modules)]="modules" value={quill_data.delta}>
            <p-header></p-header>
            <p-header>
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
            <span class="ql-format-separator"></span>
            <button class="ql-link"></button>
            <button class="ql-emoji"></button>
            </p-header>
        </p-editor> -->
        
    </div>
</div>