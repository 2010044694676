import { Component, OnInit, Input } from '@angular/core';
import { LandingService } from '../../../../services/landing.service';

interface Field {
  name: string,
  code: string
}

@Component({
  selector: 'app-button-cta',
  templateUrl: './button-cta.component.html',
  styleUrls: ['./button-cta.component.scss']
})

export class ButtonCtaComponent implements OnInit {


  @Input() data: any;
  availableTypes: Field[];
  availableModules: Field[] = [];
  newType:Field;

  constructor(
    private dataLandingService: LandingService,
  ) { 
    this.availableTypes = [
      {name: 'External Link (link to URL)', code: 'link'},
      {name: 'Internal Link (link to any module)', code: 'module'}
  ];
    this.newType = {name: 'External Link (link to URL)', code: 'link'};
  }

  ngOnInit(): void {

    this.dataLandingService.landingSource.subscribe((respond)=>{
      respond.modules.forEach((element,index) => {
        //console.log(element); 
        this.availableModules.push({'name':element.moduleName,code:element.moduleType});
     });
    });
  }

}
