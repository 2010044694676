
export class ColorSchemaTemplate {
  data =
  {
    type:{
      mode:true
    },
    schemas:[
        {
          id: 0,
          type: 'custom',
          active: false,
          title: 'Default Color Schema',
          color:'light',
          schema:
            [
                {color: '#ebebeb', variableName:'primary-color'},
                {color: '#e6e6e6', variableName:'secondary-color'},
                {color: '#c8c8c8', variableName:'link-color'},
                {color: '#5e4848', variableName:'text-color'},
                {color: '#000000', variableName:'heading-color'},
                {color: '#dadada', variableName:'background-color'}
            ]
        },
        {
          id: 1,
          type: 'trending',
          active: false,
          title: 'Castlevania',
          color:'light',
          schema:
            [
              {color: '#675f5f', variableName:'primary-color'},
              {color: '#cf2323', variableName:'secondary-color'},
              {color: '#5b3c3c', variableName:'link-color'},
              {color: '#bba6a6', variableName:'text-color'},
              {color: '#93b9ff', variableName:'heading-color'},
              {color: '#1e1d1d', variableName:'background-color'}
            ]
        },
        {
          id: 2,
          type: 'trending',
          active: false,
          title: 'Rosy Dew',
          color:'light',
          schema:
            [
                {color: '#272643', variableName:'primary-color'},
                {color: '#E3F6F5', variableName:'secondary-color'},
                {color: '#2C698D', variableName:'link-color'},
                {color: '#2C698D', variableName:'text-color'},
                {color: '#1a3737', variableName:'heading-color'},
                {color: '#BAE8E8', variableName:'background-color'}
            ]
        },
        {
          id: 3,
          type: 'trending',
          active: false,
          title: 'Trees in Bloom',
          color:'light',
          schema:
            [
                {color: '#5b35d5', variableName:'primary-color'},
                {color: '#E3F6F5', variableName:'secondary-color'},
                {color: '#2C698D', variableName:'link-color'},
                {color: '#adaabf', variableName:'text-color'},
                {color: '#fff', variableName:'heading-color'},
                {color: '#0f0d1e', variableName:'background-color'}
            ]
        },
        {
          id: 4,
          type: 'trending',
          active: false,
          title: 'Power Up',
          color:'light',
          schema:
            [
                {color: '#272643', variableName:'primary-color'},
                {color: '#E3F6F5', variableName:'secondary-color'},
                {color: '#2C698D', variableName:'link-color'},
                {color: '#2C698D', variableName:'text-color'},
                {color: '#1a3737', variableName:'heading-color'},
                {color: '#BAE8E8', variableName:'background-color'}
            ]
        }
    ]
  }
}
