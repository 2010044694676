declare var Stripe: any;
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements AfterViewInit {

  @ViewChild('cardInfo', {static: true}) cardInfo: ElementRef;
  card: any;

  constructor() { }

  ngAfterViewInit() {

    // Your Stripe public key
    const _stripe = Stripe('pk_test_BjSfIHsvWGBUG372UA4SZAzr');
    const elements = _stripe.elements();
    const card = elements.create('card', {hidePostalCode: true});
    card.mount(this.cardInfo.nativeElement);

    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Listen for form submission, process the form with Stripe,
    // and get the 
    const paymentForm = document.getElementById('payment-form');
    paymentForm.addEventListener('submit', event => {
      event.preventDefault();
      card.userCountry = "spain";
      _stripe.createToken(card).then(result => {
        if (result.error) {
          console.log('Error creating payment method.');
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // At this point, you should send the token ID
          // to your server so it can attach
          // the payment source to a customer
          console.log('Token acquired!');
          console.log(result);
          console.log(result.token);
          console.log(result.token.id);
        }
      });
    });

  }

}
