import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { AuthAPIService } from '../../../services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { SocialAuthService } from "angularx-social-login";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {trigger, transition, style, animate, query, stagger} from '@angular/animations';


@Component({
  selector: 'app-dashboard',
  templateUrl: './workspace.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(5000, [
            animate('2s', style({ opacity: 0 }))
          ])
        ]),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(5000, [
            animate('2s', style({ opacity: 1 }))
          ])
        ])
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #292b2c;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
    .light-blue-backdrop {
      background-color: #5cb3fd;
    }
  `]
})
export class WorkspaceComponent implements OnInit {

  closeResult: string;
  currentItemIndex: number;
  currenItemLoaded: boolean;
  ghostThumb: any;
  loadingThumbs:any;
  public responseData: any;

  // Variables relacionadas con los usuarios
  ///////////////////////////////////////////////////////////////////////////////////////////

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  public userVal: boolean = false;
  public userLand: boolean = false;
  public userWorkSpaces: boolean = false; 

  // Variables relacionadas con landings
  ///////////////////////////////////////////////////////////////////////////////////////////

  public landingPostData = {
    method: '',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  public listLandings = []; // Landings de las que el usuario es propietario
  public collaboratorLandings = []; // Landings en las que el usuario es colaborador
  public allLandings = []; // Todas las landings asociadas al usuario. Propias y como colaborador
  public recentlyLanding = []; // Últimas landings creadas o modificadas.
  public currentLandingId: any; // Id de la landing sobre la que estamos trabajando
  public currentLandingName: any; // Nombre de la landing sobre la que estamos trabajando  
  public landingsLoaded:boolean = false; // Bool para saber si se han cargado las landings

  // Variables relacionadas con workspaces
  ///////////////////////////////////////////////////////////////////////////////////////////

  public workSpacePostData = {
    method: '',
    user_id: '',
    landing_code: '',
    workspace_code: '',
    workspace_name: ''
  };

  public listWorkspaces = []; // Workspaces del usuario

  public workSpaceName: string = ''; // variable que se usa para recuperar el valor introducido por el usuario al levantar la modal
  public workSpaceId: any; // variable temporal para pasar el id del espacio de trabajo a la modal de eliminación
  public currentWorkSpaceId: any; // Necesarios a nivel general en la página
  public currentWorkSpaceName: any; // Necesarios a nivel general en la página 
  public landingIdToWorkspace: any; // Id de la landing que queremos asociar a un espacio de trabajo
  public selectedWokspace: any; // Valor del wokspace sleccionado para asignar una landing

  // Variables relacionadas con la mensajería
  ///////////////////////////////////////////////////////////////////////////////////////////

  public messagePostData = {
    method: '',
    code: ''
  };

  public messageCode: any;
  public messageText: any; 

  // Variables relacionadas con los menus de filtrado
  ///////////////////////////////////////////////////////////////////////////////////////////

  public allActiveMenu: boolean = true;
  public favActiveMenu: boolean = false;
  public sharedActiveMenu: boolean = false;
  public collaboratingActiveMenu: boolean = false;

  public isSelectedMenu:string; 

  constructor(
    public authAPIService: AuthAPIService, 
    public user: UserService,
    private socialAuthService: SocialAuthService,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    //public activeModal: NgbActiveModal
    //public activeModal: NgbActiveModal
    ) {
    this.userPostData = this.user.getData();
  }

  checkThumbLoaded(state, i){
    this.currentItemIndex = i;
    this.currenItemLoaded = state;
  }

  goToLanding(state){
    this.router.navigateByUrl('/editor/'+state);
  }

  ngOnInit() {

    if (this.userPostData){
    // Usuario logado correctamente

        if (this.userPostData.email_conf === '1'){

          if (this.userPostData.user_type === 'guest'){

            // Acceso indebido. Los usuarios invitados no tienen acceso al dashboard
            this.user.logOut();
            
          } else {
          
            // Cuenta está confirmada
            this.userVal = true;
            // Obtenemos el id del wokspace
            this.currentWorkSpaceId = this.route.snapshot.paramMap.get('id');

            // Recuperamos las landings del usuario asociadas al espacio de trabajo
            this.getLandings();
            // Recuperamos los workspaces del usuario
            this.getWorkSpaces();
          
          }
          
        } else {
          // Si no lo está y la url lleva el parámetro de validación, la activamos y damos acceso al usuario
          const tokenUri: string = this.route.snapshot.queryParamMap.get('t');

          if (tokenUri){
          // Acceso a dashboard con token. Validación de cuenta
    
          this.userPostData.method  = 'valAccount';
          this.userPostData.token   = tokenUri;
          this.apiConnection(this.userPostData);
    
          } else {
          // Acceso indebido. Rediección a Login
            this.user.sessionOut();
          }
        }

    } else {
    // Usuario no logado

      const tokenUri: string = this.route.snapshot.queryParamMap.get('t');
      if (tokenUri){
      // Acceso a dashboard con token. Validación de cuenta

        this.userPostData = {
          method: 'valAccount',
          email: '',
          email_conf: '',
          name: '',
          provider: '',
          password: '',
          provider_id: '',
          provider_pic: '',
          token: tokenUri,
          user_id: '',
          user_type: ''
        };

        this.apiConnection(this.userPostData);

      } else {
      // Acceso indebido. Rediección a Login
        this.user.sessionOut();
      }

    }
    
  }

  logout() {
    if (this.userPostData.provider == 'okb'){
      this.user.logOut();
    } else {
      this.socialAuthService.signOut().then(data => {
        this.user.logOut();
      });
    }  
  }

  resendConfirmationEMail() {
    this.userPostData.method  = 'resendconfirmation';
    this.apiConnection(this.userPostData);
  }

  // Operaciones relacionadas con el usuario ////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////

  apiConnection(data:any) {

    this.authAPIService.postData(this.userPostData).then( result => {
        this.responseData = result;

        if (this.responseData.code == 200) {

          //Almacenamos los valores devueltos en la variable de sesión
          if (this.responseData.userData) {
            this.user.storeData(this.responseData.userData);
            this.userPostData = this.user.getData();
          }

          //Damos acceso al dashboard
          this.user.sessionIn();

          if (this.responseData.userData.email_conf === '1'){
            this.userVal = true;
            this.getLandings();
          } else {
            this.userVal = false;
          }
          
        } else {

          // Error en el proceso de actualización.
          this.user.sessionOut(); 
        }

      },
      err => {
          console.log('Error');
      }
    );
  }

  // Operaciones relacionadas con las landings ////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  // Landings de usuario /////////////////////////////////////////////////////////

  getLandings() {

    // Modificado con respecto al de dashboard. En este caso son necesarias solo las asociadas  al espacio de trabajo

    this.isSelectedMenu = 'home';
    this.landingsLoaded = false;
    this.landingPostData.method = 'list';
    this.landingPostData.user_id = this.userPostData.user_id;
    
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        // Comprobamos si hay landings creadas o no

        if(this.responseData.allLandings.length > 0 ){

          this.ghostThumb = [1,2,3,4,5,6,7,8];
          this.loadingThumbs = [1,2,3,4,5,6,7,8];

          this.userLand = true; // Al menos como propietario o colaborador tiene una landing
          this.ghostThumb.splice(0, this.responseData.allLandings.length); // mostramos 8 marcos vacios, quitamos los que tengan que estar completados con landings
          this.allLandings = this.responseData.allLandings;

          this.getWorkspaceLandings(this.currentWorkSpaceId);

          setTimeout(()=>{
            // All landings - Propias y colaborador
            this.landingsLoaded = true;
          },1000);
          //this.recentlyLanding = this.responseData.allLandings;
          
        }
        
        this.allActiveMenu = true;
        this.favActiveMenu = false;
        this.sharedActiveMenu = false;
        this.collaboratingActiveMenu = false;
              
      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );
  } 

  // Nueva landings de usuario //////////////////////////////////////////////////

  startLanding() {

    // Redirección a la pantalla de Start
    this.router.navigate(['start']);
    
    
  }

  // Borrado de una landing //////////////////////////////////////////////////

  removeLanding(landingCode) {

    // Solo puede hacerlo el propietario de la landing

    this.landingPostData.method = 'removeLanding';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = landingCode;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        this.ghostThumb = [1,2,3,4,5,6,7,8];
        this.loadingThumbs = [1,2,3,4,5,6,7,8];

        this.userLand = true; 
        this.ghostThumb.splice(0, this.responseData.allLandings.length); // mostramos 8 marcos vacios, quitamos los que tengan que estar completados con landings

        // All landings - Propias y colaborador - Si no tiene se envía un array vacio

        this.allLandings = this.responseData.allLandings;
          
        // Landings en las que es colaborador - Si no tiene se envía un array vacio

        this.collaboratorLandings = this.responseData.collaboratorLandings;

      }
        
      // ... y se cierra la modal
      this.modalService.dismissAll();

    },
    err => { console.log('Error');}
    );
    
  }

  // Duplicado de una landing //////////////////////////////////////////////////

  duplicateLanding(landingCode) {

    // Solo puede hacerlo el propietario de la landing

    this.landingPostData.method = 'duplicateLanding';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = landingCode;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        if(this.responseData.allLandings.length > 0 ){

          this.userLand = true; // Al menos como propietario o colaborador tiene una landing

          // All landings - Propias y colaborador

          this.allLandings = this.responseData.allLandings;
          
          // Landings en las que es colaborador

          this.collaboratorLandings = this.responseData.collaboratorLandings;

        }
        
        // ... y se cierra la modal
        this.modalService.dismissAll();

      } 

    },
    err => { console.log('Error');}
    ); 
    
  }

  // Función para dejar de seguir una landing de la que el usuario no es propietario //////////////////////////////////////////////////

  unfollowLanding(landingCode) {

    this.landingPostData.method = 'unfollowLanding';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = landingCode;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        if(this.responseData.allLandings.length > 0 ){

          this.userLand = true; // Al menos como propietario o colaborador tiene una landing

          // All landings - Propias y colaborador
          this.recentlyLanding = this.responseData.allLandings;
          this.allLandings = this.responseData.allLandings;
          
          // Landings en las que es colaborador

          this.collaboratorLandings = this.responseData.collaboratorLandings;

          // solo afecta si la acción se realiza desde la modal
          this.modalService.dismissAll();

        }

      } 
    },
    err => { console.log('Error');}
    );

  }

  // Operaciones relacionadas con los workSpaces ////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  // WorkSpaces de usuario /////////////////////////////////////////////////////////

  getWorkSpaces() {
    
    this.workSpacePostData.method = 'list';
    this.workSpacePostData.user_id = this.userPostData.user_id;
    
    this.authAPIService.postDataWorkSpaces(this.workSpacePostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        if(this.responseData.userWorkspaces.length > 0){

          this.userWorkSpaces = true; // El usuario tiene al menos un espacio de trabajo personal
        
        }

        this.listWorkspaces = this.responseData.userWorkspaces;

        var index = this.listWorkspaces.findIndex(obj => obj.wsp_code === this.currentWorkSpaceId);

        this.currentWorkSpaceName = this.listWorkspaces[index].wsp_name;

      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  // Creación de un espacio de trabajo /////////////////////////////////////////////////////////

  createWorkspace(workspaceName) {

    if (workspaceName !== ''){

      this.workSpacePostData.method = 'create';
      this.workSpacePostData.user_id = this.userPostData.user_id;
      this.workSpacePostData.workspace_name = workspaceName;
      
      this.authAPIService.postDataWorkSpaces(this.workSpacePostData).then( result => {
        this.responseData = result;

        if (this.responseData.code == 200) {

          // Todo ha ido bien. Se actualiza el listado de espacios de trabajo...
          this.listWorkspaces = this.responseData.userWorkspaces;

          // ... y se cierra la modal
          this.modalService.dismissAll();

        } else {

          // Ha ocurrido un error. API nos devuelve el listado original
          this.listWorkspaces = this.responseData.userWorkspaces;
        }

      },
      err => {
          console.log('Error');
      }
      );

    }
  
  }

  // Eliminación de un espacio de trabajo /////////////////////////////////////////////////////////

  removeWorkspace(workspaceCode) {
    
    this.workSpacePostData.method = 'remove';
    this.workSpacePostData.user_id = this.userPostData.user_id;
    this.workSpacePostData.workspace_code = workspaceCode;

    
    this.authAPIService.postDataWorkSpaces(this.workSpacePostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        // Todo ha ido bien. Se actualiza el listado de espacios de trabajo...
        this.listWorkspaces = this.responseData.userWorkspaces;
        // ... y se cierra la modal
        this.modalService.dismissAll();

      } else {

        // Ha ocurrido un error. API nos devuelve el listado original
        alert('no se ha podido eliminar el espacio de trabajo');
        this.listWorkspaces = this.responseData.userWorkspaces;
      
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  // Asociacion de una landing a un espacio de trabajo /////////////////////////////////////////////////////////

  associateLandingToWorkspace(landingCode, workspaceCode) {

    this.workSpacePostData.method = 'associate';
    this.workSpacePostData.landing_code = landingCode;
    this.workSpacePostData.user_id = this.userPostData.user_id;
    this.workSpacePostData.workspace_code = workspaceCode;

    this.authAPIService.postDataWorkSpaces(this.workSpacePostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        //Redirección al espacio de trabajo
        this.router.navigateByUrl('dashboard', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/dashboard/'+workspaceCode+'/workspace');
        });

        // ... y se cierra la modal
        this.modalService.dismissAll();

      } else {

        alert('Se ha producido un error al asociar la landing al espacio de trabajo');
        
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  // Rcuperación de las landings asociadas a un espacio de trabajo /////////////////////////////////////////////////////////

  getWorkspaceLandings(workspaceCode) {
    this.isSelectedMenu = workspaceCode;
    this.landingsLoaded = false;
    this.workSpacePostData.method = 'getwsplandings';
    this.workSpacePostData.user_id = this.userPostData.user_id;
    this.workSpacePostData.workspace_code = workspaceCode;

    this.authAPIService.postDataWorkSpaces(this.workSpacePostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

          this.ghostThumb = [1,2,3,4,5,6,7,8];
          this.loadingThumbs = [1,2,3,4,5,6,7,8];

          this.userLand = true; // Al menos como propietario o colaborador tiene una landing
          this.ghostThumb.splice(0, this.responseData.wspLandings.length); // mostramos 8 marcos vacios, quitamos los que tengan que estar completados con landings
          this.allLandings = this.responseData.wspLandings;
          // Recently Landings - Propias y colaborador
          //this.landingsLoaded = true;

          setTimeout(()=>{
            // All landings - Propias y colaborador
            this.landingsLoaded = true;
          },1000);
          //this.recentlyLanding = this.responseData.allLandings;

          //this.allLandings = this.responseData.wspLandings;

      } else {

        // Ha ocurrido un error. API nos devuelve el listado original
        alert('No se han podido recuperar las landings asociadas a ese espacio de trabajo');
      
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  // Redirección al workspace seleccionado /////////////////////////////////////////////////////////

  redirectToSelectedWorkspace(workspaceCode) {

    // No podemos hacer la redirección sin refresco de url por eso es necesario pasar por aquí

    this.router.navigateByUrl('dashboard', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('/dashboard/'+workspaceCode+'/workspace');
    });

  }


  // Operaciones relacionadas con las landings Favoritas ////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  // Landings favoritas del usuario /////////////////////////////////////////////////////////

  getLandingsFav() {
    
    this.landingPostData.method = 'getFavourites';
    this.landingPostData.user_id = this.userPostData.user_id;
    
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        // Todas las landings marcadas como favoritas

        this.allLandings = this.responseData.landingsFav;

        this.allActiveMenu = false;
        this.favActiveMenu = true;
        this.sharedActiveMenu = false;
        this.collaboratingActiveMenu = false;

      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  // Landings favoritas del usuario /////////////////////////////////////////////////////////

  changeFavouriteState(landingCode,favState,index) {
    
    this.landingPostData.method = 'changeFavouriteState';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = landingCode;
    this.landingPostData.landing_favourite = favState;
    
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;
      
      if (this.responseData.code == 200) {

          if(favState == 1) {
            favState = 0;
          } else {
            favState = 1;
          } 
          this.allLandings[index].landing_favourites = favState;

          if (this.allActiveMenu) {

            // All

            //this.allLandings = this.responseData.allLandings;

          } else if (this.favActiveMenu){

            // Favourites

            //this.allLandings = this.responseData.favLandings;

          } else if (this.sharedActiveMenu){

            // Shared

            //this.allLandings = this.responseData.sharedLandings;

          } else {

            // Collaborating

            //this.allLandings = this.responseData.collabLandings;

          }

      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  // Operaciones relacionadas las landings compartidas ////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  // Landings compartidas por el usuario /////////////////////////////////////////////////////////

  getLandingsShared() {
    
    this.landingPostData.method = 'landingsUserShared';
    this.landingPostData.user_id = this.userPostData.user_id;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

          this.allLandings = this.responseData.landingsList;

      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );

    this.allActiveMenu = false;
    this.favActiveMenu = false;
    this.sharedActiveMenu = true;
    this.collaboratingActiveMenu = false;

      
  }

  // Operaciones relacionadas las landings en las que colaboro ////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  // Landings en las que colabora el usuario /////////////////////////////////////////////////////////

  getLandingsCollaborating() {
    
    this.landingPostData.method = 'landingsUserCollaboration';
    this.landingPostData.user_id = this.userPostData.user_id;
    
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

          this.allLandings = this.responseData.landingsList;

      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );

    this.allActiveMenu = false;
    this.favActiveMenu = false;
    this.sharedActiveMenu = false;
    this.collaboratingActiveMenu = true;

      
  }

  // Funciones relacionadas con las modales /////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  // Modal para nuevo espacio de trabajo ///////////////////////////////////////////
  
  showModalNewWorkSpace(content) {
    this.modalService.open(content, { centered: true });
  }


  // Modal para eliminar un espacio de trabajo ////////////////////////////////////
  
  showModalRemoveWorkSpace(content,wspId, wspName) {
    this.workSpaceName = wspName;
    this.workSpaceId = wspId;
    this.modalService.open(content, { centered: true });
  }

  // Modal para asociar landing a un espacio de trabajo //////////////////////////

  showModalAsociateWorkSpaceToLanding(content,landingId) {
    this.landingIdToWorkspace = landingId;
    this.modalService.open(content, { centered: true });
  }

  // Modal para dejar de seguir una landing que han compartido con el usuario //////////////////////////

  showModalUnfollowLanding(content,landingId,landingName) {
    this.currentLandingId = landingId;
    this.currentLandingName = landingName;
    this.modalService.open(content, { centered: true });
  }

   // Modal para eliminar una landing ///////////////////////////////////////////
  
   showModalRemoveLanding(content,landingId,landingName) {
    this.currentLandingId = landingId;
    this.currentLandingName = landingName;
    this.modalService.open(content, { centered: true });
  }

  // Modal para duplicar una landing //////////////////////////

  showModalDuplicateLanding(content,landingId,landingName) {
    this.currentLandingId = landingId;
    this.currentLandingName = landingName;
    this.modalService.open(content, { centered: true });
  }


}