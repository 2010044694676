import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components

// Login
import { SigninComponent } from './login/signin/signin.component';
import { SignupComponent } from './login/signup/signup.component';
import { RecoveryComponent } from './login/recovery/recovery.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';

// Dashboard
import { DashboardComponent } from './dashboard/dashboard.component';

// Workspace
import { WorkspaceComponent } from './dashboard/workspace/workspace.component';

// Start
import { StartComponent } from './start/start.component';

// Editor
import { EditorComponent} from './editor/editor.component';
import { TryoutComponent} from './editor/tryout.component';
import { ScreensTemplateComponent } from './editor/screens-template/screens-template.component';
import { ScreensTemplateListComponent } from './editor/screens-template-list/screens-template-list.component';

// Collaboration
import { OverviewComponent } from './editor/overview/overview.component';
import { CollaborationComponent } from './editor/collaboration/collaboration.component';
import { DuplicateComponent } from './editor/duplicate/duplicate.component';
import { DesignComponent } from './editor/design/design.component';
import { FontComponent } from './editor/font/font.component';
import { SettingsComponent } from './editor/settings/settings.component';
import { IntegrationsComponent } from './editor/integrations/integrations.component';
import { ModulesComponent } from './editor/modules/modules.component';
import { PreviewComponent } from './editor/preview/preview.component';

// Preview
import { LandingPreviewComponent } from './landing-preview/landing-preview.component';

// Export
import { ExportComponent } from './export/export.component';

// Screen
import { DetailScreenComponent } from './detail-screen/detail-screen.component';
import { SettingsScreenComponent } from './detail-screen/settings-screen/settings-screen.component';
import { LinkedTemplateComponent } from './detail-screen/linked-template/linked-template.component';


// User Account
import { UserComponent } from './user/user.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { PreferencesComponent } from './user/preferences/preferences.component';
import { CurrentPlanComponent } from './user/current-plan/current-plan.component';
import { ToolsComponent } from './user/tools/tools.component';

// Others
import { BaseComponent} from './templates/base/base.component';
import { AuthGuard } from './guards/auth.guard';
import { SingleDownloadComponent } from './export/buy/single-download/single-download.component';
import { BuyComponent } from './export/buy/buy.component';
import { StartupComponent } from './export/buy/startup/startup.component';
import { StartupYearlyComponent } from './export/buy/startup-yearly/startup-yearly.component';
import { ScreensComponent } from './dashboard/screens/screens.component';
import { TemplatesComponent } from './dashboard/templates/templates.component';

const routes: Routes = [
  { path: '',   redirectTo: '/signin', pathMatch: 'full' },
  { path: 'signup', component: SignupComponent, data:{      
    title: 'Landing Page - Login SignUp',
    titlePage: 'SignUp'
  }},
  
  { path: 'signin', component: SigninComponent, data:{      
    title: 'Landing Page - Login SignIn',
    titlePage: 'SignIn'
  }},
  
  { path: 'recovery', component: RecoveryComponent, data:{      
    title: 'Landing Page - Login Recovery',
    titlePage: 'Recovery'
  }},

  { path: 'password-reset', component: PasswordResetComponent, data:{      
    title: 'Landing Page - Login Password Reset',
    titlePage: 'Password Reset'
  }},
  
  { path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'templates', pathMatch: 'full' },
      {
        path: 'screens',
        component: ScreensComponent
      },
      {
        path: 'templates',
        component: TemplatesComponent
      }
    ]
  },

  { path: 'dashboard/:id/workspace', component: WorkspaceComponent, data: {
    title: 'Landing Page - Dashboard - Workspace',
    titlePage: 'Dashboard'
  }},

  { path: 'start', component: StartComponent, data: {
    title: 'Landing Page - Start',
    titlePage: 'Star'}, canActivate: [ AuthGuard ]
  },

  { path: 'editor/:id', 
    component: EditorComponent, 
    data: {
    title: 'Landing Page - Editor',
    titlePage: 'Editor'},
    children: [
      {
          path: '',
          pathMatch: 'full',
          redirectTo: 'overview'
      },
      {
        path: 'screens',
        component: ScreensTemplateComponent,
        data: {
            title: 'Screens - Edit your landing',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'screens-list',
        component: ScreensTemplateListComponent,
        data: {
            title: 'Screens - Edit your landing',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
          path: 'overview',
          component: OverviewComponent,
          data: {
              title: 'BoostUp - Edit your landing',
              menuId: "home",
              showTabs: true,
              showBreadcrumb: true,
          }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
            title: 'BoostUp - Edit your landing',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'design',
        component: DesignComponent,
        data: {
            title: 'BoostUp - design',
            menuId: "color",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'font',
        component: FontComponent,
        data: {
            title: 'BoostUp - font',
            menuId: "font",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'integrations',
        component: IntegrationsComponent,
        data: {
            title: 'BoostUp - Integrations ',
            menuId: "integrations",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'components',
        component: ModulesComponent,
        data: {
            title: 'BoostUp - Select modules ',
            menuId: "components",
            showTabs: true,
            showBreadcrumb: true,
        },
        children: []
      },
      {
        path: 'components/:id',
        component: ModulesComponent,
        data: {
            title: 'BoostUp - Add module',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'new-version',
        component: DuplicateComponent,
        data: {
            title: 'BoostUp - Create new version ',
            menuId: "duplicate",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'collaborate',
        component: CollaborationComponent,
        data: {
            title: 'BoostUp - Create new version ',
            menuId: "collaboration",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'preview',
        component: PreviewComponent,
        data: {
            title: 'Preview - Edit your screen',
            menuId: "preview",
            showTabs: true,
            showBreadcrumb: true,
        }
      }]
    , canActivate: [ AuthGuard ]
  },
  { path: 'screen/:id', 
    component: DetailScreenComponent, 
    data: {
    title: 'Screen - Detail',
    titlePage: 'Screen'},
    children: [
      {
          path: '',
          pathMatch: 'full',
          redirectTo: 'settings'
      },
      {
        path: 'settings',
        component: SettingsScreenComponent,
        data: {
            title: 'Screens - Detail',
            menuId: "screen",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'template',
        component: LinkedTemplateComponent,
        data: {
            title: 'Screens - Detail',
            menuId: "linkedTemplate",
            showTabs: true,
            showBreadcrumb: true,
        }
      }
    ]
  },
  { path: 'user/:id', 
    component: UserComponent, 
    data: {
    title: 'User - Account',
    titlePage: 'User Account'},
    children: [
      {
          path: '',
          pathMatch: 'full',
          redirectTo: 'user-profile'
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        data: {
            title: 'User - Account',
            menuId: "userProfile",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'preferences',
        component: PreferencesComponent,
        data: {
            title: 'User - Account',
            menuId: "preferences",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'current-plan',
        component: CurrentPlanComponent,
        data: {
            title: 'User - Account',
            menuId: "currentPlan",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'linked-tools',
        component: ToolsComponent,
        data: {
            title: 'User - Account',
            menuId: "tools",
            showTabs: true,
            showBreadcrumb: true,
        }
      }
    ]
  },
  { path: 'tryout/:id', 
    component: TryoutComponent, 
    data: {
    title: 'Landing Page - Editor',
    titlePage: 'Editor'},
    children: [
      {
          path: '',
          pathMatch: 'full',
          redirectTo: 'overview'
      },
      {
          path: 'overview',
          component: OverviewComponent,
          data: {
              title: 'BoostUp - Edit your landing',
              menuId: "home",
              showTabs: true,
              showBreadcrumb: true,
          }
      },
      {
        path: 'design',
        component: DesignComponent,
        data: {
            title: 'BoostUp - design',
            menuId: "color",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'font',
        component: FontComponent,
        data: {
            title: 'BoostUp - font',
            menuId: "font",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'integrations',
        component: IntegrationsComponent,
        data: {
            title: 'BoostUp - Integrations ',
            menuId: "integrations",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'components',
        component: ModulesComponent,
        data: {
            title: 'BoostUp - Select modules ',
            menuId: "components",
            showTabs: true,
            showBreadcrumb: true,
        },
        children: []
      },
      {
        path: 'components/:id',
        component: ModulesComponent,
        data: {
            title: 'BoostUp - Add module',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'new-version',
        component: DuplicateComponent,
        data: {
            title: 'BoostUp - Create new version ',
            menuId: "duplicate",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'collaborate',
        component: CollaborationComponent,
        data: {
            title: 'BoostUp - Create new version ',
            menuId: "collaboration",
            showTabs: true,
            showBreadcrumb: true,
        }
      },
      {
        path: 'preview',
        component: PreviewComponent,
        data: {
            title: 'Preview - Edit your screen',
            menuId: "preview",
            showTabs: true,
            showBreadcrumb: true,
        }
      }]
    , canActivate: [ AuthGuard ]
  },

  { path: 'landing/:id', component: BaseComponent, data: {
    title: 'Landing Page - Preview',
    titlePage: 'Preview'}, canActivate: [ AuthGuard ]
  },

  { path: 'preview/:id', component: LandingPreviewComponent, data: {
    title: 'Landing Page - Preview',
    titlePage: 'Preview'}, canActivate: [ AuthGuard ]
  },

  { path: 'export/:id', component: ExportComponent, data: {
    title: 'Landing Page - Preview',
    titlePage: 'Preview'}, canActivate: [ AuthGuard ],
    children: []
  },

  { path: 'buy/:id', component: BuyComponent, data: {
    title: 'Landing Page - Preview',
    titlePage: 'Preview'}, canActivate: [ AuthGuard ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'startup'
    },
    {
        path: 'startup',
        component: StartupComponent,
        data: {
            title: 'BoostUp - Edit your landing',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
    },
    {
        path: 'startup',
        component: StartupComponent,
        data: {
            title: 'BoostUp - Edit your landing',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
    },
    {
        path: 'startup-yearly',
        component: StartupYearlyComponent,
        data: {
            title: 'BoostUp - Edit your landing',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
    },
    {
        path: 'single-download',
        component: SingleDownloadComponent,
        data: {
            title: 'BoostUp - Edit your landing',
            menuId: "home",
            showTabs: true,
            showBreadcrumb: true,
        }
    },
    ]
  },

  { path: 'collaboration/:id', component: CollaborationComponent, data: {
    title: 'Landing Page - Collaboration',
    titlePage: 'Preview'}, canActivate: [ AuthGuard ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true /*, enableTracing: true*/ })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
