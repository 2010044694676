import { Component, OnInit } from '@angular/core';
import { AuthAPIService } from '../../../services/auth-api.service';
import { UserService } from '../../../services/user.service';
import { Md5 } from 'ts-md5/dist/md5';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styles: []
})
export class PasswordResetComponent implements OnInit {

  public responseData: any;
  public showErrorPass: boolean = false; 
  public messageError: any;

  public userPostData = {
    method: 'password',
    email: '',
    email_conf: '1',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: ''
  };

  constructor(public authAPIService: AuthAPIService,
    public user: UserService, private route: ActivatedRoute) { }

  ngOnInit() {
    const tokenUri: string = this.route.snapshot.queryParamMap.get('t');
    this.userPostData.token = tokenUri;
  }

  // el email del usuario es necesario aquí

  public resetPassword(password: string) {

    if (password.length == 0) {

      this.showErrorPass = true;
      this.messageError = 'You must fill in this field';

    } else if (password.length < 6) {

      this.showErrorPass = true;
      this.messageError = 'Please: use a six or more characters password';

    } else {

      this.userPostData.password      = (Md5.hashStr(password) as string);
      this.apiConnection(this.userPostData);
    
    }

  }

  apiConnection(data:any) {


    this.authAPIService.postData(this.userPostData).then( result => {
        this.responseData = result;

        if (this.responseData.code == 200) {

          //Almacenamos los valores devueltos en la variable de sesión
        
          if (this.responseData.userData) {
            this.user.storeData(this.responseData.userData);
          }

          //Redirect a dashboard

          this.user.sessionIn();

        } else {

          // Error en el proceso de actualización. 

          this.showErrorPass        = true;
          this.messageError         = this.responseData.message;

        }

      },
      err => {
          console.log('Error');
      }
    );
  }

}
