import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, Input, Type } from '@angular/core';
import { LandingService } from '../../../services/landing.service';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-publish-button',
  templateUrl: './publish-button.component.html',
  styleUrls: ['./publish-button.component.scss']
})
export class PublishButtonComponent implements OnInit, AfterViewChecked {

  @ViewChild('btnPublish') btnPublish: ElementRef;
  code:any;
  userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  constructor(
    public dataLandingService: LandingService,
    public user: UserService,
    public router: Router,
    private route: ActivatedRoute
  ) { 
    this.userPostData = this.user.getData();
  }

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('id');
  }

  ngAfterViewChecked() {
    this.dataLandingService.setBtnPublish(this.btnPublish.nativeElement);
  }

  publishLanding(){
    //this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, this.landingPostData.landing_save_and_publish );
    this.dataLandingService.publishLanding(this.userPostData.user_id, this.code, 1 );
  }

}
