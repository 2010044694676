import { Component, OnInit, OnChanges, ElementRef, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';


// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-button-upload',
  templateUrl: './button-upload.component.html',
  styleUrls: ['./button-upload.component.scss']
})
export class ButtonUploadComponent implements OnInit, OnChanges {

  @ViewChild('fileuploader', {static: true}) fileuploader: ElementRef;
  @Input() data:any;
  
  subscription:Subscription;
  uploadFile: any[] = []; //File;
  landingContentUpdate: any;
  code:any;
  userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: ''
  };

  constructor(
    private dataLandingService: LandingService,
    private router: Router,
    private user: UserService,
  ) {
    this.landingContentUpdate = this.data;
   }

  ngOnInit(): void {

    this.landingContentUpdate = this.data;
    console.log(this.landingContentUpdate.properties.src);

    this.code         = this.router.routerState.snapshot.root.children[0].params.id
    this.userPostData = this.user.getData();

    // this.subscription = this.dataLandingService.landingSource.subscribe((respond:any)=>{
    //   console.log(respond);
    // });

    // let blob = null;

    // let self = this;
    // blob = new Blob([], {type: "image/jpeg"});
    // blob.lastModifiedDate = new Date();
    // blob.name = item['url'].split("/").pop();
    // blob.objectURL = self.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(blob)));
    // self.fileUpload.files.push(blob);

  }

  onUpload(event) {
    this.uploadFile = [];

    for (let file of event.files) {
      this.uploadFile.push(file);
    }

    //this.uploadFileToActivity();

    this.dataLandingService.uploadFile(this.userPostData.user_id,this.code, this.uploadFile).then((respond:any) => {
      console.log(respond.data);
      console.log(this.landingContentUpdate);
      this.landingContentUpdate.properties.src = respond.data.mediaLink;
      //this.dataLandingService.changeLandingData(this.landingContentUpdate);
    }, error => {
      console.log(error);
    });

  }

  uploadFileToActivity() {
    console.log(this.uploadFile);
    console.log(this.data);
    this.dataLandingService.uploadFile(this.userPostData.user_id,this.code, this.uploadFile).then((respond:any) => {
      console.log(respond.data);
      this.data.properties.src = respond.data.mediaLink;
    }, error => {
      console.log(error);
    });
  }

  progressReport($event: any) {
    this.fileuploader = $event;
  }

  dealWithFiles(event){

  }

  ngOnChanges(){
    this.landingContentUpdate = this.data;
  }

}
