import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-day-select',
  templateUrl: './day-select.component.html',
  styleUrls: ['./day-select.component.scss']
})
export class DaySelectComponent implements OnInit {

  value: any;
  days: any[];

  constructor() { 
    this.days = [
      {name: 'Monday', value: 'Mon'},
      {name: 'Tuesday', value: 'Tue'},
      {name: 'Wednesday', value: 'Wed'},
      {name: 'Thursday', value: 'Thu'},
      {name: 'Friday', value: 'Fri'},
      {name: 'Saturday', value: 'Sat'},
      {name: 'Sunday', value: 'Sun'}
  ];
  }

  ngOnInit(): void {
  }

}
