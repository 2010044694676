import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthAPIService } from '../../../services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { LandingService } from '../../../services/landing.service';
import { LandingTemplate } from '../../landing.template';
import { SocialAuthService } from "angularx-social-login";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {trigger, transition, style, animate, query, stagger} from '@angular/animations';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  closeResult: string;
  currentItemIndex: number;
  currenItemLoaded: boolean;
  ghostThumb: any;
  loadingThumbs:any;
  public responseData: any;

  selectedFilter: string;
  filters: any[];

  // Variables relacionadas con los usuarios
  ///////////////////////////////////////////////////////////////////////////////////////////

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  public userVal: boolean = false;
  public userLand: boolean = false;
  public userWorkSpaces: boolean = false; 

  // Variables relacionadas con landings
  ///////////////////////////////////////////////////////////////////////////////////////////

  public landingPostData = {
    method: '',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  public listLandings = []; // Landings de las que el usuario es propietario
  public collaboratorLandings = []; // Landings en las que el usuario es colaborador
  public allLandings = []; // Todas las landings asociadas al usuario. Propias y como colaborador
  public recentlyLanding = []; // Últimas landings creadas o modificadas.
  public currentLandingId: any; // Id de la landing sobre la que estamos trabajando
  public currentLandingName: any; // Nombre de la landing sobre la que estamos trabajando  
  public landingsLoaded:boolean = false; // Bool para saber si se han cargado las landings

  // Variables relacionadas con workspaces
  ///////////////////////////////////////////////////////////////////////////////////////////

  public workSpacePostData = {
    method: '',
    user_id: '',
    landing_code: '',
    workspace_code: '',
    workspace_name: ''
  };

  public listWorkspaces = []; // Workspaces del usuario

  public workSpaceName: string = ''; // variable que se usa para recuperar el valor introducido por el usuario al levantar la modal
  public workSpaceId: any; // variable temporal para pasar el id del espacio de trabajo a la modal de eliminación
  public landingIdToWorkspace: any; // Id de la landing que queremos asociar a un espacio de trabajo
  public selectedWokspace: any; // Valor del wokspace sleccionado para asignar una landing

  // Variables relacionadas con la mensajería
  ///////////////////////////////////////////////////////////////////////////////////////////

  public messagePostData = {
    method: '',
    code: ''
  };

  public messageCode: any;
  public messageText: any; 

  // Variables relacionadas con los menus de filtrado
  ///////////////////////////////////////////////////////////////////////////////////////////

  public allActiveMenu: boolean = true;
  public favActiveMenu: boolean = false;
  public sharedActiveMenu: boolean = false;
  public collaboratingActiveMenu: boolean = false;

  public isSelectedMenu:string; 

  constructor(
    public authAPIService: AuthAPIService, 
    public user: UserService,
    private socialAuthService: SocialAuthService,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    private dataLandingService: LandingService,
    //public activeModal: NgbActiveModal
    //public activeModal: NgbActiveModal
    ) {
    this.userPostData = this.user.getData();
    this.ghostThumb = [1,2,3,4,5,6,7,8,9];
    this.loadingThumbs = [1,2,3,4,5,6,7,8];

    this.filters = [
      {name: 'Date create'},
      {name: 'A-Z'},
      {name: 'Z-A'}
    ];
  }
  
  ngOnInit() {

    if (this.userPostData){
    // Usuario logado correctamente

        if (this.userPostData.email_conf === '1'){

          if (this.userPostData.user_type === 'guest'){

            // Acceso indebido. Los usuarios invitados no tienen acceso al dashboard
            this.user.logOut();
            
          } else {
          
            // Cuenta está confirmada
            this.userVal = true;
            // Recuperamos las landings del usuario
            this.getLandings();
            // Recuperamos los workspaces del usuario
            this.getWorkSpaces();
            // Recuperamos mensaje de bienvenida
            //this.getMessage('M000');
            // Borramos cuaqluier valor de una landing, para los casos que venismo de un detalle
            this.startLandingClear();

          }
          
        } else {
          // Si no lo está y la url lleva el parámetro de validación, la activamos y damos acceso al usuario
          const tokenUri: string = this.route.snapshot.queryParamMap.get('t');

          if (tokenUri){
          // Acceso a dashboard con token. Validación de cuenta
    
          this.userPostData.method  = 'valAccount';
          this.userPostData.token   = tokenUri;
          this.apiConnection(this.userPostData);
    
          } else {

            // Acceso indebido. Rediección a Login
            this.user.sessionOut();

          }
        }

    } else {
    // Usuario no logado

      const tokenUri: string = this.route.snapshot.queryParamMap.get('t');
      if (tokenUri){
      // Acceso a dashboard con token. Validación de cuenta

        this.userPostData = {
          method: 'valAccount',
          email: '',
          email_conf: '',
          name: '',
          provider: '',
          password: '',
          provider_id: '',
          provider_pic: '',
          token: tokenUri,
          user_id: '',
          user_type: ''
        };

        this.apiConnection(this.userPostData);

      } else {
      // Acceso indebido. Rediección a Login
        this.user.sessionOut();
      }

    }
    
  }

  /**
   * Recuperamos Templates del usuario
   */

  getLandings() {

    this.isSelectedMenu = 'home';
    this.landingsLoaded = false;
    this.landingPostData.method = 'list';
    this.landingPostData.user_id = this.userPostData.user_id;
    
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      
      if (this.responseData.code == 200) {
        
        // Comprobamos si hay landings creadas o no
        if(this.responseData.allLandings.length > 0 ){

          this.userLand = true; // Al menos como propietario o colaborador tiene una landing
          this.ghostThumb.splice(0, this.responseData.allLandings.length); // mostramos 8 marcos vacios, quitamos los que tengan que estar completados con landings
          this.allLandings = this.responseData.allLandings;

          this.landingsLoaded = true;

          //this.recentlyLanding = this.responseData.allLandings;
          
        } else {
          // No hay landings creadas
          this.landingsLoaded = true;
        }
        
        this.allActiveMenu = true;
        this.favActiveMenu = false;
        this.sharedActiveMenu = false;
        this.collaboratingActiveMenu = false;
              
      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );
  } 

  /**
   * Templates marcadas como favoritas por el usuario
   */

  getLandingsFav() {
    
    this.landingPostData.method = 'getFavourites';
    this.landingPostData.user_id = this.userPostData.user_id;
    
    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        // Todas las landings marcadas como favoritas

        this.allLandings = this.responseData.landingsFav;

        this.allActiveMenu = false;
        this.favActiveMenu = true;
        this.sharedActiveMenu = false;
        this.collaboratingActiveMenu = false;

      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  /**
   * Redirecciónp para comenzar a crear un nuevo template
   */
  startLanding() {
    // Redirección a la pantalla de Start
    this.router.navigate(['start']);

  }

  /**
   * 
   * Redirección a detalle template
   */

  goToLanding(state){
    this.router.navigateByUrl('/editor/'+state);
  }

  /**
   * Revisar
   */
   checkThumbLoaded(state, i){
    this.currentItemIndex = i;
    this.currenItemLoaded = state;
  }

  // Operaciones relacionadas con el usuario ////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////

  apiConnection(data:any) {

    this.authAPIService.postData(this.userPostData).then( result => {
        this.responseData = result;

        if (this.responseData.code == 200) {

          //Almacenamos los valores devueltos en la variable de sesión
          if (this.responseData.userData) {
            this.user.storeData(this.responseData.userData);
            this.userPostData = this.user.getData();
          }

          //Damos acceso al dashboard
          this.user.sessionIn();

          if (this.responseData.userData.email_conf === '1'){
            this.userVal = true;
            this.getLandings();
          } else {
            this.userVal = false;
          }
          
        } else {

          // Error en el proceso de actualización.
          this.user.sessionOut(); 
        }

      },
      err => {
          console.log('Error');
      }
    );
  }

  /**
   * Recuperamos Workspaces
   */
   getWorkSpaces() {
    
    this.workSpacePostData.method = 'list';
    this.workSpacePostData.user_id = this.userPostData.user_id;
    
    this.authAPIService.postDataWorkSpaces(this.workSpacePostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        if(this.responseData.userWorkspaces.length > 0){

          this.userWorkSpaces = true; // El usuario tiene al menos un espacio de trabajo personal
        
        }

        this.listWorkspaces = this.responseData.userWorkspaces;

      } else {

        // Error en el proceso de creación
        this.user.sessionOut(); 
      }

    },
    err => {
        console.log('Error');
    }
    );
  }

  // Hacemos limpia para poder tener el objeto de landing limpio, para los casos que vengamos de un detalle

  startLandingClear() {
    this.dataLandingService.clearLandingData(); 
  }

  askCodeScreen(content) {
    this.modalService.open(content, { centered: true });
  }


}
