<div class="preview-component" (click)="addModule(moduleData)" #previewComponent>
  <div class="preview-component-top">
    <div class="preview-component-wrapper">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" #iframe frameBorder="0" seamless="seamless"></iframe>
      </div>
    </div>
    <div class="preview-component-add">
      <button type="button" class="btn btn-primary btn-icon btn-icon__right btn-add">
        Add <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>
      </button>
    </div>
  </div>
  <div class="preview-component-bottom">
    <a href="" class="font-weight-bold">{{moduleData.moduleName}}</a>
    <div>{{moduleParentInfoName}}</div>
  </div>
</div>