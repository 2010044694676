<div class="c-choice c-choice__block">
  <div class="c-choice-hidden">
      <input #rb type="radio" [attr.name]="name" [attr.value]="value">
  </div>
  <div (click)="handleClick($event, rb, true)" class="c-choice-content" [ngClass]="{'active':active}">
      <div class="c-choice-key">
          <span>{{key}}</span>
      </div>
      <div class="c-choice-value">
          <span>{{label}}</span>
      </div>
  </div>
</div>
