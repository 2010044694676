import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBar',
  pure: false
})
export class SearchBarPipe implements PipeTransform {

  transform(value: any, input: string) {
    if (input) {
        //console.log(value);
        input = input.toLowerCase();
        return value.filter(function (el: any) {
            return el.landing_name.toLowerCase().indexOf(input) > -1;
        })
    }
    return value;
}

}
