<div class="l-panel-editor">

  <!-- Panel Editor: Header -->
  <div class="l-panel-editor__header">
    <div class="l-panel-editor__header__title">Colors</div>
    <div class="l-panel-editor__header__actions">
      <app-save-button></app-save-button>
      <app-publish-button></app-publish-button>
    </div>
  </div>
  <!-- / Panel Editor: Header -->

  <!-- Panel Editor: Body -->
  <div class="l-panel-editor__body">

    <div class="scroll-wrap">

      <div class="l-panel-editor__body__content editor-cnt-mw">

        <p-tabView *ngIf="dataLoaded">
          <p-tabPanel header="Presets">
            <!-- Module Box Color -->
            <div class="c-module-box">
              <div class="c-module-item-cab">Global Color Palette</div>
              <!-- Module Item -->
              <ng-container>
                <div class="c-module-item py-0">
                    <!-- Palette 01 -->
                      <div class="w-100">
                        <div class="color-palette">
                          <div class="color-palette-colors">
                            <div class="color-palette-items order-2 order-sm-1">
                              <span class="color-palette-item col"
                              *ngFor="let detail of savedColorSchema.schema; let a = index"
                              [ngStyle]="{backgroundColor:detail.color}"
                              [pTooltip]="detail.variableName"
                              tooltipPosition="top"
                              appendTo="body"
                              ></span>
                            </div>
                            <div class="color-palette-name text-truncate order-1 order-sm-2">{{savedColorSchema.title}}</div>
                          </div>
                        </div>
                      </div>
                    <!-- / Palette 01 -->
                </div>
              </ng-container>
            </div>
            <!-- / Module Box Color -->
            <!-- Module Box Color -->
            <div class="c-module-box">
              <div class="c-module-item-cab">Trending Color Palettes</div>
              <!-- Module Item -->
              <ng-container *ngFor="let item of defaultColorSchemas.schemas; let i = index">
                <div class="c-module-item" *ngIf="item.type == 'trending' ">
                    <!-- Palette 01 -->
                      <div class="w-100">
                        <div class="color-palette">
                          <div class="color-palette-colors">
                            <div class="color-palette-items order-2 order-sm-1" (click)="setColorSchema(i)">
                              <span class="col color-palette-item"
                              *ngFor="let detail of item.schema; let a = index"
                              [ngStyle]="{backgroundColor:detail.color}"
                              [pTooltip]="detail.variableName"
                              tooltipPosition="top"
                              appendTo="body"></span>
                            </div>
                            <div class="color-palette-name text-truncate order-1 order-sm-2">{{item.title}}</div>
                          </div>
                          <button class="btn btn-icon btn-icon__right" [ngClass]="{'checked btn-secondary': item.title == savedColorSchema.title, 'btn-outline-primary':!item.active}" (click)="setColorSchema(i)"> <!--  -->
                            <ng-container *ngIf="item.title == savedColorSchema.title; else elseBlock">Active <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-check-fill"/></svg></ng-container>
                            <ng-template #elseBlock>Use <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></ng-template>
                          </button>
                          <!-- <button class="btn btn-plus" [ngClass]="{'checked btn-plus':item.active, 'btn-sm btn-gray':!item.active}" (click)="setColorSchema(i)"> 
                            <span *ngIf="item.active" class="ic-check" href="#"><svg class="icon ic-28"><use href="assets/icons/icons-sprite.svg#svg-check"/></svg></span>
                            <span *ngIf="!item.active">Use</span>
                          </button> -->
                        </div>
                      </div>
                    <!-- / Palette 01 -->
                </div>
              </ng-container>
              <!-- / Module Item -->
            </div>
            <!-- / Module Box Color -->
          </p-tabPanel>
          <p-tabPanel header="Custom">
            <!-- Module Box Color -->
            <div class="c-module-box">
              <div class="c-module-item-cab">Global Color Palette</div>
              <ng-container>
                <div class="c-module-item py-0">
                    <!-- Palette 01 -->
                      <div class="w-100">
                        <div class="color-palette">
                          <div class="color-palette-colors">
                            <div class="color-palette-items order-2 order-sm-1">
                              <span class="color-palette-item col"
                              *ngFor="let detail of savedColorSchema.schema; let a = index"
                              [ngStyle]="{backgroundColor:detail.color}"
                              [pTooltip]="detail.variableName"
                              tooltipPosition="top"
                              appendTo="body"
                              ></span>
                            </div>
                            <div class="color-palette-name text-truncate order-1 order-sm-2">{{savedColorSchema.title}}</div>
                          </div>
                        </div>
                      </div>
                    <!-- / Palette 01 -->
                </div>
              </ng-container>
              <!-- Module Item -->
              <ng-container *ngFor="let item of defaultColorSchemas.schemas; let i = index">
                <div class="c-module-item py-0" *ngIf="item.type == 'saved'">
                    <!-- Palette 01 -->
                      <div class="w-100">
                        <div class="color-palette">
                          <div class="d-flex align-items-center w-100">
                            <div class="color-palette-items" (click)="setColorSchema(i)">
                              <span class="color-palette-item d-flex align-items-center justify-content-center col"
                              *ngFor="let detail of item.schema; let a = index"
                              [ngStyle]="{backgroundColor:detail.color}"
                              [pTooltip]="detail.variableName"
                              tooltipPosition="top"
                              appendTo="body"
                              ></span>
                            </div>
                            <div class="color-palette-name text-truncate order-1 order-sm-2">{{item.title}}</div>
                          </div>
                          <!-- <button class="btn btn-icon btn-icon__right" [ngClass]="{'checked btn-secondary':item.active, 'btn-outline-primary':!item.active}" (click)="setColorSchema(i)">
                            <ng-container *ngIf="item.active; else elseBlock">Active <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-check-fill"/></svg></ng-container>
                            <ng-template #elseBlock>Use <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></ng-template>
                          </button> -->

                        </div>
                      </div>
                    <!-- / Palette 01 -->
                </div>
              </ng-container>
            </div>
            <!-- / Module Box Color -->
            <!-- Module Box Color -->
            <div class="c-module-box">
              <div class="c-module-item-cab">Create new</div>
              <!-- Module Item -->
                <div class="c-module-item py-0">
                    <!-- Palette 01 -->
                      <div class="w-100">
                        <div class="color-palette">
                          <div class="d-flex align-items-center w-100">
                            <div class="color-palette-items">
                              <span class="color-palette-item d-flex align-items-center justify-content-center col"
                                *ngFor="let detail of defaultColorSchemas.schemas[0].schema; let a = index"
                                [ngStyle]="{backgroundColor:detail.color}"
                                [cpPosition]="'bottom'"
                                [cpPositionOffset]="'50%'"
                                [cpOutputFormat]="'hex'"
                                [cpPositionRelativeToArrow]="true"
                                [(colorPicker)]="detail.color"
                                (colorPickerChange)="setCustomcolorSchema(0,a,$event)"
                                [pTooltip]="detail.variableName"
                                tooltipPosition="top"
                                appendTo="body">
                                <button class="btn btn-plus btn-xs">
                                  <svg class="icon ic-white ic-14"><use href="assets/icons/icons-sprite.svg#svg-edit"/></svg>
                                </button>
                              </span>
                            </div>
                          </div>
                            <button class="btn btn-icon btn-icon__right" [ngClass]="{'checked btn-secondary':defaultColorSchemas.schemas[0].active, 'btn-outline-primary':!defaultColorSchemas.schemas[0].active}"  *ngIf="defaultColorSchemas.schemas[0].type == 'custom'" (click)="setColorSchema(0)">
                              <!-- <span *ngIf="defaultColorSchemas.schemas[0].active" class="ic-check"><svg class="icon ic-28"><use href="assets/icons/icons-sprite.svg#svg-check"/></svg></span> -->
                              <ng-container *ngIf="defaultColorSchemas.schemas[0].active; else elseBlock">Active <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-check-fill"/></svg></ng-container>
                              <ng-template #elseBlock>Use <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></ng-template>
                            </button>
                        </div>
                        <!-- <p class="text-center">{{item.title}}</p> -->
                      </div>
                    <!-- / Palette 01 -->
                </div>
              <!-- / Module Item -->
            </div>
            <!-- / Module Box Color -->
          </p-tabPanel>
        </p-tabView>
                
        <!-- Module Box Request -->
        <div class="c-module-box no-bg mt-40"  *ngIf="dataLoaded">
          <div class="c-module-box-title">Request Featured <span>- What do you wish XXXX could do or add?</span></div>
          <div class="form-group">
            <textarea class="form-control" id="commentRequestFeatured" rows="3" placeholder="Please include detail or some examples"></textarea>
          </div>
          <button type="submit" class="btn btn-gray">Send Request</button>
        </div>
        <!-- / Module Box Request -->

      </div>

    </div>

  </div>
  <!-- / Panel Editor: Body -->

</div>
