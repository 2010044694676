<!-- Module Collaborate -->
<div class="module-section">
  <div class="detail-module">
      <div class="detail-module-hd bg-white">
        <p><strong>Share &  Collaborate</strong> - Landing Page title</p>
        <span routerLink="../overview"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg></span>
      </div>
    <div class="detail-module-cnt bg-light">
      <div class="scroll-wrap">
        
        <!--                                               Añadir colaboradores                                          -->
        <!-- ----------------------------------------------------------------------------------------------------------  -->

        <div class="c-module-box" *ngIf="showShare ">
          <div class="c-module-box-title">Share your project</div>

          <!-- Bloque para añadir colaboradores -->

          <div class="c-module-item border-0 add">
            <div class="form-group w-100">
              <label>Add User by email</label>
              <div class="form-row">
                <div class="col">
                  <p-chips styleClass="w-100" [(ngModel)]="listOfCollaborator" separator="," ></p-chips>
                </div>
                <div class="col-auto">
                  <button class="btn btn-gray" type="button" (click)="sendListofCollaborator(listOfCollaborator)">Add</button>
                </div>
              </div>
            </div>
          </div>

          <!-- / Bloque para añadir colaboradores -->

          <!-- Lista de colaboradores y estado -->

          <ng-container *ngFor="let infoCollaborator of resumeCollaborator">

            <div class="c-module-item added" *ngIf="infoCollaborator.enabled === 1">
              <div class="c-module-item-name">
                <svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-check"/></svg>
                <label>{{infoCollaborator.email}}</label>
              </div>
              <button (click)="removeCollaboratorFromList(infoCollaborator.email)" type="button" class="btn btn-gray btn-sm btn-remove ml-15">Remove</button>
            </div>

            <div class="c-module-item resend" *ngIf="infoCollaborator.enabled === 0">
              <div class="c-module-item-name">
                <svg class="icon ic-gray"><use href="assets/icons/icons-sprite.svg#svg-clock"/></svg>
                <label>{{infoCollaborator.email}}</label>
              </div>
              <button (click)='resendReminder(infoCollaborator.email)' type="button" class="btn btn-link btn-sm btn-edit ml-15">Resend email</button>
            </div>
            
          </ng-container>

          <!-- / Lista de colabroadores y estado -->

          <!-- <div class="log-box-custom" *ngIf="showShare ">
            <p class="log-box-custom-intro">Introduce la lista de contactos con los que quieres compartir tu proyecto.</p>
            <form class="log-box-custom-form">

                <mat-form-field class="example-chip-list">
                  <mat-chip-list #chipList>
                    <mat-chip *ngFor="let collaborator of listOfCollaborator" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(collaborator)">
                      {{collaborator.email}}
                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Lista de participantes"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)">
                  </mat-chip-list>
                </mat-form-field>

                  <div class="log-box-custom-form-fieldset submit">
                    <input type="submit" (click)="sendListofCollaborator(listOfCollaborator)" value="Compartir">
                  </div>
                </form>
          </div> -->
        
          <!-- <div class="log-box-footer"> 
            <span>Colaboradores: </span>
            <ul class="list-unstyled">
              <li *ngFor="let infoCollaborator of resumeCollaborator">
                <p *ngIf="infoCollaborator.initial.length == 1">Inicial: {{infoCollaborator.initial}}</p>
                <p *ngIf="infoCollaborator.name.length > 1">Nombre: {{infoCollaborator.name}}</p>
                <p>Email: {{infoCollaborator.email}}</p>
                <p *ngIf="infoCollaborator.enabled === 1">Activo</p>
                <p *ngIf="infoCollaborator.enabled === 0">Pendiente de confirmación</p>
                <button (click)='resendReminder(infoCollaborator.email)' class="btn btn-logout" *ngIf="infoCollaborator.enabled === 0"><span class="fa fa-sign-out"></span> Enviar recordatorio</button> 
              </li>
            </ul>
          </div> -->
        </div>


        <!--                                                    Solicitudes                                              -->
        <!-- ----------------------------------------------------------------------------------------------------------  -->

        <!-- Module Box Request -->
        <div class="c-module-box no-bg">
          <div class="c-module-box-title">Request Featured <span>- What do you wish XXXX could do or add?</span></div>
          <div class="form-group">
            <textarea class="form-control" id="commentRequestFeatured" rows="3" placeholder="Please include detail or some examples"></textarea>
          </div>
          <button type="submit" class="btn btn-gray">Send Request</button>
        </div>
        <!-- / Module Box Request -->
      </div>
    </div>
  </div>
</div>
<!-- / Module Collaborate -->
