<div class="c-form-editor-item">
    
    <div class="c-form-editor-item-left">
        <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" ngDefaultControl [(ngModel)]="data.properties.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
    </div>
    
    <div class="d-flex align-items-center flex-grow-1">
        <div class="c-form-editor-form" [ngClass]="{'c-form-editor-hide':!data.properties.visible}">
            <div class="p-float-label">
                <input id="float-input" type="text" pInputText [(ngModel)]="data.properties.data"> 
                <label for="float-input">{{data.properties.label}}</label>
                <small *ngIf="data.properties.note">{{data.properties.note}}</small>
            </div>  
        </div>
        <div class="ml-5" *ngIf="data.properties.customSize">
            <!-- <p-button icon="pi pi-plus" (click)="fontUp()"></p-button> -->
            <button class="btn btn-gray btn-size-type-down" (click)="fontDown()">
                <span>A</span>
                <span class="pi pi-angle-down"></span>
            </button>
            <button class="btn btn-gray btn-size-type-up  ml-5" (click)="fontUp()">
                <span>A</span>
                <span class="pi pi-angle-up"></span>
            </button>
            <!-- <p-button class="ml-5" icon="pi pi-minus" (click)="fontDown()"></p-button> -->
        </div>
    </div>
</div>

