import { Component, OnInit } from '@angular/core';

import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
  }

  confirmRemoveComponentProfile(event: Event) {
    this.confirmationService.confirm({
        target: event.target,
        message: 'Do you want to permanently delete this Account?',
        //icon: 'pi pi-exclamation-triangle',
        accept: () => {
            //this.removeUserProfile();
        },
        reject: () => {
            //reject action
        }
    });
  }

}
