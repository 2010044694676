<!-- <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu> -->
<div class="form-group">
    <p-selectButton [options]="items" [(ngModel)]="mediaType" optionValue="value" (onChange)="onChange($event)">
        <ng-template let-item>
            <i [class]="item.icon"></i>
        </ng-template>
    </p-selectButton>
</div>

<div *ngIf="mediaType == 'upload'">
        <div class="form-group">
            <p-toast></p-toast>
            <label for="">Upload your own Image</label>
            <p-fileUpload name="customFile[]" url="./upload.php" multiple="multiple"
            accept="image/*" [auto]="true" (onUpload)="onUpload($event)"></p-fileUpload>
            <!-- <p-fileUpload #fubauto mode="basic" name="customFile[]" url="./upload.php" accept="image/*" maxFileSize="1000000" (onUpload)="onBasicUploadAuto($event)" [auto]="true" chooseLabel="Browse"></p-fileUpload> -->
            <small>2Mb max size</small>
        </div>
</div>

<div  *ngIf="mediaType == 'image'">
    <div class="form-group">
        <div class="p-inputgroup">
            <span class="p-float-label">
                <!-- <input type="text" pInputText [(ngModel)]="unsplashSearch"> -->
                <p-chips [(ngModel)]="unsplashSearch" separator=","></p-chips>
                <!-- <label for="float-input">{{data.properties.label}}</label> -->
                <button type="button" pButton pRipple label="Search" (click)="searchPhotos(unsplashSearch)"></button>
            </span>       
        </div>
    </div>
    
    <p-galleria [value]="unsplashPhotos" [numVisible]="2">
        <ng-template pTemplate="item" let-item>
            <div style="width: 100%; height: 200px; overflow: hidden; background-size: cover;" [ngStyle]="{'background-image':'url(' + item.urls.small + ')'}" (click)="usePhoto(item.urls.full)">
                <!-- <img [src]="item.urls.small" [alt]="item.alt_description" style="width: 100%; display: block;"/> -->
            </div>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.urls.thumb" style="height: 50px;" />
            </div>
        </ng-template>
    </p-galleria>
</div>


