import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AuthAPIService } from '../../../services/auth-api.service';
import { UserService } from '../../../services/user.service';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styles: []
})
export class SigninComponent implements OnInit {

  public responseData: any;
  public showErrorEmail: boolean = false; 
  public showErrorPass: boolean = false; 
  public messageError: any;
  
  public userPostData = {
    method: '',
    email: '',
    email_conf: '1',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: ''
  };

  constructor(
    private socialAuthService: SocialAuthService,
    public authAPIService: AuthAPIService,
    public user: UserService) {
    this.user.sessionIn();
  }

  ngOnInit() {}

  // Login a través de las Redes Sociales

  public socialSignIn(socialPlatform: string) {
    
    let socialPlatformProvider;
    
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    } 

    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      this.apiConnection(userData);
    });

  }

  // Login

  public localSignIn(email:any,password:any) {

    if (email.length != 0 && password.length != 0){

      // Validación de campos ok

      this.userPostData.email         = email;
      this.userPostData.email_conf    = '1';
      this.userPostData.name          = 'N/A';
      this.userPostData.provider      = 'okb';
      this.userPostData.password      = (Md5.hashStr(password) as string);
      this.userPostData.provider_id   = 'N/A';
      this.userPostData.provider_pic  = 'N/A';
      this.userPostData.token         = 'N/A';

      this.apiConnection(this.userPostData);

    } else {

      if (email.length == 0) {

        this.showErrorEmail = true;
        this.messageError = 'You must fill in this field';

      } else if (password.length == 0) {

        this.showErrorEmail = false;
        this.showErrorPass = true;
        this.messageError = 'You must fill in this field';

      }
      
    }

  }

  // Conexión a API 

  apiConnection(data:any) {

    this.userPostData.method        = 'signin';
    this.userPostData.email         = data.email;
    this.userPostData.name          = data.name;
    this.userPostData.provider      = data.provider;
    if (data.provider == 'okb') {
      this.userPostData.password    = data.password;
    } else {
      this.userPostData.password    = 'N/A';
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_id   = data.provider_id;
    } else {
      this.userPostData.provider_id   = data.id;
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_pic  = data.provider_pic;
    } else {
      if (data.image === undefined){
        this.userPostData.provider_pic  = 'N/A';
      } else {
        this.userPostData.provider_pic  = data.photoUrl;
      }
    }
    if (data.provider == 'okb') {
      this.userPostData.token         = data.token;
    } else if (data.provider == 'FACEBOOK') {
      this.userPostData.token         = data.authToken;
    } else {
      this.userPostData.token         = data.idToken;
    }
 
    this.authAPIService.postData(this.userPostData).then( result => {
        this.responseData = result;

        if (this.responseData.code != 200) {

          // Error en login de Usuario

          if (this.responseData.code == 301) { // Error email
            this.showErrorEmail             = true;
            this.showErrorPass              = false;
          }

          if (this.responseData.code == 302) { // Error Password
            this.showErrorEmail             = false;
            this.showErrorPass              = true;
          }

          this.messageError          = this.responseData.message;
          this.userPostData.password  = '';

        } else {

          // El usuario está registrado. 

          this.showErrorEmail             = false;
          this.showErrorPass              = false;
          
          //Almacenamos los valores devueltos en la variable de sesión
          
          if (this.responseData.userData) {
            this.user.storeData(this.responseData.userData);
          }

          //Redirect a dashboard

          this.user.sessionIn();

        }

      },
      err => {
          console.log('error');
      }
    );
  }

  // Proceso de creación de landings sin generar un usuario

  public autoSignUp() {

    this.userPostData.method        = 'signup';
    this.userPostData.email         = 'guestuser@boostup.io';
    this.userPostData.email_conf    = '1';
    this.userPostData.name          = 'guestuser';
    this.userPostData.provider      = 'N/A';
    this.userPostData.password      = 'N/A';
    this.userPostData.provider_id   = 'N/A';
    this.userPostData.provider_pic  = 'N/A';
    this.userPostData.token         = 'N/A';

    this.authAPIService.postData(this.userPostData).then( result => {
      this.responseData = result;

        if (this.responseData.code != 200) {
          
          // Error en la creación del usuario guest
          this.user.sessionOut();

        } else {

          //Almacenamos los valores devueltos en la variable de sesión
          // En el propio storeData se hace rediección a start
          
          if (this.responseData.userData) {
            this.user.storeData(this.responseData.userData);
          }

        }

      },
      err => {
          console.log('Error');
      }
    );

  }

}
