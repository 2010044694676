<div class="c-module-item module-component" [ngClass]="{'added': modulesData.moduleAdded}">
  <!-- <div class="module-component-icons">
      <span class="ic-check ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-check"></use></svg></span>
      <img class="ic-svg ic-rocket" src="../../../../assets/img/icons/rocket.svg" alt="rocket">    
  </div> -->
  <div class="d-flex align-items-center flex-grow-1">
    <div class="c-module-item-miniature">
      <span _ngcontent-xjo-c1="" class="badge badge-pill badge-primary" style="border:none" *ngIf="modulesData.numberOfUsedModules >= 1">{{modulesData.numberOfUsedModules}}</span>
      <img class="module-component-prev" src="{{modulesData.moduleImage}}" alt="{{modulesData.moduleDescription}}">
    </div>
    <div>
      <h6>{{modulesData.moduleName}}</h6>
      <span class="module-component-desc">{{modulesData.moduleDescription}}</span>
    </div>
  </div>
  <div class="module-component-btn">
    <button type="button" class="btn btn-icon" (click)="addModule(index)"><svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#arrow_right4"/></svg></button>
    <!-- <button type="button" class="btn btn-gray btn-sm btn-add" *ngIf="modulesData.moduleAdded" (click)="removeModule(index)">Remove</button> -->
  </div>
</div>
 