import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthAPIService } from '../../../services/auth-api.service';
import { LandingService } from '../../../services/landing.service';
import { LandingTemplate } from '../../landing.template';
import { UserService } from '../../../services/user.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('btnSave') btnSave: ElementRef;
  //@ViewChild('checkMark') checkMark: ElementRef;

  _serviceSubscription:any;
  code : any;
  fakeUserID : any;
  allowSave: any;
  nonGuest: any;
  showMenuMobile: boolean = false;

  public showErrorName: boolean = false;
  public showErrorEmail: boolean = false; 
  public showErrorPass: boolean = false; 
  public messageError: any;

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  constructor(

    private dataLandingService: LandingService,
    public authAPIService: AuthAPIService, 
    public user: UserService,
    private socialAuthService: SocialAuthService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  
  ) {

    this.userPostData = this.user.getData();
    this.code = this.route.snapshot.paramMap.get('id');

  }

  public landingPostData = {
      method: 'getLandingByID',
      user_id: '',
      landing_id: '',
      landing_type: '',
      landing_code: '',
      landing_content: {},
      landing_publish: '0',
      landing_collaborators: '',
      landing_collaborators_pending: '',
      landing_favourite: 0,
      landing_extra_fake_user_id: '', // Parametro necesario para el proceso de reasignación del propietario de la landing
      landing_extra_real_user_id: '' // Parametro necesario para el proceso de reasignación del propietario de la landing

  };

  responseData: any;
  responseUserData: any;
  landingContentUpdate = new LandingTemplate().data;
  lastUpdateDate: any;
  textToshowInLastUpdate: any;

  subscription: Subscription;
  intervalId: number;

  ngOnInit() {

    // Check para mostrar o no el botón de guardar

    if (this.code === null) {
      this.allowSave = false;
    } else {
      this.allowSave = true;
    }

    // Variable para capar ciertos enlaces a usuarios invitados.

    if (this.userPostData.user_type === 'guest'){

      this.nonGuest = false;
      
    } else {

      this.nonGuest = true;

    }

    // Almacenamos en una variable temporal el id provisional del usuario
    
    this.fakeUserID = this.userPostData.user_id;

  }

  ngOnDestroy() {
    
    // this.subscription && this.subscription.unsubscribe();

  }

  logout() {
    if (this.userPostData.provider == 'okb'){
      this.user.logOut();
    } else {
      this.socialAuthService.signOut().then(data => {
        this.user.logOut();
      });
    }  
  }


  // Modal para logarse como usuario registrado ////////////////////////////////////
  
  showModalLogin(content) {
    this.modalService.open(content, { centered: true });
    this.userPostData.email = '';
    this.userPostData.password = '';
  }

  // Modal para registro //////////////////////////////////////////////////////////

  showModalSignUp(content) {
    this.modalService.open(content, { centered: true });
    this.userPostData.name = '';
    this.userPostData.email = '';
    this.userPostData.password = '';
  }

  // Login ////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  // Login a través de las Redes Sociales

    public socialSignIn(socialPlatform: string) {
      let socialPlatformProvider;
      if (socialPlatform === 'facebook') {
        socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      } else if (socialPlatform === 'google') {
        socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      } 

      this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
          this.apiConnectionLogin(userData);
      });
    }

  // Login local

  public localSignIn(email:any,password:any) {

    if (email.length != 0 && password.length != 0){

      // Validación de campos ok

      this.userPostData.email         = email;
      this.userPostData.email_conf    = '1';
      this.userPostData.name          = 'N/A';
      this.userPostData.provider      = 'okb';
      this.userPostData.password      = (Md5.hashStr(password) as string);
      this.userPostData.provider_id   = 'N/A';
      this.userPostData.provider_pic  = 'N/A';
      this.userPostData.token         = 'N/A';

      this.apiConnectionLogin(this.userPostData);

    } else {

      if (email.length == 0) {

        this.showErrorEmail = true;
        this.messageError = 'You must fill in this field';

      } else if (password.length == 0) {

        this.showErrorEmail = false;
        this.showErrorPass = true;
        this.messageError = 'You must fill in this field';

      }
      
    } 

  }

  // Conexión a API - Común para todos los métodos de login para usuarios registrados

  apiConnectionLogin(data:any) {

    this.userPostData.method        = 'signin';
    this.userPostData.email         = data.email;
    this.userPostData.name          = data.name;
    this.userPostData.provider      = data.provider;
    if (data.provider == 'okb') {
      this.userPostData.password    = data.password;
    } else {
      this.userPostData.password    = 'N/A';
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_id   = data.provider_id;
    } else {
      this.userPostData.provider_id   = data.id;
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_pic  = data.provider_pic;
    } else {
      if (data.image === undefined){
        this.userPostData.provider_pic  = 'N/A';
      } else {
        this.userPostData.provider_pic  = data.photoUrl;
      }
    }
    if (data.provider == 'okb') {
      this.userPostData.token         = data.token;
    } else if (data.provider == 'FACEBOOK') {
      this.userPostData.token         = data.authToken;
    } else {
      this.userPostData.token         = data.idToken;
    }
 
    this.authAPIService.postData(this.userPostData).then( result => {
        this.responseUserData = result;

        if (this.responseUserData.code != 200) {

          // Error en login de Usuario

          if (this.responseUserData.code == 301) { // Error email
            this.showErrorEmail             = true;
            this.showErrorPass              = false;
          }

          if (this.responseUserData.code == 302) { // Error Password
            this.showErrorEmail             = false;
            this.showErrorPass              = true;
          }

          this.messageError          = this.responseUserData.message;
          this.userPostData.password  = ''; 

        } else {

          // El usuario está registrado. 

          this.showErrorEmail             = false;
          this.showErrorPass              = false;

          // Reasignamos la landing generada como invitado a al usuario registrado.

          this.landingPostData.method = 'reassignLandingGuest';
          this.landingPostData.landing_code = this.code;
          this.landingPostData.landing_extra_fake_user_id = this.fakeUserID;
          this.landingPostData.landing_extra_real_user_id = this.responseUserData.userData.user_id;

          this.authAPIService.postDataLanding(this.landingPostData).then( result => {

            this.responseData = result;

            if (this.responseData.code == 200) {

              //Almacenamos los nuevos valores devueltos en la variable de sesión y redireccionamos al dashboard

              this.user.storeData(this.responseUserData.userData);

              // Ocultamos la modal

              this.modalService.dismissAll();

            } 

          },
          err => {
              console.log('Error');
          }); 

        }

      },
      err => {
          console.log('error');
      }
    );
  }

  // Registro /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  // Registro RRSS

    public socialSignUp(socialPlatform: string) {
    
      let socialPlatformProvider;
      
      if (socialPlatform === 'facebook') {
      
        socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      
        } else if (socialPlatform === 'google') {
      
          socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      
        } 

      this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
        this.apiConnectionSignUp(userData);
      });
    }


  // Registro Local

  public localSignUp(name:any,email:any,password:any) {

    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name.length == 0) {

      this.showErrorName  = true;
      this.showErrorEmail = false;
      this.showErrorPass = false;
      this.messageError   = 'You must fill in this field';
    
    } else if (email.length == 0) {

      this.showErrorName = false;
      this.showErrorEmail = true;
      this.showErrorPass = false;
      this.messageError = 'You must fill in this field';

    } else if (!validEmailRegEx.test(email)) {

      this.showErrorName = false;
      this.showErrorEmail = true;
      this.showErrorPass = false;
      this.messageError = 'It looks like an invalid email address';

    } else if (password.length == 0) {

      this.showErrorName = false;
      this.showErrorEmail = false;
      this.showErrorPass = true;
      this.messageError = 'You must fill in this field';

    } else if (password.length < 6) {

      this.showErrorName = false;
      this.showErrorEmail = false;
      this.showErrorPass = true;
      this.messageError = 'Please: use a six or more characters password';

    } else {

        this.userPostData.email         = email;
        this.userPostData.email_conf    = '0';
        this.userPostData.name          = name;
        this.userPostData.provider      = 'okb';
        this.userPostData.password      = (Md5.hashStr(password) as string);
        this.userPostData.provider_id   = 'N/A';
        this.userPostData.provider_pic  = 'N/A';
        this.userPostData.token         = 'N/A';

        this.apiConnectionSignUp(this.userPostData);
        
    }

  }

  apiConnectionSignUp(data:any) {

    this.userPostData.method        = 'signup';

    this.userPostData.email         = data.email;
    this.userPostData.name          = data.name;
    this.userPostData.provider      = data.provider;
    if (data.provider == 'okb') {
      this.userPostData.password    = data.password;
    } else {
      this.userPostData.password    = 'N/A';
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_id   = data.provider_id;
    } else {
      this.userPostData.provider_id   = data.id;
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_pic  = data.provider_pic;
    } else {
      if (data.image === undefined){
        this.userPostData.provider_pic  = 'N/A';
      } else {
        this.userPostData.provider_pic  = data.photoUrl;
      }
    }
    if (data.provider == 'okb') {
      this.userPostData.token         = data.token;
    } else if (data.provider == 'FACEBOOK') {
      this.userPostData.token         = data.authToken;
    } else {
      this.userPostData.token         = data.idToken;
    }

    this.authAPIService.postData(this.userPostData).then( result => {
      this.responseUserData = result;

      if (this.responseUserData.code != 200) {

        // Error en el registro o email ya registrado
        
        this.showErrorName         = false;
        this.showErrorEmail        = true;
        this.showErrorPass         = false;
        this.messageError          = this.responseUserData.message;

      } else {

        // Registro completado correctamente 

        this.showErrorName         = false;
        this.showErrorEmail        = false;
        this.showErrorPass         = false;

        // Reasignamos la landing generada como invitado a al usuario registrado.

        this.landingPostData.method = 'reassignLandingGuest';
        this.landingPostData.landing_code = this.code;
        this.landingPostData.landing_extra_fake_user_id = this.fakeUserID;
        this.landingPostData.landing_extra_real_user_id = this.responseUserData.userData.user_id;

        this.authAPIService.postDataLanding(this.landingPostData).then( result => {

          this.responseData = result;

          if (this.responseData.code == 200) {

            //Almacenamos los nuevos valores devueltos en la variable de sesión y redireccionamos al dashboard

            this.user.storeData(this.responseUserData.userData);

            // Ocultamos la modal

            this.modalService.dismissAll();

          } 

        },
        err => {
            console.log('Error');
        });

      }

    },
      err => {
          console.log('Error');
    });
  }

}


