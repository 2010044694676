<body class="log-grid">
    <header class="log-header">
      Tell everyone what you're the best at!
    </header>
    <main class="log-main">
      <div class="log-main-box">
        <!-- Título de la vista para recuperar una contraseña -->
        <h1 class="login-box-title">Reset your password?</h1>

        <div>
          <p class="login-box-paragraph">Please, enter the new password.</p>
          <form class="login-form">
            <div class="login-form-field text long">
              <label for="email-field">New Password</label>
              <input type="password" [(ngModel)]="userPostData.password" [ngModelOptions]="{standalone: true}" name="password-field" id="password-field">
              <span class="field-error" *ngIf="showErrorPass">{{messageError}}</span>
            </div>
            <div class="login-form-field button long">
              <input type="submit" (click)="resetPassword(userPostData.password)" value="Reset Password">
            </div>
          </form>
        </div>
      </div>
    </main>

    <footer class="login-footer"> 
      <span><a routerLink="/signin">Log in</a></span> here if you don't need to reset your password.
    </footer>

  </body>
