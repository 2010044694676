<div class="l-panel-editor">

  <!-- Panel Editor: Header -->
  <div class="l-panel-editor__header">
    <div class="l-panel-editor__header__title">Components</div>
  </div>
  <!-- / Panel Editor: Header -->

  <!-- Panel Editor: Body -->
  <div class="l-panel-editor__body">

    <div class="scroll-wrap">

      <div class="l-panel-editor__body__content">

        <div class="editor-cnt-mw" *ngIf="!showModulesItemsSidebar">
          <!-- <app-module-item *ngFor="let item of allGroupModules; let i = index" [modulesData]="item" [index]="i" (click)="showModulesItems(item.modules)"></app-module-item> -->
          <app-module-item *ngFor="let item of allGroupModules; let i = index" [modulesData]="item" [index]="i" (click)="showModulesItems(item)"></app-module-item>
        </div>

        <!-- Module Detail -->
        <div class="l-module-detail__backdrop" *ngIf="showModulesItemsSidebar" (click)="hideModulesItems(i);"></div>
        <div class="l-module-detail" *ngIf="showModulesItemsSidebar">

          <div class="l-module-detail__header">
            <div class="l-module-detail__header__title">Component category</div>
            <div class="l-module-detail__header__actions">
              <button type="button" class="ic-24 ic-svg ic-inline" (click)="hideModulesItems(i)"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg></button>
            </div>
          </div>

          <div class="l-module-detail__body">
            <div class="scroll-wrap">

              <div class="l-module-detail__body__content">

                <div class="row">
                  <div class="col-sm-6 col-xl-4 col-xxxl-6" *ngFor="let item of selectedGroupModules; let i = index" [index]="i">
                    <!-- Module Item Preview -->
                    <app-module-item-preview [moduleData]="item" [moduleParentInfo]="selectedGroupModulesInfo" (addModuleEvent)="addModule($event)" (removeModuleEvent)="removeModule($event)"></app-module-item-preview>
                    <!-- Module Item Preview -->
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
        <!-- / Module Detail -->

      </div>

    </div>

  </div>
  <!-- / Panel Editor: Body -->

</div>