import { Component, OnInit, Input } from '@angular/core';

interface Field {
  name: string,
  code: string
}

@Component({
  selector: 'app-custom-form-type',
  templateUrl: './custom-form-type.component.html',
  styleUrls: ['./custom-form-type.component.scss']
})
export class CustomFormTypeComponent implements OnInit {

  @Input() data: any;
  availableTypes: Field[];
  customEndPoint:any;
  customEndPointType:any;
  newType:Field;
  showCusmEndPoint:boolean = false;

  constructor() { }

  ngOnInit(): void {

    console.log(this.data);

    this.availableTypes = [
      {name:"Email",code:"email"},
      {name:"Form Handler (Salesforce / Pardot)",code:"form_handler"},
      {name:"getform.io",code:"getform"},
      {name:"Slack",code:"slack"},
      {name:"Asana",code:"asana"},
      {name:"Custom POST Action",code:"form_action"}
    ]

   //this.newType = this.data.properties.type;
    //this.customEndPoint = this.data.properties.data;

    if(this.data.properties.type) {
      let event = {value:{name:'',code:''}};
      event.value.code = this.data.properties.type.code;
      event.value.name = this.data.properties.type.name;
      this.setCustomEndPoint(event);
    }

  }

  setCustomEndPoint(event) {
    if(event.value == null) {
      this.showCusmEndPoint = false;
    } else {
      this.data.properties.type.code = event.value.code;
      this.data.properties.type.name = event.value.name;
      this.customEndPointType = event.value.code;
      this.showCusmEndPoint = true;
    }
    
    console.log('event :' + event);
    console.log(event.value);
  }

}
