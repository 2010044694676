<div class="l-panel-editor">

  <!-- Panel Editor: Header -->
  <div class="l-panel-editor__header">
    <div class="l-panel-editor__header__title">Linked screens</div>
  </div>
  <!-- / Panel Editor: Header -->

  <!-- Panel Editor: Body -->
  <div class="l-panel-editor__body">

    <div class="scroll-wrap">

      <div class="l-panel-editor__body__content" *ngIf="dataLoaded">

        <!-- No tenemos pantallas asocidas, mostramos creatividad y botón -->
        <div *ngIf="!linkedScreens.length" class="c-message-no-content">
              
          <div class="c-message-no-content__media">
            <img class="img-fluid" src="assets/img/no-screens-yet.png" alt="No screens yet">
          </div>
          <div class="c-message-no-content__body">
            <h5 class="c-message-no-content__title">No screens yet</h5>
            <div class="c-message-no-content__description">
              <p>Start adding your first screen to this template!</p>
            </div>
            <a class="c-message-no-content__link" href="#">More information</a>
          </div>
          <div class="c-message-no-content__action">
            <button type="button" class="btn btn-primary btn-icon btn-icon__right" (click)="askCodeScreen(changeLandingNameModal)">Add a screen<svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></button>
          </div>

        </div>
        <!-- ENd creatividad y botón -->

        <!-- HAY PANTALLAS -->
        <div *ngIf="linkedScreens.length">
                
          <!-- Tenemos pantallas asociadas ya al template, las incluimos junto al botón de añadir una nueva -->
          <div *ngIf="linkedScreens">
            
            <!-- Botón incluir nueva pantalla -->
            <div class="text-center mb-30">
              <button type="button" class="btn btn-primary btn-icon btn-icon__right" (click)="askCodeScreen(changeLandingNameModal)">Add a screen<svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></button>
            </div>

            <div class="c-list-row">
              <div class="c-list-row__item" *ngFor="let item of linkedScreens; let i = index">
                
                <div class="c-list-row__item__content">
                  <div class="c-card-row">
                    <div class="c-card-row__col c-card-row__col--media">
                      <div class="c-device-badge c-device-badge--1x1">
                          <div class="c-device-badge__item">
                            <svg class="icon icon-stroke ic-32"><use href="assets/icons/icons-sprite.svg#desktop"/></svg>
                          </div>
                      </div>
                    </div>
                    <div class="c-card-row__col">
                        <app-data-label size="sm">
                            <strong data-label-text>Meeting room screen</strong>
                            <div>
                              <span app-status type="warning" size="xs">Paused</span>
                            </div>
                        </app-data-label>
                    </div>
                    <div class="c-card-row__col c-card-row__col--actions">
                      <button *ngIf="item.template_code" type="button" class="btn btn-danger btn-icon btn-icon__right" (click)="leaveScreen(item.screen_code, i, linkedScreens, true)">Leave<svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#connected"/></svg></button>
                    </div>
                  </div>
                </div>

                <div class="c-list-row__item__actions">
                  <button (click)="play = !play" type="button" class="btn btn-icon btn-icon-only">
                    <ng-template [ngIf]="play"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#play-circle"/></svg></ng-template>
                    <ng-template [ngIf]="!play"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#pause-circle"/></svg></ng-template>
                  </button>
                </div>

              </div>
            </div>

            <!-- No tenemos pantallas asociadas, mostramos CTA, ilustración con botón de incluir pantalla -->
            <div *ngIf="!linkedScreens">
              <!-- Ilustración -->
              <button class="add-module" routerLink="../screens-list">Link Your first Screen</button>
            </div>

          </div>
        <!-- / HAY PANTALLAS -->

        </div>

      </div>

    </div>

  </div>
  <!-- / Panel Editor: Body -->

</div>

<!-- Modal para agregar nueva pantalla -->
<ng-template #changeLandingNameModal let-modal>
  <div class="modal-card modal--code">
    
    <!-- Screen List + Add Screen by code -->
    <ng-container *ngIf="!showModalIntroCode">
      
      <div class="modal-header">
        <h4 class="modal-title">Add a screen</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>

      <div class="modal-body">
        <div class="text-center mb-15">
          <div class="mb-25">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <button type="button" class="btn btn-primary btn-icon btn-icon__right" (click)="showModalIntroCode = true">Pair a new screen<svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></button>
        </div>
      </div>

      <div class="modal-body bg-light" *ngIf="linkedScreensAvailables.length">
        <div class="c-line mb-15">
          <span class="c-line__text bg-light">or</span>
        </div>
        <div class="c-list-row">
          <div class="c-list-row__item" *ngFor="let item of linkedScreensAvailables; let i = index">
            <div class="c-card-row">
                <div class="c-card-row__col c-card-row__col--media">
                    <div class="c-device-badge c-device-badge--1x1">
                        <div class="c-device-badge__item">
                          <svg class="icon icon-stroke ic-32"><use href="assets/icons/icons-sprite.svg#desktop"/></svg>
                        </div>
                    </div>
                </div>
                <div class="c-card-row__col">
                    <app-data-label size="sm">
                        <strong data-label-text>Meeting room screen</strong>
                        <div>
                          <span app-status type="info" size="xs">Free</span>
                        </div>
                    </app-data-label>
                </div>
                <div class="c-card-row__col c-card-row__col--actions">
                  <button *ngIf="!item.template_code" type="button" class="btn btn-outline-primary btn-icon btn-icon__right" (click)="useAvailableScreen(item.screen_code, i, linkedScreensAvailables)">Connect<svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#disconnected"/></svg></button>
                  <button *ngIf="item.template_code" type="button" class="btn btn-success btn-icon btn-icon__right" (click)="leaveScreen(item.screen_code, i, linkedScreensAvailables, false)">Connected<svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#connected"/></svg></button>
                </div>
            </div>
          </div>
        </div>
      </div>        

    </ng-container>
    <!-- / Screen List + Add Screen by code -->

    <!-- Add Screen -->
    <ng-container *ngIf="showModalIntroCode">

      <div class="modal-header">
        <h4 class="modal-title">Pair a new screen</h4>
        <button type="button" class="close" aria-label="Close" (click)="showModalIntroCode = false">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>

      <!-- Screen Code View -->
      <div class="modal-body">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius tortor ultrices volutpat nunc erat ullamcorper enim. <a href="https://screen.inapp.io/" target="_blank">screen.inapp.io</a></p>
        <div class="my-25">
          <h6>Enter screen code</h6>
          <div class="c-code-input">
            <code-input 
              (codeChanged)="onCodeChanged($event)"
              (codeCompleted)="onCodeCompleted($event)">
            </code-input>
          </div>
          <!-- <p-inputMask  [(ngModel)]="screen_code" mask="999999"></p-inputMask> -->
        </div>
        <small *ngIf="modalRespond">{{modalRespond}}</small>
        <p class="small">Do you have any problem to pair your screens?<br> <a href="#" target="_blank">More information</a></p>
      </div>
      <!-- End Screen Code View -->

      <div class="modal-footer">
        <button class="alt-button" (click)="showModalIntroCode = false">Cancel</button>
        <button class="focus-button" (click)='addNewScreenToTemplate()'>Save</button>
      </div>

    </ng-container>
    <!-- / Add Screen -->

  </div>
</ng-template>
<!-- Fin Modal para agregar nueva pantalla -->