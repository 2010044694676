<div class="c-menu">
    <div class="c-menu-block">
      <button type="button" class="btn btn-menu" routerLink="/dashboard">
        <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#home"/></svg>
        <span class="ml-15">Home</span>
      </button>
    </div>
    <div class="c-menu-block">
      <button type="button" class="btn btn-menu" routerLinkActive="active" routerLink="/dashboard/templates">
        <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#template-2"/></svg> 
        <span class="ml-15">Templates</span>
      </button>
      <button type="button" class="btn btn-menu" routerLinkActive="active" routerLink="/dashboard/screens">
        <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#desktop"/></svg> 
        <span class="ml-15">Screens</span>
      </button>
    </div>
</div>
