
<!-- <componente-preview></componente-preview> -->
<div class="l-panel-editor">

    <!-- Panel Editor: Header -->
    <div class="l-panel-editor__header">
      <div class="l-panel-editor__header__title">Timeline</div>
      <div class="l-panel-editor__header__actions">
        <app-save-button></app-save-button>
        <app-publish-button></app-publish-button>

      </div>
    </div>
    <!-- / Panel Editor: Header -->
  
    <!-- Panel Editor: Body -->
    <div class="l-panel-editor__body">
  
  
        <div class="l-panel-editor__body__content">

            <div class="h-100 col-form-sidebar bg-light">
                <div class="d-flex align-items-center justify-content-between px-25 pt-15 pb-20">
                  <div>Preview</div>
                  <button type="button" class="btn btn-icon btn-icon-only"><svg class="icon icon-stroke ml-10"><use href="assets/icons/icons-sprite.svg#rotate"/></svg></button>
                  <button type="button" class="btn btn-icon btn-icon-only" routerLink="../overview" ><svg class="icon ic-inline ml-0"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg></button>
                </div>
                <div class="scroll-wrap">
                  <!-- Component Preview -->
                  <iframe #iframe frameBorder="0" src="" id="lndPreview"></iframe>
                  <!-- Component Preview -->
                </div>
              </div>

        </div>
  
  
    </div>
    <!-- / Panel Editor: Body -->
  
  </div>