import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewContainerRef, ComponentFactoryResolver, HostListener, AfterViewInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
//import { NoneComponent } from 'angular6-json-schema-form';

import { AuthAPIService } from '../../services/auth-api.service';
import { UserService } from '../../services/user.service';
import { LandingService } from '../../services/landing.service';
import { IframeService } from '../../services/iframe.service';

//import { AuthService } from 'angular-6-social-login';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { TemplateModules } from '../templates/template.modules';
import { LandingTemplate } from '../landing.template';
import { ColorSchemaTemplate } from '../colorSchema.template';
import { FontFamilyTemplate } from '../fontFamilies.template';



@Component({
  selector: 'app-tryout',
  templateUrl: './tryout.component.html'
})

export class TryoutComponent implements OnInit, OnDestroy, AfterViewInit {
  
  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  @ViewChild('editorPrev', {static: true}) editorPrev: ElementRef;
  public responseData: any;
  public landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  compRef: any;
  screenWidth: any;
  screenHeight: any;
  windowWidth: any;
  _scaleW:any;
  _scaleH:any;

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  public landingPostData = {
    method: 'getLandingByID',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  public currentLandingId: any; // Id de la landing sobre la que estamos trabajando
  public currentLandingName: any; // Nombre de la landing sobre la que estamos trabajando  

  //public currentModuleForm:any;
  //showModuleFormSidebar:boolean = false; // Mostrar u ocultar el sidebar para editar el modulo
  //currentModuleSection:string = "home"; // Mostrar u ocultar el modulo Design
  currentdisplayType:string = "desktop"; // Visualización preview desktop/tablet/mobile
  landingContentUpdate = new LandingTemplate().data;

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  defaultColorType = "ligth";

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;
  
  //allModulesAbailables:any = []; 
  subdomainCode: any;
  code: any;
  numberOfCollaborators: any;
  urlLanding:any;
  private sub: any;
  private _routeSubscription;
  prop: any;  //Se usa en las funciones de comparación

  constructor(
    private route: ActivatedRoute,
    public authAPIService: AuthAPIService, 
    public user: UserService,
    public router: Router,
    protected sanitizer: DomSanitizer,
    private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private dataLandingService: LandingService,
    private iframeService: IframeService,
    private modalService: NgbModal,
  ) {

    this.userPostData = this.user.getData();
    this.dataLandingService.changeLandingData(this.landingContentUpdate); // nos aseguramos que empezamos la landing desde 0 para refrescar cualquier dato de otra edición (pasa al usar el servicio para compartir información)
   }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    //Calculamos la escala del iframe en función del ancho del contenedor que lo envuelve y de la resolución de la pantalla
    this.changeIframeScale(this.currentdisplayType);
  }

    //Calculamos la escala del iframe en función del ancho del contenedor que lo envuelve y de la resolución de la pantalla
  changeIframeScale(displayType) {

    this.windowWidth = window.innerWidth;
    this.screenWidth = this.editorPrev.nativeElement.offsetWidth//document.getElementById('editorPrev').offsetWidth;
    this.screenHeight = document.getElementById('editorPrev').offsetHeight;
    let paddingTop; 
    if(displayType =='desktop') {
      paddingTop = 6;
      this._scaleW = this.screenWidth/1280;
      this.iframe.nativeElement.style.transform = ("scale("+ this._scaleW + ")");
    } else if(displayType =='tablet') {
        paddingTop = 9;
        this._scaleW = this.screenWidth/768;
        this.iframe.nativeElement.style.transform = ("scale("+ this._scaleW + ")");
    } else if(displayType =='mobile') {
      paddingTop = 9;
      this._scaleW = 1;//this.screenWidth/375;
      this.iframe.nativeElement.style.transform = ("scale("+ this._scaleW + ")");
    }
    this._scaleH = this.screenHeight/600;

    let _scale = 100 - (this._scaleH * 100);
    this.iframe.nativeElement.style.height = this.screenHeight+((this.screenHeight * _scale)/100) + 'px';

  }

  addContentToIframe(_element:any, _index) {
    let _component = TemplateModules[_element.landingType]['modules'][_element.moduleType];
    const compFactory = this.resolver.resolveComponentFactory(_component);
    this.compRef = this.vcRef.createComponent(compFactory);
  
    // Combinamos las dos estructuras, la del componente y la de BBDD por si hubiera cambios o mejoras en el componente
    let newStructure = this.compareJSON(this.compRef.instance.structure, _element);

    if(newStructure.length) {
      _element = newStructure[0];
    }
    this.landingContentUpdate.modules[_index] = _element;
    this.compRef.instance.structure = _element;
    this.landingView.body.appendChild(this.compRef.location.nativeElement);
  }
  
  displayType(type:string) {
    this.currentdisplayType = type;
    // Calculamos tamaños
    setTimeout(()=>{
      this.changeIframeScale(type);
    },0);
    
  }

  // Funciones para la comparación //////////////////////////////////////////////
  
  compareJSON(json1, json2) {
    var objectsDiffering = [];
    this.compareJSONRecursive(json1, json2, objectsDiffering);
    return objectsDiffering;
  }
  
  compareJSONRecursive(json1, json2, objectsDiffering) {

    for(this.prop in json1) {

      if(json2.hasOwnProperty(this.prop)) {

        // Si existe la propiedad en base de datos

        switch(typeof(json1[this.prop])) {

        // Comprobamos si de trata de un objeto o no
        case "object":

          if (this.prop === 'noCompare'){

            json1[this.prop] = json2[this.prop];

          } else {

            // Si es obejto llamada recursiva para recorrer cada elmento
            this.compareJSONRecursive(json1[this.prop], json2[this.prop], objectsDiffering);

          }
          break;

        default:

          // Si se trata de una variable

          if(json1[this.prop] !== json2[this.prop]) {
            
            // Si los valores son distintos actualizamos con lo que viene de base de datos sobre json1 que el que finalmente se inyecta
            json1[this.prop] = json2[this.prop];
            
            //objectsDiffering.push(json2);
          } else {

            // Si son iguales nos da igual. Dejamos el que tenemos en memoria
            objectsDiffering.push(json1);
          }
          break;
        }

      } else {

        // Si no existe la propiedad en base de datos, se añade al nuevo objeto

        objectsDiffering.push(json1);
        break;
      }

    }

  }

  ngOnInit() {

    if (this.userPostData.user_type !== 'guest'){

      this.user.logOut();
      
    } 

    this.code = this.route.snapshot.paramMap.get('id');

    // Mostramos la seccion correspondiente en función de la URL, check si hay cambio de URL
    this._routeSubscription = this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log(event);
      }

      if (event instanceof NavigationEnd) {
          // Hide loading indicator
          console.log(event);
          //this.dataLandingService.changeLandingData(this.landingPostData);
          //this.showModuleSection(this.route.snapshot.firstChild.data.menuId);
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }
    });

    // Mostramos la seccion correspondiente en función de la URL, check la primera vez que accedemos
    this.route.url.subscribe(() => {
      //this.showModuleSection(this.route.snapshot.firstChild.data.menuId);
    });

    this.landingView = this.iframe.nativeElement.contentDocument || 
    this.iframe.nativeElement.contentWindow; // Asociamos iframe en el HTML a landingView que usaremos para cargar los diferentes modulos

    // Pasamos el objeto iframe al servicio para compartirlo
    this.iframeService.setLandingView(this.landingView);

    // Llamada a servicio para traernos los datos de la landing

    //Pasamos URL al iframe con la landing que vamos a editar
    //this.urlLanding = this.sanitizer.bypassSecurityTrustResourceUrl("/#/landing/"+ this.code);

    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = this.code;

    this.authAPIService.postDataLanding(this.landingPostData).then( respond => {
      this.responseData = respond;

      this.landingPostData.landing_id = this.responseData.landing[0].landing_id; // Valor necesario para guardar los cambios
      this.subdomainCode = this.responseData.landing[0].landing_subdomain_code

      if (this.responseData.code == 200) {
        let _result = JSON.parse(this.responseData.landing[0].landing_content);
        _result.landing_id = this.responseData.landing[0].landing_id; // Valor necesario para guardar los cambios y que necesitamos compartir
        _result.landing_name = this.responseData.landing[0].landing_name; // Valor necesario para guardar los cambios y que necesitamos compartir
       

        // Incluimos en el iframe que hemos creado vacio los assets necesarios, CSS, SCRIPTS, etc..
        this.iframeService.setCssScriptsToIframe(_result, this.landingView);
        
        // Este servicio DESENCADENA la creación de la landing en el iframe
        // Compartimos la información de la landing mediante un servicio para poder hacer y visualizar los cambios en tiempo real
        setTimeout(()=>{ // Esperamos XXX para que se carge el CSS y no tengamos un flash de la landing - Pensar en una mejor solución
          this.dataLandingService.changeLandingData(_result);
          //this.dataLandingService.onGetData.emit(_result);
        },0)
    
      } else {

        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }
    },
    err => {
        console.log('Error');
    });

    // Cambio en el objeto landing actualizamos modulos
    this.dataLandingService.landingSource.subscribe((respond)=>{
      
      // Pasamos a la vista solo los modules de la respuesta
      this.landingContentUpdate = respond;

      // Pasamos a la vista solo los modules de la respuesta
      this.landingContentUpdate = respond;
      
      // Pasamos el objeto Iframe al servicio para luego poder utilizarlo en las diferentes funciones
      this.iframeService.setLandingView(this.landingView);
      
      // Incluimos en el iframe que hemos creado vacio los assets necesarios, CSS, SCRIPTS, etc..
      //this.iframeService.setCssScriptsToIframe(this.landingContentUpdate);

      // Configuramos el schema de color
      this.iframeService.setColorSchemaToIframe(this.landingContentUpdate.config.landingColor, this.landingView);

      // Configuramos el schema de color
      this.iframeService.setFontSchemaToIframe(this.landingContentUpdate.config.landingFont, this.landingView);
      
      // Borramos el contenido del iframe antes de incluir los modulos, esto es necesario para volver a montar la landing con nuevos modulos y que no los sume a los actuales
      this.landingView.body.innerHTML = '';
      // Por cada modulo recibido en la respuesta injectamos su componente en el iframe, esto nos facilita no tener que tener URL en el desarrollo para incluir en el desarrollo
      respond.modules.forEach((element,index) => {
         this.addContentToIframe(element,index); 
      });      
      
    })

    // Check para comprobar si el usuario es propietario o no. En caso negativo, 
    // no se permite ni duplicar landing, ni compartirla
    ////////////////////////////////////////////////////////////////////////////////////////

    this.landingPostData.method = 'getLandingByID';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = this.code;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {

      // Enviamos id del usuario actual y de la landing  

      this.responseData = result;

      if (this.responseData.code == 200) {

        // Aprovechamos la query para obtener el nombre de la landing necesario en alguna modal

        this.currentLandingId =  this.code;
        this.currentLandingName = this.responseData.landing[0].landing_name;

      } else {

        // Error en la consulta
      }

    },
    err => {
        console.log('Error');
    });

  }

  ngOnDestroy() {
    this._routeSubscription.unsubscribe();
  }

  ngAfterViewInit() {

    // // Calculamos tamaños

    //Calculamos la escala del iframe en función del ancho del contenedor que lo envuelve y de la resolución de la pantalla
    this.changeIframeScale('desktop');
    
  }

}
