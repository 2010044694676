import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LandingService } from '../../../services/landing.service';
import { IframeService } from '../../../services/iframe.service';
import { LandingTemplate } from '../../landing.template';
import { FontFamilyTemplate } from '../../fontFamilies.template';
import { Subscription } from 'rxjs';

interface Field {
  name: string,
  code: string
}

@Component({
  selector: 'app-font',
  templateUrl: './font.component.html',
  styleUrls: ['./font.component.scss']
})
export class FontComponent implements OnInit, AfterViewInit {

  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  landingContentUpdate = new LandingTemplate().data;
  availableHeadsTypes: Field[];
  availableParagraphTypes: Field[];
  subscription: Subscription;
  dataLoaded:boolean = false; 

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;

  constructor(
    private dataLandingService: LandingService,
    private iframeService: IframeService
  ) { 

    this.defaultFontFamily.predefined.forEach(element => {
      element['title'] = this.dataLandingService.getCustomValue('subtitle','random');
      element['paragraph'] = this.dataLandingService.getCustomValue('largeText','random');
    });

  }

  setFontFamily(fontIndex,item){
    console.log(item);
    //marcamos como activo el schema seleccionado, necesitamos pasar todos a desactivado y luego activar el seleccionado
    this.defaultFontFamily.predefined.forEach(element => {
      element.active = false;
    });
    //activamos el schema seleccionado
    this.defaultFontFamily.predefined[fontIndex] = item;
    this.defaultFontFamily.predefined[fontIndex].active = true;
    
    //actualizamos el la fuente utilizada en la landing, para ello actualizamos el valor que tenga landingFont en el objeto de la landing con todos los datos
    this.landingContentUpdate.config.landingFont = item;

    //actualizamos el objeto landing que compartimos con el resto de componentes para tenerlos actualizados
    this.dataLandingService.changeLandingData(this.landingContentUpdate);

  }

  setCustomHeadFont(font){
    console.log(font.value);
  }

  getFontUrl(name){
    return `https://fonts.googleapis.com/css?family=${name.replace(' ', '+')}`;
  }

  ngOnInit() {

    // this.availableHeadsTypes = this.defaultFontFamily.head;
    // this.availableParagraphTypes = this.defaultFontFamily.paragraph;

    //   // Optenemos la información de la landing, vamos a guardar los schemas de color y tipo en el objeto de landing compartido en la aplicación para poder almacenar los cambios
    //   this.subscription = this.dataLandingService.landingSource.subscribe((respond)=>{
    
    //     // pasamos al objeto landingContentUpdate toda la información de la landing
    //     this.landingContentUpdate = respond;

    //     // Configuramos el schema de color
    //     this.iframeService.setFontSchemaToIframe(this.landingContentUpdate.config.landingFont, this.landingView);
        
    //   })

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  
  private renderLayoutModules() {

    this.availableHeadsTypes = this.defaultFontFamily.head;
    this.availableParagraphTypes = this.defaultFontFamily.paragraph;

      // Optenemos la información de la landing, vamos a guardar los schemas de color y tipo en el objeto de landing compartido en la aplicación para poder almacenar los cambios
      this.subscription = this.dataLandingService.landingSource.subscribe((respond)=>{
        
        if(respond.landing_id === null) return;
        // Bool para cambiar el estado de carga
        this.dataLoaded = true;
        // pasamos al objeto landingContentUpdate toda la información de la landing
        this.landingContentUpdate = respond;
        // Configuramos el schema de color
        this.iframeService.setFontSchemaToIframe(this.landingContentUpdate.config.landingFont, this.landingView);
        
      })

  }

  ngAfterViewInit(){

    Promise.resolve(null).then(() => this.renderLayoutModules());

  }

}
