<div class="l-panel-full">
    <div class="l-panel-full__content">
        <div class="l-panel-full__header">
            <h5>User profile</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius tortor ultrices volutpat nunc erat ullamcorper enim.</p>
        </div>
        <div class="l-panel-full__body">
            <div class="l-panel-full__body__content">
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">User details</div>
                        <div class="form-row">
                            <div class="col-sm">
                                <div class="form-group mb-10 mb-sm-0">
                                    <div class="p-float-label">
                                        <input id="float-input" type="text" pInputText value="Name"> 
                                        <label for="float-input">Name</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="form-group mb-10 mb-sm-0">
                                    <div class="p-float-label">
                                        <input id="float-input" type="text" pInputText value="Surname"> 
                                        <label for="float-input">Surname</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="form-group mb-10 mb-sm-0">
                                    <div class="p-float-label">
                                        <input id="float-input" type="text" pInputText value="n.surname"> 
                                        <label for="float-input">Account name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">Login details</div>
                        <p-table [value]="login" dataKey="id" editMode="row" class="c-table-edit">
                            <ng-template pTemplate="body" let-login let-editing="editing" let-ri="rowIndex">
                                <tr [pEditableRow]="login">
                                    <td pEditableColumn *ngIf="login.email">
                                        <app-data-label size="sm" *ngIf="login.email">
                                            <span data-label-caption>Email</span>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="login.email">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span data-label-text>{{login.email}}</span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </app-data-label>
                                    </td>
                                    <td pEditableColumn *ngIf="login.password">
                                        <app-data-label size="sm">
                                            <span data-label-caption>Password</span>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="login.password">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span data-label-text>{{login.password}}</span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </app-data-label>
                                    </td>
                                    <td class="c-table-edit__actions c-table-edit__actions--sm">
                                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow (click)="onRowEditInit(login)" class="btn btn-link px-0 p-button-text">Edit</button>
                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(login)" class="p-button-rounded p-button-text p-button-success"></button>
                                        <!-- <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(login)" class="p-button-rounded p-button-text p-button-danger"></button> -->
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
