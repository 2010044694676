<div 
cdkDropList
[cdkDropListData]="data.items"
(cdkDropListDropped)="drop($event)">
    <div class="c-form-editor-item" *ngFor="let item of data.items; let i = index" cdkDrag> 
        <div class="c-form-editor-item-left">
            <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" ngDefaultControl [(ngModel)]="item.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
        </div>
        <div class="c-form-editor-form c-form-editor-repetible list-modules-item pt-10 pb-10 pl-10" [ngClass]="{'c-form-editor-hide':!item.visible}">
            <app-icon-select [(data)]="item['icon'].properties"></app-icon-select>
            <div class="row">
                <div class="col">
                    <div class="p-float-label">
                        <input id="float-input" type="text" pInputText [(ngModel)]="item['title'].properties.data"> 
                        <label for="float-input">{{item['title'].properties.label}}</label>
                    </div>
                </div>
            </div>
            <div class="c-form-editor-repetible-drag-element" cdkDragHandle></div>
        </div>
        <div class="c-form-editor-item-right" [ngClass]="{'c-form-editor-hide':!item.visible}" *ngIf="data.items.length > 1">
            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeField(i)"></button>      
        </div>
    </div>
</div>


<div class="c-form-editor-item c-form-editor-item__bg mt-10">
    <div class="c-form-editor-item-left"></div>
    <div class="c-form-editor-form">
    </div>
    <div class="pl-5">
        <button pButton pRipple type="button" label="Add new feature" icon="pi pi-plus" class="p-button-sm p-button-text mb-10 mr-40" (click)="addField(data)"></button>
    </div>
</div>

