export class EditorOptionsConfigTemplate {
  data =
    {   
      modules:[  
      {    
        title: "Third-Party Scripts",
        options: [
          {
            id: "GA",
            label: "Google Analytics",
            typeForm: "text",
            help: "On the Google Analytics Dashboard, click on Admin > Tracking Info > Tracking Code and then copy the Tracking ID."
          },
          {
            id: "GTM",
            label: "Google Tag Manager",
            typeForm: "text",
            help: "On the Google Analytics Dashboard, click on Admin > Tracking Info > Tracking Code and then copy the Tracking ID."
          },
          {
            id: "facebookPixel",
            label: "Facebook Pixel",
            typeForm: "text",
            help: "On the Google Analytics Dashboard, click on Admin > Tracking Info > Tracking Code and then copy the Tracking ID."
          },
          {
            id: "intercom",
            label: "Intercom",
            typeForm: "text",
            help: "On the Google Analytics Dashboard, click on Admin > Tracking Info > Tracking Code and then copy the Tracking ID."
          }
        ]
      },
      {
        title: "Custom script",
        options: [
          {
            id: "custom",
            label: "Add your custom script",
            typeForm: "textarea",
            help: null
          }
        ]
      },
      {
        title: "Custom meta Tags",
        options: [
          {
            id: "metaTitle",
            label: "Title",
            typeForm: "text",
            help: null
          },
          {
            id: "metaDescription",
            label: "Description",
            typeForm: "textarea",
            help: null
          }
        ]
      }
    ]
  }       
}