import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LandingService } from '../../../../services/landing.service';


@Component({
  selector: 'app-form-config',
  templateUrl: './form-config.component.html',
  animations: [
    trigger('tabContent', [
        state('hidden', style({
            height: '0',
            overflow: 'hidden'
        })),
        state('visible', style({
            height: '*'
        })),
        transition('visible <=> hidden', [style({overflow: 'hidden'}), animate('{{transitionParams}}')]),
        transition('void => *', animate(0))
    ])
  ],
})
export class FormConfigComponent implements OnInit {

  @Input() label: string;
  @Input() help: string;
  @Input() typeForm: string;
  @Input() landingObject: any;
  @Input() id: any;
  @Input() transitionOptions: string = '400ms cubic-bezier(0.86, 0, 0.07, 1)';

  modelValue:any;
  selected: boolean = false;

  constructor(
    private dataLandingService: LandingService
  ) { }

  ngOnInit(): void {
    console.log('<<<<<<<<<<<<<<<<<<<<<<<');
    console.log(this.id);
    console.log(this.landingObject);
    if(!this.landingObject.config.landingIntegrations.hasOwnProperty(this.id)) {
      this.landingObject.config.landingIntegrations[this.id] = '';
    }

    console.log(this.landingObject.config.landingIntegrations[this.id])

  }

  toggle(event) {
    if (this.selected) {
      this.selected = false;
    } else {
      this.selected = true;
    }
    event.preventDefault();
  }

  get hasValue():boolean {
    return this.landingObject.config.landingIntegrations[this.id] && this.landingObject.config.landingIntegrations[this.id].length > 0;
  }

  save(){
    
    if (this.selected) {
      this.selected = false;
    } else {
      this.selected = true;
    }
    
    console.log(this.landingObject);
    //this.landingObject.config.landingIntegrations[this.id] = this.modelValue;
    
    //actualizamos el objeto landing que compartimos con el resto de componentes para tenerlos actualizados
    //this.dataLandingService.changeLandingData(this.landingObject);

  }

}
