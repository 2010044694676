import { Component, OnInit, Input } from '@angular/core';

//import Quill from 'quill'
import 'quill-emoji/dist/quill-emoji.js'

@Component({
  selector: 'app-textarea-toolbar',
  templateUrl: './textarea-toolbar.component.html',
  styleUrls: ['./textarea-toolbar.component.scss']
})
export class TextareaToolbarComponent implements OnInit {

  @Input() data: any;
  
  modules = {};
  content = '';
  matContent = '';
  emojiIcon = '<svg class="i" viewBox="0 0 24 24"><use href="#emoticon-happy"></use></svg>';

  constructor() {
    this.modules = {
      'emoji-shortname': true,
      //'emoji-textarea': true,
      "emoji-toolbar": true,
      'toolbar': [
        ['bold', 'italic', 'underline', 'strike', 'link', 'clean','emoji'],        // toggled buttons
        //['blockquote', 'code-block'],

        //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
        //[{ 'list': 'ordered' }, { 'list': 'bullet' }],
       // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
       // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

       // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //[{ 'font': [] }],
       // [{ 'align': [] }],

        //['clean'],                                         // remove formatting button

        //['link', 'image', 'video'],                         // link and image, video
        //['emoji']

      ]
    }
  }

  addBindingCreated(quill) {
    quill.keyboard.addBinding({
      key: '/',
      altGrKey: true,
    }, (range, context) => {
      // tslint:disable-next-line:no-console
      console.log('KEYBINDING B', range, context)
    })

    quill.keyboard.addBinding({
      key: 'B',
      shiftKey: true
    }, (range, context) => {
      // tslint:disable-next-line:no-console
      console.log('KEYBINDING SHIFT + B', range, context)
    })
  }

  ngOnInit(): void {
  }

}
