import { Component, OnInit, Input } from '@angular/core';
// Importamos los diferentes campos comunes para poder reutilizar los que sean necesarios
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { EditorCommonsOptions } from '../../../editor.commons.options';

interface Field {
  name: string,
  code: string
}

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements OnInit {

  @Input() data: any;
  availableTypes: Field[];
  newLabel:any;
  newType:Field;
  newRequired:any;

  constructor() {
        this.availableTypes = [
            {name: 'Text', code: 'text'},
            {name: 'Email', code: 'email'},
            {name: 'Phone', code: 'phone'},
            {name: 'Number', code: 'number'},
            {name: 'Date', code: 'date'},
            {name: 'Checkbox', code: 'checkbox'},
            {name: 'Dropdown', code: 'select'},
            {name: 'Hidden', code: 'hidden'}
        ];
        this.newType = { name: 'Text', code: 'text'};
   }

  ngOnInit(): void {
    console.log(this.data);
  }

  removeField(index){
    this.data.fields.splice(index,1);
  }

  addField(_data,fieldLabel,fieldType,newRequired){
      let newField = new EditorCommonsOptions().schema.form.properties;
      newField.label = null;
      newField.name = null;
      newField.type = {name:'Text',code:'text'};
      //newField.required = 0;

      // Los incluimos en el objeto que vamos a utilizar para pasar al editor y a la vista
      _data.fields.push(newField);
      console.log(_data);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  addOptionToSelect(item) {
    
    if(!item.options) {
      item.options = [];
    }
    
    item.options.push({'label':null,'value':null});
  }

  removeOptionToSelect(item,index){
    item.options.splice(index,1);
  }

}
