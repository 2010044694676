import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewContainerRef, ComponentFactoryResolver} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthAPIService } from '../../services/auth-api.service';
import { UserService } from '../../services/user.service';
import { LandingService } from '../../services/landing.service';
//import { AuthService } from 'angular-6-social-login';

import { TemplateModules } from '../templates/template.modules';
import { LandingTemplate } from '../landing.template';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  public responseData: any;
  landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  currentdisplayType:string = "desktop"; // Visualización preview desktop/tablet/mobile
  landingContentUpdate = new LandingTemplate().data;
  compRef: any;
  code: any;

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  public landingPostData = {
    method: 'getLandingByID',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  script = `<html>
               <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
              <script>
                console.log('I am in the function 1') 
                console.log(window);
                
                window.onload = function(){
                  console.log('asdddd');
                }
               
               $(window).on('load', function() {
                 console.log('I am in the function') 
               })

               $( document ).ready(function() {
                console.log( "ready!" );
                });
                
             </script>
         <body>
             <h1>Hello world</h1>
             
         </body>
        
      </html>`;

  constructor(
    private route: ActivatedRoute,
    public authAPIService: AuthAPIService, 
    public user: UserService,
    public router: Router,
    protected sanitizer: DomSanitizer,
    private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private dataLandingService: LandingService
  ) { 
    this.userPostData = this.user.getData();
  }

  goToLanding(state){
    console.log(state);
    this.router.navigateByUrl('/editor/'+state);
  }

  displayType(type:string) {
    this.currentdisplayType = type;
  }

  addContentToIframe(_element:any, _index) {
    let _component = TemplateModules[_element.landingType][_element.moduleType];
    const compFactory = this.resolver.resolveComponentFactory(_component);
    this.compRef = this.vcRef.createComponent(compFactory);
  
    // Combinamos las dos estructuras, la del componente y la de BBDD por si hubiera cambios o mejoras en el componente
    //let newStructure = this.compareJSON(this.compRef.instance.structure, _element);

    //if(newStructure.length) {
    //  _element = newStructure[0];
    //}
    this.landingContentUpdate.modules[_index] = _element;
    this.compRef.instance.structure = _element;
    this.landingView.body.appendChild(this.compRef.location.nativeElement);

  }

  ngOnInit() {

    // Bloqueamos acceso para usuarios de tipo invitado.

    if (this.userPostData.user_type === 'guest'){

      this.user.logOut();
      
    } 

    //this.landingView = this.iframe.nativeElement.contentDocument || 
    //this.iframe.nativeElement.contentWindow; // Asociamos iframe en el HTML a landingView que usaremos para cargar los diferentes modulos

    this.code = this.route.snapshot.paramMap.get('id');
    // Llamada a servicio para traernos los datos de la landing

    //Pasamos URL al iframe con la landing que vamos a editar
    //this.urlLanding = this.sanitizer.bypassSecurityTrustResourceUrl("/#/landing/"+ this.code);

    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = this.code;

    this.authAPIService.postDataLanding(this.landingPostData).then( respond => {
      this.responseData = respond;
      //this.landingPostData.landing_id = this.responseData.landing[0].landing_id; // Valor necesario para guardar los cambios
    },
    err => {
        console.log('Error');
    });


  }

}
