<main class="db db-sidebar-expand">
    <!-- Alert -->
    <!-- <app-alert></app-alert> -->
    <!-- / Alert -->
    <!-- Header -->
    <app-header></app-header>
    <!-- / Header -->
  
    <!-- Menu -->
    <div class="db-menu-mobile-header">
      <div class="c-menu-mobile-header">
        <div class="c-menu-mobile-header__left">
          <button type="button" class="btn btn-icon btn-icon-only" routerLink="/dashboard">
            <svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#home"/></svg>
          </button>
        </div>
        <div class="c-menu-mobile-header__right">
          <button type="button" class="btn btn-landing-title"> <!-- (click)="changeNameLanding(changeLandingNameModal)" -->
            <!-- <span [innerHTML]="landingContentUpdate.landing_name"></span> -->
            <span>Screen name</span>
            <svg class="icon icon-stroke ic-18 ml-20"><use href="assets/icons/icons-sprite.svg#edit"/></svg>
          </button>
          <button type="button" class="btn btn-link pr-0 ml-5" routerLink="preview">Preview</button>
        </div>
      </div>
    </div>
    <div class="db-menu-mobile-footer">
      <div class="c-menu-mobile-footer">
        <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="settings">
          <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#setting"/></svg>
          <span>Settings</span>
        </button>
        <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="template">
          <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#linked-screen"/></svg>
          <span>Linked template </span>
          <!-- <span class="badge badge-pill badge-primary" *ngIf="linkedScreens.length">{{linkedScreens.length}}</span> -->
          <span class="badge badge-pill badge-primary" >1</span>
        </button>
      </div>
    </div>
    <div class="db-sidebar">
      <div class="db-sidebar-menu">
        <div class="db-sidebar-wrap pb-20 h-100 d-flex flex-column justify-content-between">
          <app-menu></app-menu>
        </div>
        <div class="db-sidebar-footer px-0 text-center">
          <strong>© 2022</strong>
        </div>
      </div>
      <div class="db-sidebar-submenu">
        <div class="db-sidebar-wrap pb-20 h-100 d-flex flex-column justify-content-between">
          <div class="c-submenu">
            <div class="c-submenu-block">
              <button type="button" class="btn btn-landing-title"> <!-- (click)="changeNameLanding(changeLandingNameModal)" -->
                <!-- <span [innerHTML]="landingContentUpdate.landing_name"></span> -->
                <span>Screen title</span>
                <svg class="icon icon-stroke ic-18 ml-20"><use href="assets/icons/icons-sprite.svg#edit"/></svg>
              </button>
            </div>
            <div class="c-submenu-block">
              <div class="c-submenu-title">Content</div>
              <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="settings">
                <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#setting"/></svg>
                <span>Settings</span>
              </button>
            </div>
            <div class="c-submenu-block">
              <div class="c-submenu-title">Global configuration</div>
              <button type="button" class="btn btn-submenu" routerLinkActive="active" routerLink="template">
                <svg class="icon icon-stroke ic-24"><use href="assets/icons/icons-sprite.svg#linked-screen"/></svg>
                <span>Linked screens </span>
                <!-- <span class="badge badge-pill badge-primary" *ngIf="linkedScreens.length">{{linkedScreens.length}}</span> -->
                <span class="badge badge-pill badge-primary">1</span>
              </button>
            </div>
          </div>
        </div>
        <div class="db-sidebar-footer">
          <button type="button" class="btn btn-icon d-flex align-items-center p-0" routerLink="new-version"> <!-- *ngIf="landingOwner" (click)='showModalRemoveLanding(removeLandingModal,currentLandingId,currentLandingName)' -->
            <svg class="icon icon-stroke ic-danger ic-20 mr-10"><use href="assets/icons/icons-sprite.svg#delete"/></svg>
            <span>Delete screen</span>
          </button>
        </div>
      </div>
    </div>
    <!-- / Menu -->
  
    <section class="db-panel">
      
      <!-- Editor Content -->
      <div class="l-template-container">
        
        <div class="l-template-container__editor">
          <!-- Edit Component -->
          <router-outlet></router-outlet>
          <!-- End Edit Component -->
        </div>
  
        <div class="l-template-container__preview" [ngClass]="{'show': showPreview}">
          
          <!-- Editor Preview -->
          <div class="l-panel-preview">
  
            <!-- Panel Editor: Header -->
            <div class="l-panel-preview__header">
  
              <div class="l-panel-preview__header__left">
  
              </div>
  
              <div class="l-panel-preview__header__center">
                <div class="l-panel-preview__header__actions">
                  <button type="button" class="btn btn-icon btn-icon-only"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#arrow_left4"/></svg></button>
                  <button type="button" class="btn btn-icon btn-icon-only"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#rotate"/></svg></button>
                  <button type="button" class="btn btn-icon btn-icon-only"><svg class="icon icon-stroke"><use href="assets/icons/icons-sprite.svg#arrow_right4"/></svg></button>
                </div>
              </div>
  
              <div class="l-panel-preview__header__right">
                <div>
                  <div app-status type="success" value="3" size="sm">Streaming</div>
                </div>
              </div>
              
            </div>
            <!-- / Panel Editor: Header -->
  
            <!-- Panel Editor: Body -->
            <div class="l-panel-preview__body">
  
              <div class="scroll-wrap">
  
                <div class="l-panel-preview__body__content">
                
                  <div id="editorPrev" #editorPrev class="c-prev-mockup">
                    <div class="c-prev-mockup__iframe c-prev-mockup__iframe--16x9" #iframeWrapper>
                      <!-- <componente-preview></componente-preview> -->
                      <iframe #iframe frameBorder="0" src="" id="lndPreview"></iframe>
                      <!-- <div class="bg-secondary"></div> -->
                    </div>
                  </div>
  
                </div>
  
              </div>
  
            </div>
            <!-- / Panel Editor: Body -->
  
          </div>
  
        </div>
          
      </div>
      <!-- / Editor Content -->
    </section>
  
  </main>
  
