<div 
    cdkDropList
    [cdkDropListData]="data.fields"
    (cdkDropListDropped)="drop($event)">

    <div class="c-form-editor-item" *ngFor="let item of data.fields; let i = index" cdkDrag>
        <div class="c-form-editor-item-left">
            <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" ngDefaultControl [(ngModel)]="item.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
        </div>
        <div class="c-form-editor-form c-form-editor-repetible list-modules-item pb-10 pl-10" [ngClass]="{'c-form-editor-hide':!item.visible}">
            <div class="row pl-5">
                <div class="col mt-5">
                    <div class="d-flex">
                        <p-inputSwitch [(ngModel)]="item.required"></p-inputSwitch>
                        <span class="ml-10" *ngIf="item.required">Required</span>
                        <span class="ml-10" *ngIf="!item.required">Not required</span>    
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="item.type.code != 'checkbox'">
                <div class="col">
                    <div class="p-float-label">
                        <p-dropdown [options]="availableTypes" [(ngModel)]="item.type" optionLabel="name" [showClear]="false" placeholder="Select Type"></p-dropdown>  
                        <!-- <label for="float-input">Type</label> -->
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="item.type.code != 'checkbox'">
                <div class="col">
                    <div class="p-float-label">
                        <input id="float-input" type="text" pInputText [(ngModel)]="item.label"> 
                        <label for="float-input">Label</label>
                    </div>
                </div>
            </div>
            <!-- Para los casos de campos hidden mostramos un campo adicional para poder pasar un value por defecto en este input -->
            <div class="row" *ngIf="item.type.code == 'hidden'">
                <div class="col">
                    <div class="p-float-label">
                        <input id="float-input" type="text" pInputText [(ngModel)]="item.value"> 
                        <label for="float-input">Default value</label>
                    </div>
                </div>
            </div>
            <!-- Para los casos en que se seleccione checkbox ponemos un campo editor para que el usuairo pueda enlazar a alguna página en el label (ej. términos legales,e tc..) -->
            <div class="row" *ngIf="item.type.code == 'checkbox'">
                <div class="col">
                    <div class="p-float-label">
                        <p-dropdown [options]="availableTypes" [(ngModel)]="item.type" optionLabel="name" [showClear]="false" placeholder="Select Type"></p-dropdown>  
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="item.type.code == 'checkbox'">
                <div class="col">
                   
                        <p-editor [(ngModel)]="item.value" [style]="{'height':'80px'}" class="mt-10">
                            <p-header>
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-strike"></button>
                            <span class="ql-format-separator"></span>
                            <button class="ql-link"></button>
                            <!-- <button class="ql-emoji"></button> -->
                            </p-header>
                        </p-editor>
                </div>
            </div>
            <!-- Campos Select -->
            <div class="row" *ngIf="item.type.code == 'select'">
                <ng-container *ngFor="let option of item.options; let i=index">
                    <div class="row ml-5">
                        <div class="col mt-5">
                            <div class="p-float-label">
                                <input type="text" [(ngModel)]="option.label" pInputText>
                                <label for="float-input">Option Label</label>
                            </div>
                        </div>
                        <div class="col mt-5">
                            <div class="p-float-label">
                                <input type="text" [(ngModel)]="option.value" pInputText>
                                <label for="float-input">Value</label>
                            </div>
                        </div>
                        <div class="c-form-editor-item-right mt-5">
                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeOptionToSelect(item,i)"></button>      
                        </div>
                    </div>
                </ng-container>
                <div class=" pl-5">
                    <button pButton pRipple type="button" label="Add new option" icon="pi pi-plus" class="p-button-sm p-button-text mt-5" (click)="addOptionToSelect(item)" style="font-size: 12px;"></button>
                </div>
            </div>
            <div class="c-form-editor-repetible-drag-element" cdkDragHandle></div>
        </div>
        <div class="c-form-editor-item-right" [ngClass]="{'c-form-editor-hide':!item.visible}">
            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeField(i)"></button>      
        </div>
    </div>

</div>

<div class="c-form-editor-item c-form-editor-item__bg mt-10">
    <div class="c-form-editor-item-left"></div>
    <div class="c-form-editor-form">
    </div>
    <div class="pl-5">
        <button pButton pRipple type="button" label="Add new field" icon="pi pi-plus" class="p-button-sm p-button-text mr-40" (click)="addField(data,newLabel,newType,newRequired)"></button>
    </div>
</div>


