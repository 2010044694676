import { Component,OnInit,Input,Output,ElementRef,EventEmitter,forwardRef,ViewChild,ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'choiceRadioButton',
  templateUrl: './choice-radio-button.component.html',
  styleUrls: ['./choice-radio-button.component.scss']
})
export class ChoiceRadioButtonComponent implements OnInit {

  @Input() value: any;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() key: string;
  @Input() active: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('rb', {static: true}) inputViewChild: ElementRef;

  public onModelChange: Function = () => {};
  public onModelTouched: Function = () => {};
  public checked: boolean;
  public focused: boolean;

  constructor() { }

  handleClick(event, radioButton, focus) {
    event.preventDefault();

    if (this.disabled) {
        return;
    }

    this.select(event);

    if (focus) {
        radioButton.focus();
    }
  }

  select(event) {
      if (!this.disabled) {
          this.inputViewChild.nativeElement.checked = true;
          this.checked = true;
          this.onModelChange(this.value);
          this.onClick.emit(event);
      }
  }

  ngOnInit() {
    //console.log(this.active);
  }

}
