export class LandingFontSizes {
    data ={
        "title":[
            {value: 'fs-20', default:false},
            {value: 'fs-28', default:false},
            {value: 'fs-38', default:true},
            {value: 'fs-48', default:false},
            {value: 'fs-58', default:false},
            {value: 'fs-68', default:false},
            {value: 'fs-78', default:false}             
          ],
        "hero":[
            {value: 'fs-38', default:false},
            {value: 'fs-48', default:false},
            {value: 'fs-58', default:true},
            {value: 'fs-68', default:false},
            {value: 'fs-78', default:false},
            {value: 'fs-88', default:false},
            {value: 'fs-98', default:false},
            {value: 'fs-108', default:false},
            {value: 'fs-118', default:false} 
          ],
        "paragraph":[
            {value: 'fs-14', default:false},
            {value: 'fs-16', default:false},
            {value: 'fs-18', default:true},
            {value: 'fs-20', default:false},
            {value: 'fs-24', default:false}
          ]
            
    }
}