<div class="l-panel-full">
    <div class="l-panel-full__content">
        <div class="l-panel-full__header">
            <h5>Preferences</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius tortor ultrices volutpat nunc erat ullamcorper enim.</p>
        </div>
        <div class="l-panel-full__body">
            <div class="l-panel-full__body__content">
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">Language</div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <select class="form-control">
                                        <option selected value="en">English</option>
                                        <option value="es">Spanish</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">Comunications</div>
                        <div class="form-group">
                            <div class="d-flex align-items-center justify-content-between">
                                <app-data-label size="sm" class="mr-10">
                                    <span data-label-text>Lorem ipsum dolor sit amet, consectetur</span>
                                    <span data-label-caption>Lorem ipsum dolor sit amet, consectetur</span>
                                </app-data-label>
                                <p-inputSwitch [(ngModel)]="comunication1"></p-inputSwitch>
                            </div>
                        </div>
                        <div class="c-form-editor-line"></div>
                        <div class="form-group">
                            <div class="d-flex align-items-center justify-content-between">
                                <app-data-label size="sm" class="mr-10">
                                    <span data-label-text>Lorem ipsum dolor sit amet, consectetur</span>
                                    <span data-label-caption>Lorem ipsum dolor sit amet, consectetur</span>
                                </app-data-label>
                                <p-inputSwitch [(ngModel)]="comunication2"></p-inputSwitch>
                            </div>
                        </div>
                        <div class="c-form-editor-line"></div>
                        <div class="form-group">
                            <div class="d-flex align-items-center justify-content-between">
                                <app-data-label size="sm" class="mr-10">
                                    <span data-label-text>Lorem ipsum dolor sit amet, consectetur</span>
                                    <span data-label-caption>Lorem ipsum dolor sit amet, consectetur</span>
                                </app-data-label>
                                <p-inputSwitch [(ngModel)]="comunication3"></p-inputSwitch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

