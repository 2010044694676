import { Component, OnInit } from '@angular/core';
import { ScreensService } from 'src/services/screens.service';
import { UserService } from 'src/services/user.service';
import { LandingService } from 'src/services/landing.service';
import { AuthAPIService } from 'src/services/auth-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-screens',
  templateUrl: './screens.component.html',
  styleUrls: ['./screens.component.scss']
})
export class ScreensComponent implements OnInit {

  linkedScreens:any;
  userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  landingPostData = {
    method: 'getLandingByID',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  responseData:any;
  modalRespond:any;
  subscription:any;
  template_code:any;

  selectedFilter: string;
  filters: any[];

  constructor( 
    private screens: ScreensService,
    private user: UserService,
    private authAPIService: AuthAPIService,
    private modalService: NgbModal,
    private route: ActivatedRoute, 
    private dataLandingService: LandingService
    ) { 
      
      this.userPostData = this.user.getData();
      this.route.parent?.params.subscribe((result:any) => this.template_code = result.id);

      this.filters = [
        {name: 'Date create'},
        {name: 'A-Z'},
        {name: 'Z-A'}
      ];

    }

  ngOnInit(): void {
    
    this.getScreensByUserAndTemplate();

    // Cambio en el objeto landing actualizamos modulos
    this.subscription = this.dataLandingService.landingSource.subscribe((respond)=>{
      console.log(respond);
    });

  }

  getScreensByUserAndTemplate(){
    /**
     * Recuperamos las pantallas que el usuario tiene asociadas (las que están asociadas al template y las que no)
     */

     let query = 
     {
     "template_code": "",
     "screen_user": this.userPostData.user_id
     }

   console.log(this.userPostData.user_id);

   this.screens.getScreenByUser(query).subscribe(data => {
    console.log(data);
     if (data.code == 200) {

       console.log(data);
       this.linkedScreens = data.data;

       this.linkedScreens.forEach(element => {
         
         /**
          * Si tenemos template a sociado a la pantalla recuperamos su información
          */

         if(element.template_code) {
           this.getTemplateInfo(element.template_code).then((data:any) => {
             console.log(data);
             if(data != undefined) {
               element.template_name = data.landing_name;
             }
           });
         }

       });
     } else {
       // Error en el proceso de validación usuario/landing
       this.user.logOut();
     }

   })
  }

  getTemplateInfo(templateCode) {

    /**
     * Recuperamos información relacionada con el template por el ID
     * 
     */

     return new Promise((resolve, reject) => {
      
      this.landingPostData.user_id       = this.userPostData.user_id;
      this.landingPostData.landing_code  = templateCode;
  
      this.authAPIService.postDataLanding(this.landingPostData).then( respond => {
        this.responseData = respond;
        
        console.log(this.responseData);

        if (this.responseData.code == 200) {
          resolve(this.responseData.landing[0]);
        } else {
          // Error en el proceso de validación usuario/landing
          //this.user.logOut();
        }

      },
      err => {
          console.log('Error');
      });
    });
  }

  /**
   * TEMPORAL AGREGAR PANTALLA
   */

   addNewScreenToTemplate(screen_code) {

    let data = {
      "screen_user": this.userPostData.user_id,
      "screen_code": screen_code,
      "template_code": '',
      "screen_status": 0
    }

    this.screens.addTemplateToScreen(data).subscribe((responseData)=>{
      
      console.log(responseData);

      if (responseData.code == 200) {
        console.log(responseData);
        
        if(responseData.error === 1) {
          // No existe el código introducido
          this.modalRespond = responseData.message;

        } else {
          this.getScreensByUserAndTemplate();
        
          this.modalRespond = responseData.message;
          // ... y se cierra la modal
          setTimeout(()=>{
            this.modalService.dismissAll();
          },2000);

        }
        

      } else {
        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }

    })
    
  }

  askCodeScreen(content) {
    this.modalService.open(content, { centered: true });
  }

}
