export class LandingCommonsOptions {
    data =
        {
            "activeMenu": false,
            "scrollTo": false,
            "hoverTo": false,
            "getShortDescription": null,
            "editorComponentName": null,
            "id":null,
            "idMenu":null
        }           
}