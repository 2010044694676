


<ng-container *ngIf="dataLanding.schema.media.properties.type.code == 'image'">
    <img [(src)]="dataLanding.schema.media.properties.src" 
    style="position: relative;
    
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    ">
</ng-container>

<ng-container *ngIf="dataLanding.schema.media.properties.type.code == 'video/mp4'">
    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        <source [(src)]="dataLanding.schema.media.properties.src"  type="video/mp4">
      </video>
</ng-container>