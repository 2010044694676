import { Injectable } from '@angular/core';
import { LandingTemplate } from '../../src/app/landing.template';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from './../environments/environment';
import { AuthAPIService } from './auth-api.service';
import { TemplateGroups } from 'src/app/templates/template.groups';

// Importamos los diferentes valores que podemos pasar a los componentes (textos, imagenes, etc)
import { LandingDefaultCommonsValues } from './../app/landing.default.commons.values';

// Cabeceras para API Pexel
const pexels_httpOptions ={  
  headers : new HttpHeaders({  
    'Authorization':environment.pexelsApiKey  
  })  
} 

// Cabeceras para API Pexel
const unsplash_httpOptions ={  
  headers : new HttpHeaders({  
    'Authorization': 'Client-ID '+ environment.unsplashAccessKey  
  })  
} 

@Injectable({
  providedIn: 'root'
})


export class LandingService {

  landingDefaultCommonsValues = new LandingDefaultCommonsValues().data;
  //Reviewnp
  landingObject = new LandingTemplate().data;
  _collaboratorNumber = 0;
  public collaboratorNumber = new BehaviorSubject(this._collaboratorNumber);
  
  public landingSource = new BehaviorSubject(this.landingObject);
  currentLanding = this.landingSource.asObservable();
  landingContentUpdate = new LandingTemplate().data;
  
  //Unsplash
  unplashKey = environment.unsplashAccessKey;
  unsplashPage = 1;
  unsplashPer_page = 50;

  //Botón guardar
  btnSave:any;
  btnPublish:any;
  isSaving:boolean = false; // chck para saber si estamos en el proceso de guardado y no poner llamadas en cola

  //Objeto para guardar landing
  landingPostData = {
    method: 'getLandingByID',
    user_id: '',
    landing_media: [],
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_save_and_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0,
    landing_autosave: 1,
    landing_extra_fake_user_id: '', // Parametro necesario para el proceso de reasignación del propietario de la landing
    landing_extra_real_user_id: '', // Parametro necesario para el proceso de reasignación del propietario de la landing
    subdomain_url: ''

  };

  constructor(
    private httpClient: HttpClient,
    public authAPIService: AuthAPIService
  ) { }

  // Recuperamos información de un grupo y sus modulos asociados en la creación
  getModuleName(moduleTypeParent, moduleType){

    let groupInfo = {
      'parentGroupTitle': '',
      'groupTitle': '',
      'groupColor': '',
      'groupIcon': '',
      'modules':[]
    }

    let group = TemplateGroups['groups'].find((element:any) => {
      return  element.id == moduleTypeParent
    });

    let module = group.groups.find((element:any) => {
      return  element.layout == moduleType
    });

    groupInfo.parentGroupTitle = group.moduleName;
    groupInfo.groupTitle = module.moduleName;
    groupInfo.groupColor = module.moduleColor;
    groupInfo.groupIcon = module.moduleIcon;
    groupInfo.modules = module.modules;

    return groupInfo;

  }

  getModuleIcon(){
  }

  getModuleIconColor(){
  }

  changeCollaboratorNumber(_number) {
    this.collaboratorNumber.next(_number);
  }

  changeLandingData(landingObject) {

    if(landingObject.landing_id != null) {

      landingObject.modules.forEach((element, index) => {
        let info = this.getModuleName(element.moduleTypeParent, element.moduleType);
        element.parentGroupTitle = info.parentGroupTitle;
        element.groupTitle = info.groupTitle;
        element.groupColor = info.groupColor;
        element.groupIcon = info.groupIcon;
      });

      this.landingContentUpdate = landingObject;
      this.landingSource.next(this.landingContentUpdate);

    } else {
      return
    }
    
    //this.onGetData.emit(message);
  }

  clearLandingData() {
      this.landingSource.next(this.landingObject);
  }

  getLandingData() {
    return this.landingSource;
  }

  getRandomNumber() {
  return (Math.floor(Math.random()*100));
  }

  getCustomValue(element:any, type:any){
    if(!this.landingDefaultCommonsValues[element]) {
      console.warn('No Text for '+element+' property');
      return '';
    } else {

      let _elements = this.landingDefaultCommonsValues[element];

      if(type == 'unsplash'){

        //let url = 'https://api.unsplash.com/photos?client_id=${this.unplashKey}'
        //let image = this.httpClient.get('https://source.unsplash.com/collection/3669740?sig='+this.getRandomNumber());
        // image.subscribe((resp: any) => {
        //   //console.log(resp.url);
        // });
        //return 'https://source.unsplash.com/collection/3669740?sig='+this.getRandomNumber();
        return this.httpClient.get<any>('https://source.unsplash.com/collection/3669740?sig='+this.getRandomNumber(),unsplash_httpOptions);
      }

      if(type == 'random') {
        return this.landingDefaultCommonsValues[element][Math.floor(Math.random()*(_elements.length))];
      } else {
          if(this.landingDefaultCommonsValues[element][type]){
            return this.landingDefaultCommonsValues[element][type];
          } else {
            // hemos pasado un indice superior al número de elementos que tneemos pasamos el último de la lista que tengamos
            return this.landingDefaultCommonsValues[element][_elements.length-1];
          }
      }
    }
  }

  getUnsplashImages(keyword:string, page:number) {
    // return this.httpClient.get(
    //   `https://api.unsplash.com/search/photos?page=${this.unsplashPage}&unsplashP${this.unsplashPer_page}&client_id=${this.unplashKey}&query=${keyword}`
    // );

    let url;
    
    // no pasamos término de búsqueda recupermos los populares en pexels
    if(keyword == '') {
      url = "https://api.unsplash.com/photos/random?page="+page+"&count=50&query";
    } else {
      console.log(keyword);
      url="https://api.unsplash.com/search/photos?page="+page+"&per_page=50&query="+keyword;
    }

    return this.httpClient.get<any>(url,unsplash_httpOptions);

  }

  getPexelImages(keyword:string, page:number) {
    
    let url;
    
    // no pasamos término de búsqueda recupermos los populares en pexels
    if(keyword == '') {
      url = "https://api.pexels.com/v1/curated?page="+page+"&per_page="+50;
    } else {
      url="https://api.pexels.com/v1/search?query="+keyword+"&page="+page+"&per_page="+50;
    }

    return this.httpClient.get<any>(url,pexels_httpOptions);

  }

  getPexelVideos(keyword:string, page:number) {

    let url;
    
    // no pasamos término de búsqueda recupermos los populares en pexels
    if(keyword == '') {
      url = "https://api.pexels.com/videos/popular?page="+page+"&per_page="+50;
    } else {
      url = "https://api.pexels.com/videos/search?query="+keyword+"&page="+page+"&per_page="+50;
    }
       
    return this.httpClient.get<any>(url,pexels_httpOptions);

  }

  setBtnSave(btn:any) {
    this.btnSave = btn; 
  }

  setBtnPublish(btn:any) {
    this.btnPublish = btn; 
  }

  saveLanding(userId, code, saveAndPublish) {
    console.log(code);
    if(this.isSaving) return; // hay una petición aun pendiente para guardar, cancelamos

    this.isSaving = true;
    this.btnSave.classList.remove('checkmark');
    this.btnSave.classList.remove('draw');
    this.btnSave.innerHTML = "Save";
    this.btnSave.classList.add('spinning');

    this.landingPostData.method = 'update';
    this.landingPostData.landing_code = code;
    this.landingPostData.landing_id = this.landingContentUpdate.landing_id;
    this.landingPostData.user_id = userId;
    this.landingPostData.landing_content = this.landingContentUpdate;
    this.landingPostData.landing_autosave = 1;
    this.landingPostData.landing_save_and_publish = saveAndPublish; 

    console.log(this.landingContentUpdate);

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      //Controlamos no volver a poder guardar hasta despues de 1400ms
      console.log(result);
      setTimeout(()=>{
        this.isSaving = false;
      },1400);
    },
    err => {
        console.log('Error');
    });

    setTimeout(()=>{
      this.btnSave.classList.remove('spinning');
      this.btnSave.innerHTML = "Save";
      this.btnSave.classList.add('checkmark');
      this.btnSave.classList.add('draw');
    },400);

    setTimeout(()=>{
      this.btnSave.classList.remove('checkmark');
    },1000);
  }

  publishLanding(userId, code, saveAndPublish) {
    
    if(this.isSaving) return; // hay una petición aun pendiente para guardar, cancelamos

    this.isSaving = true;
    this.btnPublish.classList.remove('checkmark');
    this.btnPublish.classList.remove('draw');
    this.btnPublish.innerHTML = "Updating screens";
    this.btnPublish.classList.add('spinning');

    this.landingPostData.method = 'update';
    this.landingPostData.landing_code = code;
    this.landingPostData.landing_id = this.landingContentUpdate.landing_id;
    this.landingPostData.user_id = userId;
    this.landingPostData.landing_content = this.landingContentUpdate;
    this.landingPostData.landing_autosave = 1;
    this.landingPostData.landing_save_and_publish = saveAndPublish; 

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      //Controlamos no volver a poder guardar hasta despues de 1400ms
      setTimeout(()=>{
        this.isSaving = false;
      },1400);
    },
    err => {
        console.log('Error');
    });

    setTimeout(()=>{
      this.btnPublish.classList.remove('spinning');
      this.btnPublish.innerHTML = "Publish";
      this.btnPublish.classList.add('checkmark');
      this.btnPublish.classList.add('draw');
    },1400);

    setTimeout(()=>{
      this.btnPublish.classList.remove('checkmark');
      this.btnPublish.innerHTML = "Publish <svg class='icon ic-20'><use href='assets/icons/icons-sprite.svg#svg-publish'/></svg>";
    },2000);
  }

  // Servicio para publicar una landing ///////////////

  /**
   * Upload files
   */

   uploadFile(userId, code, file) {

    
    this.landingPostData.method = 'uploadFile';
    this.landingPostData.landing_code = code;
    this.landingPostData.user_id = userId;
    this.landingPostData.landing_media = file;

    return new Promise((resolve, reject) => {

      this.authAPIService.uploadFile(this.landingPostData).then( res => {
        resolve(res);
      },
      err => {
        reject(err);
      });

    })
    
  }

  //  postFile(id_alteracion: string, filesToUpload: FileUploadModel[], catalogacion: any): Observable<any> {
  //   let url = urlAPIAlteraciones + '/';
  //   url += id_alteracion + '/documentos';

  //   const formData: FormData = new FormData();

  //   formData.append('json', JSON.stringify(catalogacion));

  //   for (let file of filesToUpload) {
  //     formData.append('documento', file.data, file.data.name);
  //   }

  //   console.log(formData);

  //   let headers = new HttpHeaders();

  //   return this._http.post(url, formData, { headers: headers });
  // }

}
