<div class="c-form-editor-item">
    <div class="c-form-editor-form">
        <div class="p-float-label mb-5">
            <p-dropdown [options]="availableTypes" [(ngModel)]="data.properties.type" optionLabel="name" name="newType" [showClear]="false" (onChange)="setCustomEndPoint($event)" placeholder="Forward to"></p-dropdown>  
            <!-- <label for="float-input">Forward to</label> -->
        </div>
        <div *ngIf="showCusmEndPoint && customEndPointType == 'email'">
            <div class="p-float-label">
                <input type="text" [(ngModel)]="data.properties.data[customEndPointType]" pInputText>
                <label for="float-input">Email</label>
            </div>
            <small>E.g. "your-email@your-domain.com"</small>
        </div>

        <div *ngIf="showCusmEndPoint && customEndPointType == 'form_handler'">
            <div class="p-float-label">
                <input type="text" [(ngModel)]="data.properties.data['form_handler']" pInputText>
                <label for="float-input">Add here your Endpoint URL</label>
            </div>
            <small>E.g. "http://your-domain.com/l/126891/2024-11-24/2xwphb"</small>
        </div>
        
        <div *ngIf="showCusmEndPoint && customEndPointType == 'slack'">
            <div class="p-float-label">
                <input type="text" [(ngModel)]="data.properties.data['slack']" pInputText>
                <label for="float-input">Add here your Slack Incoming Webhook</label>
            </div>
        </div>

        <div *ngIf="showCusmEndPoint && customEndPointType == 'asana'">
            <div class="p-float-label">
                <input type="text" [(ngModel)]="data.properties.data['asana']" pInputText>
                <label for="float-input">Add here your Asana App ID</label>
            </div>
        </div>

        <div *ngIf="showCusmEndPoint && customEndPointType == 'getform'">
            <div class="p-float-label">
                <input type="text" [(ngModel)]="data.properties.data['getform']" pInputText>
                <label for="float-input">Add here your Getform URL</label>
            </div>
            <small>E.g. "https://getform.io/f/your-form-endpoint-goes-here"</small>
        </div>

        <div *ngIf="showCusmEndPoint && customEndPointType == 'form_action'">
            <div class="p-float-label">
                <input type="text" [(ngModel)]="data.properties.data['form_action']" pInputText>
                <label for="float-input">Add here your POST URL</label>
            </div>
            <small>E.g. "https://your-domain.com/sample-url-data-script/"</small>
        </div>

    </div>
</div>
<!-- <div class="col">
    <div class="p-float-label">
        <input type="text" [(ngModel)]="newLabel" pInputText>
        <label for="float-input">Add new field</label>
    </div>
</div>
<div class="col">
    <div class="p-float-label">
        
    </div>
</div> -->
