<div class="c-module-item no-cursor">
    <div class="c-form-config" [class]="{'active':landingObject.config.landingIntegrations[id]}">
        <div class="c-form-config-header" (click)="toggle($event)">
            <span class="c-form-config-header-icon"><svg class="icon ic-success"><use href="assets/icons/icons-sprite.svg#svg-check"/></svg></span>
            <div class="c-form-config-header-text" style="overflow: hidden;">
                <span class="c-form-config-header-label">{{label}}</span>
                <span class="c-form-config-header-value text-truncate pr-20">{{landingObject.config.landingIntegrations[id]}}</span>
            </div>
            <button class="c-form-config-header-btn btn btn-gray btn-sm" type="button">{{!selected ? hasValue ? 'Edit' : 'Add' : 'Cancel'}}</button>
        </div>
        <div class="c-form-config-toggleable" [@tabContent]="selected ? {value: 'visible', params: {transitionParams: transitionOptions}} : {value: 'hidden', params: {transitionParams: transitionOptions}}">
            <div class="c-form-config-content">
                <div class="form-group">
                    <input *ngIf="typeForm === 'text'" class="c-form-config-content-input form-control" type="text" pInputText [(ngModel)]="landingObject.config.landingIntegrations[id]">
                    <textarea *ngIf="typeForm === 'textarea'" class="c-form-config-content-input form-control" rows="1" pInputTextarea autoResize="autoResize" [(ngModel)]="landingObject.config.landingIntegrations[id]"></textarea>
                    <small *ngIf="help" class="c-form-config-content-help">{{help}}</small>
                </div>
                <div class="c-form-config-content-actions">
                    <!-- <button class="btn btn-gray" type="button" (click)="toggle($event)">Cancel</button> -->
                    <button class="btn btn-primary" type="button" (click)="save()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>