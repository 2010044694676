<div class="l-panel-editor">

  <!-- Panel Editor: Header -->
  <div class="l-panel-editor__header">
    <div class="l-panel-editor__header__title">Font Family</div>
    <div class="l-panel-editor__header__actions">
      <app-save-button></app-save-button>
      <app-publish-button></app-publish-button>
    </div>
  </div>
  <!-- / Panel Editor: Header -->

  <!-- Panel Editor: Body -->
  <div class="l-panel-editor__body">

    <div class="scroll-wrap">

      <div class="l-panel-editor__body__content editor-cnt-mw">

        <p-tabView>
          <p-tabPanel header="Presets">
            <!-- Module Item -->
            <!-- Current Selection -->
            <ng-container *ngIf="dataLoaded">
              <div class="c-module-box cursor-pointer" [ngClass]="{'active':landingContentUpdate.config.landingFont.active}">
                <div class="d-flex align-items-center justify-content-between mb-10"  >
                  <span class="btn btn-sm btn-secondary btn-label my-5">Active</span>
                </div>
                <div class="w-100">
                  <link rel="stylesheet"  [attr.href]="getFontUrl(landingContentUpdate.config.landingFont.font[0].type.code) | safeurl">
                  <span class="text-small">Header - {{landingContentUpdate.config.landingFont.font[0].type.name}}</span>
                  <div class="c-module-font" [ngClass]="{'active':landingContentUpdate.config.landingFont.active}" [ngStyle]="{fontFamily:landingContentUpdate.config.landingFont.font[0].type.name}" style="text-transform: none;">{{landingContentUpdate.config.landingFont.title}}</div>
                  <span class="text-small">Paragraph - {{landingContentUpdate.config.landingFont.font[1].type.name}}</span>
                  <div [ngClass]="{'active':landingContentUpdate.config.landingFont.active}">
                    <link rel="stylesheet"  [attr.href]="getFontUrl(landingContentUpdate.config.landingFont.font[1].type.name) | safeurl">
                    <p class="c-module-paragraph" [ngStyle]="{fontFamily:landingContentUpdate.config.landingFont.font[1].type.name}" style="font-weight: normal;">{{landingContentUpdate.config.landingFont.paragraph}}</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- End Current Selection -->
              <!-- Font Family 01 -->
              <ng-container *ngFor="let item of defaultFontFamily.predefined; let i = index">
                <div class="c-module-box cursor-pointer" [ngClass]="{'active':item.active}" *ngIf="i != 0" (click)="setFontFamily(i,item)">
                  <div class="d-flex align-items-center justify-content-between mb-10"  >
                    <span class="btn btn-sm btn-primary btn-label my-5" *ngIf="item.tag">{{item.tag}}</span>
                    <span *ngIf="!item.tag"></span>
                    <button class="btn btn-icon btn-icon__right" [ngClass]="{'checked btn-secondary':item.active, 'btn-outline-primary':!item.active}">
                      <ng-container *ngIf="item.active; else elseBlock">Active <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-check-fill"/></svg></ng-container>
                      <ng-template #elseBlock>Use <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></ng-template>
                    </button>

                  </div>
                  <div class="w-100">
                    <link rel="stylesheet"  [attr.href]="getFontUrl(item.font[0].type.code) | safeurl">
                    <span class="text-small">Header - {{item.font[0].type.name}}</span>
                    <div class="c-module-font" [ngClass]="{'active':item.active}" [ngStyle]="{fontFamily:item.font[0].type.name}" style="text-transform: none;">{{item.title}}</div>
                    <span class="text-small">Paragraph - {{item.font[1].type.name}}</span>
                    <div [ngClass]="{'active':item.active}">
                      <link rel="stylesheet"  [attr.href]="getFontUrl(item.font[1].type.name) | safeurl">
                      <p class="c-module-paragraph" [ngStyle]="{fontFamily:item.font[1].type.name}" style="font-weight: normal;">{{item.paragraph}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- / Font Family 03 -->
            <!-- / Module Item -->
          </p-tabPanel>
          <p-tabPanel header="Custom">
            <!-- Module Box Font -->
              <!-- Module Item -->
              <ng-container *ngFor="let item of defaultFontFamily.predefined; let i = index">
                <div class="c-module-box cursor-pointer" [ngClass]="{'active':item.active}" *ngIf="i == 0" (click)="setFontFamily(0, item)">
                  <!-- Font Family 01 -->
                  <div class="d-flex align-items-center justify-content-between mb-10" *ngIf="i == 0">
                    <span class="btn btn-sm btn-primary btn-label my-5">Preview</span>
                    <button class="btn btn-icon btn-icon__right" [ngClass]="{'checked btn-secondary':item.active, 'btn-outline-primary':!item.active}">
                      <ng-container *ngIf="item.active; else elseBlock">Active <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-check-fill"/></svg></ng-container>
                      <ng-template #elseBlock>Use <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></ng-template>
                    </button>
                  </div>
                  <div class="w-100" *ngIf="i == 0">
                    <link rel="stylesheet"  [attr.href]="getFontUrl(item.font[0].type.code) | safeurl">
                    <span class="text-small">Header - {{item.font[0].type.name}}</span>
                    <div class="c-module-font" [ngClass]="{'active':item.active}" [ngStyle]="{fontFamily:item.font[0].type.name}" style="text-transform: none;">{{item.title}}</div>
                    <span  class="text-small">Paragraph - {{item.font[1].type.name}}</span>
                    <div [ngClass]="{'active':item.active}">
                      <link rel="stylesheet"  [attr.href]="getFontUrl(item.font[1].type.name) | safeurl">
                      <p class="c-module-paragraph" [ngStyle]="{fontFamily:item.font[1].type.name}" style="font-weight: normal;">{{item.paragraph}}</p>
                    </div>
                  </div>
                  <!-- / Font Family 03 -->
                </div>
              </ng-container>
              <!-- / Module Item -->
              <div class="bg-gray-light c-module-box px-15">
                <div class="p-float-label mb-5">
                  <p-dropdown [options]="availableHeadsTypes" [(ngModel)]="defaultFontFamily.predefined[0].font[0].type" optionLabel="name" name="newType" [showClear]="false" (onChange)="setCustomHeadFont($event)" placeholder="Heads Font"></p-dropdown>  
                  <label for="dropdown">Headers</label>
                </div> 
                <div class="p-float-label mb-10">
                  <p-dropdown [options]="availableParagraphTypes" [(ngModel)]="defaultFontFamily.predefined[0].font[1].type" optionLabel="name" name="newType" [showClear]="false" (onChange)="setCustomHeadFont($event)" placeholder="Paragraphs Font"></p-dropdown>  
                  <label for="dropdown">Paragraphs</label>
                </div>
                <div class="d-flex">
                  <button class="btn btn-gray ml-auto">Set custom Font Family</button>
                </div>
              </div>
          </p-tabPanel>
        </p-tabView>
               
        <!-- / Module Box Font -->
        <!-- Module Box Request -->
        <div class="c-module-box no-bg mt-40">
          <div class="c-module-box-title">Request Featured <span>- What do you wish XXXX could do or add?</span></div>
          <div class="form-group">
            <textarea class="form-control" id="commentRequestFeatured" rows="3" placeholder="Please include detail or some examples"></textarea>
          </div>
          <button type="submit" class="btn btn-gray">Send Request</button>
        </div>
        <!-- / Module Box Request -->

      </div>

    </div>

  </div>
  <!-- / Panel Editor: Body -->

</div>