<div 
cdkDropList
[cdkDropListData]="data.items"
(cdkDropListDropped)="drop($event)">
    <div class="c-form-editor-item" *ngFor="let item of data.items; let i = index" cdkDrag>  
        <div class="c-form-editor-item-left">
            <p-toggleButton styleClass="c-form-editor-btn-visible p-togglebutton-text" ngDefaultControl [(ngModel)]="item.visible" onIcon="pi pi-eye" offIcon="pi pi-eye-slash"></p-toggleButton>
        </div>
        <div class="c-form-editor-form c-form-editor-repetible list-modules-item py-5 pl-10" [ngClass]="{'c-form-editor-hide':!item.visible}">
            <img *ngIf="item.src" [src]="item.src" style="padding: 5px 15px; height: 30px;">
            <p-fileUpload *ngIf="!item.src" showUploadButton="false" mode="basic" name="demo[]" accept="image/*" maxFileSize="1000000" (uploadHandler)="onUpload($event)" chooseLabel="Browse" customUpload="true"></p-fileUpload>
            <div class="c-form-editor-repetible-drag-element" cdkDragHandle></div>
        </div>
        <div class="c-form-editor-item-right" [ngClass]="{'c-form-editor-hide':!item.visible}">
            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeField(i)"></button>      
        </div>
    </div>
</div>


<div class="c-form-editor-item c-form-editor-item__bg mt-10">
    <div class="c-form-editor-item-left"></div>
    <div class="c-form-editor-form">
    </div>
    <div class="pl-5">
        <button pButton pRipple type="button" label="Add new image" icon="pi pi-plus" class="p-button-sm p-button-text mb-10 mr-40" (click)="addField(data)"></button>
    </div>
</div>
