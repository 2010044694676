import { Component, OnInit, Input } from '@angular/core';
import { LandingService } from 'src/services/landing.service';

@Component({
  selector: 'app-button-add-media',
  templateUrl: './button-add-media.component.html',
  styleUrls: ['./button-add-media.component.scss']
})
export class ButtonAddMediaComponent implements OnInit {

  // DAtos desde el componente padre para poder asociar la imagen seleccionada al objeto del template
  @Input() data:any;
  @Input() available:any;

  mediaData: any;
  displayModal: boolean = false;
  currentTabMedia: any = 4;
  selectedMenu: string;
  allVendors: any[];
  vendors:any = [];

  // items: SelectItem[];
  // item: string;

  constructor(
    private landingService: LandingService
  ) { 

    this.allVendors = [
      {
        id: 'upload',
        name: 'Upload', 
        icon: 'upload', 
        tab: '1',
        src: null
      },
      {
        id: 'my_gallery', 
        name: 'My gallery', 
        icon: 'folder', 
        tab: '2',
        src: null
      },
      {
        id: 'unsplash', 
        name: 'Unsplash', 
        icon: null,
        tab: '3',
        src: './assets/img/logo-unsplash.png'
      },
      {
        id: 'pexels_images', 
        name: 'Pexels Images', 
        icon: null,
        tab: '4',
        src: './assets/img/logo-pexels.png'
      }
      ,
      {
        id: 'pexels_videos', 
        name: 'Pexels Videos', 
        icon: null,
        tab: '5',
        src: './assets/img/logo-pexels.png'
      }
    ];

  }

  ngOnInit(): void {
    
    if(this.available == 'all') {
      // Mostramos todas las opciones multimedia
      this.vendors = this.allVendors;

    } else {
      // Mostramos solo las que se han indicado en el modulo multimedia
      let availableVendors = this.available.split(',');
      availableVendors.forEach(element => {
        this.vendors.push(this.allVendors.find(item => item.id == element));
      });

    }

  }

  closeModalDialog() {
    console.log('PARENT COMPONENT CALL');
    this.displayModal = false;
  }

  showModalDialog() {
    this.displayModal = true;
  }

}
