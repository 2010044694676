import { TemplateGroupsBasic } from "./templates-groups/template.groups.basic";

export const TemplateGroups = {
        'groups': [
            {
                'id':'group-default',
                'name':'Templates',
                'moduleName':'Templates',
                'moduleDescription':'This is the menu of your landing page and it helps your users to find remarkable content in a simple and intuitive way.',
                'moduleImage':  "../../../../assets/img/components/min-center-media.svg",
                'groups': TemplateGroupsBasic
            }
        ]
};