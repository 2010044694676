import { Component, OnInit, ElementRef, ViewChild, Input, Output, ComponentFactoryResolver, ViewContainerRef, EventEmitter, AfterViewInit } from '@angular/core';
import { TemplateLayouts } from 'src/app/templates/layouts/template.layouts';
import { LandingService } from '../../../services/landing.service';
import { IframeService } from '../../../services/iframe.service';
import { ColorSchemaTemplate } from '../../colorSchema.template';
import { FontFamilyTemplate } from '../../fontFamilies.template';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-thumb-landing',
  templateUrl: './thumb-landing.component.html',
  styleUrls: ['./thumb-landing.component.scss']
})
export class ThumbLandingComponent implements OnInit {

  @Input() modules: any;
  @Output() thumbLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  data$: Subject<string> = new Subject();

  landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  compRef: any;

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  defaultColorType = "ligth";

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;

  constructor(
    private resolver: ComponentFactoryResolver,
    private vcRef: ViewContainerRef,
    private dataLandingService: LandingService,
    private iframeService: IframeService
  ) { }
  
  ngOnInit() {

    this.landingView = this.iframe.nativeElement.contentDocument || 
    this.iframe.nativeElement.contentWindow; // Asociamos iframe en el HTML a landingView que usaremos para cargar los diferentes modulos

    let contentLanding = JSON.parse(this.modules);

    // Pasamos el objeto Iframe al servicio para luego poder utilizarlo en las diferentes funciones
    this.iframeService.setLandingView(this.landingView);
    
    // Incluimos en el iframe que hemos creado vacio los assets necesarios, CSS, SCRIPTS, etc..
    this.iframeService.setCssScriptsToIframe(contentLanding, this.landingView);

    // Configuramos el schema de color
    this.iframeService.setColorSchemaToIframe(contentLanding.config.landingColor, this.landingView);

    // Configuramos el schema de color
    this.iframeService.setFontSchemaToIframe(contentLanding.config.landingFont, this.landingView);
    
    contentLanding.modules.forEach((element,index) => {
      if(index >=2) return; // hacemos esto para no cargar toda la landing completa en los previews, solo se visualiza una parte de la página
      this.addContentToIframe(element,index);
    });

    this.thumbLoaded.emit(true);

  }

  addContentToIframe(_element:any, _index) {

    


    let _component = TemplateLayouts['layouts'][_element.moduleType];
    
    if(!_component) return;

    const compFactory = this.resolver.resolveComponentFactory(_component);
    this.compRef = this.vcRef.createComponent(compFactory);

    this.compRef.instance.structure = _element;
    this.compRef.instance.dataModule = _element;
    this.landingView.body.appendChild(this.compRef.location.nativeElement);

  }

}
