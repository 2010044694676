<!-- Module Integrations -->
<div class="module-section">
  <div class="detail-module">
      <div class="detail-module-hd bg-white">
        <p><strong>Integrations</strong> - Landing Page title</p>
        <span routerLink="../overview"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg></span>
      </div>
    <div class="detail-module-cnt bg-light">
        <div class="scroll-wrap">
          <!-- Module Box Scripts -->
          <div class="c-module-box" *ngFor="let item of editorOptionsConfig">
            <div class="c-module-box-title">{{item.title}}</div>
            
            <ng-container *ngFor="let option of item.options">
              <!-- Module Item -->
              <app-form-config
                [(landingObject)]="landingContentUpdate" 
                [label]="option.label" 
                [id]="option.id"
                [typeForm]="option.typeForm"
                [help]="option.help">
              </app-form-config>
              <!-- / Module Item -->
            </ng-container>

          </div>
          <!-- / Module Box Scripts -->

          <!-- Module Box Request -->
          <div class="c-module-box no-bg">
            <div class="c-module-box-title">Request Featured <span>- What do you wish XXXX could do or add?</span></div>
            <div class="form-group">
              <textarea class="form-control" id="commentRequestFeatured" rows="3" placeholder="Please include detail or some examples"></textarea>
            </div>
            <button type="submit" class="btn btn-gray">Send Request</button>
          </div>
          <!-- / Module Box Request -->
          
        </div>
    </div>
  </div>
</div>
<!-- / Module Integrations -->