<main class="d-flex flex-column h-100">
    <!-- Alert -->
    <app-alert></app-alert>
    <!-- / Alert -->
    <!-- Header -->
    <app-header></app-header>
    <!-- / Header -->

    <section class="sec-editor flex-grow-1">
        <!-- Editor Menu -->
        <!-- <div class="editor-menu justify-content-between h-100"></div> -->
        <!-- / Editor Menu -->
        <!-- Editor Content -->
        <div class="row no-gutters flex-grow-1">

            <!-- Create Start -->
            <div class="col-12 h-100 bg-light" *ngIf="currentStep == 'start'">
                <div class="db-panel-wrap h-100">
                    <div class="scroll-wrap scroll-hide">
                        <div class="c-create-start row">
                            <div class="c-create-start-col col-lg">
                                <div class="text-center text-sm-left text-lg-center d-sm-flex d-lg-block align-items-center">
                                    <img class="img-fluid" src="/assets/img/creation-buyer.jpg" alt="">
                                    <div>
                                        <h2 class="mb-15 mb-xxxl-25">Incididunt ut labore et dolore magna aliqua</h2>
                                        <p class="mb-30 mb-xxxl-40">Cconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                        <button class="btn btn-lg btn-fixed-width btn-primary" (click)="goToStep('question0')">Buyer Persona</button>
                                    </div>
                                </div>
                            </div>
                            <div class="c-create-start-col col-lg">
                                <div class="text-center text-sm-left text-lg-center d-sm-flex d-lg-block align-items-center">
                                    <img class="img-fluid" src="/assets/img/creation-buyer.jpg" alt="">
                                    <div>
                                        <h2 class="mb-15 mb-xxxl-25">Lorem ipsum dolor sit amet, consectetur</h2>
                                        <p class="mb-30 mb-xxxl-40">Cconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                        <button class="btn btn-lg btn-fixed-width btn-primary" (click)="showCatalogLanding()">Start from catalog</button>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / Create Start -->

            <!-- Buyer Persona -->
            <ng-container *ngFor="let item of createQuestions; let i = index">
                <div class="col-12 h-100 bg-light" *ngIf="currentStep == 'question' + i">
                    <div class="db-panel-wrap h-100 py-0">
                        <div class="scroll-wrap scroll-hide">
                            <div class="c-create-panel d-flex flex-column h-100">
                                <div class="c-create-panel-header pt-20 pt-md-40">
                                    <div class="c-step-counter mx-auto">
                                        <span class="c-step-counter-current">{{i + 1}}</span>
                                        <span class="c-step-counter-total">{{createQuestions.length+1}}</span>
                                    </div>
                                </div>
                                <div class="c-create-panel-content flex-grow-1">
                                    <div class="c-create-form d-flex flex-column h-100 justify-content-xxxl-center">
                                        <div class="c-create-form-question w-100">
                                            <p>{{item.question}}</p>
                                        </div>
                                        <div class="c-create-form-answers w-100">
                                            <div class="form-row row-cols-sm-2" [@questionAnimation]="item.options.length">
                                                <div class="form-group col-sm" *ngFor="let option of item.options; let a = index">
                                                    <choiceRadioButton
                                                        (click)="setAnswer(option,item.name,i,a)"
                                                        name="{{item.name}}"
                                                        value="{{option.value}}"
                                                        label="{{option.label}}"
                                                        key="{{option.key}}"
                                                        active="{{option.active}}">
                                                    </choiceRadioButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="c-create-panel-footer row">
                                    <div class="c-progress">
                                        <div class="c-progress-bar" [ngStyle]="{'transform': 'translateX('+ (i*100)/(createQuestions.length+1) +'%)'}"><span>{{(i*100)/createQuestions.length+1}}%</span></div>
                                    </div>
                                    <div class="col-6 col-sm-auto">
                                        <button class="btn btn-lg btn-fixed-width btn-outline-primary" *ngIf="i != 0" (click)="goToStep('question' + (i - 1))">Anterior</button>
                                    </div>
                                    <div class="col-6 col-sm-auto ml-auto">
                                        <button class="btn btn-lg btn-fixed-width btn-primary" (click)="goToNextStep('question' + (i + 1),i,item.name)">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- Buyer Persona -->

            <!-- Catalog -->

            <ng-container *ngIf="currentStep == 'catalog'">

                <div class="db-panel-wrap">

                    <div class="db-panel-section-thumbs">

                        <div class="section-thumbs row">

                            <ng-container *ngFor="let item of catalogLandings; let i = index">
                                <div class="col-sm-6 col-lg-4">
                                    <div class="landing-thumb">
                                        <div class="landing-thumb-top">
                                            <div class="thumb-wrapper">
                                                <app-thumb-landing [modules]="item.landing_content" (thumbLoaded)='checkThumbLoaded($event, i)'></app-thumb-landing>
                                            </div>
                                        </div>
                                        <div class="landing-thumb-bottom">
                                            <div class="landing-thumb-info">
                                                <a class="font-weight-bold" style="font-size: 14px; margin-bottom: 3px">{{item.landing_name}}</a>
                                                <div style="font-size: 12px; opacity: .5">{{item.create_date | date:'dd MMM yyyy'}}</div>
                                            </div>
                                            <a (click)="createLandingFromCatalog(i);">Use</a> | <a href="https://{{item.landing_subdomain_code}}.boostup.io" target="_blank">Preview</a>
                                        </div>

                                    </div>
                                </div>
                            </ng-container>

                        </div>

                    </div>
                </div>    

            </ng-container>

            <!-- Catalog -->

        </div>
        <!-- / Editor Content -->
    </section>

</main>
