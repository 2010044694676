import { Component, OnInit } from '@angular/core';

export interface billingItem {
  id?:string;
  name?: string;
  adress?: any;
}
export interface paymentItem {
  id?:string;
  method?: string;
  img?: any;
  card?: any;
}
interface method {
  name: string;
  img: any;
}

export interface invoicesItem {
  plan?:string;
  month?: string;
  currency?: string;
  currencySymbol?: any;
  value?: any;
  date?: any;
  status?: any;
}

@Component({
  selector: 'app-current-plan',
  templateUrl: './current-plan.component.html',
  styleUrls: ['./current-plan.component.scss']
})
export class CurrentPlanComponent implements OnInit {

  subscription: boolean = true;
  selectedCurrency: string;
  currency: any[];

  submitted: boolean;

  // Billing information
  billingDialog: boolean;
  billingItem: billingItem;
  selectedBillingValues: string;

  billingItems: billingItem[]= [
    {
      id: '1',
      name: 'Name Surname',
      adress: 'Egestas natoque morbi ornare'
    }
  ];

  clonedBillingItem: { [s: string]: billingItem; } = {};


  //Payment method
  paymentMethod: method[];
  paymentDialog: boolean;
  paymentItem: paymentItem;
  selectedPaymentValues: string;

  paymentItems: paymentItem[]= [
    {
      id: '1',
      method: 'Visa Debit Card',
      img: 'visa',
      card: '123 456'
    }
  ];

  clonedPaymentItem: { [s: string]: paymentItem; } = {};

  //Invoices
  invoicesItem: invoicesItem;

  invoicesItems: invoicesItem[]= [
    {
      plan: 'Basic Plan',
      month: 'Feb 2022',
      currency: 'EUR',
      currencySymbol: '€',
      value: '10,00',
      date: 'Feb 01, 2022',
      status: 'Pending'
    },
    {
      plan: 'Basic Plan',
      month: 'Ene 2022',
      currency: 'EUR',
      currencySymbol: '€',
      value: '10,00',
      date: 'Ene 01, 2022',
      status: 'Paid'
    },
    {
      plan: 'Basic Plan',
      month: 'Dic 2021',
      currency: 'EUR',
      currencySymbol: '€',
      value: '10,00',
      date: 'Dic 01, 2021',
      status: 'Paid'
    }
  ];


  constructor() { 
    this.currency = [
      {name: 'EUR'},
      {name: 'USD'}
    ];

    this.paymentMethod = [
      {name: 'Visa Debit Card', img: 'visa'},
      {name: 'Mastercard Credit Card', img: 'mastercard'}
    ];
  }

  ngOnInit(): void {
  }

  // Billing information
  onRowEditInitBilling(billingItems: billingItem) {
    this.clonedBillingItem[billingItems.id] = {...billingItems};
  }

  onRowEditSaveBilling(billingItems: billingItem) {}

  onRowEditCancelBilling(element: billingItem, index: number) {
    var result = this.billingItems.filter(billing => {
      return billing.id != element.id;
    })
    
    this.billingItems = result;
  }

  openNewBilling() {
    this.billingItem = {};
    this.submitted = false;
    this.billingDialog = true;
  }

  hideDialogBilling() {
    this.billingDialog = false;
    this.submitted = false;
  }

  saveBillingItem(billingName:any, billingAdress:any) {
    this.submitted = true;
    this.billingItem.id = this.createId();
    this.billingItem.name = billingName;
    this.billingItem.adress = billingAdress;
    this.billingItems.push(this.billingItem);
    this.billingDialog = false;
}

// Payment method
onRowEditInitPayment(paymentItems: paymentItem) {
  this.clonedPaymentItem[paymentItems.id] = {...paymentItems};
}

onRowEditSavePayment(paymentItems: paymentItem) {}

onRowEditCancelPayment(element: paymentItem, index: number) {
  var result = this.paymentItems.filter(payment => {
    return payment.id != element.id;
  })
  
  this.paymentItems = result;
}

openNewPayment() {
  this.paymentItem = {};
  this.submitted = false;
  this.paymentDialog = true;
}

hideDialogPayment() {
  this.paymentDialog = false;
  this.submitted = false;
}

savePaymentItem(selectedPaymentMethod:any, paymentCard:any) {
  this.submitted = true;
  this.paymentItem.id = this.createId();
  this.paymentItem.method = selectedPaymentMethod.name;
  this.paymentItem.img = selectedPaymentMethod.img;
  this.paymentItem.card = paymentCard;
  this.paymentItems.push(this.paymentItem);
  this.paymentDialog = false;
}

createId(): string {
  let id = '';
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for ( var i = 0; i < 5; i++ ) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
}

}
