import { Component, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AuthAPIService } from '../../../services/auth-api.service';
import { UserService } from '../../../services/user.service';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styles: []
})
export class SignupComponent implements OnInit {

  public responseData: any;
  public showErrorName: boolean = false;
  public showErrorEmail: boolean = false; 
  public showErrorPass: boolean = false; 
  public messageError: any;
  
  public userPostData = {
    method: '',
    email: '',
    email_conf: '0',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: ''
  };

  constructor(
    private socialAuthService: SocialAuthService,
    public authAPIService: AuthAPIService,
    public user: UserService) {
    this.user.sessionIn();
  }

  ngOnInit() {}

  // Registro RRSS

  public socialSignUp(socialPlatform: string) {
    
    let socialPlatformProvider;
    
    if (socialPlatform === 'facebook') {
    
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    
    } else if (socialPlatform === 'google') {
  
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
  
    } 

    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      this.apiConnection(userData);
    });
  }

  // Registro Local

  public localSignUp(name:any,email:any,password:any) {

    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name.length == 0) {

      this.showErrorName  = true;
      this.showErrorEmail = false;
      this.showErrorPass = false;
      this.messageError   = 'You must fill in this field';
    
    } else if (email.length == 0) {

      this.showErrorName = false;
      this.showErrorEmail = true;
      this.showErrorPass = false;
      this.messageError = 'You must fill in this field';

    } else if (!validEmailRegEx.test(email)) {

      this.showErrorName = false;
      this.showErrorEmail = true;
      this.showErrorPass = false;
      this.messageError = 'It looks like an invalid email address';


    } else if (password.length == 0) {

      this.showErrorName = false;
      this.showErrorEmail = false;
      this.showErrorPass = true;
      this.messageError = 'You must fill in this field';

    } else if (password.length < 6) {

      this.showErrorName = false;
      this.showErrorEmail = false;
      this.showErrorPass = true;
      this.messageError = 'Please: use a six or more characters password';

    } else {

        this.userPostData.email         = email;
        this.userPostData.email_conf    = '0';
        this.userPostData.name          = name;
        this.userPostData.provider      = 'okb';
        this.userPostData.password      = (Md5.hashStr(password) as string);
        this.userPostData.provider_id   = 'N/A';
        this.userPostData.provider_pic  = 'N/A';
        this.userPostData.token         = 'N/A';

        this.apiConnection(this.userPostData);
        
    }

  }

  // Conexión a API 

  apiConnection(data:any) {

    this.userPostData.method        = 'signup';
    this.userPostData.email         = data.email;
    this.userPostData.name          = data.name;
    this.userPostData.provider      = data.provider;
    if (data.provider == 'okb') {
      this.userPostData.password    = data.password;
    } else {
      this.userPostData.password    = 'N/A';
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_id   = data.provider_id;
    } else {
      this.userPostData.provider_id   = data.id;
    }
    if (data.provider == 'okb') {
      this.userPostData.provider_pic  = data.provider_pic;
    } else {
      if (data.image === undefined){
        this.userPostData.provider_pic  = 'N/A';
      } else {
        this.userPostData.provider_pic  = data.photoUrl;
      }
    }
    if (data.provider == 'okb') {
      this.userPostData.token         = data.token;
    } else if (data.provider == 'FACEBOOK') {
      this.userPostData.token         = data.authToken;
    } else {
      this.userPostData.token         = data.idToken;
    }

    this.authAPIService.postData(this.userPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code != 200) {

        // Error en el registro o email ya registrado
        
        this.showErrorName         = false;
        this.showErrorEmail        = true;
        this.showErrorPass         = false;
        this.messageError          = this.responseData.message;

      } else {

        // Registro completado correctamente 

        this.showErrorName         = false;
        this.showErrorEmail        = false;
        this.showErrorPass         = false;
        
        //Almacenamos los valores devueltos en la variable de sesión
        
        if (this.responseData.userData) {
          this.user.storeData(this.responseData.userData);
        }

        //Redirect a dashboard

        this.user.sessionIn();

      }

      },
      err => {
          console.log('Error');
      }
    );
  }
}
