import { Component, OnInit, AfterViewInit, DoCheck, ViewChild, ElementRef, HostListener, Input, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

// Importamos las opciones comunues que tiene la landing, en general objetos para acciones, scrollto, et..
import { LandingCommonsOptions } from '../../../landing.commons.options';
import { ModuleTemplate } from '../../../module.template';
//import { TemplateModules } from '../../../template.modules';

// Importamos los diferentes campos comunes para poder reutilizar los que sean necesarios
import { EditorCommonsOptions } from '../../../editor.commons.options';

// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';

@Component({
  selector: 'app-layout-three-column',
  templateUrl: './layout-three-column.component.html',
  styleUrls: ['./layout-three-column.component.scss']
})
export class LayoutThreeColumnComponent implements OnInit {

  @Input() dataModules: any = null;

  // Una vez que el componente ha sido usado le pasamos los datos finales para evitar que vuelva a usar estos datos
  @Input() newData: any;

  // Creamos una instancia de las opciones comunes
  landingCommonsOptions = new LandingCommonsOptions();

  // Creamos una instancia de los diferentes campos que tendrá el editor
  //editorCommonsOptions = new EditorCommonsOptions();
  //public structure:any = new ModuleTemplate().data;
  
  public dataLanding: any;
  public adminSchema: any = {};
  public customStyleObject = {};
  public schemaColor: any = {
    id: 0,
    type: 'custom',
    active: false,
    title: 'Default Color Schema',
    color:'light',
    schema:
      [
          {color: '#ebebeb', variableName:'primary-color'},
          {color: '#e6e6e6', variableName:'secondary-color'},
          {color: '#c8c8c8', variableName:'link-color'},
          {color: '#5e4848', variableName:'text-color'},
          {color: '#000000', variableName:'heading-color'},
          {color: '#dadada', variableName:'background-color'}
      ]
  };

  public structure: any = {
    "componentName": null,
    "landingType": "type-a",
    "moduleTypeParent": "layouts",
    "moduleType": "layout-two-column",
    "moduleName": null,
    "moduleIcon": null,
    "moduleImage":  null,
    "moduleColor":  null,
    "data": [{}],
    "schema": this.adminSchema,
    "modules": [],
    "commonsOptions": {}
  };

  constructor(
    public element: ElementRef,
    private dataLandingService: LandingService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef) {}

  @HostListener('mouseenter') onMouseEnter(){
    this.dataLanding.commonsOptions.hoverTo = true;
    this.dataLanding.commonsOptions.scrollTo = false;
  }

  @HostListener('mouseleave') onMouseLeave(){
    this.dataLanding.commonsOptions.hoverTo = false;
  }

  @HostListener('click') onClick(){
    this.dataLanding.commonsOptions.activeMenu = true;
  }

  // recuperamos el componente asociado al nombre que pasamos desde el modulo asociado al layout
  private getComponentForCardType(moduleType:string) {
    //let _component = TemplateModules['type-a']['lmodules'][moduleType];
    //return _component;
  }

  ngOnInit() {

    //Datos de los modulos que recibimos desde la configuración para incluir
    console.log(this.dataModules);
    this.structure.schema = this.adminSchema;
    this.structure.modules = this.dataModules;

    console.log(this.structure);

    // Pasamos a la vista la estructura necesaria para montar el componente y el formulario de edición
    this.dataLanding = this.structure;

    console.log(this.dataLanding);
    if(this.newData) {

      this.dataLanding = this.newData;

    }

    //** CONFIGURACIÓN NECESARIA EN TODOS LOS MODULOS */
    this.dataLanding.moduleName = this.dataModules.moduleName;
    this.dataLanding.moduleColor = this.dataModules.moduleColor;
    this.dataLanding.moduleIcon = this.dataModules.moduleIcon;
    //** CONFIGURACIÓN NECESARIA EN TODOS LOS MODULOS */

    if(!this.dataLanding.commonsOptions){
      this.dataLanding.commonsOptions = this.landingCommonsOptions;
    }

    // Definimos que campos serán los que tenga el editor de este componente

    if(!this.dataLanding.schema.cards) {

      let items = 4; // Lo usamos para incluir el número de items deseados

      this.dataLanding.schema.cards = new EditorCommonsOptions().schema.card;

      // loop creamos una tarjeta en cada loop y la incluimos en la propiedad items
      for (let index = 0; index < items; index++) {

        let icon = new EditorCommonsOptions().schema.media;
        icon.properties.label = "Icon";

        let title = new EditorCommonsOptions().schema.text;
        title.properties.data = this.dataLandingService.getCustomValue('title','random');
        title.properties.label = "Title";

        let subtitle = new EditorCommonsOptions().schema.text;
        subtitle.properties.data = this.dataLandingService.getCustomValue('subtitle',"random");
        subtitle.properties.label = "Subtitle";

        let description = new EditorCommonsOptions().schema.text;
        description.properties.data = this.dataLandingService.getCustomValue('largeText',"random");
        description.properties.label = "Description";

        let btn = new EditorCommonsOptions().schema.buttom;
        btn.properties.data = this.dataLandingService.getCustomValue('buttomText',1);
        btn.properties.label = "Text link CTA";
        btn.properties.type.code ='link';
        btn.properties.type.name ='External Link (link to URL)';

        let item = {
          "visible": true,
          "icon":icon,
          "title": title,
          "subtitle": subtitle,
          "description": description,
          "btn": btn
        };

        //Incluimos los datos en Items
        this.dataLanding.schema.cards.items.push(item);

      }

    }

    // // Recuperarmos el texto del primer campo disponible para pasarlos en los listados
    this.dataLanding.commonsOptions.getShortDescription = this.dataLanding.schema.cards.items[0]['title'].properties.data;

    // // Indicamos el nombre del componente que actuará como editor para este módulo, será el que muestre los diferentes campos etc..
    this.dataLanding.commonsOptions.editorComponentName = "EditorCardsPhotoAComponentClass";
    if(!this.dataLanding.schema.schemaColor) {
      this.dataLanding.schema.schemaColor = this.schemaColor;
    }

    if(this.dataLanding.schema.schemaColor.schema != undefined) {
      if(this.dataLanding.schema.schemaColor.active) {
        this.dataLanding.schema.schemaColor.schema.forEach(data => {
          this.customStyleObject[`--${data.variableName}`] = data.color;
        });
      }
    }

  }

  ngAfterViewInit(): void {

      //const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponentForCardType(this.dataModules.modules[0]));
      //let viewContainerRef = this.container;
      //this.viewContainerRef.clear();
      //const componentRef = viewContainerRef.createComponent(componentFactory);
      //(<TemplateBaseComponent>componentRef.instance).newData = this.data;
    
    
  }

  ngDoCheck() {
    // if(this.dataLanding.commonsOptions.activeMenu) {
    //   this.element.nativeElement.scrollIntoView({behavior:"smooth"});
    // }
  }

}
