<!-- Module Components -->
<div class="module-section module-section-components h-100">
  <div class="detail-module">
      <div class="detail-module-hd bg-white">
        <p><strong>Your Screens</strong> - Template Name Here</p>
        <span routerLink="../screens"><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg></span>

      </div>
    <div class="detail-module-cnt bg-white position-relative">
      <div class="row no-gutters h-100">
        <div class="h-100 col" >
          <div class="scroll-wrap">

            <!-- Screen Item -->
            <div class="c-list-row">

              <div class="c-list-row__item">
                <div class="c-card-row" (click)="askCodeScreen(changeLandingNameModal)">
                    <div class="c-card-row__col">
        
                        <div class="c-add-btn">
                            <div class="c-add-btn__btn btn btn-primary btn-icon btn-icon-only rounded-circle">
                                <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#plus"/></svg>
                            </div>
                            <div class="c-add-btn__label">Add a screen</div>
                        </div>
                        
                    </div>
                </div>
              </div>

              <div class="c-list-row__item" *ngFor="let item of linkedScreens; let i = index">

                <div class="c-card-row">
                    <div class="c-card-row__col c-card-row__col--media">
                        <div class="embed-responsive embed-responsive-1by1">
                            <div class="embed-responsive-item bg-primary"></div>
                        </div>
                    </div>
                    <div class="c-card-row__col">
                        <app-data-label size="sm">
                            <strong data-label-text>Meeting room screen</strong>
                            <span data-label-caption>26 May, 2022</span>
                        </app-data-label>
                    </div>
                    <div class="c-card-row__col c-card-row__col--data">
                        <app-data-label size="sm">
                            <span data-label-caption>Code used</span>
                            <span data-label-text>{{item.screen_code}}</span>
                        </app-data-label>
                    </div>
                    <div class="c-card-row__col">
                      <button *ngIf="!item.template_code" type="button" class="btn btn-gray btn-sm btn-add" (click)="useAvailableScreen(item.screen_code, i)">Link Screen</button>
                      <button *ngIf="item.template_code" type="button" class="btn btn-danger btn-sm btn-add" (click)="leaveScreen(item.screen_code, i)">Leave</button>
                    </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- / Module Components -->


   <!-- Modal para cambio de nombre landing -->
   <ng-template #changeLandingNameModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Add Screen Code</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <p>Puedes configurar una nueva pantalla en <a href="https://screen.inapp.io/" target="_blank">screen.inapp.io</a></p>
        <p-inputMask  [(ngModel)]="screen_code" mask="999999"></p-inputMask>
        <small *ngIf="modalRespond">{{modalRespond}}</small>
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='addNewScreenToTemplate(screen_code)'>Save</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal para cambio de nombre landing -->