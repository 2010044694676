import { Component, OnInit } from '@angular/core';
import { LandingService } from '../../../services/landing.service';

import { EditorOptionsConfigTemplate } from '../../../app/editor.optionsConfig.template';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  editorOptionsConfig;
  landingContentUpdate;
  subscription: Subscription;

  constructor(
    private dataLandingService: LandingService
  ) { }

  ngOnInit() {
    console.log(this.editorOptionsConfig);

    // Optenemos la información de la landing, vamos a guardar los schemas de color y tipo en el objeto de landing compartido en la aplicación para poder almacenar los cambios
    this.subscription = this.dataLandingService.landingSource.subscribe((respond)=>{
      
      if(respond.landing_id == null) return;

      // pasamos al objeto landingContentUpdate toda la información de la landing
      this.landingContentUpdate = respond;

      if(this.landingContentUpdate.config.landingIntegrations === undefined) {
        this.landingContentUpdate.config['landingIntegrations'] = {};
      }

      // Precargarmos todas las opciones que damos al usuario para poder customizar su landing:
      // Google Analytics, Facebook, etc
      this.editorOptionsConfig = new EditorOptionsConfigTemplate().data.modules;
      console.log(this.editorOptionsConfig);
      
    })

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



}
