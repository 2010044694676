import { Component, OnInit, AfterViewInit, DoCheck, ViewChild, ElementRef, HostListener, Input, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

// Importamos las opciones comunues que tiene la landing, en general objetos para acciones, scrollto, et..
import { LandingCommonsOptions } from '../../../landing.commons.options';
import { ModuleTemplate } from '../../../module.template';
import { TemplateModules } from 'src/app/templates/template.modules';
import { ColorSchemaTemplate } from 'src/app/colorSchema.template';
import { LayoutBaseComponent } from './../layout-base';


// Importamos los diferentes campos comunes para poder reutilizar los que sean necesarios
import { EditorCommonsOptions } from '../../../editor.commons.options';

// Importamos Servicio
import { LandingService } from '../../../../services/landing.service';

@Component({
  selector: 'app-layout-two-column',
  templateUrl: './layout-two-column.component.html',
  styleUrls: ['./layout-two-column.component.scss']
})
export class LayoutTwoColumnComponent implements OnInit {

  @ViewChild('container', {read: ViewContainerRef}) container!: ViewContainerRef;

  @Input() dataGroup: any;
  @Input() dataModule: any;

  // Una vez que el componente ha sido usado le pasamos los datos finales para evitar que vuelva a usar estos datos
  @Input() newData: any;

  // Creamos una instancia de las opciones comunes
  landingCommonsOptions = new LandingCommonsOptions();
  compRef:any;

  // Creamos una instancia de los diferentes campos que tendrá el editor
  //editorCommonsOptions = new EditorCommonsOptions();
  //public structure:any = new ModuleTemplate().data;
  

  public previewComponent: boolean; // Pasamos true desde el listado de componentes, cuando hacemos click en asociar
  public layoutModules: any;
  public dataLanding: any;
  public adminSchema: any = {};
  public customStyleObject = {};
  public modulesData = {};
  public schemaColor: any = {
    id: 0,
    type: 'custom',
    active: false,
    title: 'Default Color Schema',
    color:'light',
    schema:
      [
          {color: '#ebebeb', variableName:'primary-color'},
          {color: '#e6e6e6', variableName:'secondary-color'},
          {color: '#c8c8c8', variableName:'link-color'},
          {color: '#5e4848', variableName:'text-color'},
          {color: '#000000', variableName:'heading-color'},
          {color: '#dadada', variableName:'background-color'}
      ]
  };

  public structure: any = {
    "componentName": null,
    "landingType": "type-a",
    "moduleTypeParent": "layouts",
    "moduleType": "layout-one-column",
    "moduleImage":  null,
    "data": [],
    "schema": this.adminSchema,
    "modules": [],
    "modulesData": undefined,
    "commonsOptions": {}
  };


  constructor(
    public element: ElementRef,
    private dataLandingService: LandingService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef) {}

  @HostListener('mouseenter') onMouseEnter(){
    this.dataLanding.commonsOptions.hoverTo = true;
    this.dataLanding.commonsOptions.scrollTo = false;
  }

  @HostListener('mouseleave') onMouseLeave(){
    this.dataLanding.commonsOptions.hoverTo = false;
  }

  @HostListener('click') onClick(){
    this.dataLanding.commonsOptions.activeMenu = true;
  }

  ngOnInit() {

    // console.warn('ONINIT - dataModule: Información de los modulos que componen el layout');
    // console.log(this.dataModule);
    
    // console.warn('ONINIT - newData: Datos guardados para este componente, undefined componente incluido desde listado');
    // console.log(this.newData);

    //Datos de los modulos que recibimos desde la configuración para incluir
    //this.structure.schema = this.adminSchema;

    // Recuperamos los modulos que tiene asociado este layout desde su configuración, evitamos si hacemos cambios mantener
    // los modulos guardados en BBDD

    if(this.newData) {

      let dataModules = this.dataLandingService.getModuleName(this.dataModule.moduleTypeParent, this.dataModule.moduleType);
      // vamos a reescribir los modulos asociados al grupo, podemos saltar este paso en los casos
      // que hagamos que el usuario pueda selecionar que componente va en cada "columna" del layout

      // console.warn('Modulos del layout, actualizamos desde la definición');
      // console.log(dataModules);

      this.newData.modules = dataModules.modules;
      this.structure.modules = dataModules.modules;
      this.layoutModules = dataModules.modules;

    } else {

      // Si tenemos true en previewComponent la estructura de los modulos no viene encapsulado en 'modules'
      if(this.previewComponent) {
        this.structure.modules = this.dataModule;
        this.layoutModules = this.dataModule;
      } else {
        this.structure.modules = this.dataModule.modules;
        this.layoutModules = this.dataModule.modules;
      }

    } 
    
    // Pasamos a la vista la estructura necesaria para montar el componente y el formulario de edición
    this.dataLanding = this.structure;

    if(this.newData) {
      
      this.dataLanding = this.newData;

    } else {
      
      // Almacenamos los datos de los diferentes modulos asignados al layout
      this.layoutModules.forEach(element => {
        let asociatedModule = {
          'moduleId': element,
          'moduleData': {}
        }
        this.dataLanding.data.push(asociatedModule);
      });

    }

    //this.dataLanding.commonsOptions.editorComponentName = TemplateModules['modules'][this.dataModule.module[0]]['editorClass'];

    if(!this.dataLanding.schema.schemaColor) {
      this.dataLanding.schema.schemaColor = this.schemaColor;
    }

    if(this.dataLanding.schema.schemaColor.schema != undefined) {
      if(this.dataLanding.schema.schemaColor.active) {
        this.dataLanding.schema.schemaColor.schema.forEach(data => {
          this.customStyleObject[`--${data.variableName}`] = data.color;
        });
      }
    }

  }

  private renderLayoutModules() {

    this.layoutModules.forEach((element,index) => {
      
      let _moduleComponent = TemplateModules['modules'][element];
      
      // Check si el modulo existe, podemos haberlo discontinuado o eliminado
      if(_moduleComponent) {
        
        let moduleComponent = _moduleComponent['componentClass'];
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory( moduleComponent );
        
        let viewContainerRef = this.container;
        this.viewContainerRef.clear();
        this.compRef = viewContainerRef.createComponent(componentFactory);
        this.compRef.instance.newData = this.dataLanding.data[index];

      }

    });

  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => this.renderLayoutModules());
  }

  ngDoCheck() {
    // if(this.dataLanding.commonsOptions.activeMenu) {
    //   this.element.nativeElement.scrollIntoView({behavior:"smooth"});
    // }
  }
}
