<!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          <!-- //////////                                  START: Dashboard Highlights                                                 //////////// -->
          <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          
          <div class="db-panel-section-thumbs">
            <!-- Header Filters -->
           
            <div class="section-header">
             <h2>Templates</h2>
             <div class="row">
               <div class="col-auto">
                 <button class="btn btn-icon btn-icon__right btn-primary" (click)='startLanding()'>
                   <span class="d-none d-md-block">Create a new template</span> 
                   <span class="d-md-none">Create new</span>
                   <svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>
                  </button>
               </div>
               <div class="col-auto ml-auto section-header__filters">
                  <div class="d-flex align-items-center">
                    <!-- <div class="search-box">
                      <input type="submit" value=""><input type="search" placeholder="Search" [(ngModel)]="inputSearch">
                    </div> -->
                    <p-dropdown [options]="filters" [(ngModel)]="selectedFilter" optionLabel="name"></p-dropdown>
                    <nav class="tab-nav">
                      <div [ngClass]="{'on': allActiveMenu }" (click)="getLandings()">All</div>
                      <div [ngClass]="{'on': favActiveMenu }" (click)="getLandingsFav()">Favorites</div>
                      <!-- <div [ngClass]="{'on': sharedActiveMenu }" (click)="getLandingsShared()">Shared</div>
                      <div [ngClass]="{'on': collaboratingActiveMenu }" (click)="getLandingsCollaborating()">Collaborating</div> -->
                    </nav>
                    <div class="tab-nav-alt">
                      <select>
                        <option value="all" (click)="getLandings()">All</option>
                        <option value="favorites" (click)="getLandingsFav()">Favorites</option>
                        <!-- <option value="shared" (click)="getLandingsShared()">Shared</option> -->
                        <!-- <option value="collaborating" (click)="getLandingsCollaborating()">Collaborating</option> -->
                      </select>
                    </div>
                  </div>
               </div>
             </div>
           </div>

           <!-- End Header Filters -->
           <div class="section-thumbs" *ngIf="landingsLoaded">
            <!-- No tenemos templates, mostramos creatividad y botón -->
            <div class="mt-20 mt-md-100 mt-lg-60 mt-xxxl-100" *ngIf="!allLandings.length">
              <div class="c-message-no-content">
                <div class="c-message-no-content__media">
                  <img class="img-fluid" src="assets/img/no-templates-yet.svg" alt="No template yet">
                </div>
                <div class="c-message-no-content__body">
                  <h5 class="c-message-no-content__title">No templates yet</h5>
                  <div class="c-message-no-content__description">
                    <p>Start creating your first template!</p>
                  </div>
                  <a class="c-message-no-content__link" href="#">More information</a>
                </div>
                <div class="c-message-no-content__action">
                  <button type="button" class="btn btn-primary btn-icon btn-icon__right" (click)='startLanding()'>Create a new template<svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg></button>
                </div>
              </div>
            </div>
            <!-- ENd creatividad y botón -->
            
            <div class="row" *ngIf="allLandings.length">
              <!-- Add a new landing page -->
              <!-- <div class="col-sm-6 col-xl-4">
                <div class="add-thumb" (click)='startLanding()'>
                  <div class="add-thumb-top"> -->
                      <!-- <div class="c-add-btn" (click)="askCodeScreen(changeLandingNameModal)"> -->
                      <!-- <div class="c-add-btn">
                        <div class="c-add-btn__btn btn btn-primary btn-icon btn-icon-only rounded-circle">
                            <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#plus"/></svg>
                        </div>
                        <div class="c-add-btn__label">New template</div>
                      </div>
                  </div>
                </div>
              </div> -->

              <!-- List of most recently landings-->
              <!-- This DIV is shown only while landing-thumbs are loading. Fade-out when landing-thumbs are loaded  -->
              <ng-container *ngFor="let item of loadingThumbs">
                <div class="col-sm-6 col-xl-4" *ngIf="!landingsLoaded">
                  <div class="loading-thumb on placeholder-loading" style="position: relative;">
                    <div class="top">
                    </div>
                    <div class="bottom">
                      <div><span></span><span></span></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- This DIV is shown only while landing-thumbs are loading. Fade-out when landing-thumbs are loaded  -->

              <ng-container *ngFor="let item of allLandings; let i = index">
                <div class="col-sm-6 col-xl-4" *ngIf="landingsLoaded">
                  <div class="landing-thumb">
                    <div class="landing-thumb-top">
                      <div app-status type="success" value="3" size="sm">Streaming</div>
                      <div class="thumb-wrapper" (click)="goToLanding(item.landing_code);">
                        <app-thumb-landing [modules]="item.landing_content"  (thumbLoaded)='checkThumbLoaded($event, i)'></app-thumb-landing>
                      </div>
                    </div>
                    <div class="landing-thumb-bottom">
                      <div class="landing-thumb-info">
                        <a class="font-weight-bold" style="font-size: 14px; margin-bottom: 3px" routerLink="../editor/{{item.landing_code}}">{{item.landing_name}}</a>
                        <div style="font-size: 12px; opacity: .5">{{item.create_date | date:'dd MMM yyyy'}} <span style="font-style: italic;" *ngIf="item.landing_wsp_name !== 'N/A'">({{item.landing_wsp_name}})</span></div>
                      </div>
                      
                      <!-- Diferentes iconos, estados y acciones en función de si la landing es propia o no y si está compartida o no  -->
                      <!-- ----------------------------------------------------------------------------------------------------------  -->

                      <!-- PROPIAS -->

                      <!-- Landing aún no compartida -->
                      <div class="shorthand-share-off" *ngIf="landingPostData.user_id === item.landing_user_id && item.landing_collaborators_number === '0'" routerLink="/editor/{{item.landing_code}}/collaborate">
                          <span><svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-share-with"/></svg><i>0</i></span>
                      </div>
                      
                      <!-- Landing compartida con al menos 1 usuario -->
                      <div class="shorthand-share-on" *ngIf="landingPostData.user_id === item.landing_user_id && item.landing_collaborators_number !== '0'" routerLink="/editor/{{item.landing_code}}/collaborate">
                        <span><svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-share-with"/></svg><i>{{item.landing_collaborators_number}}</i></span>
                        </div>

                      <!-- DE TERCEROS -->
                    
                      <div class="shorthand-collaborating" *ngIf="landingPostData.user_id !== item.landing_user_id">
                          <span><svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-team"/></svg><i>5</i></span>
                        </div>

                      <!--                                                     Favoritas                                               -->
                      <!-- ----------------------------------------------------------------------------------------------------------  -->
                    
                      <div [ngClass]="(item.landing_favourites == 1)?'shorthand-fav-on':'shorthand-fav-off'" (click)="changeFavouriteState(item.landing_code,item.landing_favourites,i)">
                        <svg class="icon ic-18"><use href="assets/icons/icons-sprite.svg#svg-star"/></svg>
                        </div>

                    </div>
                    <div class="landing-thumb-menu" ngbDropdown placement="bottom-right">
                      <button class="landing-thumb-menu-btn" ngbDropdownToggle><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-dots-menu"/></svg></span></button>
                      <ul class="action-menu list-unstyled" ngbDropdownMenu>
                        <li ngbDropdownItem><button routerLink="/preview/{{item.landing_code}}"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-view"/></svg></span>Preview</button></li>
                        <li ngbDropdownItem><button routerLink="/editor/{{item.landing_code}}"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-edit"/></svg></span>Edit</button></li>
                        
                        <!--                                     Espacios de trabajo - Asignados o no                                    -->
                        <!-- ----------------------------------------------------------------------------------------------------------  -->
                        
                        <li ngbDropdownItem *ngIf="landingPostData.user_id === item.landing_user_id">
                          <!-- Asignada a un espacio de trabajo -->  
                          <button *ngIf="item.landing_wsp_name !== 'N/A'" (click)="showModalAsociateWorkSpaceToLanding(asociateWorkspacetoLandingModal,item.landing_code)"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-workspace"/></svg></span>Change WorkSpace</button>
                          <!-- No asignada a un espacio de trabajo -->
                          <button *ngIf="item.landing_wsp_name === 'N/A'" (click)="showModalAsociateWorkSpaceToLanding(asociateWorkspacetoLandingModal,item.landing_code)"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-workspace"/></svg></span>Assign Workspace</button>
                        </li>
                        
                        
                        <li ngbDropdownItem *ngIf="landingPostData.user_id === item.landing_user_id"><button (click)="showModalDuplicateLanding(duplicateLandingModal,item.landing_code,item.landing_name)"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-duplicate"/></svg></span>Duplicate</button></li>
                        <li ngbDropdownItem><button routerLink="/export/{{item.landing_code}}"><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-download"/></svg></span>Download</button></li>
                        
                        <!--                                     Eliminación o dejar de seguir una landing                                    -->
                        <!-- ----------------------------------------------------------------------------------------------------------  -->

                        <!-- Landing propia. Permitir eliminarla  -->
                        <li ngbDropdownItem><button class="warning" *ngIf="landingPostData.user_id === item.landing_user_id" (click)='showModalRemoveLanding(removeLandingModal,item.landing_code,item.landing_name)'><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-trash"/></svg></span>Delete</button></li>
                        <!-- Landing de tercero. Permitir desvincularse -->
                        <li ngbDropdownItem><button class="warning" *ngIf="landingPostData.user_id !== item.landing_user_id" (click)='showModalUnfollowLanding(unfollowLandingModal,item.landing_code,item.landing_name)'><span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-leave"/></svg></span>Stop Collaborating</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Only show when user doesn't have any landing right now -->
              <ng-container *ngFor="let item of ghostThumb;">
                <div class="col-sm-6 col-xl-4" *ngIf="landingsLoaded">
                  <div class="ghost-thumb">
                    &nbsp;
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
         </div>
         <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
         <!-- //////////                                          END: Dashboard Highlights                                             ////////// -->
         <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

  <!-- Modal para cambio de nombre landing -->
   <ng-template #changeLandingNameModal let-modal>
    <div class="modal-card">
      <div class="modal-header">
        <h4 class="modal-title">Change Landing Name</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
        </button>
      </div>
      <div class="modal-body">
        <input [(ngModel)]="landingContentUpdate.landing_name" type="text">
      </div>
      <div class="modal-footer">
        <button class="alt-button" (click)="modal.close('Close click')">Cancel</button>
        <button class="focus-button" (click)='updateLandingName(landingContentUpdate.landing_name)'>Save</button>
      </div>
    </div>
  </ng-template>
  <!-- Fin Modal para cambio de nombre landing -->