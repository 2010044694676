<header class="header">
  <!-- <div class="editor-menu" *ngIf='nonGuest'>
    <a class="btn btn-link" routerLink="/dashboard" pTooltip="Back to dashboard" appendTo="body"><span class="ic-24 ic-svg ic-inline"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-home"></use></svg></span></a>
  </div> -->
    <div class="c-burguer" [ngClass]="{'open': showMenuMobile }" (click)="showMenuMobile = !showMenuMobile">
      <div class="c-burguer-menu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="header-brand">
      <!-- <img src="assets/img/logo.svg" alt=""> -->
      <strong>inApp.io</strong>
    </div>
    <div class="header-right">
      <nav class="header-menu" [ngClass]="{'show': showMenuMobile }">
        <a href="#" class="header-menu-item">About</a>
        <a href="#" class="header-menu-item">Precios</a>
        <a href="#" class="header-menu-item">Contacto</a>
      </nav>
      <div class="header-profile">
        <div class="c-profile">
          <div ngbDropdown placement="bottom-right">
            <ng-container *ngIf='nonGuest'>
              <a class="c-profile-btn" id="dropdownProfile" ngbDropdownToggle>
                <div class="c-profile-avatar">
                  <img src="{{userPostData.provider_pic}}" alt="" *ngIf="userPostData.provider_pic != 'N/A'">
                  <img src="../../assets/img/avatars/avatar.01.svg" alt="" *ngIf="userPostData.provider_pic === 'N/A'">
                </div>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownProfile">
                <div class="c-profile-user">
                  <div class="c-profile-avatar">
                    <img src="{{userPostData.provider_pic}}" alt="" *ngIf="userPostData.provider_pic != 'N/A'">
                    <img src="../../assets/img/avatars/avatar.01.svg" alt="" *ngIf="userPostData.provider_pic === 'N/A'">
                  </div>
                  <div class="c-profile-name">
                    {{userPostData.name}}
                    <span>Settings</span>
                  </div>
                </div>
                <div  class="c-profile-storage">
                  <div class="c-profile-storage__cab">
                    <h6 class="c-profile-storage__title">Storage Usage</h6>
                    <div class="c-profile-storage__percent">70%</div>
                  </div>
                  <div class="c-bar"><span style="width: 70%"></span></div>
                  <small>30GB used of 32GB</small>
                  <div class="mt-15 d-flex justify-content-end">
                    <button class="btn btn-icon btn-icon__right btn-primary">Upgrade <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-upgrade"/></svg></button>
                  </div>
                </div>
                <div class="c-profile-list">
                  <button ngbDropdownItem routerLinkActive="active" routerLink="/user/555"><svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#profile"/></svg> User profile</button>
                  <button ngbDropdownItem routerLinkActive="active" routerLink="/user/555/preferences"><svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#setting"/></svg> Preferencese</button>
                  <button ngbDropdownItem routerLinkActive="active" routerLink="/user/555/current-plan"><svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#wallet"/></svg> Current plan & billing</button>
                  <button ngbDropdownItem routerLinkActive="active" routerLink="/user/555/linked-tools"><svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#links"/></svg> Linked tools</button>
                  <button ngbDropdownItem ><svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#danger_circle"/></svg> Help</button>
                  </div>
                <div class="c-profile-footer">
                  <button class="btn btn-icon btn-icon__left text-danger p-0" (click)='logout()'><svg class="icon icon-stroke ic-danger ic-20"><use href="assets/icons/icons-sprite.svg#logout"/></svg> Log out</button>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!nonGuest">
              <a class="btn btn-link" (click)="showModalSignUp(signUpModal)">SignUp</a> or <a class="btn btn-link" (click)="showModalLogin(loginModal)">Login </a> to add pages, upload files & Publish
            </ng-container>
          </div>
        </div>
      </div>
    </div>
</header>

<!-- Modal para logarse como usuario registrado -->

<ng-template #loginModal let-modal>
  <div class="modal-card">
    <div class="modal-header">
      <h4 class="modal-title">Accede a tu cuenta</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="log-box-social">
        <button type="button" (click)="socialSignIn('google')" class="log-box-social-btn">
          <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-google"/></svg></span>
          <span>Con Google</span>
        </button>
        <button type="button" (click)="socialSignIn('facebook')" class="log-box-social-btn">
          <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-facebook"/></svg></span>
          <span>Con Facebook</span>
        </button>
      </div>
      <!-- Custom SignUp -->
      <div class="log-box-custom">
        <h2 class="log-box-custom-title">O con tu usuario y contraseña</h2>
        <form class="log-box-custom-form">
          <div class="log-box-custom-form-fieldset input">
            <input type="email" [(ngModel)]="userPostData.email" [ngModelOptions]="{standalone: true}" name="email-field" placeholder="Correo electrónico">
            <span class="field-error" *ngIf="showErrorEmail"> {{messageError}}</span>
          </div>
          <div class="log-box-custom-form-fieldset input">
            <input type="password" [(ngModel)]="userPostData.password" [ngModelOptions]="{standalone: true}" name="password-field" id="password-field" placeholder="Contraseña">
            <span class="field-error" *ngIf="showErrorPass">{{messageError}}</span>
          </div>
          <div class="log-box-custom-form-fieldset submit">
            <input type="submit" (click)="localSignIn(userPostData.email,userPostData.password)" value="Acceder">
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!-- Fin Modal para logarse como usuario registrado -->

<!-- Modal para logarse sin registro previo - Registro más Login -->

<ng-template #signUpModal let-modal>
  <div class="modal-card">
    <div class="modal-header">
      <h4 class="modal-title">Regístrate gratis</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg aria-hidden="true" class="icon"><use href="assets/icons/icons-sprite.svg#svg-close"/></svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="log-box-social">
        <button type="button" (click)="socialSignUp('google')" class="log-box-social-btn">
          <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-google"/></svg></span>
          <span>Con Google</span>
        </button>
        <button type="button" (click)="socialSignUp('facebook')" class="log-box-social-btn">
          <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-facebook"/></svg></span>
          <span>Con Facebook</span>
        </button>
      </div>
      <!-- Custom SignUp -->
      <div class="log-box-custom">
        <h2 class="log-box-custom-title">O rellena estos campos</h2>
        <form class="log-box-custom-form">
          <div class="log-box-custom-form-fieldset input">
            <input type="text" [(ngModel)]="userPostData.name" [ngModelOptions]="{standalone: true}" name="username-field" id="username-field" placeholder="Usuario">
            <span class="field-error" *ngIf="showErrorName">{{messageError}}</span>
          </div>
          <div class="log-box-custom-form-fieldset input">
            <input type="email" [(ngModel)]="userPostData.email" [ngModelOptions]="{standalone: true}" name="email-field" id="email-field" placeholder="Correo electrónico">
            <span class="field-error" *ngIf="showErrorEmail">{{messageError}}</span>
          </div>
          <div class="log-box-custom-form-fieldset input">
            <input type="password" [(ngModel)]="userPostData.password" [ngModelOptions]="{standalone: true}" name="password-field" id="password-field" placeholder="Contraseña">
            <span class="field-error" *ngIf="showErrorPass">{{messageError}}</span>
          </div>
          <div class="log-box-custom-form-fieldset check">
            <p>Al crear esta cuenta confirmas que has leído y aceptas nuestras <a href="https://site.landing.okbestudiointeractivo.com/">condiciones&nbsp;de&nbsp;uso</a> así como nuestra <a href="https://site.landing.okbestudiointeractivo.com/">política&nbsp;de&nbsp;privacidad</a>.</p>
          </div>
          <div class="log-box-custom-form-fieldset submit">
            <input type="submit" (click)="localSignUp(userPostData.name,userPostData.email,userPostData.password)" value="Crear mi cuenta">
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!-- Fin Modal para logarse como usuario registrado -->
