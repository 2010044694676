import { Component, OnInit } from '@angular/core';
import { ScreensService } from 'src/services/screens.service';
import { UserService } from 'src/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthAPIService } from 'src/services/auth-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-screens-template',
  templateUrl: './screens-template.component.html',
  styleUrls: ['./screens-template.component.scss']
})
export class ScreensTemplateComponent implements OnInit {

  dataLoaded:boolean = false;
  screen_code:any; //Código para asociar pantalla
  landingPostData = {
    method: 'getLandingByID',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_content: {},
    landing_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0
  };

  linkedScreens:any = [];
  linkedScreensAvailables:any;
  userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  template_code:any;
  responseData:any;
  modalRespond:any;
  subscription:any;
  showModalIntroCode:boolean = false;

  constructor( 
    private screens: ScreensService,
    private user: UserService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private authAPIService: AuthAPIService,
    ) { 
      this.userPostData = this.user.getData();
      this.route.parent?.params.subscribe((result:any) => this.template_code = result.id);
    }

  ngOnInit(): void {

    /**
     * Recuperamos las pantallas que el usuario tiene asociadas a este template
     */

    let query = 
      {
      "template_code": this.template_code,
      "screen_user": this.userPostData.user_id
      }

    this.screens.getScreenByTemplate(query).subscribe(data => {
      this.dataLoaded = true;
      this.linkedScreens = data.data;
      // actualizamos el valor de las pantallas asocidas al resto de componentes
      this.screens.updateScreensLinked(data.data);
    })

  }

  getScreenByUser() {

    let query = 
     {
     "template_code": this.template_code,
     "screen_user": this.userPostData.user_id
     }

    this.screens.getScreenByUser(query).subscribe(data => {

      if (data.code == 200) {
 
        console.log(data);
        this.linkedScreensAvailables = [];
        // De las pantallas del usuario excluimos las pantallas que ya están asociadas al template o están siendo usadas por el usuario en otro template
       data.data.forEach((element) => {
          if(!element.template_code) {
            this.linkedScreensAvailables.push(element);
          }
       });
 
       if(!this.linkedScreensAvailables.length) {
         this.showModalIntroCode = true;
       }
 
      } else {
        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }
 
    })

  }

  getScreenByTemplate() {
    let query = 
    {
    "template_code": this.template_code,
    "screen_user": this.userPostData.user_id
    }

    this.screens.getScreenByTemplate(query).subscribe(data => {
      this.linkedScreens = data.data;

      // actualizamos el valor de las pantallas asocidas al resto de componentes
      this.screens.updateScreensLinked(data.data);
      
    });

  }

  getScreensByUserAndTemplate(){
    /**
     * Recuperamos las pantallas que el usuario tiene asociadas (las que están asociadas al template y las que no)
     */

     this.showModalIntroCode = false;
     this.linkedScreensAvailables = [];

     let query = 
     {
     "template_code": this.template_code,
     "screen_user": this.userPostData.user_id
     }

     this.getScreenByUser();

  }

  getTemplateInfo(templateCode) {

    /**
     * Recuperamos información relacionada con el template por el ID
     * 
     */

     return new Promise((resolve, reject) => {
      
      this.landingPostData.user_id       = this.userPostData.user_id;
      this.landingPostData.landing_code  = templateCode;
  
      this.authAPIService.postDataLanding(this.landingPostData).then( respond => {
        this.responseData = respond;
    
        if (this.responseData.code == 200) {
          resolve(this.responseData.landing[0]);
        } else {
          // Error en el proceso de validación usuario/landing
          this.user.logOut();
        }

      },
      err => {
          console.log('Error');
      });
    });
  }


  addNewScreenToTemplate() {

    let data = {
      "screen_user": this.userPostData.user_id,
      "screen_code": this.screen_code,
      "template_code": this.template_code,
      "screen_status": 1
    }

    this.screens.addTemplateToScreen(data).subscribe((responseData)=>{
      
      console.log(responseData);

      if (responseData.code == 200) {
        console.log(responseData);
        
        if(responseData.error === 1) {
          // No existe el código introducido
          this.modalRespond = responseData.message;

        } else {
          //this.getScreensByUserAndTemplate();
          this.getScreenByTemplate();

          this.modalRespond = responseData.message;
          // ... y se cierra la modal
          setTimeout(()=>{
            this.modalService.dismissAll();
          },1000);

        }
        

      } else {
        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }

    })
    
  }

  useAvailableScreen(screen_code, index, list) {

    let data = {
      "screen_user": this.userPostData.user_id,
      "screen_code": screen_code,
      "template_code": this.template_code,
      "screen_status": 1
    }

    this.screens.addTemplateToScreen(data).subscribe((responseData)=>{
      
      console.log(responseData);

      if (responseData.code == 200) {
        
        if(responseData.error === 1) {
          // No existe el código introducido
          //this.modalRespond = responseData.message;

        } else {

          //this.getScreenByTemplate();
          //this.getScreenByUser();
          list[index].template_code = this.template_code;

        }
        

      } else {
        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }

    })

  }

  leaveScreen(screen_code, index, list, update) {

    let data = {
      "screen_user": this.userPostData.user_id,
      "screen_code": screen_code,
      "template_code": "",
      "screen_status": 0
    }

    this.screens.addTemplateToScreen(data).subscribe((responseData)=>{
      
      console.log(responseData);

      if (responseData.code == 200) {
        
        if(responseData.error === 1) {
          // No existe el código introducido
          //this.modalRespond = responseData.message;

        } else {

          // Actualizamos las pantallas asociadas
          // Actualizamos el listado de pantallas asociadas

          //this.getScreenByUser();
          if(update) {
            this.getScreenByTemplate();
          } else {
            list[index].template_code = "";
          }

        }
        

      } else {
        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }

    })

  }

  closeModal(content){

    // Actualizamos el listado de pantallas asociadas
    let query = 
      {
      "template_code": this.template_code,
      "screen_user": this.userPostData.user_id
      }

    this.screens.getScreenByTemplate(query).subscribe(data => {
      this.linkedScreens = data.data;

      // actualizamos el valor de las pantallas asocidas al resto de componentes
      this.screens.updateScreensLinked(data.data);

    });

    this.modalService.dismissAll();
  }

  askCodeScreen(content) {
    this.getScreensByUserAndTemplate();
    this.modalService.open(content, { centered: true });
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    console.log(code)
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    console.log(code)
    this.screen_code = code;
  }

}
