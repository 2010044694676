import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, HostListener, Input, Type } from '@angular/core';
import { LandingService } from '../../../services/landing.service';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent implements OnInit, AfterViewChecked {

  @ViewChild('btnSave') btnSave: ElementRef;
  code:any;
  userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  constructor(
    public dataLandingService: LandingService,
    public user: UserService,
    public router: Router,
    private route: ActivatedRoute
  ) { 
    this.userPostData = this.user.getData();
  }

  // Ctrl+S ejecutamos Guardar
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
          this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, 0);
          event.preventDefault();
      }
  }

  ngOnInit(): void {

    this.code = this.router.routerState.snapshot.root.children[0].params.id

  }

  ngAfterViewChecked() {
    this.dataLandingService.setBtnSave(this.btnSave.nativeElement);
  }

  saveLanding(){
    //this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, this.landingPostData.landing_save_and_publish );
    this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, 0 );
  }

}
