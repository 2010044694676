<header class="log-header">
  <div class="log-header-main">
    <div class="log-header-brand">
        <a href="#!">
          <!-- <svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-logo"></use>
          </svg> -->
        </a>
    </div>
    <span class="log-header-title">Cuéntale al mundo lo que sabes hacer</span>
    <span class="log-header-footer"><b>© 2020 Boostup.io,</b> Inc. Todos los derechos reservados.</span>
  </div>
  
  <!-- <div class="page-main-animation brown" style="top: 25vh; left: 2vw; opacity: .3">
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
  </div>
  <div class="page-main-animation brown" style="bottom: 16.6vh; right: 4vw; opacity: .3">
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
      <span style="visibility: hidden"><i>&nbsp;</i></span>
  </div> -->
</header>

<section class="log-grid">
  <main class="log-box">
    <h1 class="log-box-title">Accede a tu cuenta</h1>
    <!-- SignUp a través de RRSS -->
    <div class="log-box-social">
      <button type="button" (click)="socialSignIn('google')" class="log-box-social-btn">
        <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-google"/></svg></span>
        <span>Con Google</span>
      </button>
      <button type="button" (click)="socialSignIn('facebook')" class="log-box-social-btn">
        <span><svg class="icon"><use href="assets/icons/icons-sprite.svg#svg-facebook"/></svg></span>
        <span>Con Facebook</span>
      </button>
    </div> 
    <!-- Custom SignUp -->
    <div class="log-box-custom">
      <h2 class="log-box-custom-title">O con tu usuario y contraseña</h2>
      <form class="log-box-custom-form">
        <div class="log-box-custom-form-fieldset input">
          <input type="email" [(ngModel)]="userPostData.email" [ngModelOptions]="{standalone: true}" name="email-field" placeholder="Correo electrónico">
          <span class="field-error" *ngIf="showErrorEmail"> {{messageError}}</span>
        </div>
        <div class="log-box-custom-form-fieldset input">
          <input type="password" [(ngModel)]="userPostData.password" [ngModelOptions]="{standalone: true}" name="password-field" id="password-field" placeholder="Contraseña">
          <span class="field-error" *ngIf="showErrorPass">{{messageError}}</span>
        </div>
        <div class="log-box-custom-form-fieldset submit">
          <a routerLink="/recovery">¿Olvidaste tu contraseña?</a>
          <input type="submit" (click)="localSignIn(userPostData.email,userPostData.password)" value="Acceder">
        </div>
      </form>
    </div>
    <!-- Are you already a member? -->
    <div class="log-box-footer"> 
      ¿Todavía no tienes una cuenta? <span><a routerLink="/signup" style="text-decoration: underline">Regístrate&nbsp;gratis</a></span> ahora.
    </div>

    <div class="log-box-footer"> 
      <a (click)="autoSignUp()" style="text-decoration: underline">Crear&nbsp;site</a>
    </div>
  </main>
</section>



















<!--
<body class="log-grid">
  
  <header class="log-header">
    Tell everyone what you're the best at!
  </header>
  
  <main class="log-main">
    <div class="log-main-box">
      
      <h1 class="login-box-title">Login Form</h1>
      
      
      <div class="login-box-social">
        <button type="button" (click)="socialSignIn('google')" class="login-box-social-btn">Use a Google account</button>
        <button type="button" (click)="socialSignIn('facebook')" class="login-box-social-btn">Use a Facebook account</button>
      </div>

     
      <div>
        <h2 class="login-box-subtitle">Or use your email and password</h2>
        <form class="login-form">
          <div class="login-form-field text long">
            <label for="email-field">Email</label>
            <input type="email" [(ngModel)]="userPostData.email" [ngModelOptions]="{standalone: true}" name="email-field">
            <span class="field-error" *ngIf="showErrorEmail"> {{messageError}}</span>
          </div>
          <div class="login-form-field text long" id="password">
            <label for="password-field">Password <a routerLink="/recovery">Forgot password?</a></label>
            <input type="password" [(ngModel)]="userPostData.password" [ngModelOptions]="{standalone: true}" name="password-field" id="password-field">
            <span class="field-error" *ngIf="showErrorPass">{{messageError}}</span>
          </div>
          <div class="login-form-field button long">
            <input type="submit" (click)="localSignIn(userPostData.email,userPostData.password)" value="Log in">
          </div>
        </form>
      </div> 

    </div>
  </main>

  <footer class="login-footer">
    Not yet a member? <span><a routerLink="/signup">Create your account</a></span> now! 
  </footer>

</body>
-->