import { Component, OnInit, Input } from '@angular/core';

export type StatusType = 'success' |'warning' | 'danger' | 'info' | null;
export type StatusSize = 'sm' |'xs' | null;
export type StatusValue = number;

@Component({
  selector: '[app-status]',
  template: `
    <ng-content></ng-content>
    <span *ngIf="value" class="c-status__badge">{{value}}</span>
  `,
  host: {
    'class': 'c-status',
    '[class.c-status--success]': 'type === "success"',
    '[class.c-status--warning]': 'type === "warning"',
    '[class.c-status--danger]': 'type === "danger"',
    '[class.c-status--info]': 'type === "info"',
    '[class.c-status--sm]': 'size === "sm"',
    '[class.c-status--xs]': 'size === "xs"'
  },
})
export class StatusComponent implements OnInit {

  @Input() type: StatusType = null;
  @Input() size: StatusSize = null;
  @Input() value: StatusValue;

  constructor() { }

  ngOnInit(): void {
  }

}
