import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewContainerRef, ComponentFactoryResolver, HostListener, AfterViewChecked} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { environment } from './../../environments/environment';
//import { NoneComponent } from 'angular6-json-schema-form';

import { AuthAPIService } from '../../services/auth-api.service';
import { UserService } from '../../services/user.service';
import { LandingService } from '../../services/landing.service';
import { IframeService } from '../../services/iframe.service';
import { ScreensService } from 'src/services/screens.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { TemplateModules } from '../templates/template.modules';
import { TemplateLayouts } from '../templates/layouts/template.layouts';
import { LandingTemplate } from '../landing.template';
import { ColorSchemaTemplate } from '../colorSchema.template';
import { FontFamilyTemplate } from '../fontFamilies.template';

import { interval, Subscription } from 'rxjs';
import {TooltipModule} from 'primeng/tooltip';

import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})

export class EditorComponent implements OnInit, OnDestroy, AfterViewChecked {

  //@ViewChild('btnSave') btnSave: ElementRef;
  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  @ViewChild('iframeWrapper', {static: true}) iframeWrapper: ElementRef;
  @ViewChild('editorPrev', {static: true}) editorPrev: ElementRef;

  public responseData: any;
  public responseLanding: any;
  public landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  public linkedScreens: any;
  public showPreview: boolean = false;

  checkSaveAndPublish: any;
  lastUpdateDate: any;
  fakeUserID : any;
  nonGuest: any;
  subdomainUrl: string;
  subdomainCode: any;
  showSubdomainError: boolean=false;

  compRef: any;
  iframeWidth: any;
  iframeHeight: any;
  screenWidth: any;
  screenHeight: any;
  contentWidth: any;
  contentHeight: any;
  windowWidth: any;
  _scaleW:any;
  _test:any;
  _scaleH:any;

  public userPostData = {
    method: '',
    email: '',
    email_conf: '',
    name: '',
    provider: '',
    password: '',
    provider_id: '',
    provider_pic: '',
    token: '',
    user_id: '',
    user_type: ''
  };

  public landingPostData = {
    method: '',
    user_id: '',
    landing_id: '',
    landing_type: '',
    landing_code: '',
    landing_name: '',
    landing_content: {},
    landing_publish: '0', // Este flag es el que indica si la landing se publica o no. Una vez publicada el valor en base de datos permanece a 1.
    landing_save_and_publish: '0',
    landing_collaborators: '',
    landing_collaborators_pending: '',
    landing_favourite: 0,
    landing_extra_fake_user_id: '', // Parametro necesario para el proceso de reasignación del propietario de la landing
    landing_extra_real_user_id: '', // Parametro necesario para el proceso de reasignación del propietario de la landing
    landing_catalog: '',
    landing_subdomain_code: ''
};

  public currentLandingId: any; // Id de la landing sobre la que estamos trabajando
  public currentLandingName: any; // Nombre de la landing sobre la que estamos trabajando
  public currentLandingCatalogStatus: any; // Estatus del check de visibilidad de la landing.

  currentdisplayType:string = "screen"; // Visualización preview desktop/tablet/mobile
  landingContentUpdate = new LandingTemplate().data;

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  defaultColorType = "ligth";

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;

  //allModulesAbailables:any = [];

  code: any;
  landingOwner: any;
  landingPublish: any;
  numberOfCollaborators: any;
  urlLanding:any;
  private _routeSubscription;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public authAPIService: AuthAPIService,
    public user: UserService,
    public router: Router,
    protected sanitizer: DomSanitizer,
    private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private dataLandingService: LandingService,
    private iframeService: IframeService,
    private modalService: NgbModal,
    private screens: ScreensService,
    private confirmationService: ConfirmationService,
  ) {

    this.userPostData = this.user.getData();
    //this.landingContentUpdate.landing_id = 0;
    this.dataLandingService.changeLandingData(this.landingContentUpdate); // nos aseguramos que empezamos la landing desde 0 para refrescar cualquier dato de otra edición (pasa al usar el servicio para compartir información)
   }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    //Calculamos la escala del iframe en función del ancho del contenedor que lo envuelve y de la resolución de la pantalla
    this.changeIframeScale(this.currentdisplayType);
    //console.log("Resize");
  }

  // Ctrl+S ejecutamos Guardar
  // @HostListener('window:keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent) {
  //     if ((event.metaKey || event.ctrlKey) && event.key === 's') {
  //         this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, this.landingPostData.landing_save_and_publish);
  //         event.preventDefault();
  //     }
  // }

    //Calculamos la escala del iframe en función del ancho del contenedor que lo envuelve y de la resolución de la pantalla
  changeIframeScale(displayType) {

    // this.windowWidth    = window.innerWidth;

    // this.iframeWidth    = this.iframe.nativeElement.offsetWidth;
    // this.iframeHeight   = this.iframe.nativeElement.offsetHeight;

    // this.screenWidth    = this.editorPrev.nativeElement.offsetWidth//document.getElementById('editorPrev').offsetWidth;
    // this.screenHeight   = document.getElementById('editorPrev').offsetHeight;
    // this.contentWidth   = this.screenWidth;
    // this.contentHeight  = this.iframeWrapper.nativeElement.offsetHeight;

    // let paddingTop;
    // if(displayType =='desktop') {
    //   paddingTop = 6;
    //   this._scaleW = this.screenWidth/1280;
    //   // this._test = 2 - this._scaleW;

    //   this.iframe.nativeElement.style.height = ((this.contentHeight * this.iframeWidth) / this.contentWidth) + 'px';
    //   this.iframeWrapper.nativeElement.style.width = this.contentWidth + 'px';
    //   this.iframe.nativeElement.style.transform = ("scale("+ this._scaleW + ")");

    // } else if(displayType =='tablet') {
    //   paddingTop = 9;
    //   this._scaleW = this.screenWidth/768;

    //   this.iframe.nativeElement.style.height = ((this.contentHeight * this.iframeWidth) / this.contentWidth) + 'px';
    //   this.iframeWrapper.nativeElement.style.width = this.contentWidth + 'px';
    //   this.iframe.nativeElement.style.transform = ("scale("+ this._scaleW + ")");
    // } else if(displayType =='mobile') {
    //   paddingTop = 9;
    //   this._scaleW = this.screenWidth/375;//this.screenWidth/375;

    //   this.iframe.nativeElement.style.height = ((this.contentHeight * this.iframeWidth) / this.contentWidth) + 'px';
    //   this.iframeWrapper.nativeElement.style.width = this.contentWidth + 'px';
    //   this.iframe.nativeElement.style.transform = ("scale("+ this._scaleW + ")");

    // } else if(displayType =='screen') {
    //   paddingTop = 9;
    //   this._scaleW = this.screenWidth/375;//this.screenWidth/375;

    //   this.iframe.nativeElement.style.height = '570px';
    //   this.iframeWrapper.nativeElement.style.width = this.contentWidth + 'px';
    //   this.iframe.nativeElement.style.transform = ("scale("+ this._scaleW + ")");

    // }
  }

  addContentToIframe(_element:any, _index) {


    //let _component = TemplateModules[_element.landingType]['layouts'][_element.moduleType];
    let _component = TemplateLayouts['layouts'][_element.moduleType];
    
    if(!_component) return;
    

    const compFactory = this.resolver.resolveComponentFactory(_component);
    this.compRef = this.vcRef.createComponent(compFactory);

    //this.compRef.instance.newData = _element;
    //this.landingContentUpdate.modules[_index] = _element;
    this.compRef.instance.structure = _element;

    // Incluimos información en la estructura de datos del componente que vamos a incluir en el iframe
    // Sobreescribimos cualquier valor que tuvieramos

    // Pasamos los componentes que usará nuestro layout
    this.compRef.instance.dataModule = _element;
    this.compRef.instance.structure.commonsOptions.activeMenu = false // No marcamos ningún componente como seleccionado (en la parte izquierda del editor)
    this.compRef.instance.structure.commonsOptions.idMenu =  this.compRef.instance.structure.moduleType + '-' + _index // generamos un ID unico, lo usamos para poder hacer ancla en los menús, cada componente un ID, de momento el nombre del módulo.. no lo más elegangte..

    //console.log(this.compRef.instance.structure.customCss);
    const customCss = this.compRef.instance.structure.customCss;

    // Incluimos estilos adHoc para el modulo, pasamos el estilo definido en el componente y su ID y la posición para tener un ID único
    this.iframeService.setColorSchemaToModule(customCss, this.compRef.instance.structure.commonsOptions.idMenu);

    this.landingView.body.appendChild(this.compRef.location.nativeElement);

  }

  displayType(type:string) {
    this.currentdisplayType = type;
    // Calculamos tamaños
    this.changeIframeScale(type);
    // setTimeout(()=>{
    // },0);

  }

  //////////////////////////////////////////////////////////////////////////////

  shareLanding(){

    // Paso 1. Salvar los cambios /////////////////////////////////////////////

    this.landingPostData.method = 'update';
    this.landingPostData.landing_content = this.landingContentUpdate;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {

      this.responseData = result;

      if (this.responseData.code == 200) {

        // Paso 2. Redirect a la página de colaboración //////////////////////

        this.router.navigate(['collaboration', this.responseData.landing[0].landing_code]);

      } else {

        // Error en la actualización
        //console.log(this.responseData);

      }

    },
    err => {
        console.log('Error');
    });

  }

  ngOnInit() {

    // Recuperamos y actualizamos los colaboradores
    this.dataLandingService.collaboratorNumber.subscribe((respond)=>{
      this.numberOfCollaborators = respond;
    });

    // Recuperamos y actulizamos las pantallas asociadas
    this.screens.linkedScreens.subscribe((respond)=>{
      this.linkedScreens = respond;
    });

    if (this.userPostData.user_type === 'guest'){

      this.user.logOut();

    }

    this.code = this.route.snapshot.paramMap.get('id');

    // Mostramos la seccion correspondiente en función de la URL, check si hay cambio de URL
    this._routeSubscription = this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
          // Hide loading indicator

          //this.dataLandingService.changeLandingData(this.landingPostData);
          //this.showModuleSection(this.route.snapshot.firstChild.data.menuId);
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }
    });

    // Mostramos la seccion correspondiente en función de la URL, check la primera vez que accedemos
    this.route.url.subscribe(() => {
      //this.showModuleSection(this.route.snapshot.firstChild.data.menuId);
    });

    this.landingView = this.iframe.nativeElement.contentDocument ||
    this.iframe.nativeElement.contentWindow; // Asociamos iframe en el HTML a landingView que usaremos para cargar los diferentes modulos

    this.landingOwner = false; // Check para comprobar si el usuario es propietario o no. En caso negativo, no se permite ni duplicar landing, ni compartirla
    this.landingPublish = false; // Inicializamos la variable a false.

    this.landingPostData.method = 'getLandingByID';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = this.code;

    // Pasamos el objeto Iframe al servicio para luego poder utilizarlo en las diferentes funciones
    this.iframeService.setLandingView(this.landingView);

    this.authAPIService.postDataLanding(this.landingPostData).then( respond => {
      this.responseLanding = respond;
      let _result = JSON.parse(this.responseLanding.landing[0].landing_content);

      this.landingPostData.landing_id = this.responseLanding.landing[0].landing_id; // Valor necesario para guardar los cambios
      this.subdomainCode = this.responseLanding.landing[0].landing_subdomain_code
      
      // if(environment.production) {
      //   this.subdomainUrl = "https://"+this.subdomainCode+".boostup.io";
      // } else {
      //   this.subdomainUrl = "http://192.168.1.38:4648/landing.preview/?id="+this.subdomainCode;
      // }

      if (this.responseLanding.code == 200) {

        _result.landing_id = this.responseLanding.landing[0].landing_id; // Valor necesario para guardar los cambios y que necesitamos compartir
        _result.landing_name = this.responseLanding.landing[0].landing_name; // Valor necesario para guardar los cambios y que necesitamos compartir

        // Incluimos en el iframe que hemos creado vacio los assets necesarios, CSS, SCRIPTS, etc..
        this.iframeService.setCssScriptsToIframe(_result, this.landingView);

        // Este servicio DESENCADENA la creación de la landing en el iframe
        // Compartimos la información de la landing mediante un servicio para poder hacer y visualizar los cambios en tiempo real
        this.dataLandingService.changeLandingData(_result);

        // Aprovechamos la query para obtener el nombre de la landing necesario en alguna modal
        this.currentLandingId =  this.code;
        this.currentLandingName = this.responseLanding.landing[0].landing_name;
        this.currentLandingCatalogStatus = this.responseLanding.landing[0].landing_catalog;

        // Última actualización ////////////////////////////////////////////////////////////////////////////

        this.landingPostData.landing_save_and_publish = this.responseLanding.landing[0].landing_save_and_publish;

        this.lastUpdateDate = this.responseLanding.landing[0].update_date;

        // También comprobamos si se ha publicado o no

        if (this.landingPostData.landing_save_and_publish == '1'){
          this.landingPublish = true;
          this.checkSaveAndPublish = true;
        }

        // Colaboradores ////////////////////////////////////////////////////////////////////////////

        if (this.userPostData.user_id === this.responseLanding.landing[0].landing_user_id){

          // El usuario es propietario de la landing

          this.landingOwner = true;

          // Obtenemos el total de colaboradores de la landing para mostrarlo el número en el menú

          this.landingPostData.method = 'getCollaborators';
          this.landingPostData.user_id = this.userPostData.user_id;
          this.landingPostData.landing_code = this.code;

          this.authAPIService.postDataLanding(this.landingPostData).then( result => {

            this.responseData = result;

            if (this.responseData.code == 200) {

              this.numberOfCollaborators = this.responseData.collaborators.length;

            } 

          },
          err => {
              console.log('Error');
          });

        }

      } else {

        // Error en el proceso de validación usuario/landing
        this.user.logOut();
      }
    },
    err => {
        console.log('Error');
    });

    // Cambio en el objeto landing actualizamos modulos
    this.dataLandingService.landingSource.subscribe((respond)=>{

      if(respond.landing_id == null) return;

      // Borramos el contenido del Header
      //this.landingView.head.innerHTML = '';

      // Pasamos a la vista solo los modules de la respuesta
      this.landingContentUpdate = respond;

      // Eliminamos todos los <style> para aplicar de nuevo estilos
      this.iframeService.clearAllStyleTags();

      // Pasamos el objeto Iframe al servicio para luego poder utilizarlo en las diferentes funciones
      //this.iframeService.setLandingView(this.landingView);

      // Configuramos el schema de color
      this.iframeService.setColorSchemaToIframe(this.landingContentUpdate.config.landingColor, this.landingView);

      // Configuramos el schema de color
      this.iframeService.setFontSchemaToIframe(this.landingContentUpdate.config.landingFont, this.landingView);

      // Borramos el contenido del iframe antes de incluir los modulos, esto es necesario para volver a montar la landing con nuevos modulos y que no los sume a los actuales
      this.landingView.body.innerHTML = '';
      // Por cada modulo recibido en la respuesta injectamos su componente en el iframe, esto nos facilita no tener que tener URL en el desarrollo para incluir en el desarrollo
      respond.modules.forEach((element,index) => {
         this.addContentToIframe(element,index);
      });

      // Guardamos al inicio la versión de la landing
      setTimeout(()=>{
        //this.saveLanding();
      })

    })

    ////////////////////////////////////////////////////////////////////////////////////////
    // Variable para capar ciertos enlaces a usuarios invitados.

    if (this.userPostData.user_type === 'guest'){
      this.nonGuest = false;
    } else {
      this.nonGuest = true;
    }

    // Almacenamos en una variable temporal el id provisional del usuario
    this.fakeUserID = this.userPostData.user_id;

    /**
     * Recuperamos las pantallas que tiene asociado el template
     */

     let query = 
     {
     "template_code": this.code,
     "screen_user": this.userPostData.user_id
     }

     this.screens.getScreenByTemplate(query).subscribe(data => {
      this.linkedScreens = data.data;
    });

  }

  changeCheckStatus(){

    if(this.landingPostData.landing_save_and_publish == '1'){

      // Cambio de estado. El usuario ha deshabilitado el check

      this.landingPostData.landing_save_and_publish = '0';
      this.checkSaveAndPublish = false;

    } else {

      // Cambio de estado. El usuario ha marcado la opción de autoguardar en el check

      this.landingPostData.landing_save_and_publish = '1';
      this.checkSaveAndPublish = true;
      this.landingPublish = true;

    }

    this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, this.landingPostData.landing_save_and_publish);

  }

  // Operaciones relacionadas con las landings //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  // Guardado de una landing ////////////////////////////////////////////////////////

  // En este paso ya va indicado si se deben publicar los cambios o no. Lo hacemo sa través de this.landingPostData.landing_save_and_publish

  // saveLanding(){
  //   //this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, this.landingPostData.landing_save_and_publish );
  //   this.dataLandingService.saveLanding(this.userPostData.user_id, this.code, 0 );
  // }

  // Borrado de una landing //////////////////////////////////////////////////

  removeLanding(landingCode) {

    // Solo puede hacerlo el propietario de la landing

    this.landingPostData.method = 'removeLanding';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = landingCode;

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        // Cerramos la modal
        this.modalService.dismissAll();
        // Redirect al dashboard
        this.router.navigate(['dashboard']);

      }

    },
    err => { console.log('Error');}
    );

  }

  changeStatusCalalog(landingCode){

    // Solo puede hacerlo el propietario de la landing

    this.landingPostData.method = 'changeStatusCalalog';
    this.landingPostData.user_id = this.userPostData.user_id;
    this.landingPostData.landing_code = landingCode;

    if (this.currentLandingCatalogStatus == '1'){

      this.landingPostData.landing_catalog = '0';

    } else {

      this.landingPostData.landing_catalog = '1'

    }

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {
      this.responseData = result;

      if (this.responseData.code == 200) {

        this.currentLandingCatalogStatus = this.landingPostData.landing_catalog;

      }

    },
    err => { console.log('Error');}
    );

  }

  ///////////////////////////////////////////////////////////////////////////////////
  // Funciones relacionadas con las modales /////////////////////////////////////////

  // Modal para eliminar una landing ////////////////////////////////////////////////

  showModalRemoveLanding(content,landingId,landingName) {
    this.currentLandingId = landingId;
    this.currentLandingName = landingName;
    this.modalService.open(content, { centered: true });
  }

  // Fin Modal para eliminar una landing ////////////////////////////////////////////////

  // Modal para editar subdominio de la landing /////////////////////////////////////

  changeSubdomainCode(content) {
    this.modalService.open(content, { centered: true });
  }

  // Fin Modal para editar subdominio de la landing /////////////////////////////////////

  // Actualización de subdominio //////////////////////////////////////////////////

  updateSubdomainCode(subdomainCode) {

    this.showSubdomainError = false;

    this.landingPostData.method                 = 'checkSubdomainCode';
    this.landingPostData.landing_subdomain_code = subdomainCode;

    // Paso 1. Chequeamos si podemos usar ese subdominio

    this.authAPIService.postDataLanding(this.landingPostData).then( result => {

      this.responseData = result;

      if (this.responseData.code == 200) {

        if (this.responseData.available) {

          // Paso 2. Asignamos el nombre a la landing

          this.landingPostData.method                 = 'changeSubdomainCode';
          this.landingPostData.user_id                = this.userPostData.user_id;
          this.landingPostData.landing_code           = this.code;
          this.landingPostData.landing_subdomain_code = subdomainCode;

          this.authAPIService.postDataLanding(this.landingPostData).then( result => {

            this.responseData = result;

            if (this.responseData.code == 200) {

              // Todo bien. Se cierra la modal
              this.modalService.dismissAll();
              
            } 

          },
          err => {
              console.log('Error');
          });

        } else {

          // Nombre ocupado o en la blacklist
          this.showSubdomainError = true;

        }

      } 

    },
    err => {
        console.log('Error');
    });

  }

  // Funciones relacionadas con las modales /////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  ngOnDestroy() {
    this._routeSubscription.unsubscribe();
  }

  ngAfterViewChecked() {

    //Calculamos la escala del iframe en función del ancho del contenedor que lo envuelve y de la resolución de la pantalla
    // this.changeIframeScale('desktop');
    //this.dataLandingService.setBtnSave(this.btnSave.nativeElement);
    this.changeIframeScale(this.currentdisplayType);

  }

  // Modal para editar nombre de la landing //////////////////////////

changeNameLanding(content) {
  this.modalService.open(content, { centered: true });
}

// Actualización del nombre de una landing //////////////////////////////////////////////////

updateLandingName(LandingName) {

  this.landingPostData.method = 'editLandingName';
  this.landingPostData.user_id = this.userPostData.user_id;
  this.landingPostData.landing_name = LandingName;
  this.landingPostData.landing_code = this.code; // Nos viene dado por parámetro

  this.authAPIService.postDataLanding(this.landingPostData).then( result => {
    this.responseData = result;

    if (this.responseData.code == 200) {      
      
      // ... y se cierra la modal
      this.modalService.dismissAll();

    } 

  },
  err => { console.log('Error');}
  ); 
  
}


}


