<div class="l-panel-full">
    <div class="l-panel-full__content">
        <div class="l-panel-full__header">
            <h5>Linked tools</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius tortor ultrices volutpat nunc erat ullamcorper enim.</p>
        </div>
        <div class="l-panel-full__body">
            <div class="l-panel-full__body__content">
                <div class="form-row">
                    <div class="col-xl-9">
                        <div class="h-100 py-30">
                            <div class="c-form-editor c-form-editor-plan">
                                <div class="l-plan-tab l-plan-tab__current">Current plan</div>
                                <div class="c-form-editor-body">
                                    <div class="c-form-editor-title">Basic Plan</div>
                                    <!-- List Plan items -->
                                    <div class="form-row">
                                        <div class="col-sm-4">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#close"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#close"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#close"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#close"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#close"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#close"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- / List Plan items -->
                                    <!-- Plan Storage -->
                                    <div class="l-plan-storage">
                                        <div class="l-plan-storage__title mb-15">Storage</div>
                                        <div class="l-plan-storage__data">
                                            <h5>7.6 <span>GB used</span></h5>
                                            <h5>8 <span>GB total</span></h5>
                                        </div>
                                        <div class="c-bar"><span style="width: 95%"></span></div>
                                    </div>
                                    <!-- / Plan Storage -->
                                    <div class="form-row">
                                        <div class="col-sm-4 mb-10 mb-sm-0">
                                            <div class="l-plan-block pb-10">
                                                <div class="d-flex align-items-center">
                                                    <div class="l-plan-icon">
                                                        <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#template-2"/></svg>
                                                    </div>
                                                    <app-data-label size="sm">
                                                        <span data-label-text>Templates</span>
                                                        <span data-label-caption>2.5 GB of 8 GB used</span>
                                                    </app-data-label>
                                                </div>
                                                <div class="d-flex mt-10">
                                                    <button class="btn btn-link p-0 ml-auto">Manage</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mb-10 mb-sm-0">
                                            <div class="l-plan-block pb-10">
                                                <div class="d-flex align-items-center">
                                                    <div class="l-plan-icon">
                                                        <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#desktop"/></svg>
                                                    </div>
                                                    <app-data-label size="sm">
                                                        <span data-label-text>Screens</span>
                                                        <span data-label-caption>1.0 GB of 8 GB used</span>
                                                    </app-data-label>
                                                </div>
                                                <div class="d-flex mt-10">
                                                    <button class="btn btn-link p-0 ml-auto">Manage</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mb-10 mb-sm-0">
                                            <div class="l-plan-block pb-10">
                                                <div class="d-flex align-items-center">
                                                    <div class="l-plan-icon">
                                                        <svg class="icon icon-stroke ic-20"><use href="assets/icons/icons-sprite.svg#folder"/></svg>
                                                    </div>
                                                    <app-data-label size="sm">
                                                        <span data-label-text>Galery</span>
                                                        <span data-label-caption>4.1 GB of 8 GB used</span>
                                                    </app-data-label>
                                                </div>
                                                <div class="d-flex mt-10">
                                                    <button class="btn btn-link p-0 ml-auto">Manage</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="l-plan-comment">Basic Plan purchased until 23 Jun, 2022</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 d-none d-xl-block">
                        <div class="h-100 py-30">
                            <div class="c-form-editor c-form-editor-plan">
                                <div class="l-plan-tab">Most Popular</div>
                                <div class="c-form-editor-body">
                                    <div>
                                        <div class="c-form-editor-title">Plus Plan</div>
                                        <p class="l-plan-text">Sit id ultricies massa velit non iaculis ultricies.</p>
                                        <div class="l-plan-price">32 EUR</div>
                                    </div>
                                    <div>
                                        <div class="mt-20 mb-15">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                        <div class="l-plan-block">
                                            <div class="l-plan-storage__title">Storage</div>
                                            <div class="l-plan-storage__size">16 <span>GB</span></div>
                                            <img class="l-plan-cloud" src="./assets/img/illustrations/cloud-plus.svg" alt="Plus plan">
                                        </div>
                                        <button class="btn btn-icon btn-icon__right btn-primary w-100 mt-15">Upgrade <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-upgrade"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-plan-filters mb-sm-30">
                    <div class="l-plan-subscription">
                        <span class="l-plan-subscription__label" [ngClass]="{'active': !subscription }">Monthly</span>
                        <p-inputSwitch [(ngModel)]="subscription"></p-inputSwitch>
                        <span class="l-plan-subscription__label" [ngClass]="{'active': subscription }">Yearly <span class="l-plan-label ml-5" >Save 20%</span></span>
                    </div>
                    <div class="l-plan-currency">
                        <span class="l-plan-currency__label">Currency</span>
                        <p-dropdown [options]="currency" [(ngModel)]="selectedCurrency" optionLabel="name"></p-dropdown>
                    </div>
                </div>
                <!-- Plans -->
                <div class="form-row">
                    <div class="col-sm-6 col-xl-3">
                        <div class="h-100 py-30">
                            <div class="c-form-editor c-form-editor-plan">
                                <!-- <div class="l-plan-tab">Most Popular</div> -->
                                <div class="c-form-editor-body">
                                    <div>
                                        <div class="c-form-editor-title">Business Plan</div>
                                        <p class="l-plan-text">Sit id ultricies massa velit non iaculis ultricies.</p>
                                        <div class="l-plan-price">96 EUR</div>
                                        <span class="l-plan-label ml-5 mb-20" >Save 12€ a month</span>
                                    </div>
                                    <div>
                                        <div class="my-15">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                        <div class="l-plan-block">
                                            <div class="l-plan-storage__title">Storage</div>
                                            <div class="l-plan-storage__size">32 <span>GB</span></div>
                                            <img class="l-plan-cloud" src="./assets/img/illustrations/cloud-business.svg" alt="Business plan">
                                        </div>
                                        <button class="btn btn-icon btn-icon__right btn-outline-primary w-100 mt-15">Upgrade <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-upgrade"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xl-3">
                        <div class="h-100 py-30">
                            <div class="c-form-editor c-form-editor-plan">
                                <div class="l-plan-tab">Most Popular</div>
                                <div class="c-form-editor-body">
                                    <div>
                                        <div class="c-form-editor-title">Plus Plan</div>
                                        <p class="l-plan-text">Sit id ultricies massa velit non iaculis ultricies.</p>
                                        <div class="l-plan-price">32 EUR</div>
                                        <span class="l-plan-label ml-5 mb-20" >Save 12€ a month</span>
                                    </div>
                                    <div>
                                        <div class="my-15">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                        <div class="l-plan-block">
                                            <div class="l-plan-storage__title">Storage</div>
                                            <div class="l-plan-storage__size">16 <span>GB</span></div>
                                            <img class="l-plan-cloud" src="./assets/img/illustrations/cloud-plus.svg" alt="Plus plan">
                                        </div>
                                        <button class="btn btn-icon btn-icon__right btn-outline-primary w-100 mt-15">Upgrade <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-upgrade"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xl-3">
                        <div class="h-100 py-30">
                            <div class="c-form-editor c-form-editor-plan">
                                <div class="l-plan-tab l-plan-tab__current">Current Plan</div>
                                <div class="c-form-editor-body">
                                    <div>
                                        <div class="c-form-editor-title">Basic Plan</div>
                                        <p class="l-plan-text">Sit id ultricies massa velit non iaculis ultricies.</p>
                                        <div class="l-plan-price">16 EUR</div>
                                        <span class="l-plan-label ml-5 mb-20" >Save 12€ a month</span>
                                    </div>
                                    <div>
                                        <div class="my-15">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                        <div class="l-plan-block">
                                            <div class="l-plan-storage__title">Storage</div>
                                            <div class="l-plan-storage__size">8 <span>GB</span></div>
                                            <img class="l-plan-cloud" src="./assets/img/illustrations/cloud-basic.svg" alt="Basic plan">
                                        </div>
                                        <button class="btn btn-icon btn-icon__right btn-outline-primary w-100 mt-15">Upgrade <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-upgrade"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xl-3">
                        <div class="h-100 pt-sm-30 pb-30">
                            <div class="c-form-editor c-form-editor-plan">
                                <!-- <div class="l-plan-tab">Most Popular</div> -->
                                <div class="c-form-editor-body">
                                    <div>
                                        <div class="c-form-editor-title">Free Plan</div>
                                        <p class="l-plan-text">Sit id ultricies massa velit non iaculis ultricies.</p>
                                        <div class="l-plan-price">0 EUR</div>
                                    </div>
                                    <div>
                                        <div class="my-15">
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                            <div class="l-plan-item">
                                                <svg class="icon icon-stroke ic-gray-dark ic-20"><use href="assets/icons/icons-sprite.svg#check"/></svg>
                                                <span>Lorem ipsum dolor sit</span>
                                            </div>
                                        </div>
                                        <div class="l-plan-block">
                                            <div class="l-plan-storage__title">Storage</div>
                                            <div class="l-plan-storage__size">4 <span>GB</span></div>
                                            <img class="l-plan-cloud" src="./assets/img/illustrations/cloud-free.svg" alt="Free plan">
                                        </div>
                                        <button class="btn btn-icon btn-icon__right btn-outline-primary w-100 mt-15">Upgrade <svg class="icon ic-white ic-20"><use href="assets/icons/icons-sprite.svg#svg-upgrade"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Plans -->
                <!-- Billing -->
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">Billing information</div>
                        <p-table [value]="billingItems" dataKey="id" editMode="row" class="c-table-edit">
                            <ng-template pTemplate="body" let-billingItem let-editing="editing" let-ri="rowIndex">
                                <tr [pEditableRow]="billingItem">
                                    <td width="30px">
                                        <p-radioButton name="billing" value="{{billingItem.id}}" [(ngModel)]="selectedBillingValues"></p-radioButton>
                                    </td>
                                    <td pEditableColumn>
                                        <app-data-label size="sm" class="c-data-label--row">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="billingItem.name">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span data-label-text><strong>{{billingItem.name}}</strong></span>
                                                </ng-template>
                                            </p-cellEditor>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="billingItem.adress">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span data-label-caption>Adress: {{billingItem.adress}}</span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </app-data-label>
                                    </td>
                                    <td class="c-table-edit__actions">
                                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow (click)="onRowEditInitBilling(billingItem)" class="btn btn-link px-0 p-button-text">Edit</button>
                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSaveBilling(billingItem)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancelBilling(billingItem)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-icon__left ml-auto px-0" (click)="openNewBilling()"><svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>Add new billing information</button>
                        </div>
                    </div>
                </div>
                <!-- / Billing -->
                <!-- Payment -->
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">Payment method</div>
                        <p-table [value]="paymentItems" dataKey="id" editMode="row" class="c-table-edit">
                            <ng-template pTemplate="body" let-paymentItem let-editing="editing" let-ri="rowIndex">
                                <tr [pEditableRow]="paymentItem">
                                    <td width="30px">
                                        <p-radioButton name="payment" value="{{paymentItem.id}}" [(ngModel)]="selectedPaymentValues"></p-radioButton>
                                    </td>
                                    <td pEditableColumn>
                                        <app-data-label size="sm" class="c-data-label--row">
                                            <div class="d-flex align-items-center">
                                                <img class="mr-10" src="./assets/img/{{paymentItem.img}}.png"/>
                                                <span data-label-text><strong>{{paymentItem.method}}</strong></span>
                                            </div>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="paymentItem.card">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span data-label-caption>{{paymentItem.card}}</span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </app-data-label>
                                    </td>
                                    <td class="c-table-edit__actions">
                                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow (click)="onRowEditInitPayment(paymentItem)" class="btn btn-link px-0 p-button-text">Edit</button>
                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSavePayment(paymentItem)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancelPayment(paymentItem)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-icon__left ml-auto px-0" (click)="openNewPayment()"><svg class="icon ic-20"><use href="assets/icons/icons-sprite.svg#svg-add-circle"/></svg>Add new payment method</button>
                        </div>
                    </div>
                </div>
                <!-- / Payment -->
                <!-- Invoices -->
                <div class="c-form-editor">
                    <div class="c-form-editor-body">
                        <div class="c-form-editor-title">Invoices</div>

                        <p-table [value]="invoicesItems" responsiveLayout="scroll" class="c-table-invoices c-table-responsive">
                            <ng-template pTemplate="body" let-invoicesItem>
                                <tr>
                                    <td class="c-table-invoices__name">{{invoicesItem.plan}} - {{invoicesItem.month}}</td>
                                    <td class="c-table-invoices__label">{{invoicesItem.currency}} {{invoicesItem.value}}{{invoicesItem.currencySymbol}}</td>
                                    <td class="c-table-invoices__label">{{invoicesItem.date}}</td>
                                    <td class="c-table-invoices__status">
                                        <ng-container *ngIf="invoicesItem.status == 'Paid'; else elseStatus"><div app-status type="success" size="sm"><svg class="icon mr-5 ic-14"><use href="assets/icons/icons-sprite.svg#svg-check-outline"/></svg> {{invoicesItem.status}}</div></ng-container>
                                        <ng-template #elseStatus><div app-status type="warning" size="sm"><svg class="icon ic-14 mr-5"><use href="assets/icons/icons-sprite.svg#svg-more-circle"/></svg> {{invoicesItem.status}}</div></ng-template>
                                    </td>
                                    <td class="c-table-invoices__download"><a class="btn btn-link p-0" href="#">Download PDF</a></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <!-- / invoices -->
            </div>
        </div>
    </div>
</div>

<!-- #################################################################################################################################### -->
<!-- ######################                                             Modales                                        ###################-->
<!-- #################################################################################################################################### -->


<p-dialog [(visible)]="billingDialog" [style]="{width: '450px'}" header="Billing information" [modal]="true" styleClass="p-fluid dialog-tool">
    <ng-template pTemplate="content">
        <div class="p-field">
            <label for="billingName">Name</label>
            <input type="text" pInputText id="billingName"  [(ngModel)]="billingName" required autofocus />
        </div>
        <div class="p-field">
            <label for="billingAdress">Adress</label>
            <input type="text" pInputText id="billingAdress"  [(ngModel)]="billingAdress" required autofocus />
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="btn" (click)="hideDialogBilling()">Cancel</button>
        <button class="btn btn-primary" (click)="saveBillingItem(billingName, billingAdress)">Save</button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="paymentDialog" [style]="{width: '450px'}" header="payment information" [modal]="true" styleClass="p-fluid dialog-tool">
    <ng-template pTemplate="content">
        <div class="p-field">
            <label for="paymentMethod">Payment method</label>
            <p-dropdown [options]="paymentMethod" [(ngModel)]="selectedPaymentMethod" optionLabel="name" placeholder="Select payment method">
                <ng-template pTemplate="selectedItem">
                    <div class="d-flex"  *ngIf="selectedPaymentMethod">
                        <img class="mr-10" src="./assets/img/{{selectedPaymentMethod.img}}.png" />
                        <div>{{selectedPaymentMethod.name}}</div>
                    </div>
                </ng-template>
                <ng-template let-method pTemplate="item">
                    <div class="d-flex">
                        <img class="mr-10" src="./assets/img/{{method.img}}.png"/>
                        <div>{{method.name}}</div>
                    </div>
                </ng-template>
            </p-dropdown>        
        </div>
        <div class="p-field">
            <label for="paymentCard">Card number</label>
            <input type="text" pInputText id="paymentCard"  [(ngModel)]="paymentCard" required autofocus />
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="btn" (click)="hideDialogPayment()">Cancel</button>
        <button class="btn btn-primary" (click)="savePaymentItem(selectedPaymentMethod, paymentCard)">Save</button>
    </ng-template>
</p-dialog>

