<div class="l-panel-editor">

    <!-- Panel Editor: Header -->
    <div class="l-panel-editor__header" [ngClass]="{'show': showPreview}">
      <div class="d-flex align-items-center">
        <div class="l-panel-editor__header__title">Settings</div>
        <button type="button" class="btn btn-link d-none d-lg-inline-block d-xxxl-none ml-5" routerLink="../preview">Preview</button>
      </div>
      <div class="l-panel-editor__header__actions">
        <app-save-button></app-save-button>
        <button type="button" class="btn btn-primary btn-icon btn-icon__right btn-pause">Pause <svg class="icon icon-stroke ic-white ic-20"><use href="assets/icons/icons-sprite.svg#pause-circle"/></svg></button>
      </div>
    </div>
    <!-- / Panel Editor: Header -->
  
    <!-- Panel Editor: Body -->
    <div class="l-panel-editor__body">
  
      <div class="scroll-wrap">
  
        <div class="l-panel-editor__body__content">
  
            <div class="c-form-editor">
                <div class="c-form-editor-body">
                    <div class="c-form-editor-cab pb-10">Information</div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col">
                                <app-data-label size="sm">
                                    <span data-label-caption>Code used</span>
                                    <span data-label-text>102547</span>
                                </app-data-label>
                            </div>
                            <div class="col">
                                <app-data-label size="sm">
                                    <span data-label-caption>Size</span>
                                    <span data-label-text>1440 x500px</span>
                                </app-data-label>
                            </div>
                            <div class="col">
                                <app-data-label size="sm">
                                    <span data-label-caption>Added</span>
                                    <span data-label-text>Feb 18, 2022</span>
                                </app-data-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-form-editor">
                <div class="c-form-editor-body">
                    <div class="c-form-editor-cab pb-10">Rotation</div>
                    <div class="form-group">
                        <div class="d-flex">
                            <button type="button" class="btn btn-icon btn-icon-only mr-10"><svg class="icon icon icon-stroke"><use href="assets/icons/icons-sprite.svg#rotate"/></svg></button>
                            <app-data-label size="sm">
                                <span data-label-caption>Position</span>
                                <span data-label-text>Horizontal 0º</span>
                            </app-data-label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-form-editor">
                <div class="c-form-editor-body">
                    <div class="c-form-editor-cab pb-10">Watermark</div>
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="visibility-toggle">
                                <div class="visibility-toggle-item d-flex justify-content-center align-items-center">
                                  <p-toggleButton onIcon="pi pi-eye-slash" offIcon="pi pi-eye"></p-toggleButton>
                                </div>
                            </div>
                            <app-data-label size="sm">
                                <span data-label-caption>Company Name</span>
                                <span data-label-text>ZARA Official</span>
                            </app-data-label>
                        </div>
                    </div>
                    <div class="c-form-editor-line"></div>
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="visibility-toggle">
                                <div class="visibility-toggle-item d-flex justify-content-center align-items-center">
                                  <p-toggleButton onIcon="pi pi-eye-slash" offIcon="pi pi-eye"></p-toggleButton>
                                </div>
                            </div>
                            <app-data-label size="sm">
                                <span data-label-caption>Description</span>
                                <span data-label-text>Discover the new collection</span>
                            </app-data-label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-form-editor">
                <div class="c-form-editor-body">
                    <div class="c-form-editor-cab pb-10">Timeline</div>
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="visibility-toggle">
                                <div class="visibility-toggle-item d-flex justify-content-center align-items-center">
                                  <p-toggleButton onIcon="pi pi-eye-slash" offIcon="pi pi-eye"></p-toggleButton>
                                </div>
                            </div>
                            <app-data-label size="sm">
                                <span data-label-text>Progress lines</span>
                            </app-data-label>
                        </div>
                    </div>
                    <div class="c-form-editor-line"></div>
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="visibility-toggle">
                                <div class="visibility-toggle-item d-flex justify-content-center align-items-center">
                                  <p-toggleButton onIcon="pi pi-eye-slash" offIcon="pi pi-eye"></p-toggleButton>
                                </div>
                            </div>
                            <app-data-label size="sm">
                                <span data-label-text>QR Code</span>
                            </app-data-label>
                        </div>
                    </div>
                </div>
            </div>
  
        </div>
  
      </div>
  
    </div>
    <!-- / Panel Editor: Body -->
  
  </div>
