import { Component, OnInit, ElementRef, ViewChild, Input, Output, ComponentFactoryResolver, ViewContainerRef, EventEmitter, HostListener } from '@angular/core';
import { TemplateLayouts } from '../../../templates/layouts/template.layouts';
import { LandingService } from '../../../../services/landing.service';
import { IframeService } from '../../../../services/iframe.service';
import { ColorSchemaTemplate } from '../../../colorSchema.template';
import { FontFamilyTemplate } from '../../../fontFamilies.template';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-module-item-preview',
  templateUrl: './module-item-preview.component.html',
  styleUrls: ['./module-item-preview.component.scss']
})
export class ModuleItemPreviewComponent implements OnInit {

  @Input() moduleData : any;
  @Input() moduleParentInfo: any;
  @Input() index : any;
  @Input() showModulesItemsSidebar:boolean = false;
  @Output() addModuleEvent = new EventEmitter<object>();
  @Output() removeModuleEvent = new EventEmitter<string>();
  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  @ViewChild('previewComponent', {static: true}) previewComponent: ElementRef;

  currentLandingType: any;
  moduleParentInfoName:string;
  landingView: any; // Este elemento hace referencia al iframe donde se montarán los diferentes componentes de la landing
  compRef: any;
  widthPreviewComponent: any;
  scaleIframe: any;

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultColorSchemas = new ColorSchemaTemplate().data;
  defaultColorType = "ligth";

  // Los esquemas por defecto que cargaremos se definen aquí y se cargan desde ../../colorSchema.template:
  defaultFontFamily = new FontFamilyTemplate().data;
  subscription: Subscription;

  constructor(
    private resolver: ComponentFactoryResolver,
    private vcRef: ViewContainerRef,
    private dataLandingService: LandingService,
    private iframeService: IframeService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Escalamos la preview del iframe del listado de componentes según la resolución
    this.widthPreviewComponent = this.previewComponent.nativeElement.offsetWidth;
    this.scaleIframe = this.widthPreviewComponent/1366;
    this.iframe.nativeElement.style.transform = "scale("+ this.scaleIframe +")";
  }

  addModule(templateGroup){
    
    //let modulesArray = Object.assign({}, TemplateModules[this.currentLandingType]['modules']);;
    // Agregamos el Layout y componentes asociados a está combinación
    // let layoutsArray = Object.assign({}, TemplateModules[this.currentLandingType]['layouts']);;
    // templateGroup.index = Object.keys(layoutsArray).findIndex((element) => element == templateGroup.layout);

    // Junto con la información del grupo enviamos la información del id Parent del grupo para actualizarlo y poder consultarlo al listar los modulos
    templateGroup.id = this.moduleParentInfo.id;
    this.addModuleEvent.emit(templateGroup);

  }

  removeModule(index){
    this.removeModuleEvent.emit(index);
  }
  ngOnInit() {

    console.log(this.moduleData);
    this.moduleParentInfoName = this.moduleParentInfo.moduleName;
    this.landingView = this.iframe.nativeElement.contentDocument || 
    this.iframe.nativeElement.contentWindow; // Asociamos iframe en el HTML a landingView que usaremos para cargar los diferentes modulos

    this.subscription = this.dataLandingService.landingSource.pipe(take(1)).subscribe((respond)=>{
      
      // En algunos casos se actualiza el contenido de la landing en el servicio antes de tener datos.. revisar, hacemos una check de una de las variables
      if(!respond.config.landingUse) return;

      this.currentLandingType = respond.config.landingUse;

      // Cada vez que hacemos un cambio en la landing pasamos por aquí, eliminamos el contenido previo en los iframes para no concatenar contenidos
      this.landingView.body.innerHTML = '';
      this.landingView.head.innerHTML = '';
      
      this.addContentToIframe(this.moduleData,respond.config.landingUse);

      // Pasamos el objeto Iframe al servicio para luego poder utilizarlo en las diferentes funciones
      this.iframeService.setLandingView(this.landingView);
      
      // Incluimos en el iframe que hemos creado vacio los assets necesarios, CSS, SCRIPTS, etc..
      this.iframeService.setCssScriptsToIframeModule(respond, this.landingView);

      // Configuramos el schema de color
      this.iframeService.setColorSchemaToIframe(respond.config.landingColor, this.landingView);

      // Configuramos el schema de color
      this.iframeService.setFontSchemaToIframe(respond.config.landingFont, this.landingView);

      // Calculamos la escala la preview del iframe del listado de componentes al inicializar la página
      this.widthPreviewComponent = this.previewComponent.nativeElement.offsetWidth;
      this.scaleIframe = this.widthPreviewComponent/1366;
      this.iframe.nativeElement.style.transform = "scale("+ this.scaleIframe +")";

    });


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addContentToIframe(_group:any, _type) {

    console.log(_group);

    // Buscamos el layout que corresponde
    let layoutType = TemplateLayouts['layouts'][_group.layout];
    const compFactory = this.resolver.resolveComponentFactory(layoutType);
    this.compRef = this.vcRef.createComponent(compFactory);
    this.compRef.instance.dataModule = _group.modules;
    this.compRef.instance.dataGroup = _group.moduleName;
    this.compRef.instance.previewComponent = true;

    this.landingView.body.appendChild(this.compRef.location.nativeElement);

  }

}
