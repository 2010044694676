import { Component, OnInit } from '@angular/core';
import {MessageService} from 'primeng/api';

export interface login {
  id?:string;
  email?: string;
  password?: any;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  providers: [MessageService],
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  login: login[] = [
    {
      id: '1',
      email: 'namesurname@inapp.com'
    },
    {
      id: '2',
      password: '123456'
    }
  ];

  clonedLogins: { [s: string]: login; } = {};

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
  }

  onRowEditInit(login: login) {
    this.clonedLogins[login.id] = {...login};
  }

  onRowEditSave(login: login) {
    this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid'});
  }

  onRowEditCancel(login: login, index: number) {
    this.login[index] = this.clonedLogins[login.id];
    //delete this.login[login.id];
  }

}
