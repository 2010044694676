<div class="c-form-editor">
  <div class="c-form-editor-title">Layout placeholder</div>
    <div class="c-form-editor-body">
      <div class="form-group" *ngIf="data.schema.subtitle">
        <app-input-simple [(data)]="data.schema.subtitle"></app-input-simple>
      </div>
      <div class="form-group" *ngIf="data.schema.title">
        <app-input-simple [(data)]="data.schema.title"></app-input-simple>
      </div>
      <ng-container *ngIf="data.schema.claim">
        <div class="c-form-editor-line"></div>
        <div class="c-form-editor-cab pb-10">Description</div>
        <div class="form-group" >
          <app-textarea-toolbar [(data)]="data.schema.claim"></app-textarea-toolbar>          
        </div>
      </ng-container>
      <ng-container *ngIf="data.schema.media">
          <div class="c-form-editor-body">
            <div class="c-form-editor-cab mb-15">Media content</div>
            <app-button-add-media [available]="'all'" [(data)]="data.schema.media" ></app-button-add-media>
          </div>
      </ng-container>
      <ng-container *ngIf="data.schema.textGroup">
        <div class="c-form-editor-line"></div>
        <div class="c-form-editor-cab pb-10" >Footer menu</div>
        <div class="form-group">
          <app-button-cta-repetible [(data)]="data.schema.textGroup"></app-button-cta-repetible>
        </div>
      </ng-container>
      <ng-container *ngIf="data.schema.textGroup">
        <div class="c-form-editor-line"></div>
        <div class="c-form-editor-cab pb-10">Featured content</div>
        <div class="form-group" *ngIf="data.schema.textGroup.items[0]['description']">
          <app-input-group-repetible [(data)]="data.schema.textGroup"></app-input-group-repetible>          
        </div>
        <div class="form-group" *ngIf="!data.schema.textGroup.items[0]['description']">
          <app-input-simple-group-repetible [(data)]="data.schema.textGroup"></app-input-simple-group-repetible>          
        </div>
      </ng-container>
      <ng-container *ngIf="data.schema.textGroupList">
        <div class="c-form-editor-line"></div>
        <div class="c-form-editor-cab pb-10">Featured content</div>
        <app-card-group-repetible [(data)]="data.schema.textGroupList" typeName="Featured" limit="6"></app-card-group-repetible>
      </ng-container>
    </div>
  </div>
