import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// Modules
import { ModuleGroupTextComponent } from './components/module-group-text/module-group-text.component';
import { ModuleTextComponent } from './components/module-text/module-text.component';
import { ModuleMediaComponent } from './components/module-media/module-media.component';

// Editors
import { EditorCtaAComponent } from './editors/editor-cta-a/editor-cta-a.component';

export const TemplateModules = {
        'modules':{
            'module-group-text':{
                'componentClass': ModuleGroupTextComponent,
                'editorClass': EditorCtaAComponent
            },
            'module-text':{
                'componentClass': ModuleTextComponent,
                'editorClass': EditorCtaAComponent
            },
            'module-media':{
                'componentClass': ModuleMediaComponent,
                'editorClass': EditorCtaAComponent
            }
        }
};