<div class="l-panel-editor">

  <!-- Panel Editor: Header -->
  <div class="l-panel-editor__header">
    <div class="l-panel-editor__header__title">Settings</div>
    <div class="l-panel-editor__header__actions">
      <app-save-button></app-save-button>
      <app-publish-button></app-publish-button>
    </div>
  </div>
  <!-- / Panel Editor: Header -->

  <!-- Panel Editor: Body -->
  <div class="l-panel-editor__body">

    <div class="scroll-wrap">

      <div class="l-panel-editor__body__content editor-cnt-mw">

        <div class="c-form-editor" *ngIf="dataLoaded">
          <!-- <div class="c-form-editor-title">Content</div> -->
          <div class="c-form-editor-body">
            <div class="form-group">
              <div class="c-form-editor-cab pb-10">Company Logo</div>
              <app-button-add-media [available]="'all'" [(data)]="landingContentUpdate.config.landingSettings.logo" ></app-button-add-media>
              <!-- <app-button-upload [(data)]="landingContentUpdate.config.landingSettings.logo"></app-button-upload> -->
            </div>
            <div class="c-form-editor-line"></div>
            <div class="form-group">
              <div class="c-form-editor-cab pb-10">Company Name</div>
              <app-input-simple [(data)]="landingContentUpdate.config.landingSettings.title"></app-input-simple>
            </div>
            <div class="c-form-editor-line"></div>
            <div class="c-form-editor-cab pb-10">Description</div>
            <div class="form-group">
              <app-input-simple [(data)]="landingContentUpdate.config.landingSettings.claim"></app-input-simple>
            </div>
          </div>
        </div>

        <!-- Module Box Request -->
        <div class="c-module-box no-bg mt-40" *ngIf="dataLoaded">
          <div class="c-module-box-title">Request Featured <span>- What do you wish XXXX could do or add?</span></div>
          <div class="form-group">
            <textarea class="form-control" id="commentRequestFeatured" rows="3" placeholder="Please include detail or some examples"></textarea>
          </div>
          <button type="submit" class="btn btn-gray">Send Request</button>
        </div>
        <!-- / Module Box Request -->

      </div>

    </div>

  </div>
  <!-- / Panel Editor: Body -->

</div>
  